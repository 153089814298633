import React, { useState,useEffect} from 'react'
import { Flex, Box, Text, Collapse, Tooltip } from "@chakra-ui/react"
import parse from 'html-react-parser'
import { SlArrowDown, SlArrowUp } from "react-icons/sl"
import { markdownToHtml } from './utils' 

const MatchedResults = ({ data,replaceSASUrls }) => {
    const [expanded, setExpanded] = useState(new Array(data.data.length).fill(false))

     const [inputHtmlContents, setInputHtmlContents] = useState([])
        
    useEffect(() => {
        const checkAndReplaceSasUrl = async () => {
        const updatedValues = await Promise.all(
            data.data.map(async (input) => {
                let input_html = markdownToHtml(input.text)
                if (/SASUrl/.test(input_html)) {
                    try {
                        return await replaceSASUrls(input_html)
                    } catch (error) {
                    console.error('Error fetching replacement url:', error)
                    }
                }
                return input_html 
            })
        )
        setInputHtmlContents(updatedValues)
        }

        checkAndReplaceSasUrl()
    },[data.data])
    
    const toggleExpand = (event, index) => {
        setExpanded(prev=> prev.map((result,i) => i == index? !result : result ))
    }
    return (

        <Flex direction='column' gap='15px' onClick={e => e.preventDefault()} overflowY='auto' overflowX='hidden'>
            {data.data.map((result,i) => {
                const score = Number.parseFloat(result.score * 100).toFixed(2)
                const isBelowThreshold = score < data.threshold
                const input_text= inputHtmlContents.length>0 ? new DOMParser().parseFromString(inputHtmlContents[i], 'text/html').body.textContent : ''
                return (
                    <Box key={result.id}>
                        <Flex justify='space-between' alignItems='center' p='14px 9px' bg={isBelowThreshold ? '#F7E9CE' : '#DEE8FC'} borderRadius='5px' onClick={(e)=> toggleExpand(e,i)}>
                            <Flex gap='5px' alignItems='center'>
                                <Text as='span' fontSize='14px' color={isBelowThreshold ? '#E79B04' : '#2563EB'}>{input_text.length > 55? `${input_text.slice(0, 55)} ...`:input_text} </Text>
                                <Flex gap='4px' alignItems='center' justify='center' padding='0px 8px' borderRadius='10px' border={`1px solid ${isBelowThreshold ? '#E79B04' : '#2563EB'}`}>
                                    <Text as='span' lineHeight='14px' fontSize="14px" color={isBelowThreshold ? '#E79B04' : '#000'}>{Number.parseFloat(result.score * 100).toFixed(0)}</Text>
                                </Flex>
                            </Flex>
                            {expanded[i] ? <SlArrowUp size='17px' color='#94A3B8'/> : <SlArrowDown size='17px' color='#94A3B8' />}
                        </Flex>
                        <Collapse in={expanded[i]}>
                            <Box border='1px solid #D9D9D9' borderTop='none' fontSize="14px" p='14px 9px' borderRadius='0 0 5px 5px' overflow='auto'>
                                <Box pl='10px'>{inputHtmlContents.length > 0 ? parse(inputHtmlContents[i]) : ''}</Box>
                            </Box>
                        </Collapse>
                    </Box>


            )}
          )}
    </Flex>
  )
}

export default MatchedResults