import { connect } from 'react-redux'

import init from './common/init'
import App  from './App'

const mapStateToProps = state => ({
    tenant:   state.tenant,
    identity: state.identity,
    appData:  state.appData,
})
const mapDispatchToProps = (dispatch, ownProps) => {
    const { config, history } = ownProps

    const { AppActions } = init({ config, history, dispatch })
    return AppActions
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
