import React,{useState,useEffect,useContext, createContext} from "react"
import { useServicesContext } from "services/apiServices"
import { WorkspaceContext } from "../../../commonComponents/Layout/Layout"
import { useQuery } from "react-query"


const CrawlContext = createContext()

export const CrawlContextProvider=({children})=>{
    const initialDrawerState = {isOpen:false,page:'crawlForm'}
    const initialModalState = { isOpen:false,message:'',callBack:null}
    const initialState={
        urls:[],
        refreshUrls:true,
        checkedUrls:[],
        isFetching:false,
        totalRecords:0,
        currentPage:1,
        itemsPerPage:100,
        selectedUrl:null,
        previewUrl:null,
        drawerState:initialDrawerState,
        modalState:initialModalState
    }
    const [globalCrawlState,setGlobalCrawlState] = useState(initialState)
    const {apiCall,createOrUpdateAsync,globalState,updateCrawlUrls,triggerRefetchTotalRecords} = useServicesContext()
    const {workspaceMetadata} = useContext(WorkspaceContext)
    const top = globalCrawlState.currentPage * globalCrawlState.itemsPerPage
    const skip = top - globalCrawlState.itemsPerPage

    const transformDate = date => {
        const utcDateString = date
        const utcDate = new Date(utcDateString)
        const localDate = new Date(utcDate.getTime())
        const options = {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }
        return localDate.toLocaleString('en-US', options)
    }

    const getUrls=()=>{
        return apiCall({
         options: {
          url: `InputFiles/getFiles`,
          method: "GET",
          variables:'url',
          query:`$filter=(docType eq 'url' and crawlStatus eq 'Published')&$skip=${skip}&$top=${globalCrawlState.itemsPerPage}&$orderby=updatedAt desc`
          },
       })
      }
    const { isFetching, data} = useQuery(['urls',workspaceMetadata],getUrls,{enabled:globalCrawlState.refreshUrls})

    useEffect(()=>{
        setGlobalCrawlState(prevState=>({...prevState,isFetching}))
      },[isFetching])

    useEffect(()=>{
        if(data){
            const fileTypesFilter = data?.data.rows.map(item=>({...item,createdAt:transformDate(item.createdAt),updatedAt:transformDate(item.updatedAt),}))
            setGlobalCrawlState((prev)=>({...prev,totalRecords:data?.data.count,urls:fileTypesFilter,refreshUrls:false}))
        }
        updateCrawlUrls()
      },[data])
    
    useEffect(()=>{
        setGlobalCrawlState(prevState=> ({...prevState,refreshUrls:globalState.refetchUrls}))
    },[globalState.refetchUrls]) 

    useEffect(()=>{
        setGlobalCrawlState(prevState=>({...prevState,refreshUrls:true}))
    },[globalCrawlState.currentPage,globalCrawlState.itemsPerPage,workspaceMetadata])

    const handleCheckAllUrls = (event) =>{
        const isChecked = event.target.checked
        const listedUrls=globalCrawlState.urls.filter(e=>{return e.workspace===workspaceMetadata})
        if(isChecked) 
            setGlobalCrawlState(prevState=>({...prevState,checkedUrls:listedUrls.map(item=>item.id)}))
        else 
            setGlobalCrawlState(prevState=>({...prevState,checkedUrls:[]})) 
    }

    const handleCheckboxChange = (event) => {
        const url = event.target.value
        const isChecked = event.target.checked
        if (isChecked) {
          !globalCrawlState.checkedUrls.includes(url) && setGlobalCrawlState((currentState)=>({...currentState,checkedUrls:[...currentState.checkedUrls, url]}))
        } else {
            setGlobalCrawlState((currentState)=>({...currentState,checkedUrls:currentState.checkedUrls.filter((f) => f !== url)}))
        }
    }

    const onCreatePublishSuccess = () =>{
        setGlobalCrawlState(prevState=>({...prevState,refreshUrls:true,drawerState:{...prevState.drawerState,page:'success',text:'Selected URLs have been added to the queue and will be crawled shortly. This generally takes about 30 minutes, depending on the workload and performance of the system'}}))
        triggerRefetchTotalRecords()
    }
    const onCreatePublishFailure = () =>{
        setGlobalCrawlState(prevState=>({...prevState,refreshUrls:false,drawerState:{...prevState.drawerState,page:'fail',text:'Publishing of selected URLs failed'}}))
    }

    const onEditUrlSuccess = () =>{
         setGlobalCrawlState(prevState=>({...prevState,refreshUrls:true,drawerState:{...prevState.drawerState,page:'success',text:'Your changes have been saved successfully'}}))
         triggerRefetchTotalRecords()
    }
    const onEditUrlFailure = () =>{
        setGlobalCrawlState(prevState=>({...prevState,refreshUrls:false,drawerState:{...prevState.drawerState,page:'fail',text:'Your changes could not be saved'}}))
    }

    const onAddUrlSuccess = ({previewUrl}) => {
        setGlobalCrawlState(prevState=>({...prevState,drawerState:{...prevState.drawerState,page:'previewUrl',isPreview:false,previewUrl}}))
    }

    const onAddUrlFailure = (error) =>{
        setGlobalCrawlState(prevState=>({...prevState,drawerState:{...prevState.drawerState,page:'fail',text:`Failed to fetch Urls due to  ${error}`}}))
    }

    const onDeleteSingleUrlSuccess = ()=>{
        setGlobalCrawlState(prevState=>({...prevState,refreshUrls:true}))
    }

    const updateItemsPerPage = val => setGlobalCrawlState(prevState=>({...prevState,itemsPerPage:val,currentPage:1}))
    const updateCurrentPage = val => setGlobalCrawlState(prevState=>({...prevState,currentPage:val}))

    const handleEditUrl = id => {
        setGlobalCrawlState(prevState=>({...prevState,selectedUrl:id,drawerState:{isOpen:true,page:'editUrl'}}))
    }
    
    const handlePreviewUrl = (id) => {
        setGlobalCrawlState(prevState=>({...prevState,selectedUrl:id,drawerState:{isOpen:true,page:'previewUrl',isPreview:true}}))
    }
    const handleDeleteUrl = (id) => {
        setGlobalCrawlState(prevState=>({...prevState,selectedUrl:id,modalState:{isOpen:true,message:`Are you sure to you want to delete ${globalCrawlState.urls.find(url=>url.id===id).docName}?`,callBack:()=>deleteSingleUrl(id)}}))
    }

    const handleBulkDeleteOpen=()=>{
        setGlobalCrawlState(prevState=>({...prevState,modalState:{
            isOpen:true,
            message:`Are you sure to you want to delete ${globalCrawlState.checkedUrls.length===1 ? globalCrawlState.urls.find(url=>url.id===globalCrawlState.checkedUrls[0]).docName: `${globalCrawlState.checkedUrls.length} urls` }`,
            callBack:bulkDelete
        }}))
    }

    const openDrawer = () =>{
        setGlobalCrawlState(prevState=>({...prevState,drawerState:{...prevState.drawerState,isOpen:true}}))
    }

    const closeDrawer = () =>{
        setGlobalCrawlState(prevState=>({...prevState,drawerState:initialDrawerState}))
    }

    const closeDeleteModal = () =>{
        setGlobalCrawlState(prevState=>({...prevState,selectedUrl:null,modalState:initialModalState}))
    }

    const deleteSingleUrl = async (id)=>{
        deleteUrlAction([id])
    }
    const bulkDelete= ()=>{
        deleteUrlAction(globalCrawlState.checkedUrls)
    }

    const deleteUrlAction = async (ids) => {
        const data= {
            id:ids,
            docName:globalCrawlState.urls.filter(item=>ids.includes(item.id)).map(item=>item.docName)
        }
        try{
            const response= await createOrUpdateAsync({url:'InputFiles/delete_baseurl',method:'PUT',variables:'deleteUrl'},data)
            if(response.status===200){
                setGlobalCrawlState(prevState=>({...prevState,checkedUrls:[],refreshUrls:true,modalState:initialModalState}))
                triggerRefetchTotalRecords()
            }
        }
        catch(error){
            setGlobalCrawlState(prevState=>({...prevState,checkedUrls:[],modalState:initialModalState}))
        }
    }
    return(
        <CrawlContext.Provider value={{globalCrawlState,handleCheckAllUrls,handleCheckboxChange,onCreatePublishSuccess,onCreatePublishFailure,onEditUrlSuccess,onEditUrlFailure,onAddUrlSuccess,onAddUrlFailure,onDeleteSingleUrlSuccess,updateItemsPerPage,updateCurrentPage,handleEditUrl,handlePreviewUrl,handleDeleteUrl,handleBulkDeleteOpen,openDrawer,closeDrawer,closeDeleteModal}}>
            {children}
        </CrawlContext.Provider>
    )
}

export const useCrawlContext=()=> {
    return useContext(CrawlContext)
  }