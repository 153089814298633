import { AlertActions, config, history } from './init'

import { alertReducers } from './alert'
import { AppReducers   } from './app'
import { SidebarReducers } from './components/sidebar'
import { ServiceHelper   } from './helpers/ServiceHelper'
import { Permissions     } from './helpers/Permissions'
import { identityReducers } from './identity'
import i18n, { createI18n } from './infra/i18n'
import  ListLayout  from './components/ListLayout'
import DetailComponent from './components/DetailComponent'
import TabComponent from './components/TabComponent'
import * as userSettingHooks from './userSetting/apiHooks/userSetting'
// import TestComponent from './components/TestComponent/TestComponent'

// import { AuditLogs } from './auditLog/auditLogList.container'
// import { AuditLogServiceInst } from './auditLog/auditLogList.services'
// import Aside from './components/Aside'
// import Footer from './components/Footer'

// Components
// import Header from './components/Header'
// import Loader from './components/Loader'
// import ProfileComponent from './components/ProfileComponent'
// import Sidebar from './components/Sidebar'

// Constants
// import ErrorPage from './containers/ErrorPage'
// import Home from './containers/Home'
// import Logout from './containers/Logout'

// Containers
// import MainLayout from './containers/MainLayout'
// import PrivateRoute from './containers/PrivateRoute'
// import PrivateRouteCustom from './containers/PrivateRouteCustom'
// import Root from './containers/Root'
// import Settings from './containers/Settings'
// import RegisterContainer from './containers/RegisterContainer'
// import ProfileContainer from './containers/userData.container'
// import * as utils from './helpers/utils'


// let _config, _history, AppActions, AlertActions, IdentityActions, IdentityApi, routeList1, rootRouter1, localeLoader1, i18n

// Reducers

// Services
// const getConfig = () => {
// 	return _config
// }
// const getHistory = () => {
// 	return _history
// }
// const getRouteObjects = () => {
// 	return { routeList: routeList1, RootRouter: rootRouter1, localeLoader: localeLoader1 }
// }
// const getI18nInst = () => {
// 	return i18n
// }

// Exports
// --------------------

// export * from './analytics'
// export * from './entity'

const reducers = {
	...alertReducers,
	...identityReducers,
	...AppReducers,
	...SidebarReducers,
}

const persistentWhitelist = [
	'identity',
	'sidebar',
	'botStudio'
]

export {
	// For redux store
	persistentWhitelist,

	// Reducers
	reducers,

	// Services
	ServiceHelper,
	Permissions,

	// Actions
	AlertActions,

	// Utils
	config,
	history,
	createI18n,
	i18n,
	ListLayout,
	DetailComponent,
	TabComponent,
	userSettingHooks
}


// AuditLogServiceInst,
// Components
// ProfileComponent,
// Header,
// Aside,
// Sidebar,
// Footer,
// RegisterComponent,
// Loader,
// Containers
// AuditLogs,
// Root,
// Home,
// MainLayout,
// Settings,
// PrivateRoute,
// PrivateRouteCustom,
// Logout,
// ErrorPage,
// RegisterContainer,
// ProfileContainer,
// infra

// utils,
// getRouteObjects,
// getI18nInst,