import PropTypes from 'prop-types'

const propTypes = {
    getTableProps:         PropTypes.func,
    getTableBodyProps:     PropTypes.func,
    theme:                 PropTypes.object,
    headerGroups:          PropTypes.array,
    headerBgColor:         PropTypes.string,
    CHECKBOX_COL_NAME:     PropTypes.string,
    isAllRowsSelected:     PropTypes.bool,
    selectedFlatRows:      PropTypes.array,
    toggleAllRows:         PropTypes.func,
    rows:                  PropTypes.array,
    totalCount:            PropTypes.number,
    preGlobalFilteredRows: PropTypes.array,
    isLoadingMoreData:     PropTypes.bool,
    isRefetchingData:      PropTypes.bool,
    isItemLoaded:          PropTypes.func,
    loadMoreItems:         PropTypes.func,
    prepareRow:            PropTypes.func,
    tableHeight:           PropTypes.number,
    getRowSize:            PropTypes.func,
}

export default propTypes