/*
 *   TO ADD A NEW FORMATTER:
 *      1. Import
 *      2. Add to the components object
 *      3. Optional: Add to exports if necessary
 *
 *   Rest of the processing and validation will
 *   be taken care of on compile
 *
 */

import Badge      from './Badge'
import Chips      from './Chips'
import Timestamp  from './Timestamp'
import SimpleText from './SimpleText'

import _ from 'lodash'

const Formatters = {}
const components = {
    Badge,
    Chips,
    Timestamp,
    SimpleText,
}

_.each(components, component => {
    const { type } = component
    const existingFormatter = Formatters[component.type]

    if (existingFormatter) {
        const conflicts = `[${existingFormatter.name}, ${component.name}]`
        throw new Error(`Formatters must have unique string types! ${conflicts} both have type '${type}'`)
    }

    Formatters[component.type] = component
})

export {
    SimpleText,
}
export default Formatters