import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import configureHistory from './history'
import { persistentWhitelist } from '../common'

const configureStore = (config, rootReducer) => {
    const persistConfig = {
        key: 'root',
        storage: storage,
        whitelist: [
            ...persistentWhitelist,
        ],
    }

    const middleware = [thunk]
    const enhancers  = []
    let composeEnhancers = compose

    if (process.env.NODE_ENV === 'development') {
        const { disableReduxLogger } = config
        if (!disableReduxLogger) {
            const { logger } = require(`redux-logger`)
            middleware.push(logger)
        }

        if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        }
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    let store = createStore(persistedReducer, composeEnhancers(
        applyMiddleware(...middleware),
        ...enhancers
    ))
    let persistor = persistStore(store)

    const history = configureHistory(config)

    return { store, persistor, history }
}

export default configureStore