import _ from 'lodash'

const containsFilter = (rows, id, filterText) => {
    const filteredRows = rows.filter(row => {
        const value = _.isArray(id) ? row.values[id[0]] : row.values[id]

        if (_.isString(value)) {
            return value.match(filterText)

        } else if (_.isArray(value)) {
            return value.some(val => (_.isString(val) && val.match(filterText)))
        }
        return false
    })
    return filteredRows
}

export default containsFilter