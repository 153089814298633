import { useState, createContext, useContext,useRef,useEffect} from 'react'
import { Stack, Text, Flex, Button, Box, useToast } from "@chakra-ui/react"
import marked from 'marked'
import { TbCircleCheck } from "react-icons/tb"
import { GrFormClose } from "react-icons/gr"
import { useServicesContext } from "services/apiServices"
import AppContext from 'AppContext'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { WorkspaceContext } from 'app/commonComponents/Layout/Layout'
import config from '../../../../config/index'

const KnowledgeGapsContext = createContext(null)

const initialDrawerState = {
    isOpen:false,
    page:'home',
    id:null,
    data:null,
  }
  const initialModalState = {
    chatData:null,
    isFetching:false,
    isOpen:false,
    chatSessionId:null,
    fetchChatData:false
  }

const initialState = {
    gaps: [],
    query_responses:[],
    drawerState: initialDrawerState,
    page:'list',
    selectedGap:null,
    refreshKnowledgeGaps: true,
    fetchQueryResponse:true,
    isFetchingGaps:false,
    modalState:initialModalState
}

export function KnowledgeGapsProvider({children }) {
    const {apiCall,triggerRefetchTotalRecords,triggerRefetchArticles,triggerRefetchDocuments,triggerRefetchUrls,openArticleDetailPage,openTab } = useServicesContext()
    const {identity } = useContext(AppContext)
    const tenantId = identity.profile.tenantUid
    const [globalState, setGlobalState] = useState(initialState)
    const toast = useToast()
    const toastIdRef = useRef()
    const {createOrUpdateAsync} = useServicesContext()
    const {setActive} = useContext(WorkspaceContext)
    const history = useHistory()

    const setSelectedGap = (id)=>{
        setGlobalState(prevState => ({...prevState,selectedGap:id}))
    }

    const deleteGap =  async () => {
        const data=[globalState.selectedGap]
        const response = await createOrUpdateAsync({url:'botmgmt-service/train/deleteAll',method:'POST'},data)
        if(response){
            setGlobalState(prevState => ({...prevState,gaps:prevState.gaps.filter((gap,index)=>index!==prevState.selectedGap),refreshKnowledgeGaps:true,selectedGap:null}))
        }
        else{
            setGlobalState(prevState => ({...prevState,selectedGap:null}))
        }
    }
    
    const fixGap=async (FixingMethod,fileName)=>{
        const data={
            failedUtteranceOperationId:globalState.selectedGap,
            mappedTo:null,
            qnaAppId: null,
            trainedAs: fileName,
            trainingStatus:`Trained as ${FixingMethod}`,
            type:"genQNA",
            status:'Deleted'
        }
        return await createOrUpdateAsync({url:'botmgmt-service/train/updateTrainStatus',method:'POST'},data)
    }

    const onFixSuccessWithArticle = async (variables) => {
        const fileName = variables?.payload?.dbData?.title
        const response = await fixGap('Knowledge article',fileName)
        const message ='knowledge article'
        const callbackFn = ()=> openArticleDetailPage(variables?.payload?.dbData?.id)
        onFixSuccess(message,callbackFn)
        triggerRefetchArticles()
        triggerRefetchTotalRecords()
    }

    const onFixSuccessWithDocument = async (variables) => {
        const response = await fixGap('document',variables)
        const message ='document'
        const callbackFn = ()=> openTab('document')
        onFixSuccess(message,callbackFn)
        triggerRefetchDocuments()
        triggerRefetchTotalRecords()
    }

    const onFixSuccessWithUrl = async (variables) => {
        const message ='url'
        const response = await fixGap('Crawl Url',variables)
        const callbackFn = ()=> openTab('crawlUrl')
        onFixSuccess(message,callbackFn)
        triggerRefetchUrls()
        triggerRefetchTotalRecords()
    }

    const onFixSuccess = (message,cb) =>{
        const utterance = globalState.gaps.find(gap=>gap.id===globalState.selectedGap).utterance
        // if(selectedGapItem){
        //   const utteranceItem=globalState.gaps.filter(item=>item.id===selectedGapItem)
        //   utterance=utteranceItem[0].utterance
        // }
        const displayToast = () => {
            toastIdRef.current = toast({
                position: 'top-right',
                duration:null,
                render: () => (
                    <Flex w='458px' p='16px' gap='16px' alignItems='flex-start' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)' bg='#fff' borderRadius='8px'>
                        <Flex alignItems='flex-start' gap='12px' grow={1}>
                            <Flex padding='8px' bg='#DFF7E9' borderRadius='24px'>
                                <TbCircleCheck size='24px' color='#34D399' />
                            </Flex>
                            <Flex direction='column' alignItems='flex-start' gap='8px' pt='2px' grow={1}>
                                <Box fontWeight='500' >
                                    <Text as='span' style={{ color: '#111827' }}>{`${utterance}`}</Text>
                                    <Text as='span' color='#6B7280'> has been sucessfully added to {message}</Text>
                                </Box>
                                <Button variant='link' color='#2563EB' fontSize='14px' fontWeight='normal'
                                    onClick={() => goToKnowledge(cb,message)}>Go to {message}</Button>
                            </Flex>
                        </Flex>
                        <GrFormClose cursor='pointer' size='32px' color='#9CA3AF' onClick={closeToast}
                        />
                    </Flex>
                )
            })
        }
        setGlobalState(prevState => ({...prevState,selectedGap:null,gaps:prevState.gaps.map((record)=> {
            if(prevState.selectedGap===record.id){
                record.fixed=!record.fixed
            }
            return record
        })}))
        setTimeout(displayToast, 1000)
        setTimeout(closeToast,5000)
        closeDrawer()
    }

    const onFixFailure = () => {
        setGlobalState(prevState => ({...prevState,selectedGap:null}))
        closeDrawer()
    }

    const goToKnowledge = (cb,message) => {
        closeToast()
        cb()
        if(message === 'document') {
            history.push('/aiconsole/documents')
            setActive(1.11)
        }
        if(message === 'url') {
            history.push('/aiconsole/urls')
            setActive(1.13)
        }
        if(message === 'knowledge article') {
            history.push('/aiconsole/articles')
            setActive(1.14)
        }
    }

    const closeToast = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
            setGlobalState(prev=>({...prev,refreshKnowledgeGaps:true}))
        }
    }
    
    const openDrawer = ({page,id,data,isSuccess,isPreview,message}={}) =>{
        const newDrawerState = {
           isOpen:true,
        }
        if(page !== null && page !== undefined)
            newDrawerState['page'] = page

        if(id !== null && id !== undefined)
            newDrawerState['id'] = id
  
        if(data !== null && data !== undefined)
          newDrawerState.data= data
  
        if(isSuccess !== null && isSuccess !== undefined)
          newDrawerState.isSuccess= isSuccess
  
        if(message !== null && message !== undefined)
          newDrawerState.message= message
  
        if(isPreview !== null || isPreview !== undefined)
          newDrawerState.isPreview= isPreview
       
        setGlobalState(prevState=>({...prevState,drawerState:{...prevState.drawerState,...newDrawerState}}))
    }
  
    const closeDrawer = () =>{
        setGlobalState(prevState=>({...prevState,drawerState:{...initialDrawerState}}))
    }

    const reviewChat = id => {
        setGlobalState(prevState=>({...prevState, modalState:{...globalState.modalState,chatSessionId:id, fetchChatData:true, isOpen:true}}))
    }
  
    const closeChatModal = () => {
        setGlobalState(prevState=>({...prevState, modalState:{...globalState.modalState,isOpen:false}}))
    }

    const getKnowledgeGaps = () => apiCall({
        options: {
        url: `botmgmt-service/train/findAndCountAll`,
        method: "GET",
        query:`$filter=(status eq 'Active')&$skip=${0}&$top=${100000}&$orderby=createdAt desc`
        },
    })
    
    const {isFetching:isFetchingGaps,data:gapData} = useQuery('knowledgeGaps',()=>getKnowledgeGaps(),{enabled:globalState.refreshKnowledgeGaps})
    
    useEffect(()=>{
        if(gapData){
            setGlobalState(prev=>({...prev,gaps:gapData.rows.map(item=>{return ({...item,fixed:false})}),refreshKnowledgeGaps:false}))
        }
    },[gapData])

    useEffect(()=>{
            setGlobalState(prev=>({...prev,isFetchingGaps}))
    }, [isFetchingGaps])
    
    const fetchQueryResponses = () =>
        apiCall({
        options: {
            url: `aiquery/getAiquery`,
            method: "GET",
            query:`$filter=failedUtteranceId ge 0&$skip=${0}&$top=${100000}&$orderby=createdAt desc`
        },
    })

    const {data: queryResponse } = useQuery(['aiquery'], () => fetchQueryResponses(), { enabled: globalState.fetchQueryResponse })
    
    const fetchChatData = async (id) => {
        let chatData
        try {
            chatData = await apiCall({
                options: {
                    url: `botmgmt-service/train/getChatMessages/${globalState.gaps.find(gap => gap.id === id).sessionId}`,
                    method: "GET",
                },
            })
        } catch (error) {
            console.error(`Error fetching chat data for session id ${id}: ${error.message}`)
        }
        return chatData
    }

    useEffect(() => {
        if (queryResponse) {
            setGlobalState(prev=>({...prev,query_responses:queryResponse.rows,fetchQueryResponse:false}))
        }
    }, [queryResponse])
    function render_content () {
      let render = new marked.Renderer()
      render.list = function (body, ordered, start) {
        let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        if (ordered) {
          return `<ol>${newtext}</ol>`
        } else {
          return `<ul>${newtext}</ul>`
        }
      }

      render.blockquote = function (quote) {
        let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        return `<blockquote>${newtext}</blockquote>`
      }
      render.code = function (string) {
        return string
      }
      render.image = function (href, title, text, alt, height, width) {
        var newImage = `<img src="${href}" alt="Image" title="Image">`
        return newImage
      }
      return render
    }

    function markdownToHtml(markdownString) {
        const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
        const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
        const markdown_with_replaced_video = markdownString.replace(/\[video\](.*?)\[\/video\]/g, "<video src='$1' controls></video>")
        
        let contentStateeditor = marked(markdown_with_replaced_video, { renderer: render_content() })
        
        contentStateeditor = contentStateeditor.replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        contentStateeditor = contentStateeditor.replace(/\\n/g, '\n')
        .replace(/(\[button\]Next\[\/button\]\[pagebreak\/\])/g, '---------------')
        .replace(/(\[button\]Next\[\/button\])/g, '')
        .replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        .replace(/(\[linebreak\/\])/g, '<br>')
        .replace( /<p><video\s*src=['"]([^'"]+)['"] controls><\/video><\/p>/g,"<video src='$1' controls></video>")    
        // .replace(/<video[^>]*>([\s\S]*?)<\/video>/gi, `![image](${config.cdnUri}/cdn/thumbnail.png)`)
        .replace(/^(\d+\.)\s*(.*?)$/gm, "<br>$1$2")

        contentStateeditor = replaceAll(contentStateeditor, '<p></p>)', '')
        if (! contentStateeditor.includes('$schema'))  contentStateeditor =  contentStateeditor.replace(/\{.*?\}/g, '')
        else  contentStateeditor =  contentStateeditor.replace(/\\+([\[\]\*_])/g, '$1')

        let imagePixel = []
        let splitImgTag = contentStateeditor.split('"Image">')
        if (splitImgTag.length > 1) {
            splitImgTag.forEach(e => {
            let reg = e.split('}')[0].split('{')[1]
            if (reg) imagePixel.push(`style="${reg.replaceAll('=', ':').split(' ').join(';').concat(';')}"`)
            })
            if (imagePixel.length) {
            contentStateeditor = contentStateeditor
                .split('title="Image">')
                .map((x, idx) => {
                return x.concat(`"Image" ${imagePixel[idx]} >`)
                })
                .join('\n')
                .replace(/\{.*?\}/g, '')
                .replace('"Image" undefined >', '')
            }
        }
        contentStateeditor = replaceAll(contentStateeditor, '"Image" undefined >', '')
        return contentStateeditor
    }


    const replaceSASUrls = async (htmlContent) => {
        try {
             const sasToken = await apiCall({
                options: {
                url: `InputFiles/getSASToken`,
                method: "GET",
                query:`key=${config.containerKey}`
                }
            })
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = htmlContent
            const elementsToReplace = tempDiv.querySelectorAll(`[src*="SASUrl"], [href*="SASUrl"]`)

            elementsToReplace.forEach(element => {
                const originalUrl = decodeURIComponent(element.getAttribute('src') || element.getAttribute('href'))
                const fileName = originalUrl.match(/SASUrl\[(.*?)\]/)[1]
                const replacedUrl = `${config.cdnUri}/${tenantId}-${config.containerKey}/${fileName}?${sasToken.data.token}`  

                if (element.hasAttribute('src'))
                    element.setAttribute('src', replacedUrl) 
                if (element.hasAttribute('href'))
                    element.setAttribute('href', replacedUrl) 
            })
            return tempDiv.innerHTML
        } catch (error) {
            console.error(`There was an error in fetching SAS Token: `, error.message)
            return htmlContent
        }
    }

    return (
        <KnowledgeGapsContext.Provider value={{globalState,openDrawer,closeDrawer,onFixSuccessWithArticle,onFixFailure,setSelectedGap,onFixSuccessWithDocument,deleteGap,onFixSuccessWithUrl,reviewChat,closeChatModal,fetchChatData,markdownToHtml,replaceSASUrls}}>
            {children}     
        </KnowledgeGapsContext.Provider>
    )
}

export function useKnowledgeGapsContext() {
    return useContext(KnowledgeGapsContext)
}
