import {useState, useEffect, useCallback} from 'react'
import Select from 'react-dropdown-select'
import {Box} from '@chakra-ui/react'

const MultiSelect = (props)=>{
    const {options, name,value, width , fieldData, isMulti,style} = props
    const [multiVal, setMultiVal] = useState([])
    useEffect(() =>{
        if(fieldData) setMultiVal(fieldData)
    },[])
    const handleMultiSelect = useCallback((value,name)=>{
        props?.onChange(value, props?.name )
    }, [value, props?.onChange])

    return (
        <Box className='queue-dropdown' w={width || '100%'}>
            <Select
                onChange={handleMultiSelect}
                style={style}
                multi = {isMulti}
                options={options}
                values={((multiVal && multiVal.length>0 && multiVal) || value || [])}
                isSearchable={true}
                placeholder={props?.placeholder || 'Select ...'}
                closeOnSelect={true}
                dropdownHandleRenderer={props.dropdownHandleRenderer}
                // color='#E0E7FF'
            />
        </Box>
    )
}


export default MultiSelect