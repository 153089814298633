import { useState, useCallback } from 'react'
import { 
    EditorIcon } from './default'

import UploadModal from './uploadModal'

const Upload = (props)=>{
   const [modal, setModal] = useState(false)

   const toggle = useCallback(()=> {
    setModal(!modal)
   }, [modal])
   const iconConfig = { toggle: toggle, icon: props.icon || 'file' }
   return(
        <div className="rdw-image-wrapper">
        <EditorIcon
            {...iconConfig}
        />
        {modal && <UploadModal
            {...props}
            // mapUrl={mapUrl}
            // uploadError={uploadError}
            toggle={toggle}
        />
        }
    </div>
   )
}

export { 
    Upload
}