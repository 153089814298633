import _ from 'lodash'

type FilterFn = (key: string) => boolean

const filterProps = (props: object, filterFn: FilterFn) => {
    const filteredProps = {}
    _.each(props, (value, key) => {
        if (filterFn(key)) {
            filteredProps[key] = value
        }
    })

    return filteredProps
}

export default filterProps