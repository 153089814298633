import React, { useContext } from 'react'
import _ from 'lodash'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    ButtonGroup,
} from '@chakra-ui/react'
import ThemeContext from '../theme/ThemeContext'

const BaseModal = ({ isOpen, trapFocus, title, size = "md", onClose, onCloseText = "Close", buttons, children, onCloseButton }) => {
    const theme = useContext(ThemeContext)

    return (
    <Modal isOpen={isOpen} trapFocus={trapFocus} isCentered size={size} closeOnOverlayClick={!!onClose} onClose={onClose}>
        <ModalOverlay />
        <ModalContent textAlign="center" py={4}>
            { title &&
            <>
            <ModalHeader mb={2}>{title}</ModalHeader>
            { onClose &&
            <ModalCloseButton />
            }
            </>
            }
            <ModalBody>
                <Flex direction="column" gridRowGap={4} alignItems="center">
                    { children }
                </Flex>
            </ModalBody>
            <ModalFooter bg={theme.bg2} textAlign="center" borderBottomRadius="md" justifyContent={buttons ? "space-between" : "space-around"}>
                { (onClose && onCloseButton) &&
                <Button colorScheme="blue" onClick={onClose}>{onCloseText}</Button>
                }
                { buttons &&
                <ButtonGroup>
                    { _.map(buttons, ({ text, colorScheme, onClick, variant, style }) => (
                        <Button style={style} colorScheme={colorScheme} variant={variant} onClick={onClick}>{text}</Button>
                    ))}
                </ButtonGroup>
                }
            </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export default BaseModal