import { Flex, useColorModeValue, useToast } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { AtomicBlockUtils, ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import marked from 'marked'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as Plugins from './customPlugins'
import * as editorHelper from './helper'
import { defaultToolbar } from './toolbar'

const dynamicStyle = ({ stylesProp }) => (stylesProp.minHeight ? { minHeight: stylesProp.minHeight === 'note' ? '6rem' : 'auto' } : '')

const EditorStyled = styled.div`
.rdw-editor-main .DraftEditor-editorContainer {
  ${dynamicStyle};
}
.public-DraftStyleDefault-block {
  margin: 1em 0 !important;
  color:#676767;
  bgcolor:red;
  colorScheme='blue

}
`
const TextEditor = ({
    name,
    value,
    uploadVideo,
    uploadImage,
    uploadAttament,
    uploadLink,
    colorPicker,
    customToolbar,
    editorOnChange,
    uploadVideoCallback,
    uploadImageCallback,
    uploadFileCallback,
    uploadLinkCallback,
    minHeight,
    foreColor,
    bgColor,
    outputFormat,
    icons,
    uploadOptions,
    index,
    mention,
    editorStyle,
    toolbarStyle,
    wrapperStyle,
    wrapperClassName,
    editorClassName,
    toolbarClassName,
    imageSize,
    dataSuggestions,
    caseId,
    uploadCopyPasteCallback,
    entityType,
    isUploading
}) => {
    //const [editorReferece, setEditorReferece] = useState(null)
    const [editorStateValue, setEditorStateValue] = useState(EditorState.createEmpty())
    // const [editorState, setEditorState] = useState(
    //     () => EditorState.createEmpty(),
    //   )
    const editorRef = useRef(name)
    const bg = useColorModeValue('blue.4', '#1D1D1D')
    // const color = useColorModeValue('white', 'gray.800')
    const color = useColorModeValue('gray.500', 'gray.100')
    let toolbar = customToolbar
        ? customToolbar
        : defaultToolbar({
              uploadVideo: uploadVideo,
              uploadImage: uploadImage,
              uploadAttament: uploadAttament,
              uploadLink: uploadLink,
              colorPicker: colorPicker || false,
              uploadVideoCallback,
              uploadImageCallback,
              uploadFileCallback,
              uploadOptions,
              uploadLinkCallback,
          })
    // const fnEditorReference = ref => {
    //   setEditorReferece(ref)
    //   if (ref) ref.focus()
    // }

    const getEditorState = useCallback(() => {
        let data = editorHelper.convertCustomTags(value, true, icons)
        let drafVal
        if (data) {
            let contentStateeditor = marked(data, { renderer: editorHelper.renderContent() })
            let contentBlock = htmlToDraft(contentStateeditor, (nodeName, node) => editorHelper.renderAttachment(nodeName, node))
            let contentState = ContentState.createFromBlockArray(contentBlock)
            drafVal = EditorState.createWithContent(contentState)
        }
        return drafVal
    }, [value, icons])

    useEffect(() => {
        let drafVal = getEditorState(value || ' ')
        setEditorStateValue(drafVal)
    }, [value, getEditorState])

    const handleTextChange = useCallback(
        async value => {
            let outputVal = draftToHtml(value, {}, false, editorHelper.customEntityTransform) || value
            if (outputFormat === 'markdown') outputVal = await editorHelper.convertToOutput({ value: outputVal })
            editorOnChange(name, outputVal, index)
        },
        [editorOnChange, outputFormat, name, index]
    )

    const onEditorStateChange = useCallback(editorState => {
        setEditorStateValue(editorState)
    }, [])

    const customComponent = []
    let customComponents = toolbar && toolbar.customComponents
    if (customComponents && Array.isArray(customComponents) && customComponents.length > 0) {
        customComponents.forEach((component, idx) => {
            if (component.renderComponent && Plugins[component.renderComponent]) {
                let Plugin = Plugins[component.renderComponent]
                customComponent.push(<Plugin key={idx} {...component} />)
            }
        })
    }
    let config = {
        toolbar: toolbar,
        onChange: handleTextChange,
        toolbarCustomButtons: customComponent || [],
    }
    const toast = useToast()

    const handlePastedImage = file => {
        if(caseId){
            var reader = new FileReader()
            reader.readAsDataURL(file[0])
            // Accepted Image Format
            const imageType = ['image/png', 'image/jpeg', 'image/jpg']
            if (imageType.includes(file[0]?.type)) {
                if(file[0]?.size <= imageSize * 1000000){
                isUploading(true)
                uploadCopyPasteCallback(file[0]).then(function(res){
                    // Returns handling Promise
                    const entityData = { ...res.data }
                    const type = entityType || 'IMGLINK'
                    if (type==='IMAGE') {
                        entityData.src = res?.data?.doc
                        entityData.alt = res.data.linkName
                    }
                    const entityKey = editorStateValue.getCurrentContent().createEntity(type, 'MUTABLE', entityData).getLastCreatedEntityKey()
                    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorStateValue, entityKey, ' ')
                    setEditorStateValue(newEditorState)
                    isUploading(false)
                    return newEditorState
                }).catch(err=>{
                    isUploading(false)
                    console.log(err)
                })
            }else{
                toast({
                    title: 'File Not Supported',
                    description: `Please Upload Image not more than ${imageSize}MB`,
                    status: 'error',
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                })
            }
            
            } else {
                toast({
                    title: 'File Not Supported',
                    description: `Only png, jpeg and jpg format are supported, please try again `,
                    status: 'error',
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                })
            }
        }else{
            toast({
                title: 'Please Upload the image once the ticket is created',
                description: `Pasting images is only supported in existing ticket`,
                status: 'error',
                duration: 3000,
                position: 'top-right',
                isClosable: true,
            })

        }
    }

    return (
        <Flex
            width="full"
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            padding="2"
            bg={bg || bgColor}
            color={color || foreColor}
            zIndex={'auto'}
            position={'relative'}>
            <EditorStyled stylesProp={{ minHeight: name }} style={{ width: '100%' }}>
                <Editor
                    {...config}
                    ref={editorRef}
                    editorState={editorStateValue}
                    // editorRef={fnEditorReference}
                    onEditorStateChange={onEditorStateChange}
                    blockRendererFn={editorHelper.mediaBlockRenderer}
                    mention={mention}
                    editorStyle={editorStyle ? editorStyle : {}}
                    toolbarStyle={toolbarStyle ? toolbarStyle : {}}
                    wrapperStyle={wrapperStyle ? wrapperStyle : { width: '100%' }}
                    wrapperClassName={wrapperClassName ? wrapperClassName : ''}
                    editorClassName={editorClassName ? editorClassName : ''}
                    toolbarClassName={toolbarClassName ? toolbarClassName : ''}
                    stripPastedStyles={false}
                    handlePastedText={() => false}
                    spellCheck={true}
                    handlePastedFiles={handlePastedImage}
                    spellCheck={true}
                    caseId={caseId}
                />
            </EditorStyled>
        </Flex>
    )
}
export default TextEditor
