import {
  TableContainer,Table,Thead,Th,Tr,Td,Tbody,Flex,Checkbox,Image,Text,Box,Spinner,
} from "@chakra-ui/react"
import React,{useState,useContext} from "react"
import norecord from '../../../assets/norecord.png'
import {WorkspaceContext} from '../../commonComponents/Layout/Layout'
import { useDocumentContext } from "./context/DocumentContext"
import Pagination from '../../commonComponents/Pagination'


const TableComponent = ({searchByName}) => {

  const {globalState,handleCheckAllFiles,handleCheckboxChange,updateItemsPerPage,updateCurrentPage} = useDocumentContext()

  const records = globalState.documents
  const [isCheckAllDocs, setIsCheckAllDocs] = useState(false)
  const { workspaceMetadata} = useContext(WorkspaceContext) 
  
  const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
  const tagProps={...commonProps, color:'#fff', bg:'#41A1A4'}
  const attributeProps={...commonProps, color:'#617182', bg:'#E5E5E5'}

  const pagesLength=Math.max(1,Math.ceil(globalState.totalRecords / globalState.itemsPerPage))

  
  const recordsToBeDisplayed=records.filter((element)=>{
    if(workspaceMetadata==='noWorkSpace') return element.workspace===null
    else return (element.workspace===workspaceMetadata)
  })
  return (
    <React.Fragment>
      <TableContainer  className="documents">
        <Table>
          <Thead>
            <Tr>
              <Th>
              <Checkbox
              sx={{
                '& .chakra-checkbox__control[data-checked]': {
                  bg: '#2563EB',
                  borderColor: '#2563EB',
                }}}
              isChecked={(isCheckAllDocs && recordsToBeDisplayed.length===0) || (recordsToBeDisplayed.length > 0 && recordsToBeDisplayed.every(record=>globalState.checkedFiles.some(file=>record.id===file)))}
              onChange={(e)=> {
                setIsCheckAllDocs(!isCheckAllDocs)
                handleCheckAllFiles(e)
                }}
            />
              </Th>
              <Th>Name</Th>
              <Th>Created At</Th>
              <Th>Created By</Th>
              <Th>File Type</Th>
              <Th>Modified At</Th>
              <Th>Modified By</Th>
              <Th textAlign='center'>Tags</Th>
              <Th textAlign='center'>Audience</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              globalState.isFetching ?
                ( 
                  <Tr>
                    <Td colSpan={9}>
                      <Flex direction="column" justifyContent='center' alignItems='center' >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='#2ac769'
                            size='xl'
                        />
                        <Text my='2' data-testid='ka_table_fetching_articles_text'>Fetching documents ...</Text>
                      </Flex>
                    </Td>
                  </Tr>
                )
                :recordsToBeDisplayed.length!==0?
                (
                  recordsToBeDisplayed
                  .filter((data)=>{
                    if(searchByName===""){ return data }
                    else if( data.docName.toLowerCase().includes(searchByName.toLowerCase())){return data}
                  }).map((e,i)=>{
                    const metadata = e.tags
                    const simpleTags = metadata?.length > 0 ? metadata[1].simpleTags:null
                    const attributes = e.attributesFilter
                  return(
                      <Tr key={i}>
                      <Td> 
                      <Checkbox 
                      sx={{
                        '& .chakra-checkbox__control[data-checked]': {
                          bg: '#2563EB',
                          borderColor: '#2563EB',
                        }}}
                      value={e.id}
                      isChecked={globalState.checkedFiles.includes(e.id)}
                      onChange={handleCheckboxChange} /></Td>
                      <Td maxWidth='200px' textOverflow='ellipsis' overflow='hidden' title={e.docName} >{e.docName}</Td>
                      <Td>{e.createdAt}</Td>
                      <Td>{e.createdBy}</Td>
                      <Td>{e.docType}</Td>
                      <Td>{e.updatedAt}</Td>
                      <Td>{e.updatedBy}</Td>
                      <Td>
                      {
                      simpleTags && simpleTags.length>0?
                        <Flex wrap='wrap'>
                          {simpleTags.map(tag =>(
                            <Text key={tag.id} as='span' {...tagProps}>{`${tag.key}:${tag.value}`}</Text>
                          ))}
                        </Flex>
                        :''
                      }
                      </Td>
                      <Td>{
                        attributes?.length >0?
                        <Flex wrap='wrap'>
                          {attributes.map(attribute =>{
                            return (
                            <Text key={attribute.id} as='span' {...attributeProps}>{`${attribute.key}:${Array.isArray(attribute.value)? attribute.value.join(', '):attribute.value}`}</Text>
                          )}
                          )}
                        </Flex>
                        :''
                      }</Td>
                      </Tr>
                  )
                }))
                :
                  <Tr>
                    <Td colSpan={9}>
                      <Flex flexDir="column" justifyContent='center' alignItems='center' >
                        <Image width='140px' height='140px' src={norecord} alt='no records'/>
                        <Text as='h5' fontSize='18px' fontWeight='500' my='20px' textAlign='center' >No record found!</Text>
                        <Text>You can upload files by clicking on upload button</Text>
                      </Flex>
                    </Td>
                  </Tr>
                
            }
          </Tbody>
        </Table>
      </TableContainer>
    <Box display='flex' justifyContent='space-between' width='100%' mt="15px">
      <Box display='flex' alignItems='center'>
          Show
          <Box border='1px solid #e2e8f0' borderRadius='5px' mx='3' display='flex'  >
          <select onChange={(e)=>updateItemsPerPage(e.target.value)} style={{borderRadius:'5px',height:'30px'}} >
              <option value='100'>100</option>
              <option value='200' >200</option>
          </select>
          </Box>files per page</Box>
        <Pagination totalPages={pagesLength} currentPage={globalState.currentPage} onPageChange={updateCurrentPage} />
    </Box>
    </React.Fragment>
  )
}

export default TableComponent