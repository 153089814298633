import {
  Box,
  Text,
  Stack, HStack,
  Flex,Image,Checkbox,Divider,Button,Spinner,IconButton,Tooltip,
} from "@chakra-ui/react"
import React,{useState,useEffect,useContext} from "react"
import { useMutation,useQueryClient } from 'react-query'
import { BiChevronRight } from "react-icons/bi"
import {HiOutlineArrowCircleLeft, HiOutlineTrash} from 'react-icons/hi'
import {TbRefresh} from 'react-icons/tb'
import { RxExternalLink } from "react-icons/rx"
import back from '../../../assets/back.png'
import AppContext from 'AppContext'
import {WorkspaceContext} from '../../commonComponents/Layout/Layout'
import { useServicesContext } from "services/apiServices"
import { useCrawlContext } from "./context/CrawlContext"
const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
const orangeBadgeProps={...commonProps, color:'#E79B04', bg:'#F7E9CE', border:'1px solid #E79B04'}
const greenBadgeProps={...commonProps, color:'#2AC769', bg:'#DFF7E9', border:'1px solid #2AC769'}

function createDerivedState(currentState) {
  if(Object.keys(currentState).length>0){
    const derivedState = {
      id:currentState.id||1,
      uniqueid:currentState.id,
      url:currentState.docName||currentState.url,
      isPublished: currentState.crawlStatus==='Published'||currentState.crawlStatus==='published',
      checkboxSelected:currentState.crawlStatus==='Published'||currentState.crawlStatus==='published',
      recrawlStatus:'no',
      showSpinner:false,
      childrenState:[]
    }
    for(const url of currentState.childUrls){
      const childState = {grandChild:[]}
      childState.id=url.id  
      childState.uniqueid=currentState.id+'-'+url.id 
      childState.url=url.url
      childState.isPublished=url.crawlStatus
      childState.checkboxSelected=url.crawlStatus
      childState.recrawlStatus='no'
      childState.showSpinner=false
      for(const grandChildUrl of url.grandChild){
        const grandChildState = {grandChild:[]}
        grandChildState.id=grandChildUrl.id
        grandChildState.uniqueid=currentState.id+'-'+url.id+'-'+grandChildUrl.id
        grandChildState.url=grandChildUrl.url
        grandChildState.isPublished=grandChildUrl.crawlStatus
        grandChildState.checkboxSelected=grandChildUrl.crawlStatus
        grandChildState.recrawlStatus='no'
        grandChildState.showSpinner=false
        childState.grandChild.push(grandChildState)
      }
      derivedState.childrenState.push(childState)
    }
    return derivedState 
  }
  else return {}
}

const CrawlUrlList = () => {
  const queryClient=useQueryClient()
  const { identity } = useContext(AppContext)
  const { workspaceMetadata} = useContext(WorkspaceContext)
  const {globalCrawlState,closeDrawer,onDeleteSingleUrlSuccess,onCreatePublishSuccess,onCreatePublishFailure} = useCrawlContext()
  const {apiCall,createOrUpdateAsync} = useServicesContext()

  const [urlsToPublish, setUrlsToPublish] = useState([])
  const [expanded, setExpanded] = useState(true)
  const [childUrlsDropdown,setChildUrlsDropdown]=useState(null)
  const [previewUrlRecrawlStatus, setPreviewUrlRecrawlStatus] = useState({})
  const [isRunning, setIsRunning] = useState(false)
  const tenantId = identity?.profile?.tenantUid
  const isPreview = globalCrawlState.drawerState.isPreview
  const previewObj = isPreview ? globalCrawlState.urls.find(url=>url.id===globalCrawlState.selectedUrl): globalCrawlState.drawerState.previewUrl!== null &&  globalCrawlState.drawerState.previewUrl!== undefined ? globalCrawlState.drawerState.previewUrl :{}
  const [derivedStateData, setDerivedStateData] = useState(createDerivedState(previewObj))
  const bagdeProps = derivedStateData.isPublished? greenBadgeProps:orangeBadgeProps
  const childUrlObj=derivedStateData.childrenState

  const handleUrlOpen=(val)=>{
    window.open(val, '_blank')
  }

  useEffect(()=>{
    isPreview && setDerivedStateData(createDerivedState(globalCrawlState.urls.find(url=>url.id===globalCrawlState.selectedUrl)))
  },[globalCrawlState.urls])

  const createPublishMutation=useMutation(apiCall,{
    onMutate:()=>{
     setIsRunning(true)
    },
    onSuccess:()=>{
     queryClient.invalidateQueries('urls')
     setIsRunning(false)
     onCreatePublishSuccess()
    },
    onError:(error)=>{
      setIsRunning(false)
      onCreatePublishFailure(error)
    }   
  })
  //function to update a kvpair object in an array of objects of arbitrary level of depth
  const updateChildAttr = (childArray, id, kvPair) => {
    return childArray.map((childItem) => {
      if (childItem.uniqueid === id) {
        return { ...childItem, ...kvPair }
      }
      if (childItem.grandChild && childItem.grandChild.length > 0) {
        return { ...childItem, grandChild: updateChildAttr(childItem.grandChild,id, kvPair) }
      }
      return childItem
    })
  }

  const handleChildUrlsDropdown=(element)=>{
    if(element === childUrlsDropdown)
      setExpanded(!expanded)
    else if(element=== null)
      setExpanded(false)
    else
     setExpanded(true) 
    setChildUrlsDropdown(element)  
  }

  // function to handle checkbox check event
  const handleCheckBoxEvent = (crawlStatus,element) =>{
      if(crawlStatus)
        setUrlsToPublish([...urlsToPublish,element])
      else
        setUrlsToPublish(urlsToPublish.filter(item=>item.uniqueid!==element.uniqueid))
      
      //If the base url is checked
      if(derivedStateData.uniqueid===element.uniqueid){
        const updatedChildrenStateArray = derivedStateData.childrenState.map((item) => {
          const updatedGrandChildState = item.grandChild.map(grandChild=>{
            return { ...grandChild, checkboxSelected:crawlStatus }
          })
          return { ...item, checkboxSelected:crawlStatus,grandChild:updatedGrandChildState}
        })
        setDerivedStateData({...derivedStateData,checkboxSelected:crawlStatus,childrenState:updatedChildrenStateArray})  
        return
      }
      const updatedDataArray = derivedStateData.childrenState.map((item) => {
        //If a 1st level child is checked/unchecked then check/uncheck all its children if any,
        if (item.uniqueid === element.uniqueid) {
          const updatedGrandChildState = item.grandChild.map(grandChild=>{
            return { ...grandChild, checkboxSelected:crawlStatus }
          })
          return { ...item, checkboxSelected:crawlStatus,grandChild:updatedGrandChildState}
        }
        // Current 1st level child is not checked. Check if a granchild was checked.
        if (item.grandChild && item.grandChild.length > 0) {
          //If a grancdchild was checked and its parent was not checked, it should be checked.
          if(crawlStatus && item.grandChild.some(grandchild=>grandchild.uniqueid===element.uniqueid))
            return { ...item, checkboxSelected:crawlStatus,grandChild: updateChildAttr(item.grandChild, element.uniqueid, {checkboxSelected:crawlStatus}) }
          else
           return { ...item,grandChild: updateChildAttr(item.grandChild, element.uniqueid, {checkboxSelected:crawlStatus}) }
        }
        // this child has no grandchildren and it was not checked/unchecked, return it as is.
        return item
      })

      //Check if parent should be checked if it was unchecked and a child was checked
      if(crawlStatus)
        setDerivedStateData({...derivedStateData,checkboxSelected:crawlStatus,childrenState:updatedDataArray})
      else
        setDerivedStateData({...derivedStateData,childrenState:updatedDataArray})
  }


  //function to handle clicking on recrawl icon for  url
  const handleRecrawlEvent = async (urlId,baseUrl,childUrl,grandChildUrl) => {
    const kvpair={}
    kvpair[urlId]='crawling'
    setPreviewUrlRecrawlStatus({...previewUrlRecrawlStatus,...kvpair})
    const urlBody={tenantid:tenantId,doc_property:'rezolve.ai',base_url:baseUrl}
    if(childUrl){
      urlBody.child_url=childUrl
      if(grandChildUrl){
        urlBody.grandchild_url=grandChildUrl
        urlBody.url_type='grandchild_url'
      }
      else urlBody.url_type='child_url'
    }
    else
    urlBody.url_type='base_url'
    let options={method:'POST',url:'InputFiles/recrawl_url',variables:'crawlUrl'}
      try{
      const response=await createOrUpdateAsync(options,urlBody)
      if(response.status===200){
        kvpair[urlId]='yes'
        setPreviewUrlRecrawlStatus({...previewUrlRecrawlStatus,...kvpair})
      }else{
        kvpair[urlId]='fail'
        setPreviewUrlRecrawlStatus({...previewUrlRecrawlStatus,...kvpair})
      }
      }
      catch(error){
      kvpair[urlId]='fail'
      setPreviewUrlRecrawlStatus({...previewUrlRecrawlStatus,...kvpair})
      }
  }

  //Check if Crawl Selected Button can be enabled.
  const canSubmitPublish = () =>{
      return (
      (!derivedStateData.isPublished && derivedStateData.checkboxSelected )
      || derivedStateData.childrenState.some(child =>
        ((!child.isPublished && child.checkboxSelected)||
        (child.grandChild.some(grandChild =>
          !grandChild.isPublished && grandChild.checkboxSelected))) ))
  }

   //Handle Publish Selected Url Event
  const handlePublishSelectedEvent = () =>{
    const result={}
    derivedStateData.childrenState.forEach(item=>{
      if((!item.isPublished&& item.checkboxSelected)|| item.grandChild.some(grandChild=> !grandChild.isPublished&& grandChild.checkboxSelected)){
        result[item.url]=[]
        item.grandChild.forEach(grandChild =>{
            if(!grandChild.isPublished&& grandChild.checkboxSelected)
              result[item.url] = result[item.url].concat(grandChild.url)
          })
      }
    })
    const pineconeUrls={base_url:derivedStateData.url,child_urls:result}

    const childUrls = derivedStateData.childrenState.map(childState=>{
      const _childState = {grandChild:[]}
      _childState.id=childState.id 
      _childState.url=childState.url
      _childState.crawlStatus=childState.isPublished || childState.checkboxSelected
      _childState.grandChild = childState.grandChild.map(grandChildState =>{
        const _grandChildState = {grandChild:[]}
        _grandChildState.id=grandChildState.id 
        _grandChildState.url=grandChildState.url
        _grandChildState.crawlStatus=grandChildState.isPublished || grandChildState.checkboxSelected
        return _grandChildState
      })
      return _childState
    })
    const options={method:'POST',url:'InputFiles/publish_urls',variables:'url'}
    const payload={pineconeData:pineconeUrls,dbData:{id:previewObj.id,childUrls}}
    createPublishMutation.mutateAsync({options,payload})
  }

 
  //---Single URL delete----//
  const handleSingleUrlDelete=async(data)=>{
    let parentUrl = false
    const modifiedChildUrls = previewObj?.childUrls?.map(child=>{
      if((child.url===data.url)||child.grandChild.some(grandChild=>grandChild.url===data.url)){
        if(child.grandChild.some(grandChild=>grandChild.url===data.url)){
          return {...child,grandChild:child.grandChild.map(grandChild => grandChild.url===data.url? {...grandChild,crawlStatus:false}:grandChild)}
        } 
        parentUrl = true
        return {...child,crawlStatus:false,grandChild:child.grandChild.map(grandChild=>({...grandChild,crawlStatus:false}))}
      }
      return child
    })
    const urlBody={childUrls:modifiedChildUrls,pineconeUrl:data.url,previewUrlId:previewObj.id,parenturl:parentUrl}
    const options={method:'PUT',url:'InputFiles/delete_single_url',variables:'deleteSingleUrl'}
    try{
      const response=await createOrUpdateAsync(options,urlBody)
      if(response.status===200){
        // setUrlToPreview({...urlToPreview,childUrls:modifiedChildUrls})
        onDeleteSingleUrlSuccess()
      }
    }
    catch(error){
      // setUrlToPreview({...urlToPreview})
    }
  }

  //Handle Crawl URLs event
  const handleCrawlListUpload=async ()=>{
    const url= previewObj.url.startsWith("http:")||previewObj.url.startsWith("https://") ? previewObj.url : `https://${previewObj.url}`
  
    const hierarchicalTags=''
    const simpleTags=''
    const cumulativeData = `${hierarchicalTags}${simpleTags}team:${workspaceMetadata}`
  
    const result={}
    derivedStateData.childrenState.forEach(item=>{
      if((item.checkboxSelected)|| item.grandChild.some(grandChild=> grandChild.checkboxSelected)){
        result[item.url]=[]
        item.grandChild.forEach(grandChild =>{
            if(grandChild.checkboxSelected)
              result[item.url] = result[item.url].concat(grandChild.url)
          })
      }
    })
    const pineconeUrls={base_url:url,child_urls:result}

    const childUrls = derivedStateData.childrenState.map(childState=>{
      const _childState = {grandChild:[]}
      _childState.id=childState.id 
      _childState.url=childState.url
      _childState.crawlStatus=childState.checkboxSelected
      _childState.grandChild = childState.grandChild.map(grandChildState =>{
        const _grandChildState = {grandChild:[]}
        _grandChildState.id=grandChildState.id 
        _grandChildState.url=grandChildState.url
        _grandChildState.crawlStatus= grandChildState.checkboxSelected
        return _grandChildState
      })
      return _childState
    })
    const options={url:'InputFiles/create_url',method:'POST',variables:'url'}
    const payload={baseUrl:url,metadata:cumulativeData,workspace:workspaceMetadata,label:previewObj.website,frequency:previewObj.frequency,description:previewObj.description,childUrls,pineconeChildUrls:pineconeUrls,allowedDomains:previewObj.allowedDomains,crawlStatus:'Published',childCount:previewObj.maxChildUrlLevel}
    createPublishMutation.mutateAsync({options,payload})
  } 

  const showSpinnerForUrl = id => {
    if(derivedStateData.uniqueid ===id){
      setDerivedStateData({...derivedStateData,showSpinner:true})
      return
    }
    const modifiedChildrenState = derivedStateData.childrenState.map(child=>{
      if((child.uniqueid===id)||child.grandChild.some(grandChild=>grandChild.uniqueid===id)){
        if(child.grandChild.some(grandChild=>grandChild.uniqueid===id)){
          return {...child,grandChild:child.grandChild.map(grandChild => grandChild.uniqueid===id? {...grandChild,showSpinner:true}:grandChild)}
        } 
        return {...child,showSpinner:true}
      }
      return child
    })
    setDerivedStateData({...derivedStateData,childrenState:modifiedChildrenState})
  }

  return (
    <Stack height='100vh'>
      <Flex justifyContent="space-between" alignItems="center" p="15px" color="#374151"
      >
        <Box display="flex" alignItems="center">
          {/* <Image src={back} alt="back" cursor="pointer" onClick={closeDrawer} data-testid="preview_back" /> */}
          <HiOutlineArrowCircleLeft cursor='pointer' size='26px' color='#2563EB' onClick={closeDrawer}  data-testid="preview_back"/>
          {/* <Text fontSize="18px" mx="10px"> Preview</Text> */}
        </Box>
      </Flex>
      <Divider />
      <Stack p="15px" flexGrow={1} overflow='auto'>
      {
        !isRunning ?
          <>
            <Box borderRadius="10px" border="1px solid #e2e8f0" padding="10px" maxHeight='100%'>
              <Box>
                <Flex justifyContent="space-between" alignItems="center" >
                  <Box display="flex" alignItems="center">
                    {derivedStateData.childrenState.length > 0? <BiChevronRight style={ (expanded||childUrlsDropdown!==null)? {transform:'rotate(90deg)',transition:'all 0.3s ease-in-out'}:{transform:'rotate(0deg)',transition:'all 0.3s ease-in-out'}} size="24px" color='#6b7280' onClick={()=>{handleChildUrlsDropdown(null)}} />:null}
                     <Checkbox data-testid="check_parent" me='10px' defaultChecked={isPreview? derivedStateData.isPublished:true} isDisabled={isPreview?derivedStateData.isPublished:false} isChecked={derivedStateData.checkboxSelected} onChange={e=>handleCheckBoxEvent(e.target.checked,derivedStateData)} />
                    <Text width="100%" maxWidth="400px" color={isPreview && derivedStateData.isPublished?"#6b7280":'inherit'} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={isPreview?previewObj.docName:previewObj.base_url} fontSize='14px' >{isPreview?previewObj.docName:previewObj.url}</Text>
                    {isPreview && <Text as='span' {...bagdeProps} >{derivedStateData.isPublished? 'Crawled':'Uncrawled'}</Text>}
                  </Box>
                  {!isPreview? (
                    <Box display="flex" alignItems="center">
                    {/* <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="publish" mb="0">
                          Auto Publish
                        </FormLabel>
                        <Switch id="publish" />
                  </FormControl> */}
                      <Text ms='10px' cursor="pointer" data-testid="external_link" onClick={()=>{handleUrlOpen(previewObj?.base_url)}} ><RxExternalLink color="#6b7280" size='20px'/></Text>
                    </Box>
                    ):
                    derivedStateData.isPublished ? 
                    (
                      <HStack spacing='0px' align='center' justify='flex-start'>
                      {(derivedStateData.id in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[derivedStateData.uniqueid]==='crawling'?
                      (                    
                        <Flex align='center' transform='translateX(0)' transition='transform 0.1s ease-in-out' bg='#2563EB' px='10px' py='3px' color='white' borderRadius='5px'>
                          <TbRefresh size='12px'/>
                          <Text ml='10px' fontSize='12px'>Crawling...</Text>
                        </Flex>                   
                      )
                        :
                        <Tooltip placement='top' label='Recrawl Url'><IconButton aria-label="Recrawl URL" variant='ghost' color={(derivedStateData.uniqueid in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[derivedStateData.uniqueid]!=='no'?'#2563EB':'#94A3B8'} px='0px' marginRight='40px' onClick={()=>handleRecrawlEvent(derivedStateData.uniqueid,derivedStateData.url,null,null)} fontSize='20px'>{<TbRefresh/>}</IconButton></Tooltip>
                        }
                        {/*<Tooltip placement='top' label='Delete Url'><IconButton aria-label="Delete URL" variant='ghost' color='#94A3B8' px='0px' marginLeft='0px' onClick={()=>console.log(`[CrawlList] delete URL called`)} fontSize='20px'>{<HiOutlineTrash/>}</IconButton></Tooltip>*/}
                      </HStack>
                    ):<></>
                  }
                </Flex>
                { (expanded||childUrlsDropdown!==null) &&
                  <Box borderLeft="1px solid #e2e8f0" ps='8px' ms='8px' height='calc(100vh - 230px)' overflowY='auto' paddingRight="10px">
                {
                
                  childUrlObj.length>0 && childUrlObj.map((element,parentIndex) => {
                    const elementStatusProps = isPreview && element.isPublished? greenBadgeProps:orangeBadgeProps
                    return (
                      <Flex ps='15px' mt='20px' key={parentIndex} flexDirection="column"  >
                        <Flex justifyContent="space-between" alignItems="center"> 
                          <Box display="flex" alignItems="center">
                            {element.grandChild.length>0? <BiChevronRight data-testid="dropdown_child" style={ (childUrlsDropdown === element.id && expanded)? {transform:'rotate(90deg)',transition:'all 0.3s ease-in-out'}:{transform:'rotate(0deg)',transition:'all 0.3s ease-in-out'}} size='24px' onClick={()=>{handleChildUrlsDropdown(element.id)}} />:null}
                            <Checkbox data-testid="check_child" me='10px' defaultChecked={isPreview?element.isPublished:true} isDisabled={isPreview?element.isPublished:false} isChecked={element.checkboxSelected} onChange={e=>{handleCheckBoxEvent(e.target.checked,element)}} />
                            <Text width="100%" maxWidth="400px" color={isPreview&& element.isPublished?"#6b7280":'inherit'} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={element.url} fontSize='14px' >{element.url}</Text>
                            {isPreview && <Text as='span' ml={2} {...elementStatusProps} >{element.isPublished? 'Crawled':'Uncrawled'}</Text>}
                          </Box>
                          {!isPreview? (
                            <Box display="flex" alignItems="center">
                              <RxExternalLink size='20px' color='#6b7280' data-testid="external_link" cursor="pointer" onClick={()=>{handleUrlOpen(element.url)}} />
                            </Box>
                            ):
                            element.isPublished ? 
                              (
                                <Flex spacing='0px' align='center' justify='flex-start' position='relative'>
                                  {element.showSpinner? <Spinner size='md' color='gray.300' /> : 
                                  <HStack spacing='0px' align='center' justify='flex-start' position='relative'>
                                  {(element.uniqueid in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[element.uniqueid]==='crawling'?
                                  (
                                    <Flex align='center' transform='translateX(0)' position='relative' transition='transform 0.2s ease-in-out' bg='#2563EB' px='10px' py='3px' color='white' borderRadius='5px'>
                                      <TbRefresh size='12px'/>
                                      <Text ml='10px' fontSize='12px'>Crawling...</Text>
                                    </Flex>
                                  )
                                    :
                                    <Tooltip placement='top' label='Recrawl Url'><IconButton aria-label="Recrawl URL" variant='ghost' color={(element.uniqueid in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[element.uniqueid]!=='no'?'#2563EB':'#94A3B8'} px='0px' marginLeft='0px' onClick={()=>handleRecrawlEvent(element.uniqueid,derivedStateData.url,element.url,null)}  fontSize='20px'>{<TbRefresh/>}</IconButton></Tooltip>
                                  }  
                                  <Tooltip placement='top' label='Delete Url'>
                                    <IconButton aria-label="Delete URL" variant='ghost' color='#94A3B8' px='0px' marginLeft='0px' fontSize='20px' data-testid="delete_url"
                                      onClick={()=>{
                                        showSpinnerForUrl(element.uniqueid)
                                        handleSingleUrlDelete(element)
                                        }}>
                                      {<HiOutlineTrash/>}
                                    </IconButton>
                                  </Tooltip>
                                  </HStack>
                                  }
                                </Flex>
                              ):<></>
                          }
                        </Flex>
                        {
                          ( childUrlsDropdown === element.id && expanded) &&
                          <Box borderLeft="1px solid #e2e8f0" ps='8px' ms='8px' maxHeight="200px" overflowY="auto" >
                            {
                              element.grandChild.length>0?(
                                element.grandChild.map((item,index) => {
                                  const grandChildStatusProps = isPreview && item.isPublished? greenBadgeProps:orangeBadgeProps
                                    return (
                                    <Box ps='20px' key={index} display="flex" justifyContent="space-between" mt='20px'  >
                                      <Box display="flex" alignItems="center">
                                        <Checkbox data-testid="check_grand_child" me='10px' defaultChecked={isPreview?item.isPublished:true} isDisabled={isPreview?item.isPublished:false} isChecked={item.checkboxSelected} onChange={e=>{handleCheckBoxEvent(e.target.checked,item)}}/>
                                        <Text width="100%" maxWidth="400px" color={isPreview && item.isPublished?"#6b7280":'inherit'} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={item.url} fontSize='14px' >{item.url}</Text>
                                        {isPreview && <Text as='span' ml={2} {...grandChildStatusProps} >{item.isPublished?  'Crawled':'Uncrawled'}</Text>}
                                      </Box>
                                      {!isPreview? (
                                        <Box display="flex" alignItems="center">
                                          <RxExternalLink data-testid="external_link_grand_child" size='20px' color='#6b7280' cursor="pointer" onClick={()=>{handleUrlOpen(element.url)}} />
                                        </Box>
                                        ):
                                        item.isPublished ? 
                                        (
                                          <Flex spacing='0px' align='center' justify='flex-start' position='relative'>
                                            {item.showSpinner? <Spinner size='md' color='gray.300' /> : 
                                              <HStack spacing='0px' align='center' justify='center'>
                                                {(item.uniqueid in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[item.uniqueid]==='crawling'?
                                                (                                            
                                                  <Flex align='center' transform='translateX(0)' transition='transform 0.1s ease-in-out' bg='#2563EB' px='10px' py='3px' color='white' borderRadius='5px'>
                                                    <TbRefresh size='12px'/>
                                                    <Text ml='10px' fontSize='12px'>Crawling...</Text>
                                                  </Flex>                                          
                                                )
                                                :
                                                <Tooltip placement='top' label='Recrawl Url'><IconButton aria-label="Recrawl URL" variant='ghost' color={(item.uniqueid in previewUrlRecrawlStatus) && previewUrlRecrawlStatus[item.uniqueid]!=='no'?'#2563EB':'#94A3B8'} px='0px' marginLeft='0px' onClick={()=>handleRecrawlEvent(item.uniqueid,derivedStateData.url,element.url,item.url)}  fontSize='20px'>{<TbRefresh/>}</IconButton></Tooltip>
                                                }    
                                                <Tooltip placement='top' label='Delete Url'>
                                                  <IconButton aria-label="Delete URL" variant='ghost' color='#94A3B8' px='0px' marginLeft='0px' fontSize='20px'
                                                  data-testid="delete_url_grand_child"  
                                                  onClick={()=>{
                                                      showSpinnerForUrl(item.uniqueid)
                                                      handleSingleUrlDelete(item)}}>
                                                    {<HiOutlineTrash/>}
                                                    </IconButton>
                                                  </Tooltip>   
                                              </HStack>
                                            }
                                          </Flex>
                                        ):<></>
                                      }
                                    </Box>
                                  )
                                })
                              ):null
                            }
                          </Box>
                        }
                      </Flex>
                    )
                  })
                }
                  </Box>
                }
              </Box>
            </Box> 
            <Box my='2' display='flex' justifyContent='flex-end' spacing='10px'>
              {isPreview? (
                <HStack spacing='10px'>
                  <Button isDisabled={!canSubmitPublish()} bg="#2563EB" color="#fff" fontSize='14px' onClick={handlePublishSelectedEvent} >Crawl Selected</Button>
                  <Button bg="#2563EB" color="#fff" fontSize='14px' onClick={closeDrawer} >Close</Button>
                </HStack>
              ):
                <Button minW="100px" className="primary-button" onClick={handleCrawlListUpload} disabled={!derivedStateData.checkboxSelected}>Crawl</Button>
              }
            </Box>
          </>
        :
          <Box height='80vh' width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' >  
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500'size='xl'/>
            <Text my='2'>Crawling selected URLs</Text>
          </Box>
      }
      </Stack>
    </Stack>
  )
}

export default CrawlUrlList
