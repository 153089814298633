import React, { useState, useEffect, useRef} from 'react'
import { ErrorBoundary } from "react-error-boundary"
import { Flex, Box, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Image, useDisclosure, Tooltip } from "@chakra-ui/react"
import parse from 'html-react-parser'
import { WarningIcon} from '@chakra-ui/icons'
import { GrClose } from "react-icons/gr"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { MdOutlineInput } from "react-icons/md"
import { SlArrowDown, SlArrowRight } from "react-icons/sl"
import { AiOutlineCheckCircle } from "react-icons/ai"
import { RxCrossCircled } from "react-icons/rx"
import { GiInvertedDice6 } from "react-icons/gi"
import { CgArrowsExpandRight } from "react-icons/cg"
import { IoClose } from "react-icons/io5"
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'

import failedUtteranceTail from './assets/icons/FailedUtteranceTail.svg'
import messageBubbleTail from './assets/icons/Tail.svg'
import Avatar from './assets/icons/Avatar.svg'
import Database from './assets/icons/database.svg'
import logo from './assets/icons/Rezolve-logo.svg'
import thumbs_down from './assets/icons/thumbs-down.svg'
import ai from './assets/icons/ai.svg'
import MatchedResults from './MatchedResults.jsx'
import ChatDetails from './ChatDetails'
import './explainable.css'
import { markdownToHtml } from './utils' 

const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)

const ExplanationModal = ({ isOpen, closeModal,replaceSASUrls,explainableData,chatData}) => {
    const [hasOverflow, setHasOverflow] = useState(false)  
    const [drawerContentType, setDrawerContentType] = useState(null)
    const [drawerData, setDrawerData] = useState (null)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth*0.9,
        height: window.innerHeight*0.9
    })
    const contentRef = useRef(null)
    const modalRef = useRef(null)
    const { isOpen: isOpenInner, onOpen: onOpenInner, onClose: onCloseInner } = useDisclosure()
    const response_answers = !explainableData ? null : typeof explainableData.answers === 'string' ? JSON.parse(explainableData.answers) : explainableData.answers
    const explainable_data = response_answers? response_answers.explainable : null

    const handleViewChat = () => {
        setDrawerContentType('chat')
        onOpenInner()
    }
    const handleViewResults = (data,threshold) => {
        setDrawerContentType('results')
        setDrawerData({ data,threshold })
        onOpenInner()
    }
    const handleViewInputs = (data,threshold) => {
        setDrawerContentType('inputs')
        setDrawerData({ data, threshold })
        onOpenInner()
    }

    const enabled_sources = explainable_data? explainable_data.information?.knowledge_sources:[]
    const was_answered = explainable_data?.information?.was_answered
    const isGap = explainableData?.failedUtteranceId !== null
    const answer_source = explainable_data?.information?.answer_source
    const user_query = explainable_data?.components.find(item=>item.type==='UserQueryNode').user_intent || ''
    
    const updateSize = () => {
        setWindowSize({
        width: window.innerWidth*0.9,
        height: window.innerHeight*0.9
        })
        const contentElement = contentRef.current
        if (contentElement) {
        const hasVerticalScrollbar = contentElement.scrollHeight > contentElement.clientHeight
        setHasOverflow(hasVerticalScrollbar)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', updateSize)
    return () => {
        window.removeEventListener('resize', updateSize)
    }
    }, [])

    useEffect(() => {
        const contentElement = contentRef.current
        if (contentElement) {
            const hasVerticalScrollbar = contentElement.scrollHeight > contentElement.clientHeight
            setHasOverflow(hasVerticalScrollbar)
        }
    })

    const rightArrow = (
        <Box position='relative' borderTop='1px solid #d9d9d9' flex={1} minW='50px' maxW='150px'>
            {/* <Box borderTop='1px solid #d9d9d9' flex={1}></Box> */}
            <Box position='absolute' top='-8.5px' right='-6px'>
                <SlArrowRight color='#d9d9d9' size='16px'/>
            </Box>
        </Box>
    )

    const leftArrow = <Box flex={1} minW='50px' maxW='150px' transform='rotate(180deg)'>{rightArrow}</Box>
    const rightArrowNo = (
        <Box position='relative' flex={1} minW='50px' maxW='150px'>
            <Flex position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' w='100%'>
                {rightArrow}
            </Flex>
            <Flex position='absolute' top='-30px' left='50%' transform='translateX(-50%)' alignItems='center' justify='center' w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #CF3626">
                <Text as='span' fontSize="12px" lineHeight='14px' color='#CF3626'>No</Text>
            </Flex>
        </Box>
    )
    
    const rightArrowYes = (
         <Box position='relative' flex={1} minW='50px' maxW='150px'>
            <Flex position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' w='100%'>
                {rightArrow}
            </Flex>
            <Flex position='absolute' top='-30px' left='50%' transform='translateX(-50%)' alignItems='center' justify='center'  w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #229F54">
                <Text fontSize="12px" lineHeight='14px' color='#229F54'>Yes</Text>
            </Flex>
        </Box>
    )

    const leftArrowNo = (
         <Box position='relative' flex={1} minW='50px' maxW='150px'>
            <Flex position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' w='100%'>
                {leftArrow}
            </Flex>
            <Flex position='absolute' top='-30px' left='50%' transform='translateX(-50%)' alignItems='center' justify='center' w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #CF3626">
                <Text as='span' fontSize="12px" lineHeight='14px' color='#CF3626'>No</Text>
            </Flex>
        </Box>

    )
    
    const leftArrowYes = (
        <Box position='relative' flex={1} minW='50px' maxW='150px'>
            <Flex position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' w='100%'>
                {leftArrow}
            </Flex>
            <Flex position='absolute' top='-30px' left='50%' transform='translateX(-50%)' alignItems='center' justify='center'  w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #229F54">
                <Text fontSize="12px" lineHeight='14px' color='#229F54'>Yes</Text>
            </Flex>
        </Box>
    )

    const DownArrow = ({width,minHeight,align,marginTop,marginBottom}) => {
        return (
            <Flex flex={1} alignSelf={align} w={width} mt={marginTop} mb={marginBottom} justify='center' minH={ minHeight}>
                <Box position='relative' borderLeft='1px solid #d9d9d9'>
                    <Box position='absolute' bottom='-4px' left='-8.5px'>
                        <SlArrowDown color='#d9d9d9' size='16px'/>
                    </Box>
                </Box>
            </Flex>
        )
    } 

    const DownArrowNo = ({width,minHeight,align,marginTop,marginBottom}) => {
        return (
            <Flex id='downArrowContainer' flex={1} alignSelf={align} w={width} mt={marginTop} mb={marginBottom} justify='center' minH={ minHeight} position='relative'>
                <Box id='downArrow' position='absolute' height='100%'>
                    <Box position='relative' borderLeft='1px solid #d9d9d9' height='100%'>
                        <Box position='absolute' bottom='-4px' left='-8.5px'>
                            <SlArrowDown color='#d9d9d9'/>
                        </Box>
                    </Box>
                </Box>
                <Flex id='downArrowNo' position='absolute' top='50%' right='35px' transform='translateY(-50%) rotate(90deg)' alignItems='center' justify='center' w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #CF3626">
                    <Text as='span' fontSize="12px" lineHeight='14px' color='#CF3626'>No</Text>
                </Flex>
            </Flex>
        )
    }
    
    const DownArrowYes = ({width,minHeight,align,marginTop,marginBottom}) => {
        return (
        <Flex id='downArrowContainer' flex={1} alignSelf={align} w={width} mt={marginTop} mb={marginBottom} justify='center' minH={minHeight} position='relative'>
            <Box id='downArrow' position='absolute' height='100%'>
                <Box position='relative' borderLeft='1px solid #d9d9d9' height='100%'>
                    <Box position='absolute' bottom='-4px' left='-8.5px'>
                        <SlArrowDown color='#d9d9d9'/>
                    </Box>
                </Box>
            </Box>
            <Flex id='downArrowNo' position='absolute' top='50%' right='35px' transform='translateY(-50%) rotate(90deg)' alignItems='center' justify='center' w='42px' h='19px' padding='3px 6px' borderRadius='10px' border="1px solid #229F54">
                <Text as='span' fontSize="12px" lineHeight='14px' color='#229F54'>Yes</Text>
            </Flex>
        </Flex>    
        )
    }
    
    const DecisionNode = ({ text }) => {
        const lines = []
        const words = text.split(' ')
        if (words.length === 8) {
            lines.push(words[0],`${words[1]} ${words[2]}`,`${words[3]} ${words[4]}`,`${words[5]} ${words[6]}`, words[7])
        } else if (words.length === 13)
            lines.push(`${words[0]} ${words[1]}`,`${words[2]} ${words[3]}`,`${words[4]} ${words[5]} ${words[6]}`,`${words[7]} ${words[8]} ${words[9]}`,`${words[10]} ${words[11]}`,words[12 ])
           else if (words.length === 20)
            lines.push(`${words[0]}`,`${words[1]}`,`${words[2]} ${words[3]} ${words[4]} ${words[5]}`,`${words[6]} ${words[7]} ${words[8]} ${words[9]} ${words[10]}`,`${words[11]} ${words[12]} ${words[13]}`,`${words[14]} ${words[15]} ${words[16]}`,`${words[17]}`,`${words[18]} ${words[19]}`)
        return (
            <Flex justifyContent='center' alignItems='center' borderRadius='15px' minW="124px" maxW='124px' minH="124px" maxH='124px' transform='rotate(45deg)' transformOrigin="center" border="1.5px dashed #8463F9" overflow='hidden' mx='15px'>
                <Flex direction='column' transform='rotate(-45deg)' textAlign="center" fontSize='12px' color='#6B7280' lineHeight='14px' gap='4px'>
                    {lines.length > 0 ?
                        lines.map((line, i) => <Text key={i} as='span'>{line}</Text>)
                        :
                        <Text as='span'>{text}</Text>
                    }
                </Flex>
            </Flex>
        )
    }   

    const DataSourceNode = ({ source, matches, threshold }) => {
        const [matchHtmlContents, setMatchHtmlContents] = useState([])
        const [expandResult, setExpandResult] = useState('none')
        useEffect(() => {
            const checkAndReplaceSasUrl = async () => {
                if (matches) {
                    const updatedValues = await Promise.all(
                        matches?.map(async (match) => {
                            let input_html = markdownToHtml(match.text)
                            if (/SASUrl/.test(input_html)) {
                                try {
                                    return await replaceSASUrls(input_html)
                                } catch (error) {
                                    console.error('Error fetching replacement url:', error)
                                    return input_html
                                }
                            }
                            return input_html 
                        })
                    )
                    setMatchHtmlContents(updatedValues)
                }
            }
            checkAndReplaceSasUrl()
        }, [matches])
        
        const firstBelowThresholdMatch = matches?.find(match => Number(match.score * 100).toFixed(2) < Number(threshold))?.id
        return (
            <Flex border='1.5px dashed #2563EB' mr='20px' direction='column' p='10px' gap='15px' borderRadius='10px' position='relative' minW='216px' maxW='216px' maxH='230px' minH='165px'>
                <Flex gap='10px' alignItems='center'>
                    <Image boxSize='24px' src={Database} alignSelf='flex-end'></Image>
                    <Text as='span' fontSize='12px' fontWeight={500}>Query {source==='Flow'?'Bot Flow Triggers':source}</Text>
                </Flex>
                <ErrorBoundary fallback={
                    <Flex bg='red.50' borderRadius='10px' p={4} maxW='250px' border='1px dashed #e53e3e' alignItems='flex-start' gap={ 1}>
                        <WarningIcon color='red.400' /> <Text as='span' color='red.700' fontSize='14px'>There was an error in displaying the results. Please check the format of the results data.</Text>
                    </Flex>
                }>
                    { matches && matches.length > 0 ?
                        <Flex direction='column' justify='space-between' flex='1'>
                            <Flex gap='10px' direction='column' position='relative'>
                                {matches.slice(0, 4).map((match,i) => {
                                    const input_text= matchHtmlContents.length > 0 ? new DOMParser().parseFromString(matchHtmlContents[i], 'text/html').body.textContent : ''
                                    return (
                                        <Flex gap='10px' direction='column' key={match.id}>
                                            {(firstBelowThresholdMatch && firstBelowThresholdMatch === match.id) && <Box h='1px' borderTop='1px dashed #CF3626'></Box>}
                                                <Flex p='5px' alignItems='center' justify='space-between' bg='#DEE8FC' borderRadius='5px'>
                                                    <Text as='span' fontSize='12px' color='#2563EB'>{input_text.slice(0, 17)} {input_text.length>17? '...':''}</Text>
                                                    <Flex alignItems='center' gap='10px'>
                                                        <Flex gap='4px' alignItems='center' justify='center' padding='0px 4px' borderRadius='10px' border="1px solid #2563EB">
                                                            <Text as='span' lineHeight='14px' fontSize="12px">{Math.floor(Number.parseFloat(match.score * 100))}</Text>
                                                        </Flex>
                                                        <Box border='1px solid #94A3B8' borderRadius='5px'>
                                                            <CgArrowsExpandRight cursor='pointer' onClick={ ()=>setExpandResult(match.id)} size='12px' color='#94A3B8'/>
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                                {expandResult === match.id &&
                                                    <Flex bg='#F0F6FF' gap='5px' fontSize='12px' p='10px 5px 10px 10px' position='absolute' bottom='-10px' right='-10px' direction='column' alignItems='flex-start' maxW='331px' minW='300px' maxH='300px' borderRadius='10px' boxShadow='0px 4px 15px 0px #0000001A' zIndex={100}>
                                                        <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                                            <IoClose cursor='pointer' onClick={ ()=>setExpandResult('none')} size='13px' color='#94A3B8'/>
                                                        </Box>
                                                        <Text as='span' pl='10px' maxW='100%' maxH='360px' flexShrink={1}  whiteSpace="normal" wordBreak="break-all" overflowX='hidden' overflowY='auto' color='#6B7280' fontSize='12px'>{parse(matchHtmlContents[i])}</Text>
                                                    </Flex>
                                                }
                                            {/* } */}
                                        </Flex>
                                    )
                                })}
                            </Flex>
                    
                            {matches.length > 4 && <Flex p='5px' borderRadius='5px' color='#2563EB' justify='flex-end' >
                                <Button variant='link' color='#2563EB' fontSize='12px' fontWeight={400} onClick={() => handleViewResults(matches, threshold)} rightIcon={<HiOutlineArrowNarrowRight size='16px' color='#94A3B8' />}>All Results</Button>
                            </Flex>}
                        </Flex>
                              :
                        <Flex gap='5px' p='7px 5px' borderRadius='5px' bg='#F8F9FD' direction='column' alignItems='center'>
                            <RxCrossCircled size='24px' color='#CF3626' />
                            <Text as='span' fontSize='12px' color='#6B7280'>No Matches</Text>
                        </Flex>
                    }
                    {threshold && <Flex p='3px 6px' gap='6px' border='1px solid #D1D5DB' borderBottom='none' position='absolute' top='105px' right='-13%' transform='rotate(90deg)' transformOrigin='top right' alignItems='center' bg='#F0F6FF' borderRadius='5px 5px 0 0'>
                        <Text as='span' fontSize='12px' color='#6B7280'>Threshold</Text>
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 4px 1px 4px' borderRadius='10px' border="1px solid #E79B04">
                            <Text as='span' fontSize="12px" lineHeight='12px' color='#E79B04'>{threshold}</Text>
                        </Flex>
                    </Flex>}
                </ErrorBoundary>
            </Flex>
        )
    }
    
    const StackedNode = ({ nodes }) => {
        return (
            <Flex direction='column' gap='24px' p='19px' borderRadius='20px' border='1px solid #D9D9D9'>
                {
                    nodes.map(node => <DataSourceNode key={node.source} source={node.source} threshold={Number(node.threshold * 100).toFixed(0)} matches={node.results} />)
                }        
            </Flex>
        ) 
    }

    const ExceptionNode = ({exception}) => {
        return (
            <Flex id='exception' direction='column' bg='#F4F4F4' borderWidth='1px 0px 0px 2px' borderColor='#2563EB' p='10px' gap='10px' minW='271px' maxW='271px' borderRadius='10px'>
                <Flex gap='10px' alignItems='center'>
                    <Image boxSize='24px' src={ai}></Image>
                    <Text as='span' fontSize='14px' fontWeight={500} lineHeight='17px'>Flow overridden by AI</Text>
                </Flex>
                <Flex gap='10px' p='10px' bg='#F7E9CE' lineHeight='17px' fontSize='14px' borderRadius='5px' alignItems='center'>
                    <Text as='span' minW='fit-content' fontWeight={500}>Decision -</Text>
                    <Text as='span' color='#6B7280'>{exception.decision}</Text>
                </Flex>
                 <Text fontWeight={400} fontSize='14px' color='#6B7280' p='10px'>
                    <Text as='span' fontWeight={500} color='#000'>AI Reasoning - </Text>
                    {exception.reasoning.length > 220 ?
                        <Tooltip placement='bottom' bg='transparent' boxShadow='none'
                            label={
                                <Flex bg='#F0F6FF' fontSize='12px' p='10px' alignItems='flex-start' w='331px' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                    <Text as='span' color='#6B7280'>{exception.reasoning}</Text>
                                </Flex>
                            }>
                            {exception.reasoning.slice(0, 220)+'...'}
                        </Tooltip>
                        :
                         exception.reasoning 
                    }
                    </Text>
            </Flex>
        )
    }

    const EscalationNode = ({ escalation }) => {
        return (
            <Flex id='exception' direction='column' bg='#F4F4F4' borderWidth='1px 0px 0px 2px' borderColor='#2563EB' p='10px' gap='10px' minW='271px' maxW='271px' borderRadius='10px'>
                <Flex gap='10px' alignItems='center' pb='10px'>
                    <Image boxSize='24px' src={ai}></Image>
                    <Text as='span' fontSize='14px' fontWeight={500} lineHeight='17px'>Flow overridden by AI</Text>
                </Flex>
                <Flex gap='10px' p='10px' bg='#F7E9CE' lineHeight='17px' fontSize='14px' borderRadius='5px' alignItems='center'>
                    <Text minW='fit-content' as='span' fontWeight={500}>Decision -</Text>
                    <Text as='span' color='#6B7280'>{escalation.decision}</Text>
                </Flex>
               
                <Text fontWeight={400} fontSize='14px' color='#6B7280' p='10px'>
                    <Text as='span' fontWeight={500} color='#000'>AI Reasoning - </Text>
                    {escalation.reasoning.length > 220 ?
                        <Tooltip placement='bottom' bg='transparent' boxShadow='none'
                            label={
                                <Flex bg='#F0F6FF' fontSize='12px' p='10px' alignItems='flex-start' w='331px' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                    <Text as='span' color='#6B7280'>{escalation.reasoning}</Text>
                                </Flex>
                            }>
                            {escalation.reasoning.slice(0, 220)+'...'}
                        </Tooltip>
                        :
                         escalation.reasoning 
                    }
                    </Text>
            </Flex>

        )
    }

    const UserInputNode = ({ content }) => {
        const [expandInput, setExpandInput] = useState(false)
        const input_html = markdownToHtml(content)
        const input_text= input_html ?new DOMParser().parseFromString(input_html, 'text/html').body.textContent : ''
        return (
            <Flex id='user_inputs' border='1.5px dashed #2563EB' direction='column' p='10px' gap='10px' borderRadius='10px' minW='216px' maxW='216px' maxH='165px'>
                <Flex gap='10px' alignItems='center'>
                    <MdOutlineInput color='#2563EB' size='22px' style={{cursor:'default'}} />
                    <Text as='span' fontSize='12px' fontWeight={500}>Inputs</Text>
                </Flex>
                <Flex direction='column' justify='space-between' gap='10px' flex='1'>
                    <Flex gap='10px' justify='space-between' alignItems='center' bg='#F0F6FF' p='5px' borderRadius='5px'  position='relative'>
                        <Text as='span' fontSize='12px' color='#2563EB'>User Intent: {input_text.slice(0, 20)}{input_text.length>20 ? ' ...':'' }</Text>
                        <Box border='1px solid #94A3B8' borderRadius='5px'>
                            <CgArrowsExpandRight cursor='pointer' onClick={ ()=>setExpandInput(true)} size='12px' color='#94A3B8'/>
                        </Box>
                        {expandInput &&
                            <Flex bg='#F0F6FF' gap='5px' fontSize='12px' p='10px 5px 10px 10px' position='absolute' bottom='-10px' left='-10px' direction='column' alignItems='flex-start' maxW='331px' minW='300px' maxH='400px' borderRadius='10px' boxShadow='0px 4px 15px 0px #0000001A' zIndex={100}>
                                <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                    <IoClose cursor='pointer' onClick={ ()=>setExpandInput(false)} size='13px' color='#94A3B8'/>
                                </Box>
                                <Text as='span' pl='10px' maxW='100%' maxH='360px' flexShrink={1}  whiteSpace="normal" wordBreak="break-all" overflowX='hidden' overflowY='auto' color='#6B7280' fontSize='12px'>{parse(input_html)}</Text>
                            </Flex>
                        }
                    </Flex>
                   {/* <Box p='5px' borderRadius='5px' bg='#F0F6FF' color='#2563EB' >
                        <Flex alignItems='center' justify='space-between'>
                            <Button alignSelf='end' variant='link' color='#2563EB' fontSize='12px' fontWeight={400} onClick={handleViewChat} leftIcon={<HiOutlineChatBubbleLeftRight size='16px' />}>Entire chat history</Button>
                            <HiOutlineArrowNarrowRight size='16px' color='#94A3B8' onClick={handleViewChat}/>
                        </Flex>
                    </Box> */}
                </Flex>
            </Flex>)
    }
                                   
    const ModelNode = ({ inputs, output }) => { 
        const [inputHtmlContents, setInputHtmlContents] = useState([])
        const [answerHtml, setAnswerHtml] = useState('')
        const [expandAnswer, setExpandAnswer] = useState(false)
        const [expandInput, setExpandInput] = useState('none')
        useEffect(() => {
            const checkAndReplaceSasUrl = async () => {
                if (inputs) {
                    const updatedValues = await Promise.all(
                        inputs?.map(async (input) => {
                            const input_html = markdownToHtml(input.text)
                            if (/SASUrl/.test(input_html)) {
                                try {
                                    return await replaceSASUrls(input_html)
                                } catch (error) {
                                    console.error('Error fetching replacement url:', error)
                                    return input_html
                                }
                            }
                            return input_html 
                        })
                    )
                    setInputHtmlContents(updatedValues)
                }
                if (output && output.length > 0) {
                    const output_html = markdownToHtml(output)
                    try {
                        if (/SASUrl/.test(output_html)) {
                            const updatedAnswer = await replaceSASUrls(output_html)
                            setAnswerHtml(updatedAnswer)
                        } else
                            setAnswerHtml(output_html)
                    
                    } catch(error) {
                        console.error('Error fetching replacement url:', error)   
                         setAnswerHtml(output_html)
                    }
                }    
            }
            checkAndReplaceSasUrl()
        }, [inputs,output])

        const answer_text = response_answers ? new DOMParser().parseFromString(answerHtml, 'text/html').body.textContent : ''
        return (
            <Flex id='lang_model' border='1.5px dashed #2563EB' direction='column' p='10px' gap='15px' justify='space-between' borderRadius='10px' position='relative' minW='278px' maxW='278px' minH='274px'>
                <Flex gap='10px' alignItems='center'>
                    <GiInvertedDice6 size='16px'/>
                    <Text as='span' fontSize='12px' fontWeight={500}>Language Model</Text>
                </Flex>
                <ErrorBoundary fallback={
                     <Flex bg='red.50' borderRadius='10px' p={4} maxW='250px' border='1px dashed #e53e3e' alignItems='flex-start' gap={ 1}>
                        <WarningIcon color='red.400' /> <Text as='span' color='red.700' fontSize='14px'>There was an error in displaying model inputs. Please check the format of the model input data.</Text>
                    </Flex>}>
                    <Flex gap='10px' direction='column' position='relative'>
                        <Text as='span' fontSize='12px'>Inputs</Text>
                        {inputs.length > 0 ?
                            inputs.slice(0, 2).map((input,i) => {
                            const input_text= inputHtmlContents.length>0 ? new DOMParser().parseFromString(inputHtmlContents[i], 'text/html').body.textContent : ''
                                return (
                                    <Flex gap='10px' key={input.id} justify='space-between' alignItems='center' bg='#DEE8FC' p='5px' borderRadius='5px'>
                                        <Text as='span' fontSize='12px' color='#2563EB'>{input_text.slice(0, 17)}{input_text.length>17 ? ' ...':'' }</Text>
                                        <Box border='1px solid #94A3B8' borderRadius='5px'>
                                            <CgArrowsExpandRight cursor='pointer' onClick={ ()=>setExpandInput(input.id)} size='12px' color='#94A3B8'/>
                                        </Box>
                                        {expandInput === input.id &&
                                            <Flex bg='#F0F6FF' gap='5px' fontSize='12px' p='10px 5px 10px 10px' position='absolute' bottom='-10px' right='-10px' direction='column' alignItems='flex-start' maxW='331px' minW='300px' maxH='400px' borderRadius='10px' boxShadow='0px 4px 15px 0px #0000001A' zIndex={100}>
                                                <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                                    <IoClose cursor='pointer' onClick={ ()=>setExpandInput('none')} size='13px' color='#94A3B8'/>
                                                </Box>
                                                <Text as='span' pl='10px' maxW='100%' maxH='360px' flexShrink={1}  whiteSpace="normal" wordBreak="break-all" overflowX='hidden' overflowY='auto' color='#6B7280' fontSize='12px'>{parse(inputHtmlContents[i])}</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                )
                            })
                            :
                            <Flex gap='5px' p='7px 5px' borderRadius='5px' bg='#F8F9FD' direction='column' alignItems='center'>
                                <RxCrossCircled size='24px' color='#CF3626' />
                                <Text as='span' fontSize='12px' color='#6B7280'>No Inputs</Text>
                            </Flex>
                            }
                    </Flex>
                    { inputs.length > 2 && <Flex borderRadius='5px' color='#2563EB' justify='flex-end' >
                        <Button variant='link' color='#2563EB' fontSize='12px' fontWeight={400} onClick={() => handleViewInputs(inputs)} rightIcon={<HiOutlineArrowNarrowRight size='16px' color='#94A3B8' />}>All Inputs</Button>
                    </Flex>}
                    <Flex direction='column' gap='10px'>
                        <Text as='span' fontSize='12px'>Output</Text>
                        <Flex bg='#F0F6FF' p='5px' borderRadius='10px' direction='column'>
                            <Text as='span' color='#6B7280' fontSize='12px'>{answer_text.length > 100 ? `${answer_text.slice(0, 100)}...` : answer_text}</Text>
                            <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                <CgArrowsExpandRight cursor='pointer' onClick={ ()=>setExpandAnswer(true)} size='13px' color='#94A3B8'/>
                            </Box>
                        </Flex>
                    </Flex>
                    {expandAnswer &&
                        <Flex position='absolute' bottom='-10px' right='-10px' direction='column' p='10px 5px 10px 10px' gap='5px' borderRadius='10px' maxW='331px' minW='300px' maxH='400px' bg='#F0F6FF' boxShadow='0px 4px 15px 0px #0000001A' zIndex={100}>
                            <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                <IoClose cursor='pointer' onClick={ ()=>setExpandAnswer(false)} size='13px' color='#94A3B8'/> 
                            </Box>
                            <Text as='span' pl='20px' maxW='100%' whiteSpace="normal" wordBreak="break-all" flexShrink={1} maxH='360px' overflowX='hidden' overflowY='auto' color='#6B7280' fontSize='12px'>{parse(answerHtml)}</Text>
                        </Flex>
                    }
                </ErrorBoundary>
            </Flex>
        )
    } 
    
    const was_answered_node = (
        <Flex id='was_answered' minW='280px' maxW='280px' p='15px 13px' gap='15px' border={`1.5px dashed ${was_answered ? '#2AC769' : '#CF3626'}`} borderRadius='10px' alignItems='center' alignSelf='center' justify='center'>
            {was_answered ? <AiOutlineCheckCircle size='24px' color='#2AC769' /> : <RxCrossCircled size='24px' color='#CF3626' />}
            <Text as='span' fontSize='12px' fontWeight={500}>{was_answered ? `Marked query as answered by ${answer_source}` : 'Marked query as not answered'} </Text>
        </Flex>
    )
    const AnswerNode = ({ answer }) => {
        const [expandAnswer, setExpandAnswer] = useState(false)
        const [answerHtml, setAnswerHtml] = useState('')
        useEffect(() => {
            const checkAndReplaceSasUrl = async () => {
                if (answer && answer.length > 0) {
                    const answer_html = markdownToHtml(answer)
                    try {
                        if (/SASUrl/.test(answer_html)) {
                            const updatedAnswer = await replaceSASUrls(answer_html)
                            setAnswerHtml(updatedAnswer)
                        } else
                            setAnswerHtml(answer_html)
                    } catch(error) {
                        console.error('Error fetching replacement url:', error)   
                         setAnswerHtml(answer_html)
                    }
                }    
            }
            checkAndReplaceSasUrl()
        }, [answer])
        const answer_html = markdownToHtml(answer)
        const answer_text= response_answers ?new DOMParser().parseFromString(answer_html, 'text/html').body.textContent : ''
        return(
            <Flex id='answer' direction='column' minW='300px' maxW='380px' justify='center' gap='10px' minH='230px' maxH='230px'>
                <Flex position='relative'>
                    <Image src={logo} alignSelf='flex-end' mb='-25px' w='81px' h='72px'></Image>
                    <Flex p='6px 12px' ml='-15px' position='relative' borderRadius='18px' bg='#F2F3F4' alignSelf='flex-end' maxW='386px' flex={1}>
                        <Flex gap='10px' flex={1} justify='space-between' maxWidth='100%' wordBreak='break-all'>
                            <Text as='span' color='#6B7280' fontSize='12px'>{answer_text.length > 250 ? `${answer_text.slice(0, 250)} ...` : answer_text}</Text>
                            <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                <CgArrowsExpandRight cursor='pointer' onClick={ ()=>setExpandAnswer(true)} size='13px' color='#94A3B8'/>
                            </Box>
                        </Flex>
                        <Image position='absolute' boxSize='16px' left='-5px' bottom='-2px' src={messageBubbleTail}></Image>
                    </Flex>
                     {expandAnswer &&
                        <Flex position='absolute' bottom='-10px' right='-10px' direction='column' p='10px 5px 10px 10px' gap='5px' borderRadius='10px' maxW='331px' minW='300px' maxH='400px' bg='#F0F6FF' boxShadow='0px 4px 15px 0px #0000001A' zIndex={100}>
                            <Box alignSelf='end' border='1px solid #94A3B8' borderRadius='5px'>
                                <IoClose cursor='pointer' onClick={ ()=>setExpandAnswer(false)} size='13px' color='#94A3B8'/>
                            </Box>
                            <Text as='span' pl='20px' maxW='100%' whiteSpace="normal" wordBreak="break-all" flexShrink={1} maxH='360px' overflowX='hidden' overflowY='auto' color='#6B7280' fontSize='12px'>{parse(answerHtml)}</Text>
                        </Flex>
                    }
                </Flex>
                {was_answered && isGap && 
                    <Flex alignSelf='end' alignItems='start'>
                        <Image boxSize='42px' src={thumbs_down}></Image>
                        <Text pt='5px' pr='10px' fontSize='12px' fontWeight={500} color='#CF3626'>User marked the answer as not helpful</Text>
                    </Flex>
                }
            </Flex>
        )
    }
    
    const nodes = []
    explainable_data && explainable_data.components.forEach(component => {
        switch (component.type) {
            case 'UserInputNode': 
                nodes.push({ type: 'input', content: <UserInputNode content={ component.user_intent}/> }) 
                break
            case 'StackedNode':
                nodes.push({ type: 'stacked', content: <StackedNode nodes={component.nodes} />, count: component.nodes.reduce((total, node) => { return total + node.results?.length || 0 },0)})
                break
            case 'DataSourceNode':
                nodes.push({ type: 'datasource', content: <DataSourceNode source={component.source} threshold={Number(component.threshold * 100).toFixed(0)} matches={component.results}/>, count:component.results?.length||0 })
                break
            case 'DecisionNode':
                nodes.push({ type: 'question', decision:component.result, content: <DecisionNode text={component.question}/> })
                break
             case 'EscalationReasoningNode':
                nodes.push({ type: 'escalation', content: <EscalationNode escalation={component}/> })
                break
             case 'FlowReasoningNode':
                nodes.push({ type: 'exception', content: <ExceptionNode exception={component}/> })
                break
            case 'ModelInputOutputNode':
                nodes.push({ type: 'model', content: <ModelNode inputs={component.inputs} output={component.output }/>,count:component.inputs.length })
                break
            default:     
        }
    })
    nodes.push({ type: 'was_answered', content: was_answered_node }, { type: 'answer', content: <AnswerNode answer={response_answers ?response_answers.answer[response_answers.answer.length - 1].content :''}/> })
 
    const FlowChartNodes = ({ nodes }) => {
        const chunkNodes = (arr,size) => {
            const node_chunks = arr.reduce((acc, _, i) => {
            if (i % size === 0) acc.push(arr.slice(i, i + size))
            return acc
            }, [])
            const num_lines = node_chunks.length
            if (node_chunks[num_lines-1].length === size && size > 5) {
                node_chunks.push([node_chunks[num_lines - 1][size - 1]])
                node_chunks[num_lines-1] = node_chunks[num_lines-1].slice(0,size-1) 
            }
            return node_chunks
        }

        const chunk_size = windowSize.width < 1530 ? 4: windowSize.width < 1830 ? 5 : 6
        const chunked_nodes = chunkNodes(nodes, chunk_size)
        const stacked_node_count = chunked_nodes[0].find(node=>node.type==='stacked').count
        
        const marginTop = (index) => {
            const chunk = chunked_nodes[index]
            const last_node = chunk[chunk.length - 1]
            const next_row_has_nodes_count_greater_than_three = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].filter(node => node.type === 'datasource').some(node => node.count > 3)
            const last_node_has_count_greater_than_two = (last_node.type === 'datasource') && last_node.count > 2
            const last_row_has_nodes_count_greater_than_three = chunk.filter(node => node.type === 'datasource').some(node => node.count > 3)
            const next_row_has_model = index+1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'model')
            const model_has_greater_than_two = nodes.find(node => node.type === 'model').count > 2
            const next_row_has_data_source = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'datasource')
            const is_next_node_datasource = index + 1 < chunked_nodes.length && chunked_nodes[index + 1][0].type === 'datasource'
            const next_datasource_count_greater_than_three = is_next_node_datasource && chunked_nodes[index + 1][0].count > 3
        
            if (last_node.type === 'was_answered') {
                if (index === 0) {
                    return stacked_node_count <= 4? '-155px': stacked_node_count <= 6 ? '-180px':'-225px'
                }
                return '-110px'
            } 

            if (last_node.type === 'question') {
                if (index === 0) {
                    if (next_row_has_model) {
                        if (model_has_greater_than_two) {
                            if (stacked_node_count <= 4) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        // return '-90px' return -110px instead
                                        return '-110px'
                                    // return '-80px' return -110px instead
                                    return '-110px'
                                }
                                // return '-100px' return -110px instead
                                return '-110px'
                            } else if (stacked_node_count <= 6) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        // return '-90px' return -125px instead
                                        return '-125px'
                                    // return '-80px' return -120px instead
                                    return '-120px'
                                }
                                // return '-100px' return '-120px instead
                                return '-120px'
                            }
                            else {
                                 if (is_next_node_datasource) {
                                     if (next_datasource_count_greater_than_three)
                                        return '-180px'
                                        // return '-105px' return -180px instead
                                     // return '-95px' return -170px instead
                                     return '-170px'
                                }
                                // return '-120px' return -170px instead
                                return '-170px' 
                            }
                        } else {
                           if (stacked_node_count <= 4) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        return '-110px'
                                        // return '-90px' return -110px instead
                                    // return '-80px' return -110px instead
                                    return '-110px'
                                }
                               // return '-100px' return -110px instead
                               return '-110px'
                           } else if (stacked_node_count <= 6) { 
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        return '-120px'
                                        // return '-90px' //return -120px instead
                                    // return '-80px' return -120px instead
                                    return '-120px'
                                }
                               // return '-100px' return -120px instead
                               return '-120px'
                           } else {
                                 if (is_next_node_datasource) {
                                     if (next_datasource_count_greater_than_three)
                                        return '-175px'
                                        // return '-105px' return -175px instead
                                     // return '-95px' return -180px instead
                                     return '-180px'
                                }
                               // return '-120px' return -175px instead
                               return '-175px'
                            }
                        }
                    }
  
                }
                if (last_row_has_nodes_count_greater_than_three)
                    return '-30px'
            } 
            if (last_node.type === 'datasource') {
                if (index === 0) {
                    if (next_row_has_model) {
                        if (next_row_has_data_source) { 
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    // return '-80px' return -100px instead
                                    return '-100px'
                                // return '-65px' return -80px instead
                                return '-80px' 
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    // return '-80px' return -110px instead
                                    return '-110px' 
                                return '-80px'
                                // return '-70px' return -80px instead
                            } else {
                                if (last_node.count <= 3)
                                    // return '-90px' return -165 px instead
                                    return '-165px'
                                // return '-80px' return -140px instead
                                return '-140px'
                            }
                        }
                        else { 
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    return '-110px' //ok as is
                                return '-80px' //ok as is
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    return '-120px'
                                    // return '-110px' return -120px instead
                                // return '-80px' return -90px instead
                                return '-90px'
                            } else {
                                if (last_node.count <= 3)
                                    return '-170px' //ok as is
                                return '-150px' //ok as is
                            }
                        } 
                    } else if (next_row_has_nodes_count_greater_than_three) {
                         if (stacked_node_count <= 4) {
                            if (last_node.count <= 3)
                                return '-105px'
                             // return '-70px' return -80px instead if count >3
                             return '-80px'
                        } else if (stacked_node_count <= 6) {
                            if (last_node.count <= 3)
                                // return '-90px' return -110px instead
                                return '-110px'
                            return '-85px'
                        } else {
                            if (last_node.count <= 3)
                                return '-170px'
                             // return '-70px' return -140px instead
                             return '-140px'
                        }
                    } else {
                         if (stacked_node_count <= 4) {
                            if (last_node.count <= 3)
                                return '-110px' //ok as is
                            return '-80px' //ok as is
                        } else if (stacked_node_count <= 6) {
                            if (last_node.count <= 3)
                                return '-110px' //ok as is
                             // return '-80px' //return -85px instead
                             return '-85px'
                        } else {
                            if (last_node.count <= 3)
                                return '-170px' //ok as is
                            return '-150px' //ok as is
                        }
                    }
                }
                else {
                    if (last_row_has_nodes_count_greater_than_three) {
                        if(last_node.count<=3)
                            return '-20px'
                        return '0px'
                    } else {
                        return '0px'
                    }
                    
                    // if (next_row_has_model && hasOverflow) {
                    //     if (!last_node_has_count_greater_than_two && last_row_has_nodes_count_greater_than_three)
                    //         return '-10px'  
                    //     return '10px'
                    // }
                    // return '0px'
                }
            } 
            if (last_node.type === 'model') {
                if (index === 0) {
                    if (stacked_node_count <= 6) {
                        if (model_has_greater_than_two)
                            return '-45px'
                        return '-60px'
                    }   
                    if (model_has_greater_than_two)
                            return '-105px'
                    return '-120px'
                }
                return '0px' 
            }            
        }

        const minHeight = (index) => {
            const chunk = chunked_nodes[index]
            const last_node = chunk[chunk.length - 1]
            const next_first_node = index + 1 < chunked_nodes.length ? chunked_nodes[index + 1][0] : null
            const next_row_has_model = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'model')
            const model_has_greater_than_two = nodes.find(node=>node.type==='model').count > 2
            const next_row_has_nodes_count_greater_than_three = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].filter(node => node.type === 'datasource').some(node => node.count > 3)
            const last_row_has_nodes_count_greater_than_two = chunk.filter(node => node.type === 'datasource').some(node => node.count > 2)
            const next_row_has_data_source = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'datasource')
            const is_next_node_datasource = index + 1 < chunked_nodes.length && chunked_nodes[index + 1][0].type === 'datasource'
            const next_datasource_count_greater_than_three = is_next_node_datasource && chunked_nodes[index + 1][0].count > 3
            const answer_length = response_answers.answer[response_answers.answer.length - 1].content.length
            
            switch (last_node.type) {
                case 'model':
                    return '50px'
                    break
                case 'was_answered':
                    return '50px'
                    break
                case 'question':
                    if (index === 0) {
                        if (next_row_has_model) {
                            if (model_has_greater_than_two) {
                                if (stacked_node_count <= 4) {
                                    if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '140px'
                                        return '170px'
                                    }
                                    return '50px' //ok as is
                                } else if (stacked_node_count <= 6) {
                                     if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '155px'
                                        return '105px'
                                    }
                                    return '50px' //ok as is
                                }
                                else {
                                    if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '210px'
                                        return '230px'
                                    }
                                    return '50px' //ok as is
                                }
                            } else {
                                if (stacked_node_count <= 4) {
                                    if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '85px'
                                        return '95px'
                                    }
                                    return '50px' //ok as is
                                } else if (stacked_node_count <= 6) {
                                    if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '85px'
                                        return '100px'
                                    }
                                    return '50px' //ok as is
                                 }else {
                                    if (is_next_node_datasource) {
                                        if (next_datasource_count_greater_than_three)
                                            return '130px'
                                        return '170px'
                                    }
                                    return '50px' //ok as is
                                }
                            } 
                        }
                    }
                    break
                case 'datasource':
                    if (index === 0) {
                        if (next_row_has_model) { 
                            if (next_row_has_data_source) { 
                                if (stacked_node_count <= 4) {
                                    if (last_node.count <= 3)
                                        return '150px'
                                    return '130px'
                                } else if (stacked_node_count <= 6) {
                                    if (last_node.count <= 3)
                                        return '160px'
                                    return '130px'
                                } else {
                                    if (last_node.count <= 3)
                                        return '180px'
                                    return '190px'
                                }
                            }
                            else {
                                if (stacked_node_count <= 4) {
                                    if (last_node.count <= 3)
                                        return '50px' //ok as is
                                    return '50px' //ok as is
                                } else if (stacked_node_count <= 6) {
                                    if (last_node.count <= 3)
                                        return '50px' //ok as is
                                    return '50px'//ok as is
                                } else {
                                    return '50px'  //ok as is
                                }
                            }
                        }
                        else if (next_row_has_nodes_count_greater_than_three) {
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    return '100px'
                                return '70px'
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    return '100px'
                                return '75px' 
                            }else {
                                if (last_node.count <= 3)
                                    return '160px'
                                return '130px'
                            }
                        } else {
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    return '100px'
                                return '70px'
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    return '110px'
                                return '75px'
                            }else {
                                if (last_node.count <= 3)
                                    return '160px'
                                return '140px'
                            }
                        }  
                    }
                    if (next_row_has_model) {
                        return '70px'
                    }
                    if (!next_row_has_model) {
                        if(next_row_has_nodes_count_greater_than_three)
                            return '50px'
                        return '50px'
                    }
                    if (hasOverflow) {
                        if (last_row_has_nodes_count_greater_than_two )
                            return '50px'
                        return '50px'
                    }

                    if (last_node.count <= 2)
                        return '50px'
                    return '50px' 
                default:
                    return '50px'
            }
        }
        const marginBottom = (index) => {
            const chunk = chunked_nodes[index]
            const last_node = chunk[chunk.length - 1]
            const next_first_node = index + 1 < chunked_nodes.length ? chunked_nodes[index + 1][0] : null
            const next_row_has_model = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'model')
            const model_has_greater_than_two = nodes.find(node=>node.type==='model').count > 2
            const next_row_has_nodes_count_greater_than_three = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].filter(node => node.type === 'datasource').some(node => node.count > 3)
            const last_row_has_nodes_count_greater_than_two = chunk.filter(node => node.type === 'datasource').some(node => node.count > 2)
            const next_row_has_data_source = index + 1 < chunked_nodes.length && chunked_nodes[index + 1].some(node => node.type === 'datasource')
            const is_next_node_datasource = index + 1 < chunked_nodes.length && chunked_nodes[index + 1][0].type === 'datasource'
            const next_datasource_count_greater_than_three = is_next_node_datasource && chunked_nodes[index + 1][0].count > 3
            const answer_length = response_answers.answer[response_answers.answer.length - 1].content.length

            if (last_node.type === 'model')
                return answer_length < 100 ? '-90px': '-80px'
            if (last_node.type === 'was_answered')
                return answer_length < 100 ? '-80px': answer_length < 190?  '-40px':'-30px'
            if (last_node.type === 'question') {
                if (index === 0) {
                    if (next_row_has_model) {
                        if (model_has_greater_than_two) {
                            if (stacked_node_count <= 4) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        //  return '0px' return '-30px instead
                                        return '-30px'
                                    // return '-10px' return -65px instead
                                    return '-65px'
                                }
                                // return '-10px' return 0px instead
                                 return '0px'
                            } else if (stacked_node_count <= 6) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        //  return '0px' return '-35px instead
                                        return '-35px'
                                    // return '-10px' return -50px instead
                                    return '-50px'
                                }
                                // return '-10px' return 0px instead
                                return '0px'
                            }
                            else {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        return '-35px'
                                        // return '45px' return -35px instead
                                    // return '35px' return -60px instead
                                    return '-60px'
                                }
                                // return '40px' return 0px instead
                                 return '0px'
                            }
                        } else {
                            if (stacked_node_count <= 4) {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        return '-20px'
                                        //  return '0px' return -20px instead
                                    return '-45px'
                                }
                                // return '-10px' return '0px' instead
                                return '0px'
                            } else if (stacked_node_count <= 6) {
                                    if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                         return '-20px' 
                                    return '-45px'
                                }
                                // return '-10px' return '0px' instead
                                return '0px'
                             }
                            else {
                                if (is_next_node_datasource) {
                                    if (next_datasource_count_greater_than_three)
                                        return '-20px'
                                        // return '45px' return -20px instead
                                    // return '35px' return -50px instead
                                    return '-50px'
                                }
                                // return '40px' return '0px' instead
                                return '0px'
                            }
                        } 
                    }
                }
                if (!next_row_has_model) {
                    if (next_row_has_nodes_count_greater_than_three) {
                        if (next_first_node.count <= 2)
                            return '-30px'
                    }
                    return '0px'
                }
                if (next_first_node.type !== 'model') {
                    if (next_first_node.count <= 2)
                        return '-70px'
                    return '-40px'
                }
            }
            if (last_node.type === 'datasource') {
                if (index === 0) {
                    if (next_row_has_model) { 
                        if (next_row_has_data_source) { 
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    // return '-30px' return -60px instead if count < 3
                                    return '-60px'
                                // return '-45px'return -60px instead if count > 3
                                return '-60px'
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    return '-60px'
                                    // return '-30px' return -60px instead
                                // return '-40px' return -60px instead
                                return '-60px'
                            } else {
                                if (last_node.count <= 3)
                                    // return '10px' return -65px instead
                                    return '-65px'
                                return '-60px'
                            }
                        }
                        else {
                            if (stacked_node_count <= 4) {
                                if (last_node.count <= 3)
                                    return '-70px'
                                    // return '-60px' return -70px instead
                                // return '-50px' return -70px instead
                                return '-70px'
                            } else if (stacked_node_count <= 6) {
                                if (last_node.count <= 3)
                                    return '-70px' 
                                    // return '-60px' return -70px instead
                                // return '-55px' return -70px instead
                                return '-70px'
                            } else {
                                 if (last_node.count <= 3)
                                    return '-65px' //ok as is
                                // return '-55px' return '-70px instead
                                 return '-70px' 
                            }
                        }
                    }
                    else if (next_row_has_nodes_count_greater_than_three) {
                        if (stacked_node_count <= 4) {
                            if (last_node.count <= 3)
                                return '-30px' 
                                // return '-15px' return -30px instead if count <=3
                            // return '-20px' return -30px instead if count >3
                            return '-30px'
                        } else if (stacked_node_count <= 6) {
                            if (last_node.count <= 3)
                                // return '-10px' return -30px instead
                                return '-30px'
                            // return '-20px' return -30px instead
                                return '-30px'
                        }else {
                            if (last_node.count <= 3)
                                return '-30px'
                            // return '-20px' return '-30px instead if count > 3.
                            // return '-20px' return '-30px instead 
                            return '-30px'
                        }
                    } else {
                        // return '-10px'
                        if (stacked_node_count <= 4) {
                            if (last_node.count <= 3)
                                // return '-60px' return -10px instead
                                return '-10px'
                            // return '-50px' return -10px instead
                            return '-10px'
                        } else if (stacked_node_count <= 6) {
                            if (last_node.count <= 3)
                                return '-10px'
                                // return '-60px' return -10px instead
                            // return '-55px' return -5px instead
                            return '-5px'
                        }else {
                            if (last_node.count <= 3)
                                return '-10px' 
                                // return '-60px'  return -10px instead
                            // return '-55px' return -10px instead
                            return '-10px'
                        }
                    }  
                }
                if (next_row_has_model) {
                    if (model_has_greater_than_two)
                        return '-60px'
                    return '-50px'
                }
                if (!next_row_has_model) {
                    if(next_row_has_nodes_count_greater_than_three)
                        return '-40px'
                    return '0px'
                }
                if (hasOverflow) {
                    if (last_row_has_nodes_count_greater_than_two )
                        return '-70px'
                    return '-60px'
                }

                if (last_node.count <= 2)
                    return '-60px'
                return '-75px' 
            }   
            return '0px'
        }
        const flexElements = chunked_nodes.map((chunk, index) => {
            const downArrowProps = {
                width: chunk[chunk.length - 1].type === 'question' || chunk[chunk.length - 1].type === 'datasource' ? '154px' : chunk[chunk.length - 1].type === 'was_answered' ? '250px' : '200px',
                minHeight: minHeight(index),
                align: index % 2 == 0 ? 'flex-end' : 'flex-start',
                marginTop: marginTop(index),
                marginBottom: marginBottom(index)
            }
            const downArrowType = chunk[chunk.length - 1].type !== 'question' ? <DownArrow {...downArrowProps} /> : chunk[chunk.length - 1].decision ? <DownArrowYes {...downArrowProps} /> : <DownArrowNo {...downArrowProps}/>
            return (
                <React.Fragment key={index}>
                    <Flex direction={index % 2 === 0 ? 'row' : 'row-reverse'} justify={chunk.length === chunk_size || (index === chunked_nodes.length-2 && chunked_nodes[chunked_nodes.length-1].length===1)? 'space-between' : 'flex-start'} alignItems='center' gap='10px' minH="400px">
                        {chunk.map((node, i) => {
                            let arrowNode = null
                            if (index % 2 === 0)
                                arrowNode = node.type !== 'question' ? rightArrow : !node.decision ? rightArrowNo : rightArrowYes
                            else
                                arrowNode = node.type !== 'question' ? leftArrow :  !node.decision ? leftArrowNo : leftArrowYes

                            return (
                                <React.Fragment key={i}>
                                    <ErrorBoundary fallback={
                                        <Flex bg='red.50' borderRadius='10px' p={4} maxW='250px' border='1px dashed #e53e3e' alignItems='flex-start' gap={ 1}>
                                            <WarningIcon color='red.400' /> <Text as='span' color='red.700' fontSize='14px'>There was an error in displaying {node.type} node. Please check the node data format.</Text>
                                        </Flex>}>
                                        {node.content}
                                    </ErrorBoundary>
                                    {i < chunk.length - 1 && arrowNode}
                                </React.Fragment>)
                        }
                        )}
                    </Flex>
                    {index < chunked_nodes.length - 1 && downArrowType}
                </React.Fragment>
            )
        }
        )

        return (                                
            <Flex direction='column' gap='10px' flex='1' justify='space-evenly'>
                <Flex direction='column' alignSelf='start' gap='5px' alignItems='center' >
                        <Flex gap='5px'>
                            <Image boxSize='32px' src={Avatar} alignSelf='flex-end'></Image>
                            <Flex p='6px 12px' position='relative' borderRadius='18px' bg='#2563EB' alignSelf='flex-start' minW='200px' maxW='260px'>
                            {user_query > 60 ?
                                <Tooltip lacement='bottom' bg='transparent' boxShadow='none'
                                    label={
                                        <Flex bg='#F0F6FF' fontSize='12px' p='10px' alignItems='flex-start' w='331px' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                            <Text as='span' color='#6B7280'>{user_query}</Text>
                                        </Flex>
                                        }>
                                    <Text zIndex='10' fontWeight={500} color='#fff' fontSize='12px'>{user_query.slice(0,60)} ...</Text>
                                </Tooltip>
                                : <Text zIndex='10' fontWeight={500} color='#fff' fontSize='12px'>{user_query}</Text>}
                            <Image position='absolute' left='-5px' bottom='-2px'  boxSize='16px' src={failedUtteranceTail}></Image>
                            </Flex>
                        </Flex>
                    <Button alignSelf='end' variant='link' color='#2563EB' fontSize='12px' fontWeight={400} rightIcon={<HiOutlineArrowNarrowRight size='16px' color='#94A3B8' />} onClick={handleViewChat}>View chat history</Button>
                </Flex>
                <DownArrow width='200px' align='flex-start' marginBottom={stacked_node_count <= 4 ? '-130px' : stacked_node_count <= 6 ? '-140px' : '-190px'} marginTop={stacked_node_count > 6 ? '0px' : '0px'} minHeight={ stacked_node_count > 6?'80px':'50px'}  />
                {flexElements}
            </Flex>        
        )
    }
    
    // useEffect(() => {
    //     const handlePopupOverlayClose = (e) => {
    //     if (modalRef.current && modalRef.current.contains(e.target)) {
    //         return 
    //     }
    //     closeModal()
    //     }
    //     if (isOpen) {
    //     document.body.addEventListener('click', handlePopupOverlayClose)
    //     }
    //     return () => {
    //     document.body.removeEventListener('click', handlePopupOverlayClose)
    //     }
    // }, [isOpen, closeModal])
    return (
        <React.Fragment>
            <Modal isOpen={isOpen}  ref={modalRef} onClose={closeModal} trapFocus={false} scrollBehavior='inside' isCentered data-testid='kg_explanation_container' maxH='100vh'>
                <ModalOverlay />
                <ModalContent p='20px' h='90vh' maxW='90vw' minW='1280px' minH='90vh' ref={contentRef} position='relative' overflow='hidden'>
                    <ModalHeader>
                        <Flex justifyContent='space-between' >
                            <Flex justifyContent='start' gap='5px' alignItems='center'>
                                <Text as='span' fontSize='20px' fontWeight={600} lineHeight='24px'>Explainable AI - </Text>
                                <Text as='span' fontSize='16px' fontWeight={500} lineHeight='24px' color='#666'> {!isGap? '' :was_answered? 'Inaccurate Knowledge - ' : 'Missing Knowledge - '} {explainableData?.userQuery}</Text>
                            </Flex>
                            <GrClose color='#94A3B8' onClick={closeModal} />
                        </Flex>
                    </ModalHeader>
                    <ModalBody  >
                        <Flex direction = 'column' gap='20px' height='100%'>
                            <Flex justify='flex-end' gap='10px' alignItems='center'>
                                {/* <Flex p='10px' gap='10px' alignItems='center'>
                                    <Text as='span' fontWeight={500} minW='max-content' fontSize='15px'>Threshold Score</Text>
                                    <Flex gap='4px' alignItems='center' justify='center' padding='2px 13px' borderRadius='15px' border="1px solid #CF3626">
                                        <Text as='span' fontSize="14px" fontWeight={500} color='#CF3626'>{Number.parseFloat(thresholds?.FlowThreshold*100).toFixed(0)}</Text>
                                    </Flex>  
                                </Flex>
                                <Box h='33px' borderRight='1px solid #d9d9d9'></Box> */}
                                 <Flex p='10px' gap='10px' alignItems='center'>
                                    <Text as='span' fontWeight={500} minW='max-content' fontSize='14px'>Knowledge Sources: </Text>
                                    <Flex flexWrap='wrap' gap='10px'>
                                        {enabled_sources.map((source,i) => {
                                            const displayedSource = source === 'Flow' ? 'Bot Flow Triggers' : source
                                            return(
                                                <Text key={i} as='span' borderRadius='100px' p='2px 8px' border="1px solid #2563EB" minW='max-content' fontSize="14px" fontWeight={400} color='#2563EB'>{displayedSource}</Text>
                                              )
                                        })
                                        }
                                    </Flex>
                                </Flex>
                                <Box h='33px' borderRight='1px solid #d9d9d9'></Box>
                                 <Flex p='10px' gap='10px' alignItems='center'>
                                    <Text as='span' fontWeight={500} fontSize='14px' minW='max-content'>Answer Preferences: </Text>
                                    <Flex flexWrap='wrap' gap='10px'>
                                        <Text as='span' borderRadius='100px' border="1px solid #E79B04" p='2px 8px' minW='max-content' fontSize="14px" fontWeight={400} color='#E79B04'>Answer Strategy: {explainable_data?explainable_data.information.answer_strategy[0].toUpperCase()+(explainable_data.information.answer_strategy).slice(1):''}</Text>
                                        <Text as='span' borderRadius='100px' border="1px solid #E79B04" p='2px 8px' minW='max-content' fontSize="14px" fontWeight={ 400} color='#E79B04'>Bot Tonality: {explainable_data?explainable_data.information.bot_tonality[0].toUpperCase()+(explainable_data.information.bot_tonality).slice(1):''}</Text>
                                   </Flex> 
                                </Flex>
                            </Flex>
                            <FlowChartNodes nodes={nodes} />
                        </Flex>
                        {/* {isOpenInner && ( */}
                            <Flex className='innerModal' position='absolute' right={isOpenInner ? "0" : "-528px"} top="0" height='100%' w='528px' transition="right 0.3s ease-in-out" zIndex={ 1001} borderLeft = '1px solid #D1D5DB ' bg='transparent' justify='flex-end'>
                                <Flex direction='column' gap='20px' p='20px'  w='528px' bg='#fff' borderRadius='8px'>
                                    <Flex gap='4px' alignItems='flex-start' justify='space-between' borderBottom='1px solid #D9D9D9' pb='18px'>
                                        <Text as='span' fontSize="14px" fontWeight={500} data-testid='kg_drawer_home_heading'>{drawerContentType === 'chat' ? 'Chat History' : drawerContentType === 'results' ? 'Search Results' : 'Language Model Inputs'}</Text>
                                        <GrClose color='#94A3B8' onClick={onCloseInner} />
                                    </Flex>
                                    {drawerContentType === 'chat' ? <ChatDetails chatData={ chatData} /> : drawerContentType === 'results' || drawerContentType === 'inputs' ? <MatchedResults data={drawerData} replaceSASUrls={replaceSASUrls } /> :null}
                                </Flex>
                            </Flex>
                        {/* )} */}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}

export default ExplanationModal