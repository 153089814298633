import React, { useEffect, useState } from "react"
import { Box, Text } from "@chakra-ui/react"
import { Link } from '@chakra-ui/react'

const Footer = ()=>{
    return(
        <>
            <Box data-testid='footer' display='flex' justifyContent='flex-end' pe='15px' height='41.5px' alignItems='center' className="footer" >
                <Box width='100%' display='flex' justifyContent='flex-end' ><Link w='100%' fontSize='13px' textAlign='right' href='https://www.rezolve.ai' isExternal >copyrights@rezolve.ai</Link></Box>
            </Box>
        </>
    )
}
export default Footer