import isFilter       from './helpers/isFilter'
import containsFilter from './helpers/containsFilter'
import isNotFilter    from './helpers/isNotFilter'

const StandardFilter = {}
StandardFilter.type  = 'standard'
StandardFilter.filterFn = (rows, id, { condition, filterText }) => {
    const fnMap = {
        'isFilter':       isFilter,
        'containsFilter': containsFilter,
        'isNotFilter':    isNotFilter,
    }

    const helperFn = fnMap[condition] || null
    if (helperFn) {
        return helperFn(rows, id, filterText)
    }

    return rows
}

export default StandardFilter