import React, { useMemo } from 'react'
import _ from 'lodash'
import {
    Flex,
    Box,
} from '@chakra-ui/react'
import { Permissions } from '../../../..'
import SidebarLink from './SidebarLink'
import Subheading  from './Subheading'
import LoadingSpinner from '../../../LoadingSpinner'
import GroupedSubMenu from './GroupedSubMenu'

const Links = ({ theme, links, keyPrefix = '',isExpanded }) => {
    const content = useMemo(() => {
        return _.map(links, (elem, index) => {
            const { type, title, path, isPublic, roles, icon, subheading, heading, children, isVisible } = elem
            const key = `${keyPrefix}links.${index}`
            if (type === 'redirect') {
                return null

            } else if (type === 'sidebarBreak') {
                return <Box key={key} h={4}/>

            } else if (type === 'subHeading') {
                return <Subheading key={key} title={subheading}/>

            } else if (type === 'grouped') {
                const isLoading = _.isEmpty(children)
                return (
                    <Flex key={key} direction="column" bg="none" borderRadius="md">
                        <Subheading title={heading}/>
                        { isLoading &&
                            <LoadingSpinner bg="none" thickness="3px" size="30px" py={3}/>
                        }
                        { !isLoading && _.map(children, ({ id, label, onClick, links }) => (
                            <GroupedSubMenu key={`group.${id}`} theme={theme} label={label} onClick={onClick} links={links}/>
                        ))}
                    </Flex>
                )

            } else if (title && icon && Permissions.isAuthorized({ isPublic, roles }) && isVisible) {
                return (
                    <SidebarLink isExpanded={isExpanded} theme={theme} key={`${keyPrefix}.link.${_.camelCase(title)}.${index}`} title={title} icon={icon} path={path} visible={isVisible}
                    elem={elem}
                    />
                )
            }
        }).filter(x => !_.isNull(x))
    }, [theme, keyPrefix, links,isExpanded])

    return content
}

export default Links