import { Box, Button, Center, Flex, Spinner } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { BiLogIn } from 'react-icons/bi'
import IframeComm from 'react-iframe-comm'
import config from '../../../config'
import { Panel } from '../../ui'

let iframeStyle = {
    position: 'absolute',
    top: '40px',
    left: 0,
    width: '100%',
    height: '100%',
}
const AppLogin = props => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlSearchParams.entries())
    const [teamsParam, setTeamsParam] = useState({})
    const [loginProps, setLoginProps] = useState({
        sessionId: null,
        postMessageData: {},
        iframeurl: '',
        caseId: null,
        configAdal: {},
    })
    const [loginAuth, setLoginAuth] = useState({
        showLogin: false,
        notAuth: false,
    })

    useEffect(() => {
        // @ts-ignore
        window.microsoftTeams.initialize()
        window.microsoftTeams.getContext(teamsContext => {
            setTeamsParam(prevState => {
                return {
                    ...prevState,
                    teamsContext,
                }
            })
            let { appName, tenantId, loginHint } = queryParams
            let AdtenantId = tenantId
            localStorage.setItem('appName', appName)
            localStorage.setItem('AdtenantId', AdtenantId)

            let sessionId, caseId
            if (appName == 'agentchat') sessionId = teamsContext['subEntityId']
            if (appName == 'ticket') caseId = teamsContext['subEntityId']

            // if (appName === "ticket")
            //     sessionId = teamsContext["subEntityId"]

            if (sessionId && sessionId !== '') {
                setLoginProps(prevState => {
                    return {
                        ...prevState,
                        sessionId,
                    }
                })
            }
            if (caseId && caseId !== '') {
                setLoginProps(prevState => {
                    return {
                        ...prevState,
                        caseId,
                    }
                })
            }
            async function fetchData() {
                // let apiUrl = getTenantUris()?.apiUrl
                const response = await fetch(`${config?.globalTenantServiceUrl || config?.apiUrl}/tenant?aad_tenant_id=${AdtenantId}&app_name=${appName}`)
                let tenantResult = await response.json()
                return tenantResult
            }

            fetchData().then(tenant => {
                let configAdal = {
                    tenant: AdtenantId,
                    clientId: tenant && tenant?.teamsApId && tenant.teamsApId,
                    redirectUri: window.location.origin + `/agent/appLoginFinal`,
                    cacheLocation: 'localStorage',
                    navigateToLoginRequestUrl: false,

                    // Setup extra query parameters for ADAL
                    // - openid and profile scope adds profile information to the id_token
                    // - login_hint provides the expected user name
                    extraQueryParameter: `scope=openid+email+offline_access+profile&login_hint=` + encodeURIComponent(loginHint),
                }
                // - login_hint provides the expected user name
                if (loginHint) {
                    configAdal.extraQueryParameter = `scope=openid+offline_access+email+profile&login_hint=` + encodeURIComponent(loginHint)
                } else {
                    configAdal.extraQueryParameter = `scope=openid+email+profile+offline_access`
                }

                setLoginProps(prevState => {
                    return {
                        ...prevState,
                        configAdal,
                        loginHint,
                    }
                })
                // @ts-ignore
                let authContext = new window.AuthenticationContext(configAdal)
                authContext.acquireToken(configAdal.clientId, async (errDesc, token, err, tokenType) => {
                    if (token) {
                        AdtenantId = localStorage.getItem('AdtenantId')
                        appName = localStorage.getItem('appName')

                        // const response = await fetch(
                        //     `${config?.apiUrl}/tenant?aad_tenant_id=${AdtenantId}&app_name=${appName}`
                        // )
                        // let tenant = await response.json()

                        // When there's no cached user, ADAL.js 1.0.17 returns an access token in the callback even when asked for the id token.
                        // To work around this bug, check the token type; if it's not an id token, get the id token from cache.
                        if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
                            console.log('ADAL.js bug: requested id_token, got ' + tokenType)
                            token = authContext.getCachedToken(configAdal.clientId)
                        }
                        if (tenant && tenant.tenantId) {
                            //              that.state.postMessageData.msToken = token
                            setLoginProps(prevState => {
                                return {
                                    ...prevState,
                                    postMessageData: {
                                        ...loginProps.postMessageData,
                                        msToken: token,
                                    },
                                }
                            })

                            if (appName === 'agentchat') {
                                setLoginProps(prevState => {
                                    return {
                                        ...prevState,
                                        iframeurl: `https://${tenant.domain}/agent/inbox?name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${token}`,
                                        postMessageData: loginProps.postMessageData,
                                    }
                                })
                            } else if (appName == 'ticket') {
                                if (loginProps?.caseId) {
                                    setLoginProps(prevState => {
                                        return {
                                            ...prevState,
                                            iframeurl: `https://${tenant.domain}/agent/inbox/${loginProps.caseId}?tenantuid =${tenant.id}&email=${loginHint}&name=${loginHint}name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${token}`,
                                            postMessageData: loginProps.postMessageData,
                                        }
                                    })
                                } else
                                    setLoginProps(prevState => {
                                        return {
                                            ...prevState,
                                            iframeurl: `https://${tenant.domain}/agent/inbox?tenantuid =${tenant.id}&email=${loginHint}&name=${loginHint}name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${token}`,
                                            postMessageData: loginProps.postMessageData,
                                        }
                                    })
                            } else {
                                setLoginProps(prevState => {
                                    return {
                                        ...prevState,
                                        iframeurl: `https://${tenant.domain}/virtualagent/morsel?name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&appName=${appName}&token=${token}`,
                                        postMessageData: loginProps.postMessageData,
                                    }
                                })
                            }
                            setLoginAuth(prevState => {
                                return {
                                    ...prevState,
                                    notAuth: true,
                                    showLogin: false,
                                }
                            })
                            //setTimeout(() => {
                            // @ts-ignore
                            window.microsoftTeams.appInitialization.notifyAppLoaded()
                            // @ts-ignore
                            window.microsoftTeams.appInitialization.notifySuccess()
                            //}, timeOutTime);
                            //$("#myIframe").show();
                        } else {
                            if (appName === 'agentchat' || appName === 'ticket') {
                                alert('open rezolve.ai app here or register james tenant from here')
                            }
                            setLoginAuth(prevState => {
                                return {
                                    ...prevState,
                                    notAuth: true,
                                    showLogin: false,
                                }
                            })
                            // @ts-ignore
                            window.microsoftTeams.appInitialization.notifyAppLoaded()
                            // @ts-ignore
                            window.microsoftTeams.appInitialization.notifySuccess()
                            //$("#myIframe").show();
                        }
                    } else {
                        // alert(errDesc)
                        console.log('Failed to get id token silently: ' + errDesc)
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifyAppLoaded()
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifySuccess()
                        setLoginAuth(prevState => {
                            return {
                                ...prevState,
                                showLogin: true,
                            }
                        })
                        // Failed to get the id token silently; show the login button
                        //  $("#btnLogin").show();

                        // You could attempt to launch the login popup here, but in browsers this could be blocked by
                        // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
                    }
                })
            })
        })
    }, [])

    const login = useCallback(() => {
        let { appName, AdtenantId, loginHint } = queryParams
        //  let apiUrl = getTenantUris()?.apiUrl
        // @ts-ignore
        window.microsoftTeams.authentication.authenticate({
            url: window.location.origin + `/agent/appLoginFallback`,
            width: 600,
            height: 535,
            successCallback: async function (result) {
                // AuthenticationContext is a singleto
                // alert(result)
                AdtenantId = localStorage.getItem('AdtenantId')
                appName = localStorage.getItem('appName')

                const response = await fetch(`${config.globalTenantServiceUrl || config?.apiUrl}/tenant?aad_tenant_id=${AdtenantId}&app_name=${appName}`)
                let tenant = await response.json()
                // alert(JSON.stringify(tenant))
                // @ts-ignore
                let authContext = new window.AuthenticationContext()
                let idToken = authContext.getCachedToken(loginProps?.configAdal?.clientId)
                if (idToken) {
                    loginProps.postMessageData.msToken = idToken
                    if (tenant && tenant.tenantId) {
                        if (appName === 'agentchat') {
                            setLoginProps(prevState => {
                                return {
                                    ...prevState,
                                    iframeurl: `https://${tenant?.domain}/agent/inbox?name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${idToken}`,
                                    postMessageData: loginProps.postMessageData,
                                }
                            })
                        } else if (appName == 'ticket') {
                            if (loginProps?.caseId) {
                                setLoginProps(prevState => {
                                    return {
                                        ...prevState,
                                        iframeurl: `https://${tenant.domain}/agent/inbox/${loginProps?.caseId}?tenantuid =${tenant.id}&email=${loginHint}&name=${loginHint}name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${idToken}`,
                                        postMessageData: loginProps.postMessageData,
                                    }
                                })
                            } else
                                setLoginProps(prevState => {
                                    return {
                                        ...prevState,
                                        iframeurl: `https://${tenant.domain}/agent/inbox?tenantuid =${tenant.id}&email=${loginHint}&name=${loginHint}name=${loginHint}&adTenantId=${AdtenantId}&isTeams=true&sessionId=${loginProps?.sessionId}&appName=${appName}&token=${idToken}`,
                                        postMessageData: loginProps.postMessageData,
                                    }
                                })
                        }
                        setLoginAuth(prevState => {
                            return {
                                ...prevState,
                                showLogin: false,
                                notAuth: true,
                            }
                        })
                        //setTimeout(() => {
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifyAppLoaded()
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifySuccess()
                        //}, timeOutTime);
                        //$("#myIframe").show();
                    } else {
                        if (appName == 'agentInbox') {
                            //alert("open rezolve.ai app here or register james tenant from here")
                        } else {
                            setLoginProps(prevState => {
                                return {
                                    ...prevState,
                                    iframeurl: `https://www.${config?.rootDomains.split(',')[0]}/register?product=Morsel&adTenantId=${AdtenantId}`,
                                }
                            })
                        }
                        setLoginAuth(prevState => {
                            return {
                                ...prevState,
                                showLogin: false,
                                notAuth: true,
                            }
                        })
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifyAppLoaded()
                        // @ts-ignore
                        window.microsoftTeams.appInitialization.notifySuccess()
                        //$("#myIframe").show();
                    }
                } else {
                    console.error('Error getting cached id token. This should never happen.')
                    // At this point we have to get the user involved, so show the login button
                    // $("#btnLogin").show();
                }
            },
            failureCallback: function (reason) {
                //alert(reason)
                console.log('Login failed: ' + reason)
                if (reason === 'CancelledByUser' || reason === 'FailedToOpenWindow') {
                    console.log('Login was blocked by popup blocker or canceled by user.')
                }
                // At this point we have to get the user involved, so show the login button
                // $("#btnLogin").show();
            },
        })
    }, [])

    // parent received a message from iframe
    const onReceiveMessage = useCallback(() => {
        console.log('onReceiveMessage')
    }, [])

    const onReady = useCallback(() => {
        console.log('onReady')
    }, [])

    const { notAuth, showLogin } = loginAuth
    const { postMessageData, iframeurl } = loginProps
    console.log({ teamsParam }, { loginProps }, { loginAuth })
    return (
        <Flex direction="column" gridRowGap={4} pb={12}>
            <Panel>
                {!notAuth && (
                    <Box>
                        {' '}
                        <Center>
                            <Spinner label="Loading Application.." />
                        </Center>
                        <Center>Loading Application...</Center>
                    </Box>
                )}

                <Center>
                    {/* {showLogin && ( */}
                    <Button
                        colorScheme={'blue'}
                        variant="outline"
                        isLoading={notAuth}
                        loadingText="Loading..."
                        onClick={login}
                        rightIcon={<BiLogIn fontSize={'24px'} />}>
                        Click Here to Login
                    </Button>
                    {/* )} */}
                </Center>

                {notAuth && !showLogin && (
                    <Box style={iframeStyle}>
                        <IframeComm
                            attributes={{
                                src: iframeurl,
                                width: '100%',
                                height: '100%',
                                frameBorder: '0',
                            }}
                            postMessageData={JSON.stringify(postMessageData)}
                            handleReady={onReady}
                            handleReceiveMessage={onReceiveMessage}
                        />
                    </Box>
                )}
            </Panel>
        </Flex>
    )
}

export default withTranslation()(AppLogin)
