import _ from 'lodash'
import querystring from 'querystring'

export class ServiceHelper {
    static initialize({ config, IdentityApi }) {
        this.config = config
        this.IdentityApi = IdentityApi
    }
    static setTenantInfo({ apiUrl, basePath, socketUrl }) {
        this.apiUrl = apiUrl || this.apiUrl
        this.basePath = basePath || this.basePath
        this.socketUrl = socketUrl || this.socketUrl
    }
    // private
    // filter: { name, value, operator = 'eq', cond = 'and' }
    static #makeFilterString = ({ filter, addCond = false }) => {
        const defaultCond = 'and'
        const defaultOp = 'eq'

        let result = ''

        if (addCond) {
            result += ` ${filter.cond ? filter.cond : defaultCond} `
        }

        if (filter.operator === 'like') {
            result += `substringof('${filter.value}', ${filter.name})`
        } else if (filter.name && _.isArray(filter.value)) {
            let choices = filter.value.map(c => {
                const val = _.isString(c) ? `'${c}'` : c
                return `${filter.name} eq ${val}`
            })
            result += `(${choices.join(' or ')})`
        } else {
            const op = filter.operator || defaultOp
            result += `${filter.name} ${op} '${filter.value}'`
        }

        return result
    }

    static prepareOdataQueryStr_({ filter, orderBy, skip, limit, top, select, querySymb }) {
        const query = []

        if (filter && _.isArray(filter) && !_.isEmpty(filter)) {
            let filterStr = '$filter='
            _.each(filter, (f, index) => {
                filterStr += this.#makeFilterString({ filter: f, addCond: index !== 0 })
            })

            query.push(filterStr)
        }
        if (select) {
            query.push(`$select=${select.join(',')}`)
        }
        if (orderBy && !_.isEmpty(orderBy)) {
            query.push(`$orderby=${orderBy.join(',')}`)
        }
        if (skip) {
            query.push(`$skip=${skip}`)
        }
        if (limit) {
            query.push(`$limit=${limit}`)
        }
        if (top) {
            query.push(`$top=${top}`)
        }

        if (!_.isEmpty(query)) {
            const prefix = querySymb ? querySymb : ''
            const parts = query.join('&')

            return `${prefix}${parts}`
        }

        return null
    }

    static prepareConfig(options) {
        let { serverUrl, baseUrl, method, headers = {}, reqPath, query, data, form, url } = options || {}
        let _query = query
        if (_.isObject(query)) {
            _query = querystring.stringify(query)
        }
        if (!method) throw new Error(`No api config provided.`)
        let apiUrl = serverUrl || this.apiUrl || this.config.globalTenantServiceUrl
        baseUrl = baseUrl || this.baseUrl
        if (!url) {
            url = `${apiUrl}`
            if (baseUrl) {
                url += `${baseUrl}`
            }
        }
        Object.assign({ 'Content-Type': 'application/json', clientApp: this.config.appRootPath }, headers)
        let apiConfig = {
            method,
            headers,
            url: `${url}${reqPath ? '/' + reqPath : ''}${_query ? `?${_query}` : ''}`,
            data,
            form,
        }
        return apiConfig
    }

    static makeCall(requestOptions) {
        if (this.IdentityApi)  
            return this.IdentityApi.request(requestOptions).then(res => {
            return res
        })
        .catch(function (error) {
            return Promise.reject(error)
        })
        // if ((response && response.status >= 399) || !response) {
        //     return Promise.reject(response)
        // }
        // return response
    }

    static  makeAsyncCall(requestOptions) {
        return this.IdentityApi.request(requestOptions)
            .then(res => {
                return res
            })
            .catch(function (error) {
                return Promise.reject(error)
            })
    }

    static  prepAndCall = options => {
        let reqOpts = this.prepareConfig(options)
        return this.makeCall(reqOpts)
    }

    static prepAndAsyncCall = options => {
        let reqOpts = this.prepareConfig(options)
        return this.makeAsyncCall(reqOpts)
    }
}
