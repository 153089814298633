import * as AppConstants from './app.constants'

const initialAppData = {}
export const appData = (state = initialAppData, action) => {
	// console.log('%%%%%%%%STATE IS ',state);
	// console.log('************* ACTIONS',action)
	// let type = action.type.slice(0,action.type.length- this.resourceName.length -1)
	switch (action.type) {
		case AppConstants.APPDATA_REQUEST:
			return {
				...state,
				loading: true,
				[action.serviceName || 'global']: null,
				error: null,
			}
		case AppConstants.APPDATA_SUCCESS:
			/**
			 * payload to consist of :
			 * tenantConfig
			 * globalSettings
			 * whitelabel
			 * languages
			 *
			 */
			return {
				...state,
				loading: false,
				[action.serviceName || 'global']: action.data || action.payload,
			}
		case AppConstants.APPDATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				[action.serviceName || 'global']: null,
			}
		case AppConstants.SET_TEAMS_DATA:
			const { teams } = action
			return {
				...state, teams,
			}
		default:
			return state
	}
}

const initialTenantState = {
	loading: true,
	isValidated: false,
	registrationInitiated: false,
	isRegistering: false,
	error: null,
}
export const tenant = (state = initialTenantState, action) => {
	switch (action.type) {
		case AppConstants.GET_TENANT_REQUEST:
			return {
				...state,
				loading: true,
				error:   null,
			}

		case AppConstants.GET_TENANT_SUCCESS:
			return {
				...state,
				loading: false,
				...action.tenant,
			}

		case AppConstants.GET_TENANT_FAILURE:
			return {
				...state,
				loading: false,
				error:   action.error,
			}

		case AppConstants.GET_TENANTDATA_REQUEST:
			return {
				...state,
				loading: true,
				error:   null,
			}

		case AppConstants.GET_TENANTDATA_SUCCESS:
			return {
				...state,
				loading: false,
				...action.tenant,
			}

		case AppConstants.GET_TENANTDATA_FAILURE:
			return {
				...state,
				loading: false,
				error:   action.error,
			}

		default:
			return state
	}
}

export const userData = (state = {}, action) => {
	// console.log('%%%%%%%%STATE IS ',state);
	// console.log('************* ACTIONS',action)
	// let type = action.type.slice(0,action.type.length- this.resourceName.length -1)
	switch (action.type) {
		case AppConstants.USERDATA_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
			}
		case AppConstants.USERDATA_SUCCESS:
			/**
			 * profile
			 * user settings  :  notifications on off, language, theme, personalization settings at global level
			 * namespaces
			 *
			 *
			 */
			return {
				...state,
				loading: false,
				data: action.payload,
			}
		case AppConstants.USERDATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state
	}
}
