import {useState,useContext,useRef} from 'react'
import { Box,Stack,Flex,Text,InputGroup,Input, InputRightElement,Button,Divider, Heading, Drawer,DrawerOverlay, DrawerContent, DrawerBody,useDisclosure} from "@chakra-ui/react"
import { ToastContainer, toast } from "react-toastify"
import { FiSearch, FiUpload } from "react-icons/fi"
import { RiDeleteBin2Line } from "react-icons/ri"
import { WorkspaceContext } from '../../commonComponents/Layout/Layout'
import { useServicesContext } from "services/apiServices"
import { useDocumentContext } from "./context/DocumentContext"
import MyDevicePicker from './MyDevicePicker'
import TableComponent from './Table'
import DeleteModal from 'app/commonComponents/DeleteModal'
import Search from 'app/commonComponents/utils/Search'
import  ErrorPage from 'app/commonComponents/ErrorPage'

const DocumentContainer = () => {
    const [searchByName,setSearchByName]= useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const {workspaceMetadata} = useContext(WorkspaceContext)
    const {globalState, bulkDelete, onUploadSuccess, onUploadFailure } = useDocumentContext()
    const {disabledSources,commonAttributes, categories, simpleTags, dimensions,globalState:commonGlobalState} = useServicesContext()
    const deleteModalMessage = useRef('')
    const isEnabled = !disabledSources.includes('Documents')
    
    const openDeleteModal = () => {
        setIsModalOpen(true)
        deleteModalMessage.current = globalState.checkedFiles.length === 0 ? 'No files selected to delete'
                                    :globalState.checkedFiles.length === 1 ? `Are you sure to you want to delete ${globalState.documents.find(doc=>doc.id===globalState.checkedFiles[0]).docName}?` 
                                    : `Are you sure to you want to delete ${globalState.checkedFiles.length} files`
    }

    return (
        <Stack  borderRadius="10px" pos="relative" color='#617182'>
            <Flex justify="space-between" alignItems="center" borderBottom="1px solid #D1D5DB" paddingBottom="15px">
                <Heading as="h6" className="page-title" >Documents</Heading>
                <Flex alignItems="center" gap="10px">
                    {isEnabled && <Search searchPlaceholder={'Search By Name'} search={searchByName} setSearch={setSearchByName} />}
                    {globalState.checkedFiles.length>0 && <button style={{height:"36px", display:"flex", alignItems:"center", gap:"10px"}} className="primary-button" onClick={openDeleteModal} > <RiDeleteBin2Line size="17px" /> <Text style={{paddingTop:"2px"}}>Delete</Text> </button>} 
                    <Box position="relative" display='flex' >
                        {isEnabled && <Button h="36px" className="primary-button" leftIcon={<FiUpload size="19px" />} onClick={onOpen} isDisabled={workspaceMetadata === 'noWorkSpace'} title={workspaceMetadata === 'noWorkSpace' ? 'Please select valid workspace' : ''} ><Text paddingTop="2px">Upload</Text></Button>}
                        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md"  >
                            <DrawerOverlay />
                            <DrawerContent minW='800px'>
                            <DrawerBody p='0' >
                                <MyDevicePicker handleClose={onClose} commonAttributes={commonAttributes} categories={categories} simpleTags={simpleTags} dimensions={dimensions} totalFiles={commonGlobalState.totalRecords} onUploadSuccess={onUploadSuccess} onUploadFailure={onUploadFailure} parent='upload' />
                            </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </Box>
                </Flex>
            </Flex>
            <Box className="custom-table-header-th" height="calc(100vh - 200px)">
            {isEnabled ? <TableComponent searchByName={searchByName} />
                :
             <ErrorPage errorTitle='Access Forbidden' errorMessage={`Sorry, you don't have permission to access this page. If you believe this is an error, please contact your customer service manager.`} statusCode={403} buttonText='Go Back' />
            }
            <DeleteModal isOpen={isModalOpen} closeModal={()=>setIsModalOpen(false)} callback={bulkDelete} message={deleteModalMessage.current}/>
            <ToastContainer/>
            </Box>
        </Stack>
    )
}

export default DocumentContainer