import React, { useEffect } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import config from '../../../config'
const AppLoginFinal = props => {
    useEffect(() => {
        window.microsoftTeams.initialize()
        const urlSearchParams = new URLSearchParams(window.location.search)
        const queryParams = Object.fromEntries(urlSearchParams.entries())
        let { tenantId, appName } = queryParams
        let AdtenantId = tenantId
        AdtenantId = localStorage.getItem('AdtenantId')
        appName = localStorage.getItem('appName')

        async function fetchData() {
            try {
                const response = await fetch(`${config.globalTenantServiceUrl || config?.apiUrl}/tenant?aad_tenant_id=${AdtenantId}&app_name=${appName}`)
                let tenant = await response.json()
                let configAdal = {
                    clientId: tenant && tenant.teamsApId && tenant.teamsApId, // || morselAppId,//"5f886110-742c-4dfa-95e0-a73b5b3fc6f5",
                    redirectUri: window.location.origin + `/agent/appLoginFinal`, // This should be in the list of redirect uris for the AAD app
                    cacheLocation: 'localStorage',
                    navigateToLoginRequestUrl: false,
                }

                let authContext = new window.AuthenticationContext(configAdal)

                if (authContext.isCallback(window.location.hash)) {
                    authContext.handleWindowCallback(window.location.hash)
                    // Only call notifySuccess or notifyFailure if this page is in the authentication popup (not iframed)
                    if (window.parent === window) {
                        if (authContext.getCachedUser()) {
                            // @ts-ignore
                            window.microsoftTeams.authentication.notifySuccess()
                        } else {
                            // @ts-ignore
                            window.microsoftTeams.authentication.notifyFailure(authContext.getLoginError())
                        }
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
        fetchData()
    }, [])
    return <></>
}
export default withTranslation()(AppLoginFinal)
