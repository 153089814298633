import { createBrowserHistory } from 'history'

// const configureHistory = config => {
const configureHistory = () => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    // const { appRootPath } = config
    // return createBrowserHistory({ basename: appRootPath })
    return createBrowserHistory()
}

export default configureHistory
