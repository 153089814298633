import * as identityConstants from './identity.constants'
import createIdentityService from './identity.services'

import { createI18n, Permissions } from '../'

// const createIdentityActions = ({ config, history, AlertActions }) => {
const createIdentityActions = ({ config, AlertActions }) => {
    const { debugIdentityService: DEBUG } = config
    const log = (...msg) => {
        if (DEBUG) {
            console.log('AUTH:', ...msg)
        }
    }

    const { IdentityService, IdentityApi } = createIdentityService({ config })

    const initiateIdentityService = (dispatch, getState) => {
        const { identity, tenant } = getState()

        const teamsLogin = async () => {
            let identityInfo = await IdentityService.initiateIdentityService(tenant, dispatch)
            const { userPayload, signedPayload } = identityInfo
            let roles = null
            if (userPayload && userPayload.profile && userPayload.profile.roles) roles = userPayload && userPayload.profile && userPayload.profile.roles
            else if (userPayload && userPayload.userInfo && userPayload.userInfo.roles)
                roles = userPayload && userPayload.userInfo && userPayload.userInfo.roles

            const newIdentity = {
                ...userPayload,
                token: signedPayload,
                roles: roles,
            }
            Permissions.initialize({ config, tenant, identity: newIdentity })
            dispatch(loginSuccess(newIdentity))
        }
        teamsLogin()
        return true
    }

    const ensureAuthentication = onAuthenticatedCb => async (dispatch, getState) => {
        const { identity, tenant } = getState()
        IdentityService.setup(tenant.tenantId, tenant.idpUrl)
        const urlSearchParams = new URLSearchParams(window.location.search)
        const queryParameters = Object.fromEntries(urlSearchParams.entries())
        if (queryParameters && (queryParameters.isTeams == true || queryParameters.isTeams == 'true')) {
            await initiateIdentityService(dispatch, getState)
            onAuthenticatedCb()
        } else {
            if (IdentityService.verifyAuth({ identity, tenant })) {
                log('ALL GOOD')
                createI18n(tenant.id, identity.token, tenant.apiUrl)
                Permissions.initialize({ config, tenant, identity })
                dispatch(touch())
                onAuthenticatedCb()
            } else {
                // dispatch(initiateLogin())
                IdentityService.ensureLogin({ tenant })
                    .then(({ token, userInfo, realmAccess, tokenParsed, timeSkew }) => {
                        const roles = realmAccess?.roles ?? null
                        createI18n(tenant.id, token, tenant.apiUrl)
                        const newIdentity = {
                            isAuthenticated: true,
                            token,
                            userInfo,
                            profile: {
                                ...userInfo,
                                tenantId: tenant.tenantId,
                                tenantUid: tenant.id,
                                userId: userInfo.sub,
                                roles,
                                tenantRoles: roles,
                            },
                            roles,
                            exp: tokenParsed.exp,
                            iat: tokenParsed.iat,
                            timeSkew: timeSkew,
                            expiryDate: new Date((tokenParsed.exp + timeSkew) * 1000).toLocaleString(),
                        }

                        Permissions.initialize({ config, tenant, identity: newIdentity })
                        dispatch(loginSuccess(newIdentity))
                        onAuthenticatedCb()

                        log('LOGGED IN')
                    })
                    .catch(error => {
                        log('LOGIN ERROR:', error)
                        dispatch(loginFailure())
                    })
            }
        }
    }

    const logout = () => {
        return async (dispatch, getState) => {
            const { tenant, agentchat } = getState()

            if (agentchat && agentchat.isAgentAvailable === 'Available') {
                AlertActions.error('Cannot logout while you are available for chat as an agent, please turn off your availability status')
            } else {
                AlertActions.info('Logging out...')
                IdentityService.logout(tenant.tenantId)
                dispatch(logoutSuccess())
            }
        }
    }

    // Action Creators
    //

    const loginSuccess = payload => ({
        type: identityConstants.LOGIN_SUCCESS,
        payload,
    })

    const loginFailure = () => ({
        type: identityConstants.LOGIN_FAILURE,
        payload: {
            isAuthenticated: false,
            token: null,
            userInfo: {},
            isError: true,
        },
    })

    const logoutSuccess = () => ({ type: identityConstants.LOGOUT_SUCCESS })

    // Triggers a state change in identity, without changing any other data
    const touch = () => ({ type: identityConstants.TOUCH })

    // const loginCheckRequest = () => {
    // 	//sessionStorage.clear();
    // 	// localStorage.clear();
    // 	return {
    // 		type: identityConstants.LOGIN_CHECK,
    // 		payload: {
    // 			isInitiated: true,
    // 		},
    // 	}
    // }

    // const loginCheckSuccess = () => {
    // 	//sessionStorage.clear();
    // 	return {
    // 		type: identityConstants.LOGIN_CHECK,
    // 		payload: {
    // 			isInitiated: false,
    // 		},
    // 	}
    // }

    // const logout = t => {
    // 	return async (dispatch, getState) => {
    // 		const { tenant, agentchat } = getState()
    // 		if (agentchat && agentchat.isAgentAvailable === 'Available') {
    // 			dispatch(AlertActions.error(t('Cannot logout while you are available for chat as an agent, please turn off your availability status')))
    // 		} else {
    // 			dispatch(AlertActions.info(t('Logging out...')))
    // 			dispatch(logoutSuccess())

    // 			await IdentityService.logoutService(tenant)
    // 		}
    // 	}
    // }

    // const setIdentitySuccess = (userInfo, tenant) => {
    // 	let { singleTenant } = config || {}
    // 	return function (dispatch) {
    // 		let keycloak = IdentityService.keycloakInstances[tenant.tenantId]
    // 		if (!singleTenant) {
    // 			// TODO
    // 			// dispatch(settingsActions.getAllGlobalSettings(tenant.id, userInfo.preferred_username)) not being used.
    // 			// dispatch(AppActions.getServiceConfigData())
    // 			// dispatch(AppActions.getAllTenantConfig((tenant && tenant.id) || null))
    // 			// dispatch(settingsActions.getSsoData(tenant.tenantId))
    // 			// dispatch(whiteLabelActions.getDataWithDefaultTenant(tenant.id))
    // 		}

    // 		return dispatch({
    // 			type: identityConstants.SET_IDENTITY,
    // 			payload: {
    // 				userInfo: userInfo,
    // 				isAuthenticated: true,
    // 				isInitiated: false,
    // 				profile: {
    // 					...userInfo,
    // 					tenantId: tenant.tenantId,
    // 					tenantUid: tenant.id,
    // 					userId: userInfo.sub,
    // 					roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
    // 					tenantRoles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
    // 					// created_at: userInfo.profile.attributes.created_at[0],
    // 				},
    // 				roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
    // 				exp: keycloak.tokenParsed.exp,
    // 				iat: keycloak.tokenParsed.iat,
    // 				timeSkew: keycloak.timeSkew,
    // 				expiryDate: new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString(),
    // 			},
    // 		})
    // 	}
    // }

    // const setIdentity = () => {
    // 	return (dispatch, getState) => {
    // 		const { tenant } = getState()
    // 		log(IdentityService.keycloakInstances[tenant.tenantId])
    // 		IdentityService.keycloakInstances[tenant.tenantId]
    // 			.loadUserInfo()
    // 			.success(userInfo => dispatch(setIdentitySuccess(userInfo, tenant)))
    // 			.error(err => dispatch(loginFailure(err)))
    // 	}
    // }

    // const checkIdentity = () => {
    // 	return async (dispatch, getState) => {
    // 		const { tenant, identity } = getState()
    // 		if (tenant.isTenant) {
    // 			dispatch(loginCheckRequest())
    // 			IdentityService.setup(tenant.tenantId)
    // 			await IdentityService.ensureAuthentication({ dispatch, identity, tenant })
    // 		} else {
    // 			history.push('/error')
    // 		}
    // 	}
    // }

    // const support = () => {
    // 	let { appRootPath } = config || {}
    // 	if (appRootPath.slice(1) === 'tickets') {
    // 		return async () => {
    // 			history.push(`${appRootPath}/Support`)
    // 		}
    // 	} else {
    // 		return async () => {
    // 			history.push('/Support')
    // 		}
    // 	}
    // }

    // const getUserRole = async (tenantId, userId)=>{
    //     let roles = await IdentityService.getUserRoleById(tenantId, userId)
    //     return roles;
    // }

    // const getRolesByTenant = async (tenantId)=>{
    //     let roles = await IdentityService.getRolesByTenant(tenantId)
    //      roles = await Promise.all(roles.map(async role=>{
    //         let _role = await IdentityService.getTenantRoleById(tenantId, role.id)
    //         return _role;
    //      }))
    //     return roles;
    // }

    // const getTenantRoleById = async (tenantId, roleId) => {
    // const getTenantRoleById = async (tenantId) => {
    // 	let roles = await IdentityService.getRolesByTenant(tenantId)
    // 	return roles
    // }

    return {
        IdentityApi,
        IdentityActions: {
            ensureAuthentication,
            logout,
            initiateIdentityService,
        },
    }
}

export default createIdentityActions
