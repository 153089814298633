import marked from 'marked'
import { ContentState, ContentBlock, genKey } from 'draft-js'
import turndown from 'turndown'

let turndownService = new turndown()
turndownService.addRule('strikethrough', {
  filter: ['del', 's', 'strike'],
  replacement: function (content) {
    return '~~' + content + '~~'
  },
})
turndownService.addRule('h5', {
  filter: ['h3', 'h3', 'smallheading'],
  replacement: function (content) {
    return '##### ' + content
  },
})
//turndown rule for video tag
turndownService.addRule('video', {
  filter: ['video'],
  replacement: function (content) {
    return `[video]${content}[/video]`
  },
})

const VideoBlock = props => {
  return (
    <video
      controls
      poster={props.defaultPosterUrl}
      preload="none"
      src={props.link}
    />
  )
}

const ImageBlock = props => {
  return <img src={props.link} alt={props.title || props.link}/>
}
const AttachmentBlock = props => {
  return (
    <a href={props.link}>
      <img
        alt={props.title}
        style={{ maxWidth: '80px' }}
        src={props.icon || props.attachment}
      />
    </a>
  )
}

const ImgLinkBlock = props => {
  const {type,link,name} = props
  if(type==='link' && link && name)
  return (
    <a href={link}>
        {name}
    </a>
  )
  if(type==='image' && link && name)
  return (
    <img src={link} alt={name}/>
  )
}
// media component for blockrender func of textEditor for rendering video and image in editor
const Media = props => {
  if (props?.block?.getEntityAt(0)) { 
    const entity = props?.contentState?.getEntity(props?.block?.getEntityAt(0))
    const da  = entity.getData()
    const { icon, src, link, ftype, linkName } = da
    const type = entity.getType()
    let media
    if (type === 'VIDEO') {
      media = <div><VideoBlock link={src ?? link} /><p>&nbsp;</p></div>
    } else if (type === 'IMAGE') {
      media = <ImageBlock link={src} />
    } else if (type === 'ATTACHMENT') {
      media = <div><AttachmentBlock icon={icon} link={link} /><p>&nbsp;</p></div>
    }
    else if (type === 'IMGLINK') {
      media = <div><ImgLinkBlock icon={icon} type={ftype} name={linkName} link={link} /><p>&nbsp;</p></div>
    }
    return media
  }
}


// custom entity added for video in textEditor using in draftToHTML before save and update
const customEntityTransform = (entity, icons={}) => {
  if (entity && entity.type === 'VIDEO') {
    return `<video preload="none" poster="${icons.poster}" src="${
      entity.data.link || entity.data.src
    }">${entity.data.link || entity.data.src}</video>`
  } else if (entity && entity.type === 'ATTACHMENT') {
    return `<a href=${entity.data.link || entity.data.src}><img src=${
      entity.data.icon || icons.attachment
    } /></a>`
  } 
  else if (entity && entity.type==="IMGLINK"){
    const {ftype,link,linkName}=entity.data
    if(ftype && ftype==='image')
    return `<picture>${linkName}</picture>`
    else
    return `<iLink>${linkName}</iLink>`
  }
  else return
}

//convert video markdown to video tag
const convertCustomTags = (value, editor, icons={}) => {
  if (value && typeof value == 'string') {
    let convertToTags = value
    convertToTags = value.replace(
      /\[video\](.*?)\[\/video\]/g,
      `<video poster="${icons.defaultPosterUrl}" preload="none" src="$1"></video>`
    )
    if (editor) {
      convertToTags = convertToTags.replace(
        /\[(?:!\[(.*?)\]\((.*?)\))\]\((.*?)\)/g,
        (matchedString, title, icon, link) => {
          return `<attachment icon="${icon}" link="${link}"></attachment>`
        }
      )
    }
    return convertToTags
  } else return null
}
//convert video markdown to video tag

//Block renderer Function for Editor (video and images) atomic block check
const mediaBlockRenderer = block => {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: true,
    }
  }
  return null
}
//Block renderer Function for Editor (video and images) atomic block check

const renderAttachment = (nodeName, node) => {
  if (nodeName === 'attachment' && node) {
    let icon = node.getAttribute('icon')
    let link = node.getAttribute('link')
    return {
      type: 'ATTACHMENT',
      mutability: 'MUTABLE',
      data: { icon, link },
    }
  }
}

const serializeFileName = val => {
  if (val) return val.replace(/[/\\?%*.@$#&~^_()[\]\s+:|"<>]/g, '-')
}

const getIcon = (type, icons={}) => {
  if (type && typeof type === 'string' && icons) {
    return icons[type] ? icons[type] : icons['default']
  } else return icons['default']
}
const renderContent = () => {
  let render = new marked.Renderer()
  render.list = function (body, ordered ) {
    let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
    if (ordered) {
      return `<ol>${newtext}</ol>`
    } else {
      return `<ul>${newtext}</ul>`
    }
  }

  render.blockquote = function (quote) {
    let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
    return `<blockquote>${newtext}</blockquote>`
  }
  render.code = function (string) {
    return string
  }
  render.image = function (href, title, text) {
    var newImage = `<img src="${href}" alt="${title || text || 'Image'}"><p></p>`
    return newImage
  }
  return render
}
const atomicBlocks = async content => {
  const lastType = content.getLastBlock().getType()

  if (lastType === 'atomic') {
    const blankLine = new ContentBlock({
      key: genKey(),
      text: '',
      type: 'unstyled',
    })

    const newBlockArray = content
      .getBlockMap()
      .set(blankLine.key, blankLine)
      .toArray()

    return ContentState.createFromBlockArray(newBlockArray)
  }

  return content
}

const fixSublists = node => {
  var ulElements = Array.from(node.getElementsByTagName('ul'))
  var olElements = Array.from(node.getElementsByTagName('ol'))
  var listElements = ulElements.concat(olElements)
  listElements.forEach(function (listNode) {
    if (listNode.previousElementSibling.tagName.toUpperCase() === 'LI') {
      listNode.previousElementSibling.appendChild(listNode)
    }
  })
  return node
}

const convertToOutput = async ({
  value,
  colorPicker,
  list,
}) => {
  let output = value || {}
    if (colorPicker) {
      turndownService.addRule('p', {
        filter: ['p'],
        replacement: (content, node) => node.outerHTML + '\n\n',
      })
    }
    if (list) {
      turndownService.addRule('list', {
        filter: ['ul', 'li', 'ol'],
        replacement: (content, node) => {
          let root = fixSublists(node)
          return root.outerHTML
        },
      })
    }
    output = await turndownService.turndown(value)
  return output
}
export const convertMediaPathToPvtCdn = ({
  value,
  tenantId,
  purpose,
  cdnUri,
}) => {
  let responseValue = value
  if (value && tenantId) {
    let regex = new RegExp(`${cdnUri}/${tenantId}-${purpose}/`, 'g')

    responseValue = value.replace(regex, '')
    responseValue = responseValue.replace(/\?sv=([^)|[]+)/g, '')

    //regex for video to map video src within SASUrl[]
    responseValue = responseValue.replace(
      /\[video\](.*?)\[\/video\]/g,
      (matched, url) => {
        return `[video]SASUrl[${url}][/video]`
      }
    )
    //regex for video to map video src within SASUrl[]

    //regex for images to map image src within SASUrl[]
    responseValue = responseValue.replace(
      /!\[[^\]]*\]\((.*?)\s*(?:"(?:.*[^"])")?\s*\)/g,
      (matchedString, SASString) => {
        if (isValidUrl(SASString)) return `![image](${SASString})`
        else {
          if (SASString.indexOf('SASUrl') == -1)
            return `![image](SASUrl[${SASString}])`
          else {
            let newSASString = SASString.replace(
              /SASUrl\[['"]*([^\]^'^"]*)['"]*\]/g,
              '$1'
            )
            if (newSASString) {
              if (isValidUrl(newSASString)) return `![image](${newSASString})`
              else return `![image](SASUrl[${SASString}])`
            } else return SASString
          }
        }
      }
    )

    //regex for attachment to map attachment src within SASUrl[]
    responseValue = responseValue.replace(
      /\[(?:!\[(.*?)\]\((.*?)\))\]\((.*?)\)/g,
      (matchedString, title, icon, link) => {
        return `[![image](${icon})](SASUrl[${link}])`
      }
    )
    //regex for attachment to map attachment src within SASUrl[]
  }
  return responseValue
}
export const isValidUrl = url => {
  try {
    new URL(url)
  } catch (_) {
    return false
  }
  return true
}
export const _processOtherMedia = text => {
  if (text) {
    text = text.replace(
      /SASUrl[['"](.*?)['"]*]/g,
      (matchedString, SASString) => {
        return SASString
      }
    )
    return text
  }
}

export {
  Media,
  customEntityTransform,
  convertCustomTags,
  mediaBlockRenderer,
  renderAttachment,
  serializeFileName,
  getIcon,
  renderContent,
  atomicBlocks,
  convertToOutput,
}
