import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react'
import _ from 'lodash'
import {
    Flex,
    Text,
    Button,
    ButtonGroup,
    Checkbox,
    IconButton,
} from '@chakra-ui/react'
import {
    BiGridAlt,
    BiTable,
} from 'react-icons/bi'
import {
    useAsyncDebounce,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table"
import propTypes from './DataTable.propTypes'

import filterTypes from './filters'

import TableToolbar from './TableToolbar'
import TableCell from './helpers/TableCell'

import TableView from './views/TableView'
import CardsView from './views/CardsView'
import ThemeContext from '../theme/ThemeContext'

const FILTER_DEBOUNCE = 80 // milliseconds
const CHECKBOX_COL_NAME = 'checkbox'

const Views = {
    Table: 'table',
    Cards: 'cards',
}

const DataTable = ({
    records,
    totalCount,
    headers,
    onFetchData,
    getRowActions,
    getGroupActions,
    tableHeight = 500,
    rowHeight = 80,
    searchPlaceholder,
    customFormatters = null,
    defaultView = Views.Table,
    cardViewOpts,
    customCardBody,
    options = {},
}) => {
    const {
        showToolbar  = true,
        enableSelect = false,
        viewOpt=true
    } = options

    const { theme } = useContext(ThemeContext)

    const enabledInfiniteScroll = useMemo(() => _.isFunction(onFetchData), [onFetchData])
    const allDataIsDownloaded   = useMemo(() => (records.length === totalCount), [records.length, totalCount])
    const isSelectable = enableSelect && _.isFunction(getGroupActions)

    // Can switch between different data views
    //
    const [view, setView] = useState(defaultView)
    const isTableView = useMemo(() => (view === Views.Table), [view])
    const isCardsView = useMemo(() => (view === Views.Cards), [view])
    const changeView = useCallback(_view => () => setView(_view), [])
    const viewOptions = useMemo(() => (
        <Flex direction="row" gridColumnGap={2} alignItems="center">
            <Text fontWeight="semibold">View:</Text>
            <ButtonGroup size="sm" isAttached colorScheme="blue">
                <IconButton isActive={isTableView} icon={<BiTable/>} onClick={changeView(Views.Table)}/>
                <IconButton isActive={isCardsView} icon={<BiGridAlt/>} onClick={changeView(Views.Cards)}/>
            </ButtonGroup>
        </Flex>
    ), [changeView, isTableView, isCardsView])

    const renderCheckboxCell = useCallback(({ row }) => {
        const { checked } = row.getToggleRowSelectedProps()
        const rowProps = {...row.getToggleRowSelectedProps()}
        delete rowProps.indeterminate
        return (
            <Checkbox {...rowProps} bgColor={theme.bg1} h="unset" isChecked={checked}/>
        )
    }, [theme])

    const renderAction = useCallback(({ text, color, variant, onClick, disabled }) => (
        <Button key={`action.${text}`} colorScheme={color} variant={variant}
                borderWidth="2px" onClick={onClick} disabled={disabled}>
            {text}
        </Button>
    ), [])

    const renderRowActionCell = useCallback(({ row: { original } }) => (
        <ButtonGroup size="xs" gridColumnGap={1} alignSelf="center">
            { _.map(getRowActions(original), action => renderAction(action)) }
        </ButtonGroup>
    ), [getRowActions, renderAction])

    const defaultRenderFn = useCallback(headerProps => cellProps => (
        <TableCell customFormatters={customFormatters} options={options} {...headerProps} {...cellProps} />
    ), [customFormatters, options])

    const transformAndRender = useCallback((transformFn, renderFn) => headerProps => cellProps => {
        const newCellProps = {...cellProps}
        newCellProps.value = transformFn({ ...headerProps, ...newCellProps })
        return renderFn(headerProps)(newCellProps)
    }, [])

    const customRenderFn = useCallback(fn => headerProps => cellProps => {
        return fn({ ...headerProps, ...cellProps })
    }, [])

    const columns = useMemo(() => {
        const cols = []

        if (isSelectable) {
            // Push checkbox column
            cols.push({
                id:        CHECKBOX_COL_NAME,
                accessor:  () => null,
                Header:    "",
                Cell:      renderCheckboxCell,
                canSort:   false,
                canToggle: false,
                canFilter: false,
                disableSortBy:  true,
                disableFilters: true,
                disableGlobalFilter: true,
                hasFixedWidth: true,
            })
        }


        _.each(headers, header => {
            const isSimpleHeader = _.isString(header)
            const _header = isSimpleHeader ? { key: header } : header
            // TODO: check header schema is valid
            const { key, label, fixedWidth, renderFn, transformFn, canSearch, canSort, sortType = 'alphanumeric',
                    canToggle, canFilter, filter = 'standard' } = _header

            const column = {
                id:       key,
                accessor: key,
                Header:   label || _.capitalize(key),
                Filter:   <></>,
                filter,
                canSort,
                canToggle,
                canFilter,
                disableFilters: !canFilter,
                disableSortBy:  !canSort,
                disableGlobalFilter: !canSearch,
                width: fixedWidth,
                hasFixedWidth: !!fixedWidth,
                sortType,
            }

            // Figure out render fn
            let cellRenderFn = _.isFunction(renderFn) ? customRenderFn(renderFn) : defaultRenderFn

            // If transform, then nest renderFn into the transformFn
            if (_.isFunction(transformFn)) {
                cellRenderFn = transformAndRender(transformFn, cellRenderFn)
            }

            column.Cell = cellRenderFn(_header)

            cols.push(column)
        })

        if (_.isFunction(getRowActions)) {
            cols.push({
                id:       'rowActions',
                accessor: () => null,
                Header:   '',
                Cell:     renderRowActionCell,
                canSort: false,
                canToggle: false,
                canFilter: false,
                disableFilters: true,
                disableSortBy:  true,
                disableGlobalFilter: true,
            })
        }

        return cols
    }, [headers, isSelectable, renderCheckboxCell, getRowActions, renderRowActionCell, defaultRenderFn, customRenderFn, transformAndRender])

    const defaultColumn = React.useMemo(() => ({ width: 150 }), [])

    const getRowSize = useCallback(() => rowHeight, [rowHeight])

    // If infinite scroll is enabled, we will refetch data when sorters change,
    // in which case as data changes, don't auto-reset sort, filters state
    const autoResetSortBy  = !enabledInfiniteScroll
    const autoResetFilters = !enabledInfiniteScroll

    const plugins = [
        useBlockLayout,
        useGlobalFilter,
        useFilters,
        useSortBy,
    ]

    if (enableSelect) {
        plugins.push(useRowSelect)
    }

    const {
        state: tableState,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        selectedFlatRows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        allColumns,
        isAllRowsSelected,
        toggleAllRowsSelected,
    } = useTable({
        columns,
        data: records,
        defaultColumn,
        filterTypes,
        autoResetSortBy,
        autoResetFilters,
    }, ...plugins)

    const { globalFilter, filters, sortBy } = tableState
    const handleSearchChange = useAsyncDebounce(value => {
        setGlobalFilter(value || "")
    }, FILTER_DEBOUNCE)

    const toggleAllRows = useCallback(event => {
        const isSet = event.target.checked
        toggleAllRowsSelected(isSet)
    }, [toggleAllRowsSelected])

    const renderGroupActions = useCallback(() => {
        if (!isSelectable) {
            return null
        }

        const selectedRows = _.map(selectedFlatRows, row => row.original)

        return (
            <ButtonGroup gridColumnGap={1} alignSelf="center">
                { _.map(getGroupActions(selectedRows), action => renderAction(action)) }
            </ButtonGroup>
        )
    }, [isSelectable, getGroupActions, renderAction, selectedFlatRows])

    const headerBgColor = useMemo(() => theme.isLight ? "gray.200" : "blackAlpha.600", [theme])

    // This state tracks if we are trying to load additional rows
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)

    // This state tracks if we are re-fetching data,
    // and resetting infinte scroll / pagination
    const [isRefetchingData, setIsRefetchingData] = useState(false)

    // Reset both states after data changes
    useEffect(() => {
        if (isRefetchingData) {
            setIsRefetchingData(false)
        }
        if (isLoadingMoreData) {
            setIsLoadingMoreData(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records])

    // Make sure we don't call with the same params multiple times
    const handleFetchData = useCallback((params = {}) => {
        if (enabledInfiniteScroll && !isLoadingMoreData && !isRefetchingData) {
            const fetchParams = _.cloneDeep(params)
            if (_.isUndefined(fetchParams.skip)) {
                fetchParams.skip = 0
            }

            onFetchData(fetchParams)
            setIsLoadingMoreData(true)
        }
    }, [enabledInfiniteScroll, onFetchData, isLoadingMoreData, isRefetchingData])

    // When filters change, fetch data from scratch
    const [currentFilters, setCurrentFilters] = useState(filters)
    useEffect(() => {
        if (!_.isEqual(filters, currentFilters)) {
            setCurrentFilters(filters)

            if (enabledInfiniteScroll && !allDataIsDownloaded && filters) {
                // Translate filters into odata compatible list
                const filter = _.map(filters, ({ id, value: { operator, filterText }}) => {
                    return { name: id, operator, value: filterText }
                })
                setIsRefetchingData(true)
                handleFetchData({ filter, skip: 0, reset: true })
            }
        }
    }, [enabledInfiniteScroll, allDataIsDownloaded, handleFetchData, currentFilters, filters])

    // When sort changes, fetch data from scratch, with order
    const [currentSortBy, setCurrentSortBy] = useState(sortBy)
    useEffect(() => {
        if (!_.isEqual(sortBy, currentSortBy)) {
            setCurrentSortBy(sortBy)

            if (enabledInfiniteScroll && !allDataIsDownloaded) {
                // Translate sortBy into odata compatible list
                const orderBy = _.map(sortBy, ({ id, desc }) => `${id} ${desc ? 'desc' : 'asc'}`)
                setIsRefetchingData(true)
                handleFetchData({ orderBy, skip: 0, reset: true })
            }
        }
    }, [enabledInfiniteScroll, allDataIsDownloaded, handleFetchData, currentSortBy, sortBy])

    // Infinite Scroll
    //
    const isItemLoaded  = useCallback(index => {
        return !!rows[index]
    }, [rows])
    const loadMoreItems = useCallback(() => {
        handleFetchData({ skip: records.length })
    }, [handleFetchData, records.length])

    let dataView = null
    if (view === Views.Cards) {
        dataView = <CardsView
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    renderGroupActions={renderGroupActions}
                    showToolbar={showToolbar}
                    globalFilter={globalFilter}
                    filters={filters}
                    handleSearchChange={handleSearchChange}
                    searchPlaceholder={searchPlaceholder}
                    allColumns={allColumns}
                    options={options}
                    headerGroups={headerGroups}
                    headerBgColor={headerBgColor}
                    CHECKBOX_COL_NAME={CHECKBOX_COL_NAME}
                    isAllRowsSelected={isAllRowsSelected}
                    selectedFlatRows={selectedFlatRows}
                    toggleAllRows={toggleAllRows}
                    rows={rows}
                    totalCount={totalCount}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    isLoadingMoreData={isLoadingMoreData}
                    isRefetchingData={isRefetchingData}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreItems}
                    prepareRow={prepareRow}
                    tableHeight={tableHeight}
                    getRowSize={getRowSize}
                    viewOptions={viewOptions}
                    cardViewOpts={cardViewOpts}
                    customCardBody={customCardBody}
                    />

    } else {
        dataView = <TableView
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    renderGroupActions={renderGroupActions}
                    showToolbar={showToolbar}
                    globalFilter={globalFilter}
                    filters={filters}
                    handleSearchChange={handleSearchChange}
                    searchPlaceholder={searchPlaceholder}
                    allColumns={allColumns}
                    options={options}
                    headerGroups={headerGroups}
                    headerBgColor={headerBgColor}
                    CHECKBOX_COL_NAME={CHECKBOX_COL_NAME}
                    isAllRowsSelected={isAllRowsSelected}
                    selectedFlatRows={selectedFlatRows}
                    toggleAllRows={toggleAllRows}
                    rows={rows}
                    totalCount={totalCount}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    isLoadingMoreData={isLoadingMoreData}
                    isRefetchingData={isRefetchingData}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreItems}
                    prepareRow={prepareRow}
                    tableHeight={tableHeight}
                    getRowSize={getRowSize}
                    viewOptions={viewOptions}
                    />
    }

    return (
        <Flex w="full" maxW="full" overflow="scroll" direction="column" gridRowGap={2}>
            {viewOpt && <Flex px={1} justifyContent="space-between" direction="row-reverse">
                { viewOptions }
                { renderGroupActions() }
            </Flex>}
            { showToolbar &&
            <TableToolbar searchText={globalFilter} filters={filters}
                          onSearchChange={handleSearchChange} searchPlaceholder={searchPlaceholder}
                          columns={allColumns} options={options}/>
            }
            { dataView }
        </Flex>
    )
}
DataTable.propTypes = propTypes
DataTable.Views     = Views

export default DataTable