import React, { useContext } from 'react'
import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Portal,
    useColorModeValue,
} from '@chakra-ui/react'
import ThemeContext from '../../theme/ThemeContext'

const PopoverButton = ({ isOpen, toggleIsOpen, icon, label, width = "sm", children }) => {
    const { theme } = useContext(ThemeContext)
    const borderColor = useColorModeValue(theme.muted, "blackAlpha.700")

    return (
        <Popover isOpen={isOpen} closeOnBlur={true} onClose={toggleIsOpen} placement="bottom-end">
            <PopoverTrigger>
                <Button leftIcon={icon} onClick={toggleIsOpen} borderColor={borderColor}>{label}</Button>
            </PopoverTrigger>
            <Portal>
                <PopoverContent _focus={{ boxShadow: "lg" }} bg={theme.bg1} width={width}
                                borderColor={borderColor} border="1px solid" boxShadow="lg" p={2}>
                    <PopoverBody>
                        { children }
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}

export default PopoverButton