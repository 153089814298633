import React, { useContext } from 'react'
import {
    Flex,
    Spinner,
    Heading,
} from '@chakra-ui/react'
import { ThemeContext } from '../ui'

const LoadingSpinner = ({ title, bg = null, thickness = "6px", size = '80px', py = 8 }) => {
    const { theme } = useContext(ThemeContext)
    return (
        <Flex w="full" h="full" direction="column" gridRowGap={8} alignItems="center" justifyContent="center" bg={bg || theme.bg1} py={py}>
            <Spinner
                    width={size} height={size}
                    thickness={thickness}
                    speed="0.65s"
                    emptyColor={theme.muted}
                    color={theme.primary}
                />
            { title &&
            <Heading color={theme.fg1} size="md">{title}</Heading>
            }
        </Flex>
    )
}

export default LoadingSpinner