import * as identityConstants from './identity.constants'

const initialState = {
	isAuthenticated: false,
	userInfo: {},
	token: null,
	isError: false,
	initiateLogout: false,
}

export const identity = (state = initialState, action) => {
	switch (action.type) {
		case identityConstants.LOGIN_INITIATE:
			return {
				...state,
				...action.payload,
			}
		case identityConstants.LOGIN_SUCCESS:
			return {
				...state,
				...action.payload,
			}
		case identityConstants.LOGIN_FAILURE:
			return {
				...state,
				...action.payload,
			}
		case identityConstants.LOGOUT_INITIATE:
			return {
				...state,
				...action.payload,
			}
		case identityConstants.LOGOUT_SUCCESS:
			return {
				...initialState,
			}
		case identityConstants.SET_ROLE:
			return {
				...state,
				userInfo: { roles: action.payload },
			}
		case identityConstants.TOUCH:
			return {
				...state,
				touch: new Date().getTime(),
			}
		default:
			return state
	}
}
