import {
    Flex,HStack,Stack,Box,Divider,Heading,Text,Input,Spinner,Button,
    Drawer, DrawerOverlay, DrawerContent, DrawerBody,
} from "@chakra-ui/react"
import {useState,useContext, useEffect} from 'react'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import { FiSearch, FiUpload } from "react-icons/fi"
import SharepointTable from './SharepointTable'
import SharepointActionResultPage from "./SharepointActionResultPage"
import SharepointCreatePage from "./SharepointCreatePage"
import SharepointPreviewPage from "./SharepointPreviewPage"
import DeleteModal from '../../commonComponents/DeleteModal'
import { WorkspaceContext } from '../../commonComponents/Layout/Layout'
import Search from "app/commonComponents/utils/Search"
import { useSharepointContext } from "./context/SharepointContext"
import {useServicesContext } from "services/apiServices"
import  ErrorPage from 'app/commonComponents/ErrorPage'

const SharepointContainer = () => {
    const {globalSiteState,openDrawer,closeDrawer,handleBulkDeleteOpen,closeDeleteModal} = useSharepointContext()
    const [searchByName,setSearchByName]=useState("")

    const { workspaceMetadata, workspaceOptions } = useContext(WorkspaceContext) 
    const { disabledSources } = useServicesContext()
    const isEnabled = !disabledSources.includes('SharePoint Integration')
   
    const enableBulkDeleteSiteButton = () =>{
        return globalSiteState.sites.some(item=>(globalSiteState.checkedSites.includes(item.id)))
     }
    // -------------------End Total records---------------- 
    const drawerChildElement = globalSiteState.drawerState.isOpen && (globalSiteState.drawerState.page==='create'||globalSiteState.drawerState.page==='edit') ? 
        <SharepointCreatePage /> :
         globalSiteState.drawerState.isOpen && globalSiteState.drawerState.page==='preview' ?
            <SharepointPreviewPage  /> :
        globalSiteState.drawerState.isOpen && (globalSiteState.drawerState.page==='success'|| globalSiteState.drawerState.page==='fail')?
            <SharepointActionResultPage />:
        globalSiteState.drawerState.isOpen && globalSiteState.drawerState.page==='loading' ?
        <Box height='80vh' width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' >  
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
            <Text my='2'>{globalSiteState.drawerState.message}</Text>
        </Box>:
        <></>

  return (
    <Stack  borderRadius="10px" pos="relative" color='#617182'>
        <Flex justifyContent="space-between" alignItems="center" borderBottom="1px solid #D1D5DB" paddingBottom="15px">
            <Heading as="h6" className="page-title" >SharePoint Integration</Heading> 
              {isEnabled && 
              <HStack alignItems="center">
                 <Box><Search search={searchByName} setSearch={setSearchByName} searchPlaceholder={'Search by Url'} /></Box> 
                  {enableBulkDeleteSiteButton() && <Button h="36px" leftIcon={<MdOutlineDeleteOutline size='20px' />} className="primary-button" onClick={handleBulkDeleteOpen}> <Text paddingTop="2px">Delete</Text></Button>}
                  <Box position="relative" display='flex' >
                      <Button 
                          padding="6px 17px"
                          borderRadius="5px"
                          _hover={{
                            backgroundColor:"#0047E3"
                          }}
                          leftIcon={<FiUpload size='20px' />} bg={workspaceMetadata === 'noWorkSpace'?"#E5E7EB":"#2563EB"} color={workspaceMetadata === 'noWorkSpace'?"#8993A1":"#fff"} h='36px' fontSize='14px' fontWeight="500" lineHeight="20px" onClick={openDrawer} isDisabled={workspaceMetadata === 'noWorkSpace'}
                          title={workspaceMetadata === 'noWorkSpace' ? 'Please select valid workspace' : ''}>
                          <Text paddingTop="2px">SharePoint Integration</Text>
                      </Button>
                      <Drawer isOpen={globalSiteState.drawerState.isOpen} placement="right" onClose={closeDrawer} size="md"  >
                          <DrawerOverlay />
                          <DrawerContent minW='800px'>
                              <DrawerBody p='0' >
                                  {drawerChildElement}
                              </DrawerBody>
                          </DrawerContent>
                      </Drawer>
                  </Box>
              </HStack>
              }
        </Flex>
        <Box className="sharepoint-custom-table-head" height="calc(100vh - 200px)">
        {isEnabled ? <SharepointTable searchByName={searchByName} />
              :
              <ErrorPage errorTitle='Access Forbidden' errorMessage={`Sorry, you don't have permission to access this page. If you believe this is an error, please contact your customer service manager.`} statusCode={403} buttonText='Go Back' />
          }  
        <DeleteModal isOpen={globalSiteState.modalState.isOpen} closeModal={closeDeleteModal} callback={globalSiteState.modalState.callBack} message={globalSiteState.modalState.message}/>
        </Box>
    </Stack>
  )
}

export default SharepointContainer