import _ from 'lodash'
import React from 'react'

const OpenedSidebarIcon = ({ bg, color, size, transitionProps }) => (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
            <circle cx="17" cy="15" r="12" fill={bg} {...transitionProps}/>
        </g>
        <circle cx="17" cy="15" r="12" fill={bg} {...transitionProps}/>
        <path d="M17.9648 11L14.0273 14.9375L17.9648 18.875" fill={bg} fillOpacity="0.92" {...transitionProps}/>
        <path d="M17.9648 11L14.0273 14.9375L17.9648 18.875" stroke={color} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" {...transitionProps}/>
        <defs>
            <filter id="filter0_dd" x="0" y="0" width={size} height={size} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow"/>
                <feOffset/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.042882 0 0 0 0 0.270833 0 0 0 0 0.16873 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0431373 0 0 0 0 0.270588 0 0 0 0 0.168627 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>
)

const ClosedSidebarIcon = ({ bg, color, size, transitionProps }) => (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
            <circle cx="17" cy="15" r="12" fill={bg} {...transitionProps}/>
        </g>
        <circle cx="17" cy="15" r="12" fill={bg} {...transitionProps}/>
        <path d="M15 18.875L18.9375 14.9375L15 11" fill={bg} fillOpacity="0.92" {...transitionProps}/>
        <path d="M15 18.875L18.9375 14.9375L15 11" stroke={color} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" {...transitionProps}/>
        <defs>
            <filter id="filter0_dd" x="0" y="0" width={size} height={size} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow"/>
                <feOffset/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.042882 0 0 0 0 0.270833 0 0 0 0 0.16873 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0431373 0 0 0 0 0.270588 0 0 0 0 0.168627 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>
)

const ToggleIcon = ({ bg, color, size, isSidebarOpen, transitionTime }) => {
    const keys = ['color', 'background-color', 'fill', 'stroke']
    const transition = _.map(keys, k => `${k} ${transitionTime}`).join(', ')
    const transitionProps = { transition }

    const iconProps = { bg, color, size, transitionProps }
    const Icon = isSidebarOpen ? OpenedSidebarIcon : ClosedSidebarIcon

    return <Icon {...iconProps}/>
}

export default ToggleIcon