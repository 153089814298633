import React from 'react'
import {
    Flex,
} from '@chakra-ui/react'
import {
    Panel,
} from '../ui'
import { withTranslation } from 'react-i18next'

const TodoComponent = ({ t }) => {
    return (
        <Flex direction="column" gridRowGap={4} pb={12}>
            <Panel title={t("TODO")}>
            </Panel>
        </Flex>
    )
}

export default withTranslation()(TodoComponent)