import { ServiceHelper } from '../helpers/ServiceHelper'
const reqPaths = {
    tenant: 'tenant',
    bot: '', // bot management , live chat, bot
    ticketing: 'ticket', // ticketing
    learning: 'learning', // learning
    cms: 'cms',
    mgmt: 'mgmt',
    global: '',
}

/**
 *  @todo Custom dynamic function with dynamic urlItemName
 * @todo remove tenanatId from url as ticketing
 */
class AppService {
    constructor({ config }) {
        this.config = config
        this.apiHostUrl = config.apiHostUrl
        // this.apiUrl = config.apiUrl
    }

    // TODO: Replace with an actual API call
    async getTeamsData() {
        try {
            let headerForTeams = {  
                'Content-Type': 'application/json',
                'msTokenSignedForUser': `${sessionStorage.getItem('ms_access_token')}`,
            }
            const urlSearchParams = new URLSearchParams(window.location.search)
            let queryParameters = Object.fromEntries(urlSearchParams.entries())
            let headers = queryParameters?.isTeams === 'true' ? headerForTeams : {}
            const response = await ServiceHelper.prepAndCall({method: 'GET', reqPath: `${reqPaths.tenant}/teams/getTeams`, headers })
            let teamsData
            if(response && response.data){
                let teams = response.data.teamNames 
                let properties = response.data?.properties ?? {}
                teams = teams && Array.isArray(teams) && teams.map((team)=> {
                    let itemType = properties[team.value] ?? [{label: 'Default', value: 'default', name: 'itemType'}]
                    return {...team, itemType}
                })
                teamsData = {
                    teams
                }
            }else {
                teamsData = {
                   teams: [{label: 'IT', value: 'james', itemType: [{label: 'Default', value: 'default', name: 'itemType'}]}]
                }
            }  
            return teamsData 
        } catch (err) {
            Promise.reject((err && err.response && err.response.data && err.response.data.message) || (err && err.message) || err)
        }
    }

    async getServiceData(serviceName) {
        if (!serviceName) throw new Error(`No service name passed.`)
        let getTeam = JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.identity)?.userInfo?.defaultTeam
        try {
            let response = await ServiceHelper.prepAndCall({ method: 'GET', reqPath: `tenantConfig?$filter=status eq 'Published'`, serverUrl: this.config.globalTenantServiceUrl, headers: {usecase: getTeam || 'james'}})
            if (response && response.data == null) return
            let data = response.data.rows || response.data
            response = await ServiceHelper.prepAndCall({ method: 'GET', reqPath: `whiteLabel/findWithDefaultTenant` })
            return { tenantSettings: data, whitelabel: response?.data }
        } catch (err) {
            Promise.reject((err && err.response && err.response.data && err.response.data.message) || (err && err.message) || err)
        }
    }
    async getTenantData(tenant) {
        // tenantConfig, whitelist, languages, settings?
        try {
            let reqConfig = ServiceHelper.prepareConfig({ method: 'GET', reqPath: `tenantData`, serverUrl: tenant?.apiUrl })
            let response = await ServiceHelper.makeCall(reqConfig)
            if (response && response.data == null) return
            let data = response.data.rows || response.data
            return data
        } catch (err) {
            Promise.reject((err && err.response && err.response.data && err.response.data.message) || (err && err.message) || err)
        }
    }
    async getPublicTenantData(domain) {
        if (!domain) {
            domain = window.location.hostname.split('.')[0]
        }
        if (!domain) {
            throw new Error(`No Domain or Realm passed.`)
        }
        try {
            // let reqPath = `tenantId`
            let reqPath = 'tenant' // current
            if (domain.includes('.')) {
                domain = domain.split('.')[0]
            }
            let query = `tenantId=${domain}&ts=${new Date().getTime()}`
            let reqConfig = ServiceHelper.prepareConfig({ method: 'GET', reqPath, query, serverUrl: this.config.globalTenantServiceUrl })
            let response = await ServiceHelper.makeCall(reqConfig)
            if (response && response.data == null) {
                return
            }
            let data = response.data.rows || response.data
                localStorage.setItem('tenant', JSON.stringify(data))
            return data
        } catch (err) {
            throw err
            // return Promise.reject((err && err.response && err.response.data && err.response.data.message) || (err && err.message) || err)
        }
    }
    async getUserData(options) {
        if (!options || (options && (!options.userName || !options.userId))) {
            throw new Error('User name or id not passed')
        }
        // should return namespace and specific data that is needed in ui not provided by keycloack
        try {
            let reqPath = `userData`
            let reqConfig = ServiceHelper.prepareConfig({ method: 'GET', reqPath })
            let response = await ServiceHelper.makeCall(reqConfig)
            if (response && response.data == null) return
            let data = response.data.rows || response.data
            return data
        } catch (err) {
            Promise.reject((err && err.response && err.response.data && err.response.data.message) || (err && err.message) || err)
        }
    }
}

const createAppService = config => new AppService(config)
export default createAppService
// export default AppService
