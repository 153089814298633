import React from 'react'
import {
    IconButton,
    Icon,
    Link,
    forwardRef,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

const FixedBarButton = forwardRef(({ iconName, path, onClick }, ref) => {
    const icon = <Icon as={iconName} boxSize={5}/>

    if (path) {
        return (
            <Link ref={ref} to={path} as={NavLink} bg="none" p={1} size="md" textAlign="center"
                  borderRadius="full"
                  _hover={{ bg: 'whiteAlpha.300' }} _focus={{ boxShadow: "none" }}>
                { icon }
            </Link>
        )

    } else {
        return <IconButton  ref={ref} icon={icon}  bg="none" p={1} mb={'4px'} size="md" onClick={onClick}
                           borderRadius="full"
                           _hover={{ bg: 'whiteAlpha.300' }} _focus={{ boxShadow: "none" }} height={'35px'}/>
    }
})

export default FixedBarButton