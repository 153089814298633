import Joi from 'joi'

// Helpers
const existsThenRequired = { is: Joi.exist(), then: Joi.required() }

// Schema for a single route obj
const routeSchema = Joi.object({
    // Title displayed in Sidebar
    title: Joi.string()
            .when('showInSidebar', existsThenRequired),

    // Should be a react-icon or chakra-icon
    icon: Joi.any()
            .when('showInSidebar', existsThenRequired),

    // Path within /botautomation that this
    // Component will live at.
    // Pattern:
    //      /template  : YES
    //       template  : NO
    //       template/ : NO
    //      /template/ : NO
    path: Joi.string().pattern(/^\/.*[^/]$/)
            .when('showInSidebar', existsThenRequired),

    // The react component that should be rendered
    Component: Joi.any(),

    // TODO: Document
    showInSidebar: Joi.bool().default(false),

    // Roles that this path + component is visible for
    roles: Joi.array().items(Joi.string()).default([]),

    // TODO: Document
    productRoute: Joi.bool().optional(),
})

// Extension for having children
const routesWithChildrenSchema = routeSchema.keys({
    children: Joi.array().items(routeSchema).default(null),
})

// A route list can be a list of routes with or without children
//
const routeListSchema = Joi.array().items(routeSchema, routesWithChildrenSchema)

export default routeListSchema