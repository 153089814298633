import React, { useMemo, useState, useCallback , useEffect} from 'react'
import _ from 'lodash'
import { Permissions } from '../../../..'

import {
    Link as ChakraLink,
    Icon,
    Box,
    useBoolean,
    Text,
    Collapse,
    UnorderedList,
    ListItem,
    List,
    VisuallyHidden
} from '@chakra-ui/react'
import {
    NavLink,
    useRouteMatch,
    Link as ReactRouterLink
} from 'react-router-dom'
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons'


// This theme needs to be input because the Sidebar always uses darkTheme
const SidebarLink = (props) => {
    const { theme, title, icon, path, visible = true, elem = {}, isExpanded } = props
    const [currentPath, setCurrentPath] = useState(path)
    const match = useRouteMatch(currentPath) ?? false
    const cLink = window?.location?.pathname || null
    const [toggleNested, setToggleNested] = useBoolean(true)
    const isActive = (match && match.isExact)

    const dynamicStyle = { color: theme?.isLight ? theme.fg2 : theme.fg1, bg: 'none', height: '30px', maxHeight: '30px' }
    if (isActive) {
        dynamicStyle.color = theme.activeColor
        dynamicStyle.bg = theme.activeBg
    }

    const nestedList = useMemo(() => {
        if (Array.isArray(elem?.children) && elem?.children.length > 0) {
            return { isNested: true, list: elem?.children }
        }
        else {
            return { isNested: false }
        }

    }, [elem?.children])

    const isRedirect = useMemo(() => {
        if (elem?.isRedirect)
            return true
        else
            return false
    }, [elem?.isRedirect])
    const redirectUrl = useCallback((e) => {
        e.preventDefault()
        if(props?.elem?.routeName){
            localStorage.setItem('routeName', props?.elem?.routeName)
        }
        let redirect = `${window?.location?.protocol}//${window?.location?.host || window?.location?.hostname}${path}`
       window.location = redirect
    }, [props])

    return (
        <Box key={_.camelCase(title)} w={'220px'}>
            {nestedList?.isNested ? (
                <Box 
                    data-testid={title}
                    onClick={setToggleNested.toggle}
                    cursor="pointer"
                    display={visible ? `flex` : 'none'} alignItems="center" gridColumnGap={1} borderRadius="md"
                    _hover={{ textDecoration: 'none', bg: isActive ? '' : 'whiteAlpha.300', color: '#1E90FF' }}
                    fontSize="13px"
                    marginBottom={2}
                    pl={2}
                    {...dynamicStyle}
                    color={'#6c7280'}
                    className={props?.className || "sidebar-nested-parent"}
                >
                     {icon === 'Beta' ? <Box
                                            color="white"
                                            marginTop={'-3px'}
                                            px="5px"
                                            fontSize="8px"
                                            lineHeight="normal"
                                            justifyContent="center"
                                            alignItems="center"
                                            py="1px"
                                            style={{
                                            borderRadius: '0px 10px',
                                            background:
                                                'linear-gradient(110deg, #6DD2F5 18.55%, #677BF2 89.65%)',
                                            }}
                                        >
                                            Beta
                                        </Box>: <Icon data-testid={icon} as={icon} boxSize={props?.className ? 5 : 6} className="sidebar-icon" /> }
                    {/* <Icon as={icon} boxSize={props?.className ? 5 : 6} className="sidebar-icon" /> */}
                    <Text data-testid={title}
                    visibility={isExpanded ? setTimeout(() => {
                        return 'block'
                    }, 100) : 'hidden'} pl={props?.className ? 1 : 0} >{title}</Text>
                    <Icon className="sidebar-icon" as={toggleNested ?  ChevronDownIcon : ChevronRightIcon} boxSize={4} />
                </Box>
            ) : (
<RenderLink
{...props}
title={title}
path={path}
visible={visible}
isActive={isActive}
dynamicStyle={dynamicStyle}
isRedirect={isRedirect}
redirectUrl={redirectUrl}
icon={icon}
isExpanded={isExpanded}
/>
            )}
            <SubMenu
                nestedList={nestedList}
                isExpanded={isExpanded}
                toggleNested={toggleNested}
                visible={visible}
                cLink={cLink}
                theme={theme}
                pl={props?.className ? 4 : 1}
            />

        </Box>
    )
}
const SubMenu = (props) => {
    const { nestedList, isExpanded, toggleNested, visible, cLink, theme,pl=1 } = props
    const redirectUrl = useCallback((path,e) => {
        e.preventDefault()
        let redirect = `${window?.location?.protocol}//${window?.location?.host || window?.location?.hostname}${path}`
        window.location = redirect
    }, [])
    return (
        <>
            {nestedList?.isNested && isExpanded == 1 && (<Collapse in={toggleNested} animateOpacity>
                <List
                    styleType='none'
                    fontSize="12px"
                    data-testid={"submenuList"}
                    padding="2px"
                >
                    {nestedList && nestedList?.list.map((item, idx) => (
                        Permissions.isAuthorized({ isPublic: item?.isPublic || false, roles: item?.roles || null }) && 
                        <ListItem data-testid={item?.title} mb={1} p={1} pl={pl} key={`nested-sidebar-${item?.title}-${idx}`}>
                            {
                                item?.children ? <SidebarLink
                                {...item}
                                theme={theme}
                                title={item?.title}
                                icon={item?.icon}
                                path={item?.path}
                                visible = {true}
                                elem = {item}
                                isExpanded={isExpanded}
                                className={'sidebar-nested-parent-child'}
                                /> :                         
                            <ChakraLink data-testid={item?.title} as={ReactRouterLink} key={_.camelCase(item?.title)} 
                                to={item?.path}
                                py={'1px'} 
                                display={visible ? `flex` : 'none'} 
                                alignItems="center" 
                                gridColumnGap={1} 
                                borderRadius="md"
                                _hover={{ textDecoration: 'none', color: '#1E90FF' }}
                                fontSize="13px"
                                pl={6}
                                color={'#6c7280'}
                                className="sidebar-nested-child"
                                onClick={item?.isRedirect ? redirectUrl.bind(this,item?.path) : null}
                            >
                                <Box
                                 
                                    borderRadius={'5px'} display="flex"
                                    color={cLink.endsWith(item.path) ? theme.activeColor : 'inherit'}
                                    bg={cLink.endsWith(item.path) ? theme.activeBg : 'inherit'} 
                                   p={1}
                                className={item?.className || ''}
                                   >
                                    {item?.icon && <Icon data-testid={item.icon} className="sidebar-icon" m={'1px'} as={item.icon} boxSize={5}

                                    />}
                                    <Text 
                                    data-testid={item?.title}
                                    m={'1px'}
                                    pl={'4px'}
                                    visibility={isExpanded ? setTimeout(() => {
                                        return 'block'
                                    }, 100) : 'hidden'}>
                                        {item?.title}</Text>
                                </Box>
                            </ChakraLink>
}
                            {/* {item?.icon && <Icon as={item.icon} boxSize={4}/>}
             {item?.title || ''} */}
                        </ListItem>
                    ))}
                </List>
            </Collapse>)}
        </>
    )
}

const RenderLink=(props)=>{
    const {title,path,visible,isActive,dynamicStyle,isRedirect,redirectUrl,icon,isExpanded}=props
    const className = useMemo(()=>{
        let cls=props?.className || ''
        if(props?.elem?.className)
            cls=cls+`${props?.elem?.className}`

            return cls
    },[props?.elem?.className,props?.className])

    const to=useMemo(()=>{
        if(!isRedirect)
        return {}
    else
        return {to:path}
    },[path, isRedirect])
    return (
        isRedirect ?
        <ChakraLink data-testid={title} as={ReactRouterLink} key={_.camelCase(title)}  {...to}
        py={1} display={visible ? `flex` : 'none'} alignItems="center" borderRadius="md"
        _hover={{ textDecoration: 'none', bg: isActive ? '' : 'whiteAlpha.300', color: '#1E90FF' }}
        fontSize="13px"
        marginBottom={2}
        pl={2}
        {...dynamicStyle}
        onClick={isRedirect ? redirectUrl : null}
        color={'#6c7280'}
        className={className}
    >
        <Icon data-testid={icon} className={`${className||''} sidebar-icon`} as={icon} boxSize={6} />
        <Text data-testid={title} visibility={isExpanded ? setTimeout(() => {
            return 'block'
        }, 100) : 'hidden'} pl={1} >{title}</Text>
    </ChakraLink>
    : <ChakraLink data-testid={title} as={ReactRouterLink} key={_.camelCase(title)}  to={'/agent/support'}
    py={1} display={visible ? `flex` : 'none'} alignItems="center" borderRadius="md"
    _hover={{ textDecoration: 'none', bg: isActive ? '' : 'whiteAlpha.300', color: '#1E90FF' }}
    fontSize="13px"
    marginBottom={2}
    pl={2}
    {...dynamicStyle}
    color={'#6c7280'}
    className={className}
>
    <Icon data-testid={icon} className={`${className||''} sidebar-icon`} as={icon} boxSize={6} />
    <Text data-testid={title} visibility={isExpanded ? setTimeout(() => {
        return 'block'
    }, 100) : 'hidden'} pl={1} >{title}</Text>
</ChakraLink>
    )
}
export default SidebarLink