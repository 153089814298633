import { Button, Flex, Heading } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'

const Logout = ({ cdnUri, logout, postLogoutHook }) => {
    useEffect(() => {
        console.log('LOGGING OUT..........')
        logout()
        postLogoutHook && postLogoutHook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // TODO: history.push is not working as expected...tabling issue for now
    // const navToRoot = useCallback(() => history.push(""), [history])
    const navToRoot = useCallback(() => {
        window.location = '/botmanagement/dashboard'
    }, [])

    return (
        <Flex h="100%" w="100%" px={8} py={4} gridColumnGap={8} alignItems="center" justifyContent="center">
            <Flex direction="column" justifyContent="center" alignItems="center" gridRowGap={8}>
                <Heading size="lg" textAlign="center">
                    You are now logged out
                </Heading>
                <Button onClick={navToRoot} colorScheme="blue">
                  Login
                </Button>
            </Flex>
        </Flex>
    )
}

export default Logout
