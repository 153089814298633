import React, { useCallback, useState } from 'react'
import {
    Flex,
    Text,
} from '@chakra-ui/react'
import {
    ChevronRightIcon,
    ChevronDownIcon,
} from '@chakra-ui/icons'
import Links from './Links'

const GroupedSubMenu = ({ theme, label, isCollapsible, onClick, links }) => {
    const renderedLinks = <Links theme={theme} links={links} keyPrefix="submenu"/>

    const [isExpanded, setIsExpanded] = useState(false)

    const handleClick = useCallback(() => {
        if (isCollapsible) {
            setIsExpanded(!isExpanded)
        } else {
            onClick()
        }
    }, [isCollapsible, isExpanded, onClick])

    const expandedIcon = isExpanded ? <ChevronDownIcon/> : <ChevronRightIcon/>
    return (
        <Flex direction="column" pl={4} mt={1}>
            <Flex alignItems="center" justifyContent="space-between" onClick={handleClick}
                  px={3} py={1} borderRadius="md"
                  _hover={{ textDecoration: 'none', bg: 'whiteAlpha.300', cursor: 'pointer' }}>
                <Text fontSize="sm" fontWeight="bold">{label}</Text>
                { expandedIcon }
            </Flex>
            { isExpanded && renderedLinks }
        </Flex>
    )
}

export default GroupedSubMenu