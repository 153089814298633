import React, { useCallback, useState, useMemo, useContext } from 'react'
import _ from 'lodash'
import {
    Flex,
    Badge,
    FormControl,
    FormLabel,
    Switch,
    Button,
    useDisclosure,
} from '@chakra-ui/react'
import {
    FiEyeOff,
} from 'react-icons/fi'

import SearchField   from '../SearchField'
import PopoverButton from './PopoverButton'
import ThemeContext from '../../theme/ThemeContext'

const HideFieldsButton = ({ columns }) => {
    const { theme } = useContext(ThemeContext)
    const { isOpen, onToggle, onClose } = useDisclosure()

    const [searchText, setSearchText] = useState("")
    const handleTextChange = useCallback(text => {
        setSearchText(text)
    }, [])

    const toggleableColumns = useMemo(() => _.filter(columns, col => col.canToggle), [columns])
    const setAll = useCallback(checked => {
        _.each(toggleableColumns, column => {
            const { onChange } = column.getToggleHiddenProps()
            onChange({ target: { value: checked, checked }})
        })
    }, [toggleableColumns])
    const showAll = useCallback(() => setAll(true),  [setAll])
    const hideAll = useCallback(() => setAll(false), [setAll])

    const visibleColumns = _.filter(toggleableColumns, column => {
        if (_.isString(column.Header)) {
            return _.lowerCase(column.Header).match(_.lowerCase(searchText))
        }
    })

    let numHidden = 0
    const controls = _.map(visibleColumns, column => {
        const { id: name, Header: label } = column
        const { checked, onChange } = column.getToggleHiddenProps()

        numHidden += checked ? 0 : 1

        return (
            <FormControl key={`toggle.${name}`} display="flex" alignItems="center" gridColumnGap={2}>
                <Switch id={`toggle-${name}`} onChange={onChange} isChecked={checked}/>
                <FormLabel htmlFor={`toggle-${name}`} mb={1}>{label}</FormLabel>
            </FormControl>
        )
    })

    const label = (
        <>
            Hide Fields
            { (numHidden > 0) &&
                <Badge bg={theme.primary} color={theme.bg2} borderRadius="md" ml={2} px={2} py={1}>{numHidden}</Badge>
            }
        </>
    )

    return (
        <PopoverButton isOpen={isOpen} toggleIsOpen={onToggle} closeOnBlur={true}
                       onClose={onClose} icon={<FiEyeOff/>} label={label}>
            <Flex direction="column" gridRowGap={4}>
                <SearchField onSearchChange={handleTextChange} searchText={searchText} placeholder="Find a field..."/>
                <Flex direction="column" gridRowGap={1}>
                    { controls }
                </Flex>
                <Flex justifyContent="space-between">
                    <Button onClick={showAll}>Show All</Button>
                    <Button onClick={hideAll}>Hide All</Button>
                </Flex>
            </Flex>
        </PopoverButton>
    )
}

export default HideFieldsButton