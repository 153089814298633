import React from 'react'
import _     from 'lodash'
import Formatters, { SimpleText } from '../formatters'

const TableCell = props => {
    const { type, value, customFormatters } = props

    let Formatter = Formatters[type] || (customFormatters ? customFormatters[type] : null) || null
    if (Formatter) {
        return <Formatter {...props}/>
    }

    // default
    if (!_.isUndefined(value)) {
        return <SimpleText {...props} />
    }

    // fallback
    return null
}

export default TableCell
