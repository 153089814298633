export function isValidImageUrl(url) {
    let  regexhttps = /^http(s?):\/\//i
    if ( regexhttps.test(url) ) { 
        const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg|bmp|svg|ico|webp|tif|tiff)/gi
        return url.match(regex) 
    } else {
        return false
    }
}
export function isValidVideoUrl(url,videoSettings) {
    return videoSettings.some(setting=>new RegExp(setting.urlRegex,'i').test(url))
}