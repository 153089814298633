import { Flex,Box, Image,Select,Menu,MenuButton,MenuList,MenuItem,Avatar,Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import {useHistory} from 'react-router-dom'
import {ChevronDownIcon} from '@chakra-ui/icons'
import config from "config"

const Header = ({handleWorkpaceChange,workspaceOptions,workspaceMetadata,identity,sidebarCollapsed,logout}) => {
    const profile = identity.profile
    const history=useHistory()
    const minimizedLogo=config?.minimizedLogo
    const logo=config?.logo
    const capitalizeFirstWord=(str)=> {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const handleLogout=()=>{
        logout()
    }
    const handleMenuLink=(link)=>{
        let url=window.location.href
        let replaceUrl = url.substring(0, url.lastIndexOf('.ai') +3)
        let newUrl=replaceUrl.concat(link)
        window.location.assign(newUrl)
    }
    useEffect(()=>{
        if(window.location.pathname==='/aiconsole' || window.location.pathname === '/aiconsole/'){
           history.push('/aiconsole/documents')
        }
    },[])
    return (
        <Flex h={"45px"} alignItems='center' justifyContent='space-between' className="header" boxShadow='0 0 14px 4px rgba(159,179,193,.14)' zIndex={999} >
           <Box justifyContent='center' display='flex' alignItems='center' height='100%' >
           <Box width={sidebarCollapsed?'60px':'243px'} data-testid='logo' display='flex' alignItems='center' ps='19px' borderRight='1px solid #eaeaea' height='100%' ><Image display={sidebarCollapsed?'block':'none'} w='37px' h='30px' src={minimizedLogo} alt='logo' /><Image display={sidebarCollapsed?'none':'block'} w='145px' h='20px' src={logo} alt='logo' /></Box>
           <Flex alignItems='center'>
             { /* <Text fontSize='20px' fontWeight='600' >Switch UI</Text>
               <Switch size="lg" colorScheme  ='green' mx='10px' />
              <Box  as='span' cursor='pointer' borderRadius='5px' borderWidth='1px' borderColor='#DBDDE0' p='10px'><FaRegMoon/></Box> */
             }
           </Flex>
           </Box>
           <Flex  alignItems='center' >
                {
                  workspaceOptions.length>=1 ? (  <Select w='150px' h='30px' fontSize='12px' title={workspaceMetadata}  onChange={handleWorkpaceChange} >
                    {
                        workspaceOptions.map((e,i)=>{
                                return(
                                    <option width='100%' data-testid={e.name} key={i} value={`${e.name}`}>{capitalizeFirstWord(e.label)}</option>
                                )
                            })
                        }
                        <option width='100%' data-testid='noWorkSpace' value='noWorkSpace' >No workspace</option>
                    </Select> 
                  ):(
                    <Select w='150px' h='30px' fontSize='12px' title={workspaceMetadata}  onChange={handleWorkpaceChange} >
                    {
                        workspaceOptions.map((e,i)=>{
                                return(
                                    <option width='100%' data-testid={e.name} key={i} value={`${e.name}`}>{capitalizeFirstWord(e.label)}</option>
                                )
                            })
                        }
                    </Select> 
                  )
                }
                    <Box ms='13px' >
                   {/* <Box data-testid='support' onClick={()=>{handleMenuLink('/botmanagement/Support')}} className="support-btn">
					     <i className="fa fa-support"></i>
						 Support
                       </Box>*/}
                    </Box>
                    <Menu>
                    <MenuButton data-testid='profile_wrapper' transition='all 0.2s' className={'chakra-header-btn'} >
                        <Box
                            mr={2}
                            display="flex"
                            justifyContent={'space-between'}
                            fontSize="12px"
                            color={'#6c7280'}
                            className={'chakra-header-box'}
                        >
                            <Box>
                                <Avatar 
                                className='header-avatar'
                                fontWeight='400' name={`${profile?.name || profile?.preferred_username}`} h="24px" w="24px" />
                            </Box>
                            <Box className='header-title' pl={1} pt={1}>{profile?.name || profile?.preferred_username}</Box>
                            <Box className='header-down-icon' pt={'3px'}><ChevronDownIcon boxSize={5} /></Box>
                        </Box>
                    </MenuButton>
                    <MenuList fontSize="14px" w='196px' minW='196px'  maxW='196px' >
                        <MenuItem data-testid='profile'>
                            <div className="lnk-btn-h" style={{ padding: '0', width: '100%' }}>
                                <button
                                    style={{ padding: '0', textDecoration: 'none',width:'100%',textAlign:'left',color:'#9c9c9c',fontSize:'14px' }}
                                ><Text onClick={()=>{handleMenuLink('/botmanagement/profile')}} >Profile</Text>
                                </button>
                            </div>
                        </MenuItem>
                        <MenuItem data-testid='logout' >
                            <div className="lnk-btn-h" style={{ padding: '0', width: '100%' }}>
                                <button
                                    style={{ padding: '0', textDecoration: 'none',width:'100%',textAlign:'left',color:'#9c9c9c',fontSize:'14px' }}
                                   ><Text onClick={handleLogout} >Logout</Text>
                                    
                                </button>
                            </div>
                        </MenuItem>
                    </MenuList>
                </Menu>
                    <Box display='flex' alignItems='center' justifyContent='center' ms='10px' >
                    </Box>
                    {/* <Button border='1px solid #dbdde0' fontWeight='400' variant='outline' mx='10px'>Help</Button>
                    <Button border='1px solid #dbdde0' fontWeight='400' variant='outline' mx='10px'>Feedback</Button>
                    <Text colorScheme='#DBDDE0'  width='43px' height='43px' borderRadius='50%' border='1px solid #dbdde0' ></Text> */
                }
           </Flex>
        </Flex>
    )
}

export default Header