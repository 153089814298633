import React, { useCallback, useContext, useMemo } from 'react'
import _ from 'lodash'
import { produce } from 'immer'
import {
    Flex,
    Box,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid as Grid } from 'react-window'

import LoadingSpinner from '../../LoadingSpinner'

import commonProps from './propTypes'
import PropTypes from 'prop-types'
import ThemeContext from '../../theme/ThemeContext'

const CardsView = ({
    getTableProps,
    getTableBodyProps,
    headerBgColor,
    rows,
    totalCount,
    isRefetchingData,
    isItemLoaded,
    loadMoreItems,
    prepareRow,
    tableHeight,
    getRowSize,
    preGlobalFilteredRows,
    isLoadingMoreData,
    cardViewOpts,
    customCardBody = null,
}) => {
    const { theme } = useContext(ThemeContext)

    const {
        numCols = 2,
        paddingX = 8,
        paddingY = 16,
        gutterColSize = 4,
        gutterRowSize = 3,
        rowHeight = 230,
        cardBorder=true,
    } = cardViewOpts

    const numRows = useMemo(() => (totalCount / 2), [totalCount])

    const cardBackgroundColor = useColorModeValue(theme.bg1, "blackAlpha.300")
    const cardBorderColor     = useColorModeValue("gray.300", theme.muted)

    const add = useCallback((float, delta) => (parseFloat(float) + delta), [])

    const DefaultCardBody = ({ cells }) => {
        return _.map(cells, ({ render, column: { Header }}) => {
            if (Header) {
                return (
                    <Flex gridColumnGap={2}>
                        <Text fontSize="sm" fontWeight="bold">{Header}: </Text>
                        { render("Cell") }
                    </Flex>
                )
            }
            return null
        })
    }

    const CardBody = useMemo(() => (customCardBody || DefaultCardBody), [customCardBody])

    const renderCard = React.useCallback(({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * numCols + columnIndex
        const row = rows[index]
        if (!row) {
            console.log(`ERROR: Row not found! index:${index}, col:${columnIndex}, row:${rowIndex}`)
        }
       prepareRow(row)

        const computedStyle = produce(style, _style => {
            _style.top  = add(_style.top, paddingY)
            _style.left = add(_style.left,paddingX)

            _style.top    = add(_style.top, gutterRowSize)
            _style.height = add(_style.height, -1 * gutterRowSize * 6)

            _style.left  = add(_style.left, gutterColSize)
            _style.width = add(_style.width, -1 * gutterColSize * 6)

            _style.top  = `${_style.top}px`
            _style.left = `${_style.left}px`
        })

        return (
            <Flex direction="column" {...computedStyle} bg={cardBackgroundColor} gridRowGap={1}
                  border={cardBorder ? "1px solid gray" : '' } borderColor={cardBorderColor} borderRadius="md" p={2} boxShadow="md"
                  justifyContent="space-around">
                <CardBody cells={row.cells}/>
            </Flex>
        )
    }, [prepareRow, rows, numCols, gutterColSize, gutterRowSize, cardBackgroundColor, cardBorderColor, add, paddingX, paddingY, cardBorder])

    return (
        <Flex w="full" h={tableHeight} direction="column" {...getTableProps()}>
            { isRefetchingData &&
                <LoadingSpinner title={"Re-Fetching Content..."} />
            }

            <Box w="full" h={6} bg={headerBgColor} borderTopRadius="md"/>

            { !isRefetchingData &&
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={totalCount} loadMoreItems={loadMoreItems}>
                {({ onItemsRendered, ref }) => (
                    <AutoSizer defaultHeight={tableHeight} defaultWidth={200}>
                    {({ height, width }) => {

                        const numGutters = (numCols - 1)

                        // Calculate card width
                        const availableWidth = (width - (numGutters * gutterColSize))
                        const cardWidth = (availableWidth / numCols)

                        return (
                        <Grid ref={ref}
                            onItemsRendered={onItemsRendered}
                            {...getTableBodyProps()}
                            itemCount={totalCount}
                            itemSize={getRowSize}
                            columnCount={numCols}
                            columnWidth={cardWidth}
                            rowCount={numRows}
                            rowHeight={rowHeight}
                            height={height}
                            width={width}
                            style={{ backgroundColor: theme.bg1 }}
                            >
                            { renderCard }
                        </Grid>
                        )
                    }}
                    </AutoSizer>
                )}
            </InfiniteLoader>
            }

            { /* FOOTER */ }
            <Box position="absolute" bottom="0px" w="full" bg={headerBgColor} borderBottomRadius="md">
                { !isRefetchingData &&
                    <Flex gridColumnGap={2} px={6} py={2} fontWeight="bold" fontSize="sm">
                    <Text size="sm">Total: {totalCount} records</Text>

                    { (rows.length !== preGlobalFilteredRows.length) &&
                        <Text size="sm">Filtered {rows.length} of {preGlobalFilteredRows.length} records</Text>
                    }

                    { isLoadingMoreData &&
                    <Flex gridColumnGap={2}>
                        <Text size="sm" fontWeight="bold">·</Text>
                        <Text size="sm">Loading more records...</Text>
                    </Flex>
                    }
                    </Flex>
                }
            </Box>
        </Flex>
    )
}

CardsView.propTypes = {
    ...commonProps,
    cardViewOpts: PropTypes.object,
}

export default CardsView