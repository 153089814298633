import _ from 'lodash'

const convertToString = value => {
    if (_.isString(value)) {
        return value
    }
    if (_.isArray(value)) {
        return _.join(value, ',')
    }
    if (_.isObject(value)) {
        return JSON.stringify(value)
    }

    return `${value}`
}

export default convertToString