import React from 'react'
import { Flex, Box, Text, Image, Button } from '@chakra-ui/react'
import { HiArrowSmallLeft } from "react-icons/hi2" 
import ErrorRobot from "../../assets/icons/error-robot.svg"
import EmptyBottomVector from "../../assets/icons/empty-bottom-vector.svg"
import EmptyTopVector from "../../assets/icons/empty-top-vector.svg"
import LockIcon from "../../assets/icons/lock-svg.svg"
import HandIcon from "../../assets/icons/hand-svg.svg"
import Tail from '../../assets/icons/Tail.svg'

const ErrorPage = ({errorTitle,errorMessage,action}) => {
  return (
      <Flex justify='center' direction='column' alignItems='center' h='80vh' p='20px 10px' position='relative' overflow='hidden'>
          <Image position='absolute' top='0' left='0' src={EmptyTopVector}></Image> 
          <Flex justify='center' direction='column' gap='10px' w='70%'>
              <Flex p='28px 22px' position='relative' borderRadius='18px' bg='#F2F3F4' alignSelf='flex-end' w='60%'>
                  <Text zIndex='10' fontWeight='500' color='#6B7280'>{errorMessage}</Text>
                  <Image position='absolute' left='-17px' bottom='-8.5px' src={Tail}></Image>
              </Flex>
              <Flex justify='space-between' alignSelf='flex-end' w='85%'>
                  <Flex alignItems='center' gap='10px' justify='center'>
                      <Text fontSize='128px' fontWeight={800} color='#6B7280' as='span'>4</Text>
                      <Box border='21px solid #2AC769' borderRadius='100%' p='18px'>
                          <Image  src={ErrorRobot}></Image>
                      </Box>
                      <Text as='span' fontSize='128px' fontWeight={800} color='#6B7280'>3</Text>
                  </Flex>
                  <Flex direction='column' justify='space-between' alignItems='flex-end'>
                      <Text color='#6B7280' fontSize='24px' fontWeight='800'>{errorTitle}</Text>
                      <Flex gap='4px' p='0px 18px' h='36px' border='1px solid #2563EB' borderRadius='100px' color='#2563EB' justify='center' alignItems='center' cursor='pointer' zIndex='1' onClick={()=>(action ? action() : window.history.back())}>
                          <HiArrowSmallLeft size='14px' color='#2563EB'/>
                          <Text fontSize='14px' fontWeight={500} color='#2563EB'>Go Back</Text>
                      </Flex>
                  </Flex>
              </Flex>
          </Flex>
          <Image position='absolute' bottom='0' right='0' src={EmptyBottomVector}></Image> 
          <Image position='absolute' htmlHeight='87px' htmlWidth='67px' bottom='139px' right='-14px' src={LockIcon}></Image> 
          <Image position='absolute' htmlHeight='54px' htmlWidth='37px' bottom='47px' right='63px' src={HandIcon}></Image> 
          <Image position='absolute' htmlHeight='44px' htmlWidth='34px' bottom='40px' right='219px' src={LockIcon}></Image> 
      </Flex>
      
  )
}

export default ErrorPage