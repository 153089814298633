import React from 'react'
import _ from 'lodash'
import {
    HStack,
    ButtonGroup,
    Button,
    Link
} from '@chakra-ui/react'

import SearchField from './SearchField'

import SortButton       from './actions/SortButton'
import FilterButton     from './actions/FilterButton'
import HideFieldsButton from './actions/HideFieldsButton'
import {
    NavLink
} from 'react-router-dom'
const TableToolbar = ({ columns, filters, searchText, searchPlaceholder, onSearchChange, size = "sm", options = {}, moreActions = null }) => {
    const {
        showSearchBar = true,
    } = options

    const buttons = []

    if (moreActions && !_.isEmpty(moreActions)) {
        _.each(moreActions, ({ text, color, variant, leftIcon, rightIcon, onClick, disabled, path=false, type=null }) => {
            let _onClick = onClick
            let wrapper  = null

            if (type && type === 'link' && path) {
                wrapper = children => (
                    <Link as={NavLink} to={path} key={`action.${text}`} _hover={{ textDecor: 'none' }}>
                        { children }
                    </Link>
                )
                _onClick = undefined
            }

            let button = (
                <Button key={`action.${text}`} colorScheme={color} variant={variant}
                        size="sm" borderWidth="2px" onClick={_onClick} disabled={disabled}
                        leftIcon={leftIcon} rightIcon={rightIcon}>
                    {text}
                </Button>
            )

            if (wrapper) {
                button = wrapper(button)
            }

            buttons.push(button)
        })
    }

    const hideSortBtn       = options.hideTableBtns || options.hideSortBtn
    const hideFilterBtn     = options.hideTableBtns || options.hideFilterBtn
    const hideHideFieldsBtn = options.hideTableBtns || options.hideHideFieldsBtn

    if (!hideSortBtn) {
        buttons.push(<SortButton key="table.sort.action" columns={columns}/>)
    }
    if (!hideFilterBtn) {
        buttons.push(<FilterButton key="table.filter.action" columns={columns} filters={filters}/>)
    }
    if (!hideHideFieldsBtn) {
        buttons.push(<HideFieldsButton key="table.hideFields.action" columns={columns}/>)
    }

    return (
        <HStack justifyContent="space-between" px={1} py={2}>
            <HStack minWidth="35%" w="full">
            { showSearchBar &&
                <SearchField size={size} shouldDebounce={false}
                             onSearchChange={onSearchChange} searchText={searchText}
                             placeholder={searchPlaceholder}/>
            }
            </HStack>

            { !_.isEmpty(buttons) &&
            <ButtonGroup size={size} variant="outline">
                { buttons }
            </ButtonGroup>
            }
        </HStack>
    )
}

export default TableToolbar