import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import _ from 'lodash'
import {
    Flex,
    Text,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
    Input,
    IconButton,
    Heading,
} from '@chakra-ui/react'
import {
    IoArrowBack,
} from 'react-icons/io5'
import { useHistory } from 'react-router'

const SearchDrawer = ({ isOpen, onClose, routes }) => {

    const searchBarRef = useRef()
    useEffect(() => {
        if (isOpen && searchBarRef.current) {
            searchBarRef.current.focus()
        }
    }, [isOpen])

    const [searchText, setSearchText] = useState("")
    const handleChange = useCallback(event => {
        setSearchText(event.target.value)
    }, [])

    const [results, setResults] = useState([])
    useEffect(() => {
        if (_.isEmpty(searchText)) {
            setResults([])
        }

        const _results = []
        const _searchText = _.lowerCase(searchText)
        _.each(routes, route => {
            if (route.title && _.lowerCase(route.title).match(_searchText)) {
                _results.push(route)
            }
        })
        setResults(_results)
    }, [routes, searchText])

    const history = useHistory()
    const navigateToPath = useCallback(path => () => {
        history.push(path)
        onClose()
        setSearchText("")
    }, [history, onClose])

    const renderedResults = useMemo(() => {
        return _.map(results, ({ title, path, parent }) => (
            <Flex bg="gray.100" _hover={{ bg: 'gray.200' }} px={4} py={2} borderRadius="md"
                  cursor="pointer" gridColumnGap={2} onClick={navigateToPath(path)}>
                <Text color="gray.500">Page:</Text>
                { parent && <Text>{parent} &rsaquo;</Text> }
                <Text fontWeight="semibold">{title}</Text>
            </Flex>
        ))
    }, [results, navigateToPath])

    return (
        <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose} initialFocusRef={searchBarRef}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody>
                    <Flex gridColumnGap={4} py={8}>
                        <IconButton icon={<IoArrowBack />} onClick={onClose} borderRadius="999px" bg="none"/>
                        <Flex direction="column" flex={1} gridRowGap={4}>
                            <Input ref={searchBarRef} placeholder="Search Flows, Forms, FAQs and more..."
                                   value={searchText} onChange={handleChange}/>

                            { !_.isEmpty(searchText) &&
                            <Flex direction="column">
                                    { !_.isEmpty(results) &&
                                        <>
                                        <Heading size="xs" textTransform="uppercase">Results:</Heading>
                                        <Flex direction="column" gridRowGap={1} pt={2}>
                                            { renderedResults }
                                        </Flex>
                                        </>
                                    }
                                    { !_.isEmpty(searchText) && _.isEmpty(results) &&
                                        <Heading size="sm">No pages found</Heading>
                                    }
                            </Flex>
                            }
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default SearchDrawer