import React, { useState, useCallback, useContext, useMemo } from 'react'
import {
    Center,
} from '@chakra-ui/react'
import ToggleIcon from './ToggleIcon'
import ThemeContext from '../../theme/ThemeContext'

const ToggleButton = ({ theme, toggleExpansion, transitionTime, isExpanded }) => {

    const [onHoverToggle, setOnHoverToggle] = useState(false)
    const onMouseOverToggle = useCallback(() => setOnHoverToggle(true),  [setOnHoverToggle])
    const onMouseOutToggle  = useCallback(() => setOnHoverToggle(false), [setOnHoverToggle])

    const handleClick = useCallback(event => {
        setOnHoverToggle(s => !s)
        toggleExpansion(event)
    }, [toggleExpansion])

    // This one thing can be dark mode sensitive,
    // because otherwise it sticks out too much
    const { theme: appTheme } = useContext(ThemeContext)
    const bgColor = useMemo(() => appTheme.bg2, [appTheme])

    return (
        <Center position="absolute" boxSize={8} top={0} right={-4}
                onMouseOver={onMouseOverToggle} onMouseOut={onMouseOutToggle}
                onClick={handleClick} _hover={{ cursor: 'pointer' }} zIndex={90} w={7} h={7}>
            <ToggleIcon size={32} isSidebarOpen={isExpanded} transitionTime={transitionTime}
                        color={onHoverToggle ? theme.activeColor : theme.activeColor}
                        bg={onHoverToggle ? theme.bg2 : bgColor}/>
        </Center>
    )
}

export default ToggleButton