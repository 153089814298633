import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isExpanded: true,
}

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggle: state => {
            state.isExpanded = !state.isExpanded
        },
    },
})

const reducers = {
    sidebar: sidebarSlice.reducer,
}

const {
    toggle
} = sidebarSlice.actions

const SidebarActions = {}
SidebarActions.toggle = () => dispatch => dispatch(toggle())

export { SidebarActions }
export default reducers