import * as alertConstants from './alert.constants'

export const alert = (state = {}, action) => {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				type: 'alert-success',
				message: action.payload,
			}
		case alertConstants.ERROR:
			return {
				type: 'alert-danger',
				message: action.payload,
			}
		case alertConstants.INFO:
			return {
				type: 'alert-info',
				message: action.payload,
			}
		case alertConstants.CLEAR:
			return { type: 'alert-clear' }
		default:
			return state
	}
}
