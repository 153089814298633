import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import moment from 'moment-timezone'
import { initReactI18next } from 'react-i18next'
import { config } from '..'
import { getTenantUris } from '../helpers/utils'

// let tenantId, token
const createI18n = (tenantId, token, apiUrl) => {
    const { localeUrl, i18nSaving, appRootPath } = config
    apiUrl = apiUrl || getTenantUris()?.apiUrl || config?.apiUrl
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    const initOpts = {
        fallbackLng: 'en',
        // debug: true,
        saveMissing: i18nSaving,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default

            // eslint-disable-next-line unused-imports/no-unused-vars
            format: function (value, format, lng) {
                if (value instanceof Date) {
                    let now = new Date()
                    // @ts-ignore
                    let olderThanDay = (now - value) / 3600 / 24 / 1000 > 1
                    return olderThanDay ? moment(value).format('MMM-DD-YYYY') : moment(value).fromNow()
                }
                return value
            },
        },
        react: {
            useSuspense: true,
        },
        nonExplicitWhitelist: false,
        useSuspense: false,
    }

    i18n
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)

    // For local development, set REACT_APP_DISABLE_TRANSLATION=true in .env to disable calls to the backend
    if (!config.disableTranslation) {
        const customHeader = {}
        customHeader['x-tenantId'] = tenantId ?? ''
        customHeader['clientApp'] = appRootPath

        if (token) {
            customHeader['Authorization'] = 'Bearer ' + token
        }

        initOpts.backend = {
            loadPath: localeUrl ? `${apiUrl}${localeUrl}/Locale/{{lng}}/{{ns}}` : `${apiUrl}/Locale/{{lng}}/{{ns}}`,
            // loadPath: `${apiUrl}/languages/{{lng}}/{{ns}}/${tenantId}`,
            allowMultiLoading: false,
            crossDomain: true,
            addPath: localeUrl ? `${apiUrl}${localeUrl}/Locale/add/{{lng}}/{{ns}}` : `${apiUrl}/Locale/add/{{lng}}/{{ns}}`,
            customHeaders: customHeader,
            // addPath: `${apiUrl}/locales/add/{{lng}}/{{ns}}`
        }

        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        i18n.use(Backend)
    }

    i18n.init(initOpts)
    i18n.on('languageChanged', language => moment.locale(language))
    return i18n
}

export default i18n
export { createI18n }

// export const reloadResources = (tenantId, token) => {
// 	let customHeader = {}
// 	let { localeUrl, apiUrl, appRootPath, i18nSaving } = config || {}
// 	if (token) {
// 		customHeader = {
// 			authorization: 'Bearer ' + token,
// 			'x-tenantid': tenantId,
// 			clientApp: appRootPath,
// 		}
// 	} else {
// 		customHeader['x-tenantid'] = tenantId
// 		customHeader['clientApp'] = appRootPath
// 	}

// 	if (!tenantId) {
// 		tenantId = ''
// 		customHeader['x-tenantid'] = ''
// 		customHeader['clientApp'] = appRootPath
// 	}
// 	i18n.init({
// 		backend: {
// 			loadPath: localeUrl ? `${apiUrl}${localeUrl}/Locale/{{lng}}/{{ns}}` : `${apiUrl}/Locale/{{lng}}/{{ns}}`,
// 			// loadPath: `${apiUrl}/languages/{{lng}}/{{ns}}/${tenantId}`,
// 			allowMultiLoading: false,
// 			crossDomain: true,
// 			addPath: localeUrl ? `${apiUrl}${localeUrl}/Locale/add/{{lng}}/{{ns}}` : `${apiUrl}/Locale/add/{{lng}}/{{ns}}`,
// 			customHeaders: customHeader,
// 			// addPath: `${apiUrl}/locales/add/{{lng}}/{{ns}}`
// 		},
// 		fallbackLng: 'en',
// 		// saveMissingTo:'current',
// 		// debug: true,
// 		saveMissing: i18nSaving,
// 		interpolation: {
// 			escapeValue: false, // not needed for react as it escapes by default

// 			format: function (value, format, lng) {
// 				if (value instanceof Date) {
// 					let now = new Date()
// 					let olderThanDay = (now - value) / 3600 / 24 / 1000 > 1
// 					return olderThanDay ? moment(value).tz(moment.tz.guess()).format('MMM-DD-YYYY') : moment(value).tz(moment.tz.guess()).fromNow()
// 				}

// 				return value
// 			},
// 		},
// 		react: {
// 			wait: true,
// 		},
// 		nonExplicitWhitelist: false,
// 		useSuspense: false,
// 	})
// 	i18n.on('languageChanged', function (lng) {
// 		moment.locale(lng)
// 	})
// 	return i18n
// }
