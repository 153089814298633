import {Box,Text,Stack,Flex,Image,Divider,Button} from "@chakra-ui/react"
import React from "react"
import success from '../../../assets/success.png'
import fail from '../../../assets/fail.png'
import back from '../../../assets/back.png'
import {useCrawlContext} from './context/CrawlContext'
  
  const ActionResult = () => {
    const {globalCrawlState,closeDrawer} = useCrawlContext()
    return (
      <Stack>
          <Flex justifyContent="space-between" alignItems="center" p="15px" color="#374151">
            <Box display="flex" alignItems="center">
              <Image src={back} alt="back" cursor="pointer" onClick={closeDrawer} />
              <Text fontSize="18px" mx="10px">Preview</Text>
            </Box>
          </Flex>
          <Divider/>
          {(globalCrawlState.drawerState.page==='success' || globalCrawlState.drawerState.page==='fail') &&
          <Stack display='flex' justifyContent='center' width='100%'>     
            <Box mt='100px' mb='30px' mx='0' display='flex' justifyContent='center'>
              <Box px='50px' width='500px' height='175px' borderRadius='10px' border='1px solid #e2e8f0' bg='#f8f9fd' display='flex' flexDirection='column' justifyContent={'center'} alignItems='center'>
                <Image src={globalCrawlState.drawerState.page==='success'?success:fail} alt="back" cursor='pointer' width='50px' height='50px' />
                <Text mt='20px' textAlign='center' key={globalCrawlState.drawerState.page==='success'?'success':'fail'}>{globalCrawlState.drawerState.text}</Text>
              </Box>
            </Box>
            <Box my='20px' display='flex' justifyContent='center' > <Button minW="100px" className="primary-button" onClick={closeDrawer} data-testid='go_to_dashboard' >Go to dashboard</Button></Box>
          </Stack>
        }
        </Stack>
    )
  }
  
  export default ActionResult
  