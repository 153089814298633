import {useState,useRef} from 'react'
import { Stack, Drawer,DrawerOverlay, DrawerContent, DrawerBody} from "@chakra-ui/react"
import {useCommonContext} from 'app/context/CommonContext'
import {useKnowledgeGapsContext} from './context/KnowledgeGapsContext'
import {useServicesContext } from "services/apiServices"
import KnowledgeGapsTable from './pages/KnowledgeGapsTable'
import MatchingKnowledgebaseHomePage from './pages/MatchingKnowledgebaseHomePage'
import AddNewKnowledgePage  from './pages/AddNewKnowledgePage'
import KnowledgeArticlesCreatePage from '../KnowledgeArticles/KnowledgeArticlesCreatePage'
import MyDevicePicker from '../Documents/MyDevicePicker'
import CrawlUrl from '../CrawlUrl/CrawlUrl'
import ViewChatPage from './pages/ViewChatPage'

const KnowledgeGapsContainer = () => {
 
    const {globalState:gapState,closeDrawer,openDrawer,onFixSuccessWithArticle, onFixFailure,onFixSuccessWithDocument,onFixSuccessWithUrl} = useKnowledgeGapsContext()
    const {apiCall,globalState,commonAttributes, categories,simpleTags,dimensions} = useServicesContext()

    const pageElement = <KnowledgeGapsTable />

    const drawerChildElement = gapState.drawerState.page==='home' ? 
        <MatchingKnowledgebaseHomePage  /> :
    gapState.drawerState.page==='addKnowledge' ?
        <AddNewKnowledgePage /> :
    gapState.drawerState.page==='createArticle' ?
        <KnowledgeArticlesCreatePage page='create' title={gapState.gaps.find(gap=>gap.id===gapState.selectedGap)?.utterance} articles={globalState.totalRecords.filter(file=>file.docType==='article')} audienceAttributes={commonAttributes} editorStyle={{ boxheight:'446px',height:'327px', width:'700px'}} display='drawer'
         handleBack={()=>openDrawer({page:'addKnowledge'})} callBackend={apiCall}
         onSubmitSuccess={onFixSuccessWithArticle} onSubmitFailure={onFixFailure} parent='gap'
         />:
    gapState.drawerState.page==='createDocument' ?
        <MyDevicePicker handleClose={()=>openDrawer({page:'addKnowledge'})} commonAttributes={commonAttributes} categories={categories} simpleTags={simpleTags} dimensions={dimensions} totalFiles={globalState.totalRecords} onUploadSuccess={onFixSuccessWithDocument} onUploadFailure={onFixFailure} parent='gap' />
    :gapState.drawerState.page==='crawlUrl' ?
        <CrawlUrl closeDrawer={()=>openDrawer({page:'addKnowledge'})} page='crawlForm' totalUrls={globalState.totalRecords.filter(file=>file.docType==='url' && file.crawlStatus==='Published')} onCreateUrlSuccess={onFixSuccessWithUrl} onCreateUrlFailure={onFixFailure} parent='gap'/>
    :gapState.drawerState.page==='viewChat' ?
        <ViewChatPage />
    :<></>

    return (
        <>
            <Stack  borderRadius="10px" pos="relative" color='#617182'>
              {pageElement}
              <Drawer isOpen={gapState.drawerState.isOpen} placement="right" onClose={closeDrawer} w='740px'  >
                  <DrawerOverlay />
                  <DrawerContent minW='740px'>
                      <DrawerBody p='0' >
                          {drawerChildElement}
                      </DrawerBody>
                  </DrawerContent>
              </Drawer>
            </Stack>
        </>
       
    )
}

export default KnowledgeGapsContainer