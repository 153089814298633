import React, { useEffect, useState } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import config from '../../../config'
const AppLoginFallback = props => {
    const [tValue, setTvalue] = useState(null)

    useEffect(() => {
        window.microsoftTeams.initialize()
        const urlSearchParams = new URLSearchParams(window.location.search)
        const queryParams = Object.fromEntries(urlSearchParams.entries())
        let { tenantId, appName } = queryParams
        let AdtenantId = tenantId
        AdtenantId = localStorage.getItem('AdtenantId')
        appName = localStorage.getItem('appName')

        async function fetchData() {
            try {
                const response = await fetch(`${config.globalTenantServiceUrl || config?.apiUrl}/tenant?aad_tenant_id=${AdtenantId}&app_name=${appName}`)
                let tenant = await response.json()
                setTvalue(tenant)
                // @ts-ignore
                window.microsoftTeams.getContext(function (context) {
                    // ADAL.js configuration
                    //alert(JSON.stringify(context))
                    let configAdal = {
                        // Use the tenant id of the current organization. For guest users, we want an access token for
                        // the tenant we are currently in, not the home tenant of the guest.
                        tenant: context.tid,
                        clientId: tenant && tenant.teamsApId && tenant.teamsApId, //|| morselAppId,//"5f886110-742c-4dfa-95e0-a73b5b3fc6f5",
                        redirectUri: window.location.origin + `/agent/appLoginFinal`, // This should be in the list of redirect uris for the AAD app
                        cacheLocation: 'localStorage',
                        navigateToLoginRequestUrl: false,

                        // Setup extra query parameters for ADAL
                        // - openid and profile scope adds profile information to the id_token
                        // - login_hint provides the expected user name
                        extraQueryParameter:
                            `scope=openid+email+
    offline_access+profile&login_hint=` + encodeURIComponent(context.loginHint),
                    }

                    // Navigate to the AzureAD login page
                    // @ts-ignore
                    let authContext = new window.AuthenticationContext(configAdal)
                    authContext.login()
                })
            } catch (e) {
                console.error(e)
            }
        }
        fetchData()
    }, [])
    return <></>
}
export default withTranslation()(AppLoginFallback)
