import React from 'react'
import { Flex,Box,Text,Button,Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody} from  "@chakra-ui/react"
import { GrClose } from "react-icons/gr"

const DeleteModal = ({isOpen,closeModal, callback, message}) => {
    const handleConfirm = () => {
        callback()
        closeModal()
    }
    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={closeModal} size="md" >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex justifyContent='space-between' >
                        <Text as='h4'>Confirm Delete</Text>
                        <GrClose onClick={closeModal} />
                        </Flex>
                    </ModalHeader>
                    <ModalBody>
                        <Box>
                        <Text textAlign='left'>{message}</Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent='space-between' >
                            <Button onClick={closeModal} mx='2' bg="transparent" border='1px solid #1e90ff' color="#1e90ff" >Cancel</Button>
                            <Button onClick={handleConfirm}  bg="#dc3545"color="#fff" >Confirm</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteModal