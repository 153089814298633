import { Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

const Action = props => {
    const [actions, setActions] = useState(props.actions)
    const [actionMeta, setActionMeta] = useState(props.actionMeta) // actionDef
    const [actionsMeta, setActionsMeta] = useState(props.actionsMeta || (props.meta && props.meta.actions))
    const [disabled, setDisabled] = useState(false)
    const [fieldType, setFieldType] = useState((props.field && props.field.type) || 'text') // field.type
    const [entityValues, setEntityValues] = useState(props.entityValues || {}) // dict of field name and field value passed from the parent.
    const [forceDisable, setForceDisable] = useState(props.forceDisable) // override field level disable false to true
    const [forceEnable, setForceEnable] = useState(props.forceEnable) // overrided field level disable true to false
    const [defDisable, setDefDisable] = useState(props.defDisable)

    const handleAction = actionDef => {
        if (actionDef.callbackParent) {
            props[actionDef.callbackParent]({ item: props.entityValues })
        } else {
            props.handleAction({ item: props.entityValues })
        }
    }

    const spanAction = (actionDef, actionObj) => {
        return (
            <span disabled={props.disabled} key={actionDef.name} className="al-icon-col pull-right">
                {actionDef.classes && <i className={actionDef.classes}></i>}
            </span>
        )
    }

    const linkAction = (actionDef, actionObj) => {
        const t = props.t
        let label = (actionObj && (actionObj.label || actionObj[actionDef.labelProp])) || actionDef.label
        if (t && !actionDef.ignoreT) label = t(label)
        return (
            <Button type="button" disabled={props.disabled} key={actionDef.name} size="sm" m={1} className="btn btn-link">
                {label}
            </Button>
        )
    }
    const buttonAction = (actionDef, actionObj) => {
        const t = props.t
        let label = (actionObj && (actionObj.label || actionObj[actionDef.labelProp])) || actionDef.label
        if (t) label = t(label)
        return (
            <Button
                type="button"
                key={actionDef.name}
                disabled={disabled || actionDef.disabled}
                color={actionDef.color}
                size="sm"
                m={1}
                onClick={() => handleAction(actionDef)}>
                {label}
            </Button>
        )
    }

    const PrepareActionUI = props => {
        switch (props.type) {
            case 'button':
                return buttonAction(props.actionMeta, props.action)
            case 'span':
                return buttonAction(props.actionMeta, props.action)
            case 'link':
                return linkAction(props.actionMeta, props.action)
            default:
                break
        }
    }

    const renderAction = (actionMeta, actionObj) => {
        if (!actionMeta) return <></>
        let type = actionMeta.type
        if (actionObj && actionObj.pop) {
            return actionObj.map((a, i) => {
                return <PrepareActionUI type={type} actionMeta={actionMeta} action={a} />
            })
        } else {
            return <PrepareActionUI type={type} actionMeta={actionMeta} action={actionObj} />
        }
    }

    let actionObj
    if (actionMeta) {
        actionObj = entityValues && entityValues[actionMeta.name]
    }

    return (
        <>
            {actionMeta && renderAction(actionMeta, actionObj)}
            {actions &&
                actions.pop &&
                actions.map(a => {
                    let aDef = actionsMeta[a.name]
                    let aObj = entityValues[a.name]
                    return <>{aDef && renderAction(aDef, aObj)}</>
                })}
        </>
    )
}
export default withTranslation()(Action)
