import React, { useMemo } from 'react'
import {
    Flex,
    PopoverContent,
    PopoverBody,
    Heading,
} from '@chakra-ui/react'

import BlockMenu from '../../BlockMenu'
import { withTranslation } from 'react-i18next'

const AppSwitcher = (props) => {
    const { appRoutes, t } = props
    const appLinks = useMemo(() => appRoutes.filter(route => route.isApp), [appRoutes])
    return (
    <PopoverContent w="300px" _focus={{ boxShadow: "none" }}>
        <PopoverBody display="flex" p={4} w="full">
            <Flex direction="column" gridRowGap={2} w="full">
                <Heading size="md">{t('Switch To')}...</Heading>
                <BlockMenu links={appLinks}/>
            </Flex>
        </PopoverBody>
    </PopoverContent>
    )
}
export default withTranslation()(AppSwitcher)