import marked from 'marked'  
function render_content() {
      let render = new marked.Renderer()
      render.list = function (body, ordered, start) {
        let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        if (ordered) {
          return `<ol>${newtext}</ol>`
        } else {
          return `<ul>${newtext}</ul>`
        }
      }

      render.blockquote = function (quote) {
        let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        return `<blockquote>${newtext}</blockquote>`
      }
      render.code = function (string) {
        return string
      }
      render.image = function (href, title, text, alt, height, width) {
        var newImage = `<img src="${href}" alt="Image" title="Image">`
        return newImage
      }
      return render
    }

export function markdownToHtml(markdownString) {
    const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
    const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
    const markdown_with_replaced_video = markdownString.replace(/\[video\](.*?)\[\/video\]/g, "<video src='$1' controls></video>")
    
    let contentStateeditor = marked(markdown_with_replaced_video, { renderer: render_content() })
    
    contentStateeditor = contentStateeditor.replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
    contentStateeditor = contentStateeditor.replace(/\\n/g, '\n')
    .replace(/(\[button\]Next\[\/button\]\[pagebreak\/\])/g, '---------------')
    .replace(/(\[button\]Next\[\/button\])/g, '')
    .replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
    .replace(/(\[linebreak\/\])/g, '<br>')
    .replace( /<p><video\s*src=['"]([^'"]+)['"] controls><\/video><\/p>/g,"<video src='$1' controls></video>")    
    // .replace(/<video[^>]*>([\s\S]*?)<\/video>/gi, `![image](${config.cdnUri}/cdn/thumbnail.png)`)
    .replace(/^(\d+\.)\s*(.*?)$/gm, "<br>$1$2")

    contentStateeditor = replaceAll(contentStateeditor, '<p></p>)', '')
    if (! contentStateeditor.includes('$schema'))  contentStateeditor =  contentStateeditor.replace(/\{.*?\}/g, '')
    else  contentStateeditor =  contentStateeditor.replace(/\\+([\[\]\*_])/g, '$1')

    let imagePixel = []
    let splitImgTag = contentStateeditor.split('"Image">')
    if (splitImgTag.length > 1) {
        splitImgTag.forEach(e => {
        let reg = e.split('}')[0].split('{')[1]
        if (reg) imagePixel.push(`style="${reg.replaceAll('=', ':').split(' ').join(';').concat(';')}"`)
        })
        if (imagePixel.length) {
        contentStateeditor = contentStateeditor
            .split('title="Image">')
            .map((x, idx) => {
            return x.concat(`"Image" ${imagePixel[idx]} >`)
            })
            .join('\n')
            .replace(/\{.*?\}/g, '')
            .replace('"Image" undefined >', '')
        }
    }
    contentStateeditor = replaceAll(contentStateeditor, '"Image" undefined >', '')
    return contentStateeditor
}