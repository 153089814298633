/*
 *   TO ADD A NEW FILTER:
 *      1. Import
 *      2. Add to the components object
 *      3. Optional: Add to exports if necessary
 *
 *   Rest of the processing and validation will
 *   be taken care of on compile
 *
 */

import StandardFilter from './StandardFilter'

import _ from 'lodash'

const Filters = {}
const components = {
    StandardFilter,
}

_.each(components, component => {
    const { type } = component
    const existingFilter = Filters[component.type]

    if (existingFilter) {
        const conflicts = `[${existingFilter.name}, ${component.name}]`
        throw new Error(`Filters must have unique string types! ${conflicts} both have type '${type}'`)
    }

    Filters[component.type] = component.filterFn
})

export {
    StandardFilter,
}
export default Filters