import  {useState, useEffect} from 'react'
import {FaFile, FaVideo, FaPaperclip} from 'react-icons/fa'

const EditorIcon = props => {
    const { toggle, icon } = props
    return (
        <div className="rdw-option-wrapper editor-icon" onClick={toggle || null}>
           {icon==="video" ? <FaVideo/>: icon=="paperclip" ? <FaPaperclip/> : <FaFile/>}
        </div>
    )
}

const EditorUploadTab = props => {
    const { tab, switchTab, toggleTab } = props
    const [toggle, setToggle] = useState(switchTab)
    useEffect(()=>{
        if(toggleTab && typeof toggleTab === 'function')
        toggleTab(toggle)
    },[toggle, toggleTab])
    return (
        <div className="rdw-image-modal-header">
            {tab && tab.upload && <span className="rdw-image-modal-header-option" onClick={()=>setToggle(true)}>File Upload {switchTab ? <span className="rdw-image-modal-header-label rdw-image-modal-header-label-highlighted"></span> : <span className="rdw-image-modal-header-label"></span>}</span>}
            {tab && tab.url && <span className="rdw-image-modal-header-option" onClick={()=>setToggle(false)}>URL {!switchTab ? <span className="rdw-image-modal-header-label rdw-image-modal-header-label-highlighted"></span> : <span className="rdw-image-modal-header-label"></span>}</span>}
        </div>
    )
}

const EditorLoader = props => {
    return (
        <>
            {props && props.loading && 
            <div className="rdw-image-modal-spinner">
                <div className="rdw-spinner">
                    <div className="rdw-bounce1"></div>
                    <div className="rdw-bounce2"></div>
                    <div className="rdw-bounce3"></div>
                </div>
            </div>
            }
        </>
    )
}

const UploadUrlButton = props => {
    const { url, btndisabled, addInputUrl, toggle } = props
    return (
        <span className="rdw-image-modal-btn-section">
            {url && <button className="rdw-image-modal-btn" disabled={btndisabled} type="button" onClick={addInputUrl}>Add</button>}
            {url && <button className="rdw-image-modal-btn" type="button" onClick={toggle}>Cancel</button>}
        </span>
    )
}

const UploadUrlInput = props => {
    const { url, inputUrl, inputChange } = props
    return (
        <>
            {url && <div className="rdw-image-modal-url-section">
                <input
                    className="rdw-image-modal-url-input"
                    type="url"
                    onChange={e => inputChange(e.target.value)}
                    name="imgSrc"
                    value={inputUrl} />
                <span className="rdw-image-mandatory-sign">*</span>
            </div>
            }
        </>
    )
}

const CustomInputField = props => {
    const [input,setInput] = useState({})

    const onChange = (e) => {
        let inputValue = {[e.target.name]:e.target.value}
        setInput(inputValue)
        props.inputChange(inputValue)
    }
    const { inputConfig } = props
    return (
        <>
        {inputConfig && Array.isArray(inputConfig) && inputConfig.length>0 && inputConfig.map((field,idx)=>{
           return( <div key={idx} style={{padding:'2px'}}>
            <label htmlFor="file">{field.label || 'Enter Input'}</label>
            <input
                type = 'text'
                style={{fontSize:'12px'}}
                value = {input[field.fieldName] ? input[field.fieldName] : ''}
                onChange={onChange.bind(this)}
                name = {field.fieldName || 'input'}
                placeholder={field.placeholder || 'Enter Input'}
            />
            </div>
           )
        })}
           
        </>
    )
}

const UploadFileInput = props => {
    const [content,setContent]=useState()
    const [btndisabled,setBtndisabled] = useState(false)
    const onChange = (event) =>{
        if(event && event.target.files && event.target.files[0])
        props?.validateFileSize(event.target.files[0].size)
        setContent(event.target.files[0])
    }
    const uploadFile = () => {
        setBtndisabled(true)
        if(content){
        props.onChange(content)
        setContent()
        setBtndisabled(false)
    }
    }
    const { inputAccept, customFileConfig, onCustomInputChange, toggle } = props
    return (
        <>
            {<div className="video-modal">
            
                <div className="rdw-image-modal-upload-option">
                    <label htmlFor="file" className="rdw-image-modal-upload-option-label">
                        {content && content.name ? content.name : `Click to upload`}
                    </label>
                </div>
                <input
                    type="file"
                    id="file"
                    accept={inputAccept || '*'}
                    onChange={onChange.bind(this)}
                    className="rdw-image-modal-upload-option-input"
                />
                    {customFileConfig 
                && customFileConfig.customName 
                && customFileConfig.inputField  
                && <CustomInputField 
                        inputConfig={customFileConfig.inputField} 
                        inputChange={onCustomInputChange}
                    />

                }

       <div className="rdw-image-modal-btn-section btnupload">
            <button className="rdw-image-modal-btn" type="button" onClick={uploadFile.bind(this)}>Upload</button>
            <button className="rdw-image-modal-btn" type="button" onClick={toggle || null}>Cancel</button>
        </div>

            </div>
            }
        </>
    )
}

const UploadMultipleFileInput = props => {
    const [content,setContent]=useState([])
    const [files,setFiles]=useState()
    const [btndisabled,setBtndisabled] = useState(false)
    const onChange = async(event) =>{
        let files = []
        if(event && event.target.files && event.target.files[0]){
            let uploadedFile = event.target.files
            let filesData = Object.keys(uploadedFile)
            await asyncForEach(filesData, async (key) => {
                let fileObj = {
                    lastModified: uploadedFile[key].lastModified,
                    lastModifiedDate: uploadedFile[key].lastModifiedDate,
                    name: uploadedFile[key].name,
                    size: uploadedFile[key].size,
                    type: uploadedFile[key].type,
                    webkitRelativePath: uploadedFile[key].webkitRelativePath
                }
                files.push(fileObj)
            })
            setContent(files)
            setFiles(uploadedFile)
        }
    }

    const asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
        }
    }

    const uploadFile = () => {
        setBtndisabled(true)
        if(files && content){
        props.onChange(content, files)
        setFiles()
        setContent([])
        setBtndisabled(false)
    }
    }
    const { inputAccept } = props
    return (
        <>
            { <div className="video-modal">
            
                <div className="rdw-image-modal-upload-option">
                    <label htmlFor="file" className="rdw-image-modal-upload-option-label">
                        {content && content.length === 0 && `Click to upload`}
                        {content && content.length > 0 && content.map(c => {
                             return c && c.name
                          })
                        }
                    </label>
                </div>
                <input
                    type="file"
                    id="file"
                    accept={inputAccept || '*'}
                    onChange={onChange.bind(this)}
                    className="rdw-image-modal-upload-option-input"
                    multiple={"multiple"}
                />

       <div className="rdw-image-modal-btn-section btnupload">
            <button className="rdw-image-modal-btn" disabled={btndisabled} type="button" onClick={uploadFile.bind(this)}>Upload</button>
        </div>

            </div>
            }
        </>
    )
}

const Alert = props => {
    const {msg} = props
    return(
        <>
           {msg && <div className="text-danger" style={{ fontSize: '12px', color:'red' }}>{msg}</div>}
        </>
    )
}

export {
    EditorIcon,
    EditorUploadTab,
    EditorLoader,
    UploadUrlButton,
    UploadUrlInput,
    UploadFileInput,
    UploadMultipleFileInput,
    Alert
}

