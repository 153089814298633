import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { produce } from 'immer'
import {
    Flex,
    Image,
    Link,
    useDisclosure,
    Box
} from '@chakra-ui/react'
import {
    NavLink,
    withRouter,
} from 'react-router-dom'
import { motion } from 'framer-motion'

import Logo     from '../../../assets/RezolveLogo-White.svg'
import LogoMark from '../../../assets/RezoleLogoMark.svg'
import DarkLogo from '../../../assets/logo.png'

import FixedBar     from './sub-components/FixedBar'
import ToggleButton from './sub-components/ToggleButton'
import Links from './sub-components/items/Links'
import NestedSlidingSidebar from './sub-components/items/NestedSlidingSidebar'
// import AppContext from '../../../AppContext'
const MFlex = motion(Flex)

const Sidebar = (props) => {
    const { theme, location, appRoutes, maxWidth, isExpanded, toggle, AppContext,t } =props
    const transitionTime = "1s"
    const width = isExpanded ? maxWidth : '60px'
    const { pathname: currentPath } = location
    const currentApp = useMemo(() => appRoutes.find(({ path }) => {
        const pathRegex = new RegExp(`^${path}`)
        return currentPath.match(pathRegex)
    }), [appRoutes, currentPath])

    const {
        isOpen:  slideoverVisible,
        onOpen:  openSlideover,
        onClose: closeSlideover,
    } = useDisclosure()
    const [submenu, setSubmenu] = useState(null)

    // Set group item click handlers
    const routesWithClickHandlers = useMemo(() => {
        if (currentApp?.children) {
            return produce(currentApp.children, _routes => {
                _.each(_routes, (route, index) => {
                    if (route.type === 'grouped' && route.children) {
                        _.each(route.children, (groupItem, _index) => {
                            const actualItem = currentApp.children[index].children[_index]
                            const { label, links } = actualItem
                            groupItem.onClick = () => {
                                setSubmenu({ title: label, links })
                                openSlideover()
                            }
                        })
                    }
                })
            })
        }
    }, [currentApp?.children, openSlideover])

    const currentAppRoutes = useMemo(() => {
        return currentApp ? currentApp.children : null
    }, [currentApp])

    const slideAmount = slideoverVisible ? "-101%" : "0"
    const colPadding  = slideoverVisible ? 3 : 6
    // Docs: https://www.framer.com/docs/transition/
    const transition  = { type: 'bounce', bounce: 0.3, duration: 0.15 }

    // TODO: Figure out why we need to hard-code this value
    const parentWidth = "330px"
    return (
        <Flex 
        data-testid="sidebarTest1"
        className={`sidebar-flex-toggle${isExpanded?'':' collapse'}`}
          color={theme.fg1} position="relative"
        >
        <Flex 
        data-testid="sidebarTest2"
        className={`sidebar-flex-toggle${isExpanded?'':' collapse'}`}
        color={theme.fg1}
        >
            {/* <FixedBar t={t} theme={theme} logoSrc={LogoMark} displayLogoMark={isExpanded} transitionTime={transitionTime}
               appRoutes={appRoutes} routes={currentAppRoutes}
               links={routesWithClickHandlers}
               dimensions={{ width: 12 }} spacing={{ px: 2, py: 2 }} AppContext={AppContext}/> */}

            <Flex data-testid="sidebarTest3" direction="column" flex={1} w={isExpanded ? '' : 0} 
                   bg={theme?.isLight ? theme.bg2 : theme.bg1} 
                   color={theme?.isLight ? theme.fg2 : theme.fg1} 
            py={4} gridRowGap={5} pr={2} p="10px" pl="10px" pt={4} overflow="hidden">
                {/* <Link to="/" as={NavLink}>                    
                <Image  position="absolute"
                  top={0}
                  z-index={99}
                  width="80%"
                  left="12px" src={theme?.isLight ? DarkLogo :Logo} pr={8} mt={theme?.isLight ? 'inherit' : 3} opacity={isExpanded ? 1 : 0} />
                </Link> */}
                <Flex data-testid="sidebarTest4" className='sidebar-flex' gridColumnGap={colPadding} mt={theme?.isLight ? 0 : '10px'}   overflowX="hidden" maxW={parentWidth}
                      opacity={isExpanded ? 1 : 1} transition={`opacity 0.05s`} marginTop={'10px'}>
                  
                    <MFlex data-testid="sidebarTest5" className='sidebar-mflex' key="main-sidebar" width={'100%'} transition={transition} animate={{ x: slideAmount }}
                           direction="column" gridRowGap={1}
                           initial={{ x: '-100%' }}
                           exit={{ x: '-100%' }}
                           >
                        <Links data-testid="sidebarTest6" theme={theme} isExpanded={isExpanded} links={routesWithClickHandlers}/>
                    </MFlex>

                    { submenu && !_.isEmpty(submenu?.links) &&
                    <MFlex data-testid="sidebarTest7" animate={{ x: slideAmount }} transition={transition}  initial={{ x: '-100%' }}
                    exit={{ x: '-100%' }}>
                        <NestedSlidingSidebar data-testid="sidebarTest8" isExpanded={isExpanded} theme={theme} onNavigateBack={closeSlideover} title={submenu?.title || ''} links={submenu?.links || []}/>
                    </MFlex>
                    }
                </Flex>
            </Flex>

        </Flex>
        {/* <Flex position="absolute" zIndex={999} right={0} top="10%">
        <ToggleButton theme={theme} toggleExpansion={toggle} transitionTime={transitionTime} isExpanded={isExpanded} />
        </Flex> */}
        <Box className={isExpanded ? "sticky-arrow" : "sticky-arrow collapse"} left={isExpanded ? '186px' : '8px'}>
        <ToggleButton theme={theme} toggleExpansion={toggle} transitionTime={transitionTime} isExpanded={isExpanded} />
        </Box>
        </Flex>
    )
}

export default withRouter(Sidebar)