import React, { useMemo, useContext } from 'react'
import _ from 'lodash'
import {
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import Highlighter from 'react-highlight-words'

import convertToString from '../helpers/convertToString'
import ThemeContext from '../../theme/ThemeContext'

// TODO: Schema: { options, value, badgeColorMap, column, state }
const Badge = ({ options = {}, value, badgeColorMap, column: { disableGlobalFilter }, state: { globalFilter } }) => {
    const { theme } = useContext(ThemeContext)
    const textColor = useColorModeValue(theme.bg1, theme.bg1)
    const fontWeight = useColorModeValue("medium", "bold")

    const {
        fontSize     = 'sm',
        overflowWrap = 'anywhere',
        shouldTruncate = false,
        truncateLen    = 24,
        maxWidth       = "full",
    } = options

    let text = value
    if (!_.isString(text)) {
        text = convertToString(text)
    }

    if (shouldTruncate) {
        text = _.truncate(text.toString(), { length: truncateLen })
    }

    const searchWords = useMemo(() => {
        return globalFilter ? globalFilter.split(" ") : []
    }, [globalFilter])
    return (
        <Text fontSize={fontSize} bg={badgeColorMap[value]} align="center" alignSelf="center" color={textColor}
              w="full" maxW={maxWidth} borderRadius="md" py={1} overflowWrap={overflowWrap} fontWeight={fontWeight}>
            { disableGlobalFilter && value }
            { !disableGlobalFilter &&
                <Highlighter autoEscape searchWords={searchWords} textToHighlight={text}/>
            }
        </Text>
    )
}
Badge.type = 'badge'

export default Badge
