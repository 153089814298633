import React, { useMemo, useContext } from 'react'
import _ from 'lodash'
import {
    Flex,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import Highlighter from 'react-highlight-words'
import ThemeContext from '../../theme/ThemeContext'

// TODO: Schema: { options, value, column, state }
const Chips = ({ options = {}, value = [], column: { disableGlobalFilter, id }, state: { globalFilter } }) => {
    const { theme } = useContext(ThemeContext)
    const {
        fontSize = 'xs',
        inline = false,
    } = options

    const searchWords = useMemo(() => {
        return globalFilter ? globalFilter.split(" ") : []
    }, [globalFilter])

    const chipKeyBgColor = useColorModeValue("gray.300", "whiteAlpha.200")
    const chipValBgColor = useColorModeValue(theme.muted, "blackAlpha.500")

    const chipsTexts = _.map(value, ({ name, valueLabel }) => (
        <Flex key={`chip.${id}.${name}`}>
            <Text fontSize={fontSize} bg={chipKeyBgColor} py={1} px={2} borderLeftRadius="md">{name}</Text>
            <Text fontSize={fontSize} bg={chipValBgColor} py={1} px={2} borderRightRadius="md">
                { disableGlobalFilter && valueLabel }
                { !disableGlobalFilter &&
                    <Highlighter autoEscape searchWords={searchWords} textToHighlight={valueLabel}/>
                }
            </Text>
        </Flex>
    ))

    const dirOpts = {
        direction: 'column',
        gridRowGap: 1,
    }

    if (inline) {
        dirOpts.direction  = 'row'
        dirOpts.alignItems = 'center'
        dirOpts.h = 7
        dirOpts.gridColumnGap = 1
        delete dirOpts.gridRowGap
    }

    return (
        <Flex {...dirOpts} justifyContent="space-around">
            {chipsTexts}
        </Flex>
    )
}
Chips.type = 'chips'

export default Chips
