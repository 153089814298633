import { Quill } from 'react-quill'
const BlockEmbed = Quill.import('blots/block/embed')

export class FileAttachmentBlot extends BlockEmbed {
    static create(value) {
        const node = super.create()
        
        const linkHref = value.linkUrl || '#'
        const imageUrl = value.imageUrl || ''

        node.setAttribute('href', linkHref)
        node.setAttribute('target', '_blank')

        const img = document.createElement('img')
        img.setAttribute('src', imageUrl)
        img.setAttribute('height', 100)
        img.setAttribute('width', 100)
        img.setAttribute('alt', 'File Attachment')

        node.appendChild(img)

        return node
    }

    static value(node) {
        return {
            linkUrl: node.getAttribute('href'),
            imageUrl: node.querySelector('img').getAttribute('src')
        }
    }
}