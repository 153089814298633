import { ServiceHelper } from '../helpers/ServiceHelper'
import * as AppConstants from './app.constants'
import createAppService from './app.services'
/**
 * @todo Custom dynamic function with dynamic resourceName and service
 */

const createAppActions = ({ config, dispatch, AlertActions, IdentityApi, IdentityActions }) => {
    if (!config || !dispatch || !AlertActions || !IdentityApi || !IdentityActions) {
        throw new Error('Cannot create AppActions - a dependency is missing!')
    }

    const AppService = createAppService({ config })

    /**
     * To be invoked after Setup.init has run
     *
     * @param   {[type]}  config  config piped through from App.jsx
     */
    // constructor({ config }) {
    // 	this.config = config
    // 	AppService = createAppService({ config: this.config })
    // }

    /**
     *
     * @param {*} payload
     * returns the tenantConfig, tenant, Whitelabel, setting all non sesitive info
     * should be allowed only when CORS domain is allowed.
     */
    const request = payload => {
        let type

        if (payload && payload.method) {
            type = AppConstants[`${payload.method}_REQUEST`]
        } else {
            throw new Error('REQUEST: No method found in payload', payload)
        }

        return { type: type, serviceName: payload.serviceName }
    }

    const success = payload => {
        let type
        let { serviceName, method, ...data } = payload || {}
        if (payload && method) {
            type = AppConstants[`${method}_SUCCESS`]
        } else {
            throw new Error('SUCCESS: No method found in payload', payload)
        }

        return { type, serviceName, ...data, error: undefined }
    }

    const failure = payload => {
        let type
        if (payload && payload.method) {
            type = AppConstants[`${payload.method}_FAILURE`]
        } else {
            throw new Error('FAILURE: No method found in payload', payload)
        }

        return { type, error: payload.errorMsg, serviceName: payload.serviceName }
    }

    const setTeamsData = payload => {
        const { teams } = payload
        const type = AppConstants['SET_TEAMS_DATA']
        return { type, teams }
    }

    // return global tenantConfig, tenant, Whitelabel,
    const getTenantData = async tenant => {
        try {
            dispatch(request({ method: 'GET_TENANTDATA' }))
            let result = await AppService.getTenantData(tenant)
            if (result) {
                dispatch(success({ method: 'GET_TENANTDATA', tenant: result }))
            } else {
                let error = `Cannot fetch the required tenant's data`
                dispatch(failure({ method: 'GET_TENANTDATA', errorMsg: error }))
                AlertActions.error(error)
            }
        } catch (e) {
            dispatch(failure({ method: 'GET_TENANTDATA', errorMsg: e.message || e }))
            AlertActions.error(e)
        }
    }

    const getServiceConfigData = async options => {
        // microservice specific
        // let { tenantId, realm, serviceName } = options || {}
        let { serviceName } = options || {}

        if (!serviceName) serviceName = 'global'

        try {
            dispatch(request({ method: 'APPDATA', serviceName }))
            let result = await AppService.getServiceData(serviceName)
            if (result) {
                dispatch(success({ method: 'APPDATA', data: result, serviceName }))
            } else {
                let error = `Cannot fetch the required tenant's data`
                dispatch(failure({ method: 'APPDATA', errorMsg: error, serviceName }))
                AlertActions.error(error)
            }
        } catch (e) {
            dispatch(failure({ method: 'APPDATA', errorMsg: e.message || e }))
            AlertActions.error(e)
        }
    }

    /**
     *
     *  * returns the global tenantConfig, tenant, Whitelabel,  all non sesitive info
     * should be allowed only when CORS domain is allowed.
     */
    const getPublicTenantData = async () => {
        try {
            let tenant
            let { rootDomains, defaultSubdomains, singleTenant, realmName, landingPage } = config || {}
            dispatch(request({ method: 'GET_TENANT' }))
            const hostname = window.location.hostname
            let rDs = rootDomains?.length && rootDomains?.split(',')
            let flag = rDs?.some(r => hostname.endsWith(r))
            if (hostname.substring(hostname.indexOf('.') + 1) && flag) {
                let dS = defaultSubdomains?.split(',')
                let tenantName = hostname.substring(0, hostname.indexOf('.'))

                if (tenantName && !dS?.includes(tenantName)) {
                    let result = await AppService.getPublicTenantData(hostname)
                    if (result) {
                        tenant = {
                            tenantId: hostname.substring(0, hostname.indexOf('.')),
                            url: hostname,
                            isTenant: true,
                            idpUrl: result.federationServerUrl,
                            cdnUri: result.cdnUri,
                            ...result,
                            domain: hostname,
                            apiUrl: result.apiUrl + result.basePath,
                            socketUrl: result.apiUrl,
                        }
                        IdentityApi.interceptors.request.use(config => {
                            config.headers.common['x-tenantId'] = tenant.id
                            return config
                        })
                        ServiceHelper.setTenantInfo({ apiUrl: tenant.apiUrl, basePath: tenant.basePath, socketUrl: result.apiUrl })
                        localStorage.setItem('apiUrl', tenant.apiUrl)
                        sessionStorage.setItem('apiUrl', tenant.apiUrl)
                        localStorage.setItem('cdnUri', tenant.cdnUri)
                        sessionStorage.setItem('cdnUri', tenant.cdnUri)
                        localStorage.setItem('socketUrl', tenant.socketUrl)
                        sessionStorage.setItem('socketUrl', tenant.socketUrl)
                        dispatch(success({ method: 'GET_TENANT', tenant }))
                    } else {
                        let error = 'Cannot fetch the required tenant'
                        dispatch(failure({ method: 'GET_TENANT', errorMsg: error }))
                        AlertActions.error(error)
                    }
                } else if (singleTenant && realmName) {
                    tenant = {
                        tenantId: realmName,
                        id: 'none',
                        url: realmName,
                        isTenant: true,
                        rootDomain: rootDomains,
                        landingPage: landingPage || '/',
                    }
                    dispatch(success({ method: 'GET_TENANT', tenant }))
                } else {
                    tenant = {
                        tenantId: 'default',
                        url: hostname,
                        isTenant: false,
                        landingPage: '/register',
                        rootDomain: rootDomains,
                    }
                    dispatch(success({ method: 'GET_TENANT', tenant }))
                }
            } else {
                let error = 'Current domain does not seem to match the expected domain , please contact your administrator'
                dispatch(failure({ method: 'GET_TENANT', errorMsg: error }))
                AlertActions.error(error)
            }
        } catch (error) {
            console.log('CAUGHT', error)
            dispatch(failure({ method: 'GET_TENANT', errorMsg: error?.message ?? error }))
            AlertActions.error(error)
        }
    }

    const ensureAuthentication = () => {
        const onAuthenticated = async () => {
            setTimeout(async() => {
                const teamsData = await AppService.getTeamsData()
                dispatch(setTeamsData(teamsData))
            }, 2000)
        }
        dispatch(IdentityActions.ensureAuthentication(onAuthenticated))
    }
    const logout = () => dispatch(IdentityActions.logout())

    const getUserData = async options => {
        try {
            let { userId, userName } = options || {}
            if (!userId || !userName) {
                let errorMsg = `User name or id not provided`
                dispatch(failure({ method: 'USERDATA', errorMsg }))
                AlertActions.error(errorMsg)
                return
            }

            dispatch(request({ method: 'USERDATA' }))
            let result = await AppService.getUserData(options)
            if (result) {
                dispatch(success({ method: 'USERDATA', tenant: result }))
            } else {
                let error = `Cannot fetch the required user data`
                dispatch(failure({ method: 'USERDATA', errorMsg: error }))
                AlertActions.error(error)
            }
        } catch (e) {
            dispatch(failure({ method: 'USERDATA', errorMsg: e.message || e }))
            AlertActions.error(e)
        }
    }

    return {
        getTenantData,
        getServiceConfigData,
        getPublicTenantData,
        ensureAuthentication,
        logout,
        getUserData,
    }
}

// const createAppActions = params => new AppActions(params)
export default createAppActions

// const AppActionsInst = new AppActions()
// const getTenantData = AppActionsInst.getTenantData.bind(AppActionsInst)
// const getPublicTenantData = AppActionsInst.getPublicTenantData.bind(AppActionsInst)
// const getUserData = AppActionsInst.getUserData.bind(AppActionsInst)
// const getServiceConfigData = AppActionsInst.getServiceConfigData.bind(AppActionsInst)
// export { getTenantData, getPublicTenantData, getUserData, getServiceConfigData }
