//
//  See here for time formats:
//  https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
//

import React, { useMemo, useContext } from 'react'
import {
    Text,
} from '@chakra-ui/react'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import ThemeContext from '../../theme/ThemeContext'

// TODO: Schema: { options, value, column, state, dateFormat }
const Timestamp = ({ options = {}, value, dateFormat, column: { disableGlobalFilter }, state: { globalFilter } }) => {
    const { theme } = useContext(ThemeContext)
    const {
        fontSize     = 'sm',
        overflowWrap = 'anywhere',
        align        = 'center',
    } = options

    const searchWords = useMemo(() => {
        return globalFilter ? globalFilter.split(" ") : []
    }, [globalFilter])
    let format = 'MMMM Do YYYY, h:mm A'
    if (dateFormat) {
        format = (dateFormat === 'short') ? 'l LT' : dateFormat
    }
    const formattedValue = moment(value).format(format)

    return (
        <Text color={theme.fg1} fontSize={fontSize} alignSelf={align} overflowWrap={overflowWrap}>
            { disableGlobalFilter && formattedValue }
            { !disableGlobalFilter &&
                <Highlighter autoEscape searchWords={searchWords} textToHighlight={formattedValue}/>
            }
        </Text>
    )
}
Timestamp.type = 'datetime'

export default Timestamp
