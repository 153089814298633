import React from 'react'
import {
    FaComments,
    FaPuzzlePiece,
} from 'react-icons/fa'
import {
    GiTicket,
    GiChatBubble
} from 'react-icons/gi'
import Roles from './Roles'
const {
    asc_admin
} = Roles

type App = {
    name: string,
    isRoute: boolean,
    label: string,
    icon: React.ReactNode,
    path: string,
    roles: string[],
}

type AppsMap = {
    LiveChat: App,
    Agent: App,
    User: App,
    Config: App, 
    ML:App
}

const Apps: AppsMap = {
    LiveChat: {
        name: 'LiveChat',
        isRoute: false,
        label: 'Live Chat',
        icon:  <GiChatBubble color="#19a61e"/>,
        path: '/agentlivechat',
        roles: [asc_admin],
    },
    Agent: {
        name: 'Agent',
        isRoute: false,
        label: 'Agent App',
        icon:  <FaPuzzlePiece color="#E74C3C"/>,
        path: '/agent',
        roles: [asc_admin],
    },
    User: {
        name: 'User',
        isRoute: false,
        label: 'User App',
        icon:  <GiTicket color="#8463F9"/>,
        path: '/user-management',
        roles: [asc_admin],
    },
    Config: {
        name: 'Config',
        isRoute: false,
        label: 'Configuration App',
        icon:  <FaComments color="#F7875E"/>,
        path: '/botautomation',
        roles: [asc_admin],
    }, 
    ML:{
        name: 'mlapp',
        isRoute: false,
        label: 'mlapp',
        icon:  <FaComments color="#F7875E"/>,
        path: '/',
        roles: [asc_admin],
    }, 
}

export default Apps
export type { App }
