import { createAlertActions    } from './alert'
import { createAppActions      } from './app'
import { ServiceHelper         } from './helpers/ServiceHelper'
import { createIdentityActions } from './identity'

let AlertActions, config, history

const init = ({
	config:  _config,
	history: _history,
	dispatch,
}) => {
	config  = _config
	history = _history

	AlertActions = createAlertActions({ dispatch })
	const {
		IdentityActions,
		IdentityApi,
	} = createIdentityActions({ config, history, AlertActions })

	ServiceHelper.initialize({ config, IdentityApi })
	const AppActions = createAppActions({ config, dispatch, AlertActions, IdentityApi, IdentityActions })

	console.log("COMMON INIT - Completed")
	return { AppActions }
}

// --------------------
//   Exports

export default init
export {
	AlertActions,
	config,
	history,
}
