import {
    shouldForwardProp,
    StyleProps,
} from '@chakra-ui/react'
import filterProps from './filterProps'

const filterChakraProps = (props: StyleProps) => {
    return filterProps(props, key => !shouldForwardProp(key))
}

export default filterChakraProps
