import React from 'react'
import {
  Divider,Button,Tabs,TabList,TabPanels,Tab,TabPanel,
  Flex,Stack,Box,InputGroup,InputRightElement,Input,Image,Text, UnorderedList, ListItem,Spinner,IconButton,Tooltip, useToast,Progress
} from "@chakra-ui/react"
import {HiOutlineArrowCircleLeft} from 'react-icons/hi'
import {useKnowledgeGapsContext} from '../context/KnowledgeGapsContext'

const MatchingKnowledgebaseHomePage  = () => {
  const {closeDrawer,openDrawer} = useKnowledgeGapsContext()
  return (
    <Stack>
      <Flex direction='column' gap='20px' p='15px'>
        <Flex gap='4px' alignItems='flex-start'>
          <Text as='span' className='back-btn'><HiOutlineArrowCircleLeft cursor='pointer' color='#2563EB' onClick={closeDrawer} data-testid='kg_drawer_home_back_icon'/></Text>
          <Flex direction='column' gap='6px' paddingLeft="4px">
            <Text as='span' className="modal-title"  data-testid='kg_drawer_home_heading' paddingTop="4px">Matching Knowledgebase</Text>
            <Text as='span' fontSize="14px" color='#6B7280' data-testid='kg_drawer_home_text'>The system has identified several matching knowledge sources in the existing knowledge base. You can either merge with the existing knowledge source or create as new.</Text>
            <Tabs mt='14px'>
              {/* <TabList color='#6B7280' padding='8px 5px' bg='blackAlpha.50' gap='20px' borderRadius='5px' border='none'> */}
                  {/* <Tab _selected={{background:'#fff',color:'#374151',borderRadius:'5px',border:'1px solid #fff',outline:'none'}} background='transparent'  padding='5px 10px' data-testid='kg_drawer_knowledge_library_tab'>Knowledge Library</Tab> */}
                  {/* <Tab _selected={{background:'#fff',color:'#374151',borderRadius:'5px',border:'1px solid #fff',outline:'none'}} background='transparent'  padding='5px 10px' data-testid='kg_drawer_small_talk_tab' >Small Talk</Tab> */}
                {/* </TabList> */}
                {/* <TabPanels> */}
                  {/* <TabPanel px='0px' data-testid='kg_drawer_knowledge_library_content'> */}
                    <Flex gap='10px' justifyContent='flex-end' paddingTop="20%">
                      <Button className="secondaryButton" height='36px' variant='outline' onClick={closeDrawer} data-testid='kg_drawer_home_cancel_button'>Cancel</Button>
                      <Button className="primary-button" height='36px' onClick={()=>openDrawer({page:'addKnowledge'})} data-testid='kg_drawer_home_add_button' >Add New Knowledge</Button>
                    </Flex>
                  {/* </TabPanel> */}
                {/* </TabPanels> */}
            </Tabs>
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  )
}

export default MatchingKnowledgebaseHomePage 