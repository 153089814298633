import React, { useCallback, useState, useMemo, useEffect, useContext } from 'react'
import _ from 'lodash'
import { produce } from 'immer'
import {
    Flex,
    Text,
    Badge,
    Button,
    useDisclosure,
    Select,
    Input,
    IconButton,
} from '@chakra-ui/react'
import {
    BiFilterAlt,
} from 'react-icons/bi'
import {
    CloseIcon,
} from '@chakra-ui/icons'

import PopoverButton from './PopoverButton'
import ThemeContext from '../../theme/ThemeContext'

const FilterButton = ({ columns, filters }) => {
    const { theme } = useContext(ThemeContext)
    const { isOpen, onToggle, onClose } = useDisclosure()

    const filterableColumns = _.filter(columns, column => column.canFilter)
    const filterableFields  = _.map(filterableColumns, f => ({ name: f.id, label: f.Header }))

    const [richFilters, setRichFilters] = useState([])
    const generateRichFilters = useCallback(() => {
        const newRichFilters = []
        _.each(filters, ({ id, value: { condition, filterText }}) => {
            const column = columns.find(col => (col.id === id))
            if (column) {
                newRichFilters.push({
                    field: { name: id, label: column.Header },
                    condition, filterText,
                })
            }
        })
        setRichFilters(newRichFilters)
    }, [filters, columns])

    // Update rich filters when filters changes
    useEffect(() => {
        if (filters) {
            generateRichFilters()
        }
    }, [filters, generateRichFilters])

    const validateRichFilters = useCallback(() => {
        let hasErrors = false
        setRichFilters(produce(richFilters, _filters => {
            _.each(_filters, _filter => {
                const { field, condition, filterText } = _filter
                let errors = []
                if (!field || _.isEmpty(field) || !field.name || !field.label) {
                    errors.push('field')
                }
                if (!condition || _.isEmpty(condition)) {
                    errors.push('condition')
                }
                if (!filterText || _.isEmpty(filterText)) {
                    errors.push('filterText')
                }

                if (!_.isEmpty(errors)) {
                    hasErrors = true
                }
                _filter.errors = errors
            })
        }))
        return !hasErrors
    }, [richFilters])

    const disabledFields = useMemo(() => new Set(_.map(richFilters, filter => filter.field.name)), [richFilters])

    const conditionOptions = useMemo(() => [
        { name: 'isFilter',       operator: 'eq',   label: 'Is'       },
        { name: 'isNotFilter',    operator: 'ne',   label: 'Is Not'   },
        { name: 'containsFilter', operator: 'like', label: 'Contains' },
    ], [])

    const updateRichFilters = useCallback((field, index) => event => {
        const { value } = event.target

        setRichFilters(produce(richFilters, _filters => {
            const filter = _filters[index]
            if (filter) {
                if (field === 'field') {
                    filter.field = filterableFields.find(f => f.name === value) || {}
                } else {
                    filter[field] = value
                }
            }
        }))

    }, [richFilters, filterableFields])

    const removeRichFilter = useCallback(index => () => {
        setRichFilters(produce(richFilters, _filters => { _filters.splice(index, 1) }))
    }, [richFilters])

    const renderFilter = useCallback(({ key, field, condition, filterText, errors }, index) => {
        const errorSet = new Set(errors)

        return (
            <Flex key={`filter:${key}.${index}`} gridColumnGap={2}>
                <Select size="sm" value={field.name} onChange={updateRichFilters('field', index)}
                        placeholder="Select a field..." isInvalid={errorSet.has('field')} errorBorderColor="red.600"
                        onBlur={validateRichFilters}>
                    { _.map(filterableFields, ({ name, label }) => (
                        <option key={`option.${name}`} value={name} disabled={disabledFields.has(name)}>{label}</option>
                    ))}
                </Select>

                <Select size="sm" value={condition} onChange={updateRichFilters('condition', index)}
                        placeholder="Select condition..." isInvalid={errorSet.has('condition')}
                        errorBorderColor="red.600" onBlur={validateRichFilters}>
                    { _.map(conditionOptions, ({ name, label }) => (
                        <option key={`option.${name}`} value={name}>{label}</option>
                    ))}
                </Select>

                <Input size="sm" value={filterText} onChange={updateRichFilters('filterText', index)}
                       placeholder="Filter field for..." isInvalid={errorSet.has('filterText')}
                       errorBorderColor="red.600" onBlur={validateRichFilters}/>

                <IconButton title="Delete Filter" icon={<CloseIcon boxSize={3}/>} onClick={removeRichFilter(index)}/>
            </Flex>
        )
    }, [validateRichFilters, conditionOptions, filterableFields, disabledFields, removeRichFilter, updateRichFilters])

    const filterFields = _.map(richFilters, (filter, index) => renderFilter(filter, index))

    const onAddFilter = useCallback(() => {
        setRichFilters(produce(richFilters, _filters => {
            _filters.push({ key: 'new', field: {}, condition: '', filterText: '' })
        }))
    }, [richFilters])

    const onApplyFilters = useCallback(() => {
        if (validateRichFilters()) {
            const filterMap = {}
            _.each(richFilters, filter => {
                filterMap[filter.field.name] = filter
            })

            _.each(filterableColumns, column => {
                const { setFilter } = column
                const filterObj = filterMap[column.id]
                if (filterObj) {
                    const { condition, filterText } = filterObj
                    const condObj = conditionOptions.find(c => c.name === condition)
                    const { operator } = condObj
                    setFilter({ condition, operator, filterText })
                } else {
                    setFilter(undefined)
                }
            })

            setRichFilters([])
        }
    }, [richFilters, validateRichFilters, conditionOptions, filterableColumns])

    // Computed state
    //
    const cannotAddFilter = (filterFields.length >= filterableFields.length)

    const label = (
        <>
            Filter
            { filters && (filters.length > 0) &&
                <Badge bg={theme.primary} color={theme.bg2} borderRadius="md" ml={2} px={2} py={1}>{filters.length}</Badge>
            }
        </>
    )

    return (
        <PopoverButton isOpen={isOpen} toggleIsOpen={onToggle} closeOnBlur={true} width="xl"
                       onClose={onClose} icon={<BiFilterAlt/>} label={label}>
            <Flex direction="column" gridRowGap={3}>
                <Flex gridColumnGap={2}>
                    <Text fontWeight="semibold">Filters:</Text>
                    { _.isEmpty(filterFields) &&
                        <Text>{'(none)'}</Text>
                    }
                </Flex>
                { filterFields &&
                <Flex direction="column" gridRowGap={2} mb={1}>
                    { filterFields }
                </Flex>
                }
                <Flex justifyContent="space-between">
                    <Button onClick={onAddFilter} disabled={cannotAddFilter}>Add Filter</Button>
                    <Button onClick={onApplyFilters} colorScheme="blue">Apply Changes</Button>
                </Flex>
            </Flex>
        </PopoverButton>
    )
}

export default FilterButton