import _ from 'lodash'
import { produce } from 'immer'
import {
    Apps,
    Roles,
    AppRoute,
    PathRoute,
    GroupedLink,
    RouteData,

} from './common/router'
import {
    NotificationsComponent,
    SupportComponent,
    ProfileComponent,
    Logout
} from './common/containers'
// import ListComponent from './containers/agent/inbox/agentContainer' 
// import TeamMovement from 'containers/agent/teamMovement'
// import { SettingsComponent } from 'containers'
// import {
//     QueueComponent, 
//     CategoryHierachy, 
//     CannedNotesComponent, 
//     EmailSignature, ResponseSla, 
//     CategoryComponent,
//     EmailSettingsList,
//     CustomerSurvey
// ,AutomationList}  from 'containers/agent/settings'

// import SlaGoals from 'containers/agent/settings/sla/SlaGoals'
import Home from 'app/Home'

// Icons
//
import {
    AiOutlineAreaChart,
} from 'react-icons/ai'
import {
    BsQuestionSquare,
} from 'react-icons/bs'
import {
    HiOutlineBookOpen,
} from 'react-icons/hi'
import {
    IoAnalyticsSharp,
} from 'react-icons/io5'
import {
    RiInboxFill,
    RiMailSendLine,
    RiMailLine,
    RiUserAddFill,
    RiBarChartLine,
    RiDraftFill,
    RiMailSettingsLine,
    RiTimeLine,
    RiPenNibFill,
    RiSortDesc,
    RiAndroidLine
} from 'react-icons/ri'
import ContentManagement from 'app/content/pages/ContentManagement'
import Documents from 'app/content/pages/Documents'
import Urls from 'app/content/pages/Urls'
import Sharepoint from 'app/content/pages/Sharepoint'
import Articles from 'app/content/pages/Articles'
import Gaps from 'app/content/pages/Gaps'

const {
    asc_admin,
    LiveChatAgent,
    LiveChatSuperviser,
    LiveChatAdmin,
    TicketAgent,
    TicketUser,
    TicketSuperviser,
    TicketAdmin,
    aidochub
} = Roles

const appRoutes: AppRoute[] = []

//  ---------------------- ROUTE DEFINITIONS ----------------------


// Other Apps:
//
// Agent
//
const agentRoles = [
    asc_admin, 
    LiveChatAgent,
    LiveChatSuperviser,
    LiveChatAdmin,
    TicketAgent,
    TicketUser,
    TicketSuperviser,
    aidochub
]
appRoutes.push({
    app: Apps.ML,
    children: [
        // { type: 'pathRoute', path: 'aiconsole',            title: 'home',     icon: RiInboxFill,       Component: Documents,            roles: agentRoles },
        { type: 'pathRoute', path: 'aiconsole/documents',            title: 'documents',     icon: RiInboxFill,       Component: Documents,            roles: agentRoles },
        { type: 'pathRoute', path: 'aiconsole/urls',            title: 'crawlUrl',     icon: RiInboxFill,       Component: Urls,            roles: agentRoles },
        { type: 'pathRoute', path: 'aiconsole/sharepoint',            title: 'sharepoint',     icon: RiInboxFill,       Component: Sharepoint,            roles: agentRoles },
        { type: 'pathRoute', path: 'aiconsole/articles',            title: 'articles',     icon: RiInboxFill,       Component: Articles,            roles: agentRoles },
        { type: 'pathRoute', path: 'aiconsole/gaps',            title: 'gaps',     icon: RiInboxFill,       Component: Gaps,            roles: agentRoles },
        // { type: 'pathRoute', path: '/inbox',            title: 'Inbox',     icon: RiInboxFill,       Component: ListComponent,            roles: agentRoles },
        // { type: 'pathRoute', path: '/inbox/:caseId?',            title: 'Inbox',     icon: RiInboxFill,       Component: ListComponent,            roles: agentRoles, isVisible: false },
        // { type: 'pathRoute', path: '/inbox/MoveToTeam/:caseId?',            title: 'Inbox',     icon: RiInboxFill,       Component: TeamMovement,            roles: agentRoles, isVisible: false },
        
        // { type: 'pathRoute', path: '/appLogin',            title: 'App Login',     icon: RiInboxFill,       Component: AppLoginComponent,            roles: [], isVisible: true },
        // { type: 'pathRoute', path: '/sent',             title: 'Sent',      icon: RiMailSendLine,    Component: SentComponent,             roles: agentRoles },
        // { type: 'pathRoute', path: '/all',              title: 'All',       icon: RiMailLine,        Component: AllComponent,              roles: agentRoles },
        // { type: 'pathRoute', path: '/knowledge',        title: 'Knowledge', icon: HiOutlineBookOpen, Component: KnowledgeComponent,        roles: agentRoles },
        // { type: 'pathRoute', path: '/failedUtterances', title: 'Unmatched', icon: BsQuestionSquare,  Component: FailedUtterancesComponent, roles: agentRoles },
        // { type: 'sidebarBreak' },
        // { type: 'pathRoute', path: '/analytics', title: 'Analytics', icon: IoAnalyticsSharp, Component: AgentAnalyticsComponent, roles: agentRoles },
        // { type: 'pathRoute', path: '/reports',   title: 'Reports',   icon: AiOutlineAreaChart, Component: AgentReportsComponent,   roles: agentRoles },
        // { type: 'sidebarBreak' },
        // { type: 'NestedRoute', title: 'Settings', icon: RiUserAddFill, Component: QueueComponent, roles: agentRoles },
        

//         { type: 'pathRoute', path: '/settings/queue', title: 'Queue', icon: RiUserAddFill, Component: QueueComponent, 
//     roles: agentRoles
// },
//         { type: 'pathRoute', path: '/settings/category', title: 'Category', icon: RiSortDesc, Component: CategoryComponent, 
//     roles: [asc_admin, TicketAdmin]
// },
// { type: 'pathRoute', path: '/settings/categoryHierachy', title: 'Category Hierarchy', icon: RiBarChartLine, Component: CategoryHierachy, 
// roles: [asc_admin]

// },

    // { type: 'grouped', heading: 'Queue1', path: '/teams', dataId: 'teams', sublinks: [
    //     { type: 'pathRoute', path: '/settings/queue', title: 'Queue1', icon: RiUserAddFill, Component: QueueComponent, roles: ['asc_admin', 'TicketAdmin', 'TicketSuperviser']}
    // ]}
   
//         { type: 'pathRoute', path: '/settings/canned_notes', title: 'Canned Response', icon: RiDraftFill, Component: CannedNotesComponent, roles: [asc_admin, TicketAdmin, TicketSuperviser, TicketAgent] },
//         { type: 'pathRoute', path: '/settings/EmailSignature/general', title: 'Email Signature', icon: RiMailSettingsLine, Component: EmailSignature, roles: [asc_admin] },
//         { type: 'pathRoute', path: '/settings/CustomerSurvey/defaultSetting', title: 'Customer Survey', icon: RiBarChartLine, Component: CustomerSurvey, 
// roles: [asc_admin]

// },

//         { type: 'pathRoute', path: '/settings/ResponseSla', title: 'Response SLA', icon: RiTimeLine, Component: ResponseSla, roles: [asc_admin,
//         LiveChatAgent,
//         LiveChatSuperviser,
//         LiveChatAdmin,
//         TicketSuperviser,
//         TicketAdmin ]},
        // { type: 'pathRoute', path: '/settings/sla', title: 'SLA Goals', icon: RiPenNibFill, Component: SlaGoals, roles: [asc_admin, TicketAdmin, TicketSuperviser] },
        // { type: 'pathRoute', path: '/settings/automationList/general', title: 'Automation', icon: RiAndroidLine, Component: AutomationList, roles: [asc_admin,TicketSuperviser] },
        // { type: 'pathRoute', path: '/settings/EmailSettings/', title: 'Email Settings', icon: RiPenNibFill, Component: EmailSettingsList, roles: [asc_admin, TicketAdmin, TicketSuperviser] }
    ],
})

// Other Apps
//
// appRoutes.push({ app: Apps.User,   children: [] })
// appRoutes.push({ app: Apps.Config, children: [] })

// These are not visually rendered, but added to the Router
// const moreRoutes: RouteData[] = [
//     { type: 'frameworkRoute', path: '/notifications', Component: NotificationsComponent, roles: [] },
//     { type: 'frameworkRoute', path: '/support',       Component: SupportComponent,       roles: [] },
//     { type: 'frameworkRoute', path: '/profile',       Component: ProfileComponent,       roles: [] },
//     { type: 'frameworkRoute', path: '/logout',        Component: Logout,                 roles: [] },
// ]

//
// ---------------------- END OF ROUTE DEFINITIONS ----------------------


// --------------------- DO NOT EDIT BELOW THIS LINE ---------------------
//
//  If you are only here to add a route definition,
//  The rest of this file is just processing and converting above data into
//  a format that Sidebar and RootRouter can easily consume.
//
//
const formattedAppRoutes = _.map(appRoutes, ({ app, children }) => {
    // Recursively prefix all routes
    const prefixRoutes = (prefix: any, routes: any) => {
        _.each(routes, route => {
            if (route.path) {
                route.path = `${prefix}${route.path}`
                if (route.children) {
                    prefixRoutes(route.path, route.children)
                }
            } else if (route.type === 'grouped' && route.path) {
                route.path = `${prefix}${route.path}`
            }
        })
    }
    prefixRoutes(app.path, children)

    // The app root path will route to the first child
    //
    const firstChild   = _.find(children, c => c.type === 'pathRoute')
    const redirectPath = (firstChild && firstChild.type === 'pathRoute') ? firstChild.path : undefined

    return {
        ...app,
        isApp: true,
        redirectPath,
        children,
    }
})

// Inject the groups data into all routes
//
type GroupedLinksData = { [key: string]: GroupedLink[] }
type CreateRoutesProps = {
    groupedLinksData: GroupedLinksData,
}
const createRoutes = ({ groupedLinksData }: CreateRoutesProps) => {
    if (!groupedLinksData || _.isEmpty(groupedLinksData)) {
        return null
    }

    const routes = produce(formattedAppRoutes, _appRoutes => {
        _.each(_appRoutes, appRoute => {
            const { isApp, children } = appRoute

            if (isApp) {
                let parent: string = ""
                _.each(children, route => {
                    if (route.type === 'subHeading') {
                        parent = route.subheading

                    } else if (route.type === 'sidebarBreak') {
                        parent = ""

                    } else if (route.type === 'grouped') {
                        const { dataId, path, sublinks } = route
                        const groups = groupedLinksData[dataId]
                        if (groups) {
                            const groupItems = _.map(groups, groupItem => {
                                const { id } = groupItem
                                groupItem.links = groupItem.links || []
                                _.each(sublinks, sublink => {
                                    const link: PathRoute = _.cloneDeep(sublink)
                                    link.path = `${path}/${id}${link.path}`
                                    groupItem.links.push(link)
                                })

                                return _.cloneDeep(groupItem)
                            })

                            route.children = groupItems
                        }

                    } else if (route.type === 'pathRoute') {
                        route.parent = parent
                    }
                })
            }
        })
    })

    const allRoutes = [
        ...routes,
      
    ]

    return allRoutes
}

export { createRoutes }
