import React, { ReactNode } from 'react'
import _     from 'lodash'
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'
import { Permissions } from '..'
import ConfigType from 'common/ConfigType'
import routeListSchema from './routeSchema'
import { RouteData } from '.'

type RenderParams = {
    Component: ReactNode,
    title: string,
}
type RootRouterProps = {
    config: ConfigType,
    routes: RouteData[],
    render: (renderParams: RenderParams) => ReactNode,
    identity: any
}
const RootRouter = ({ config, routes, render,identity }: RootRouterProps) => {

    const DEBUG = false
    const log   = (...msg: any[]) => {
        if (DEBUG) {
            console.log("ROUTER:", ...msg)
        }
    }

    // Validate route schema
    //
    try {
        routeListSchema.validate(routes)
    } catch(err) {
        console.log("ROUTES VALIDATION ERROR", err)
    }

    // Compile routes based on permissions
    // Redirect unauthorized routes
    //
    const unauthorizedPath = "/unauthorized"

    /**
     * Processes _routes and adds them to routeElements array
     */
    const renderRoutes = (routeElements: ReactNode[], _routes: RouteData[], keyPrefix = 'route') => {
        _.each(_routes, (route, index) => {
            const { type } = route
            const { isApp, children, path: appPath, redirectPath: appRedirectPath }: any = route

            if (type === 'sidebarBreak' || type === 'subHeading') {
                return
            }

            let frameworkRoute, pathRoute, groupedRoute, redirectRoute
            switch (type) {
                case 'frameworkRoute':
                    frameworkRoute = route
                    break
                case 'pathRoute':
                    pathRoute = route
                    break
                case 'grouped':
                    groupedRoute = route
                    break
                case 'redirect':
                    redirectRoute = route
                    break
                default:
                    break
            }

            const isExact = true

            const roles = frameworkRoute?.roles ?? pathRoute?.roles ?? redirectRoute?.roles
            // const redirectUrl = pathRoute && pathRoute?.redirect && pathRoute?.redirect?.redirectUrl
            const path  = frameworkRoute?.path  ?? pathRoute?.path  ?? redirectRoute?.path ?? groupedRoute?.path
            let childRoutes: RouteData[] =  []

            const key = `${keyPrefix}.${index}`
            
            if (Permissions.isAuthorized({ roles })) {
                if (redirectRoute) {
                    const { path, redirectPath } = redirectRoute
                    routeElements.push(
                        <Redirect key={key} exact={isExact} path={path} to={redirectPath} />
                    )

                } else if (groupedRoute) {
                    const { children } = groupedRoute
                    // Unpack grouped children
                    childRoutes = []
                    _.each(children, ({ links }) => {
                        childRoutes = [
                            ...childRoutes,
                            ...links
                        ]
                    })

                } 
                else if (pathRoute && pathRoute?.children) {
                    childRoutes = []
                    _.each(pathRoute?.children, (elem) => {
                        childRoutes = [
                            ...childRoutes,
                                elem
                        ]
                    })
                } 
                else {
                    const Component = frameworkRoute?.Component ?? pathRoute?.Component
                    const title     = pathRoute?.title || ""
                    const isRedirect=pathRoute?.isRedirect || false                    
                    if (!Component && !isApp && !isRedirect) {
                        console.log("ERROR: Trying to render a component route without a Component!", route)
                    }

                    if (isApp) {
                        childRoutes = children
                        if (appRedirectPath) {
                            routeElements.push(
                                <Redirect key={key} exact={isExact} path={appPath} to={appRedirectPath} />
                            )
                        } else {
                            routeElements.push(
                                <Route key={key} exact={isExact} path={appPath} />
                            )
                        }


                    } else {
                        // If this is a route for another app, we need the route, but there is
                        // no Component to render. Also, we don't want to do a '!Component' check
                        // because it should fail if Component is not specified
                        //
                        routeElements.push(
                            <Route key={key} exact={isExact} path={path}>
                                { !isApp && render({ Component, title }) }
                            </Route>
                        )
                    }
                } 
                // Render child routes
                if (childRoutes && !_.isEmpty(childRoutes)) {
                    renderRoutes(routeElements, childRoutes, key)
                }

                log("YES", path, !roles, _.isEmpty(roles), Permissions.hasAny(roles))

            } 
            else {
                let forRole = pathRoute?.willRedirect && pathRoute?.willRedirect?.url && pathRoute?.willRedirect?.forRole
                if(forRole && identity){
                   let iRole=identity && identity.roles && Array.isArray(identity.roles) ? identity.roles : []
                   let checkRole=pathRoute?.willRedirect?.forRole || null
                   if(iRole && checkRole && iRole.includes(checkRole)){
                    routeElements.push(
                        <Redirect key={key} exact={isExact} path={path} to={pathRoute?.willRedirect?.url} />
                    )
                }
                }else
                routeElements.push(
                    <Redirect key={key} exact={isExact} path={path} to={unauthorizedPath} />
                )
                log("NO", path, roles)
            }
        })
    }
    const renderedRoutes: ReactNode[] = []

    renderRoutes(renderedRoutes, routes)
    const { appRootPath } = config
    return (
        <Switch>
            { renderedRoutes }
            <Redirect path="/" exact to={appRootPath} />
            <Redirect to="/error" />
        </Switch>
    )
}



export default RootRouter