import React from "react"
import {KnowledgeArticlesProvider} from 'app/components/KnowledgeArticles/context/KnowledgeArticlesContext'
import KnowledgeArticlesContainer from "app/components/KnowledgeArticles/KnowledgeArticlesContainer"

const Articles = () => {

    return (
      <React.Fragment>
            <KnowledgeArticlesProvider>
                <KnowledgeArticlesContainer />
            </KnowledgeArticlesProvider>
      </React.Fragment>
    )
}

export default Articles