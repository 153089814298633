import React from "react"
import {DocumentContextProvider} from 'app/components/Documents/context/DocumentContext'
import DocumentContainer from 'app/components/Documents/DocumentContainer'

const Documents = () => {
    return (
        <React.Fragment>
            <DocumentContextProvider >
              <DocumentContainer/>
            </DocumentContextProvider>
      </React.Fragment>
    )
}

export default Documents