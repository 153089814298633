import React from 'react'
import {withTranslation} from 'react-i18next'
import { Flex, Button } from '@chakra-ui/react'

const WorkflowButton = (props) => {
    const {possibleStates, workflowActionMeta, t} = props

    const handleWorkFlow = (workflowActionMeta, workflowState) => {
        if (!workflowActionMeta) return
        const { entityValues } = props
        if(workflowActionMeta.callbackParent){
            if (!props[workflowActionMeta.callbackParent]) console.error(`Workflow Action Fn ${workflowActionMeta.callbackParent} is not found.`)
            else props[workflowActionMeta.callbackParent]({item: entityValues, nextState: workflowState})
        }else{
            let actionFn = workflowActionMeta.action
        }
    }

    return(
        <Flex>
            {                 
                possibleStates && Array.isArray(possibleStates) 
                && possibleStates.map(workflowState=>
                    <Button size="sm" m={1} type="button" key={workflowState.label} onClick={handleWorkFlow.bind(this,workflowActionMeta, workflowState)}>{t(workflowState?.label || '')}</Button>
                )
            }
        </Flex>
    )
}


export default withTranslation()(WorkflowButton)
