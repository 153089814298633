import {Box,Text,Stack,Flex,Image,Divider,Button,Spinner} from "@chakra-ui/react"
  import React from "react"
  import { GrFormClose } from "react-icons/gr"
  import success from '../../../assets/success.png'
  import fail from '../../../assets/fail.png'
  import back from '../../../assets/back.png'
import { useSharepointContext } from './context/SharepointContext'
  
const SharepointActionResultPage = () => {
      const {globalSiteState,closeDrawer} = useSharepointContext()
    return (  
     <Stack>
        <Flex
            justifyContent="space-between"
            alignItems="center"
            p="15px"
            color="#374151"
        >
            <Box display="flex" alignItems="center">
                <Image src={back} alt="back" cursor="pointer" onClick={closeDrawer} />
                <Text fontSize="18px" mx="10px">{globalSiteState.drawerState.title}</Text>
            </Box>
        </Flex>
        <Divider/>
        <Stack display='flex' justifyContent='center' width='100%'>
            <Box mt='100px' mb='30px' mx='0' display='flex' justifyContent='center'>
            <Box px='50px' width='500px' height='250px' borderRadius='10px' border='1px solid #e2e8f0' bg='#f8f9fd' display='flex' flexDirection='column' justifyContent={'center'} alignItems='center'>
            <Image src={globalSiteState.drawerState.page==='success'?success:fail} alt="back" cursor='pointer' width='50px' height='50px' />
                <Text mt='20px' key='success'>{globalSiteState.drawerState.text}</Text>      
            </Box>
            </Box>
            <Box my='20px' display='flex' justifyContent='center' > <Button minW="100px" bg="#1e90ff" color="#fff" fontSize='16px' fontWeight='400' onClick={closeDrawer} > Go to List</Button> </Box>
        </Stack>
     </Stack>
    )
  }
  
  export default SharepointActionResultPage
  