import * as React    from 'react'
import * as ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'
import { Provider      } from 'react-redux'
import { PersistGate   } from 'redux-persist/lib/integration/react'
import * as serviceWorker from './serviceWorker'
import {
    QueryClient,
    QueryClientProvider,
  } from 'react-query'

import configureStore from './redux/store'
import rootReducer    from './redux/reducers'
import config         from './config'

import AppContainer from './AppContainer'

const { store, persistor, history } = configureStore(config, rootReducer)
const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            refetchOnWindowFocus:false
        }
    }
})
function Content() {
    return (
        <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppContainer config={config} history={history} />
            </PersistGate>
        </Provider>
    </BrowserRouter>
    )
  }
const rootElement = document.getElementById("root")
ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <Content/>
        </QueryClientProvider>,
    rootElement
)
serviceWorker.unregister()
