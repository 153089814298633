import React, { useCallback } from 'react'
import _ from 'lodash'
import {
    Flex,
    Link,
    useToast,
} from '@chakra-ui/react'
import Joi from 'joi'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
const linkSchema = Joi.object({
    icon: Joi.any().optional(),
    label: Joi.string().required(),
    path: Joi.string().required(),
    key: Joi.string().optional(),
    isRoute: Joi.bool().default(true),
    canLogout: Joi.bool().default(true)
})

const linkListSchema = Joi.array().items(linkSchema)

const BlockMenu = ({ links, compact, t }) => {
    if (!linkListSchema.validate(links)) {
        console.error("BlockMenu links are not in a valid format", links)
    }
    const toast = useToast()
    const handleClick = useCallback(path => () => {
        let canLogout = localStorage.getItem('canLogout')
        if (path == '/logout' && canLogout && JSON.parse(canLogout).logout === false) {
            return toast({
                title: t(JSON.parse(canLogout).title),
                description: t ? t(JSON.parse(canLogout).message) : JSON.parse(canLogout).message,
                status: 'error',
                isClosable: true,
                position: "top-right"
            })
        }
        else {
            window.location = `${window.origin}${path}`
        }
    }, [])
    const routeProps = useCallback((isRoute, path, onClick) => {
        let canLogout = localStorage.getItem('canLogout')
        if (isRoute && !canLogout) {
            return { as: NavLink, to: path }
        }
        else if (onClick) {
            return { onClick }
        } else if (path) {
            return { onClick: handleClick(path) }
        } 
    }, [handleClick])

    const spacing = { px: 4, py: 3, gridColumnGap: 2 }
    if (compact) {
        spacing.py = 1
    }
    return (
        <Flex direction="column" w="full" gridRowGap={1}>
            {_.map(links, ({ label, path, key, icon, isRoute, onClick, style, rightIcon }, index) => (
                <Link key={`${key || path}.${index}`}
                    {...routeProps(_.isUndefined(isRoute) ? true : isRoute, path, onClick)}
                    display="flex" {...spacing}
                    borderRadius={compact ? '' : 'md'} alignItems="center"
                    _hover={{ textDecor: 'none', bg: "blackAlpha.200" }} {...style}>
                    {icon}
                    {t(label)}
                    {rightIcon}
                </Link>
            ))}
        </Flex>
    )
}

export default withTranslation()(BlockMenu)  