import axios from 'axios'
import Keycloak from 'keycloak-js'
import { getTenantUris } from '../helpers/utils'
import IdentityApi from './identity.api'
// Interface:
//
//		- API: axios instance
//		- setup(tenant: { tenantId })
//			-- Creates a new keycloak instance if one doesn't exist already
//		- verifyAuth({ identity, tenant })
//			-- Checks if current token exists and is valid
//				-- Returns true if it is, false if not
//				-- (Might have to setInterceptor again. Technically shouldn't but that is current implementation)
//		- initiateLogin({ tenantId })
//			-- Uses keycloak instance to initiate login
//				-- On success
//					-- Gets token from instance
//					-- Set interceptor
//					-- save in session storage
//					-- if single tenant, save tenant id
//					-- Returns token
//				-- Throws error on failure

const createIdentityService = ({ config }) => {
    const { debugIdentityService: DEBUG } = config
    let keycloakInstances = {}

    const log = (...msg) => {
        if (DEBUG) {
            console.log('IdentityService: ', ...msg)
        }
    }

    const setup = (tenantId, idpUrl) => {
        if (!tenantId) {
            throw new Error('tenantId is not defined')
        }

        if (!keycloakInstances[tenantId]) {
            keycloakInstances[tenantId] = new Keycloak({
                url: idpUrl || config.idpUrl,
                realm: tenantId,
                clientId: 'adminui-service',
            })

            log('NEW KEYCLOAK', tenantId, keycloakInstances[tenantId])
        }
    }

    // const ensureAuthentication = async props => {
    // 	const { dispatch, identity, tenant } = props

    // 	//const token = sessionStorage.getItem('keycloak_token');
    // 	//const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
    // 	const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
    // 	if (identity.token && identity.isAuthenticated && !isExpired) {
    // 		//console.log("Already Logged into Actionable Science")
    // 		// let token = sessionStorage.getItem("keycloak_token");
    // 		// let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
    // 		//!identity.isAuthenticated &&
    // 		keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
    // 		dispatch(identityActions.loginCheckSuccess())
    // 		await setInterceptor(identity.token, tenant.id)
    // 		// setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);
    // 	} else {
    // 		console.log('SESSION EXPIRED : ', isExpired)
    // 		// dispatch(identityActions.clearIdentity())
    // 		dispatch(identityActions.initiateLogin())
    // 		keycloakInstances[tenant.tenantId]
    // 			.init({
    // 				onLoad: 'login-required',
    // 				flow: 'standard',
    // 			})
    // 			.success(async authenticated => {
    // 				dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
    // 				await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
    // 				setTimeout(() => dispatch(identityActions.setIdentity()), 1000)
    // 				updateSession(tenant)
    // 				if (!config.singleTenant) saveTenantId()
    // 			})
    // 			.error(err => dispatch(identityActions.loginFailure()))
    // 	}
    // }

    const verifyAuth = ({ identity, tenant }) => {
        const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
        if (identity.token && identity.isAuthenticated && !isExpired) {
            keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
            setInterceptor(identity.token, tenant.id, identity)
            return true
        } else {
            return false
        }
    }

    const ensureLogin = ({ tenant }) => {
        return new Promise((resolve, reject) => {
            const keycloak = keycloakInstances[tenant.tenantId]
            log('Keycloak Instance', keycloak)
            keycloak
                .init({
                    onLoad: 'login-required',
                    flow: 'standard',
                })
                .then(authenticated => {
                    const { token, realmAccess, tokenParsed, timeSkew } = keycloak

                    log('AUTHENTICATED', authenticated)
                    log('TOKEN', token)
                    keycloak.loadUserInfo().then(userInfo => {
                        setInterceptor(token, tenant.id, tokenParsed)
                        updateSession(tenant)
                        if (!config.singleTenant) {
                            saveTenantId()
                        }

                        log('Got UserInfo', userInfo)
                        resolve({ token, userInfo, realmAccess, tokenParsed, timeSkew })
                    })
                })
                .catch(error => reject(error))
        })
    }

    // const completeLogin = ({ tenant }) => {
    // 	return new Promise((resolve, reject) => {
    // 		const keycloak = keycloakInstances[tenant.tenantId]
    // 		console.log("KEYCLOAK", tenant.tenantId, keycloak)

    // 		const token = keycloak.token
    // 		console.log("TOKEN", token)
    // 		if (token) {
    // 			resolve(token)
    // 		} else {
    // 			console.log("NO TOKEN....INIT AGAIN")

    // 			keycloak.init({
    // 				onLoad: 'login-required',
    // 				flow: 'standard',
    // 			})
    // 			.success(authenticated => {
    // 				console.log("AUTHENTICATED", authenticated)

    // 				const token = keycloakInstances[tenant.tenantId].token
    // 				setInterceptor(token, tenant.id)
    // 				updateSession(tenant)
    // 				resolve()

    // 				if (!config.singleTenant) {
    // 					saveTenantId()
    // 				}
    // 			})
    // 			.error(error => reject(error))
    // 		}
    // 	})
    // }

    const logout = tenantId => {
        const keycloak = keycloakInstances[tenantId]
        if (keycloak) {
            const redirectUri = `${window.location.origin}/logout`

            keycloak.logout({ redirectUri }).then(() => {
                clearSession()
            })

            delete keycloakInstances[tenantId]
        } else {
            clearSession()
        }
    }

    // const fetchInfo = tenantId => {
    // 	return new Promise((resolve, reject) => {
    // 		const keycloak = keycloakInstances[tenantId]
    // 		if (keycloak) {
    // 			const { realmAccess, tokenParsed, timeSkew } = keycloak

    // 			keycloak.loadUserInfo()
    // 			.success(userInfo => resolve({ userInfo, realmAccess, tokenParsed, timeSkew }))
    // 			.error(error => reject(error))

    // 		} else {
    // 			reject(new Error(`No Keycloak instance for tenantId: '${tenantId}'`))
    // 		}
    // 	})
    // }

    // const logoutService = tenant => {
    // 	let redirectURL = `${window.location.protocol}//${window.location.host || window.location.hostname}/logout`
    // 	if (keycloakInstances[tenant.tenantId]) {
    // 		let keycloak = keycloakInstances[tenant.tenantId]
    // 		// keycloak.logout({ redirectUri: redirectURL });
    // 		delete keycloakInstances[tenant.tenantId]
    // 		sessionStorage.clear()
    // 		localStorage.clear()
    // 		window.location.href = keycloak.createLogoutUrl({ redirectUri: redirectURL })
    // 	}
    // }

    const updateSession = tenant => {
        sessionStorage.setItem('keycloak_token', keycloakInstances[tenant.tenantId].token)
        sessionStorage.setItem('keycloak_refreshToken', keycloakInstances[tenant.tenantId].refreshToken)
    }

    const clearSession = () => {
        sessionStorage.clear()
        localStorage.clear()
    }

    const setInterceptor = (token, tenantUid, tokenParsed, headerParams, isTeams = false) => {
        // const UNAUTHORIZED = 401
        IdentityApi.interceptors.response.use(
            response => response,
            error => {
                const { status } = error?.response || {}
                let isExpired = false,
                    tokenDate
                if (tokenParsed && tokenParsed.exp) {
                    try {
                        tokenDate = new Date((tokenParsed.exp + (tokenParsed.timeSkew || 0.1)) * 1000)
                    } catch (e) {
                        console.log(e, `Error in parsing token date`)
                        tokenDate = new Date()
                    }
                    isExpired = tokenDate < new Date()
                    // console.log(tokenDate, isExpired)
                }
                if (isExpired && (status === 401 || status === 403)) {
                    window.location.reload()
                }
                return Promise.reject(error)
            }
        )

        IdentityApi.interceptors.request.use(config => {
            if (isTeams) config.headers.common['msTokenSignedForUser'] = token
            else config.headers.common['Authorization'] = 'Bearer ' + token
            config.headers.common['x-tenantId'] = tenantUid
            config.headers.common['Content-Type'] = 'application/json'

            if (headerParams) {
                let headerParamsConfig = JSON.parse(headerParams)
                headerParamsConfig.forEach(p => {
                    config.headers.common[p.name] = p.value
                })
            }
            return config
        })
    }

    const saveTenantId = () => {
        let ep = getTenantUris()?.apiUrl || config.apiUrl
        let platform = 'Web'
        if (navigator.userAgent.match(/Android/i)) platform = 'Android'
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) platform = 'iOS'

        const requestOptions = {
            method: 'POST',
            data: { platform: platform },
            headers: { 'Content-Type': 'application/json' },
            url: `${ep}/botdef/saveUserData`,
        }
        return IdentityApi.request(requestOptions).then(response => {
            if (response.status !== 200 && response.status !== 304) {
                return Promise.reject(response)
            }
            return response.data
        })
    }

    // const handlerParent = event => {
    // 	let data = event.data
    // 	alert(`handleParent ${JSON.stringify(data)}`)
    // 	if (typeof data == 'string') data = JSON.parse(data)
    // 	//console.log("Hello World? from service ", data['token'], typeof data, event)
    // 	sessionStorage.setItem('ms_token', data.token)
    // 	//window.removeEventListener("message", identityServices.handlerParent)
    // }
    const initiateIdentityService = async (tenant, dispatch) => {
        let myTenant = tenant
        // seprate ms tenant check
        const urlSearchParams = new URLSearchParams(window.location.search)
        const queryParameters = Object.fromEntries(urlSearchParams.entries())
        //console.log("Query params from service", queryParameters)
        if ((queryParameters && (queryParameters.isTeams == true || queryParameters.isTeams == 'true')) || sessionStorage.getItem('isTeams')) {
            sessionStorage.setItem('isTeams', true)
            //console.log("Inside insure athantication condition", queryParameters)
            let msToken = sessionStorage.getItem('ms_token')

            if (!msToken) {
                sessionStorage.setItem('ms_token', queryParameters.token)
            }
            msToken = queryParameters.token
            if (msToken) {
                let retToken = await msIdentityService(myTenant, msToken, queryParameters.appName, dispatch)
                return retToken
            }
            return true
        } else {
            setup(myTenant?.tenantId)
            return true
        }
    }

    const msIdentityService = async (tenant, msToken, appName, dispatch) => {
        //console.log(tenant, msToken)
        //		let result = await getAcessTokenFromMsToken(msToken, appName, tenant.apiUrl)
        let apiUrl = tenant.apiUrl
        if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
        const requestOptions = {
            url: `${apiUrl}/tenant/getAuthToken/${appName}`,
            headers: { msToken: msToken },
            method: 'GET',
        }
        let result = await axios.request(requestOptions)
        let tokenData = result.data
        if (tokenData && tokenData.signedPayload) {
            IdentityApi.interceptors.request.use(config => {
                // config.headers.common['msTokenSignedForUser'] = tokenData.signedPayload
                config.headers.common['msTokenSignedForUser'] = `Bearer  ${tokenData.signedPayload.split(' ')[1]}` 
                config.headers.common['x-tenantId'] = tenant.id
                config.headers.common['Content-Type'] = 'application/json'
                return config
            })
            sessionStorage.setItem('ms_access_token', tokenData.signedPayload)
            return tokenData
        }
    }

    // const getUserRoleById = (tenantId, userId) => {
    // 	const requestOptions = {
    // 		method: 'GET',
    // 		headers: {
    // 			'Content-Type': 'application/json',
    // 		},
    // 		url: `${config.apiUrl}/role/user-roles/${userId}?tenantId=${tenantId}`,
    // 	}

    // 	return IdentityApi.request(requestOptions).then(response => {
    // 		if (response.status !== 200 && response.status !== 304) {
    // 			return Promise.reject(`Unable to fetch roles : Error code : ${response.status}`)
    // 		}

    // 		return response.data
    // 	})
    // }

    // const getRolesByTenant = tenantId => {
    // 	const requestOptions = {
    // 		method: 'GET',
    // 		headers: {
    // 			'Content-Type': 'application/json',
    // 		},
    // 		url: `${config.apiUrl}/role/tenant-roles/${null}?tenantId=${tenantId}`,
    // 	}

    // 	return IdentityApi.request(requestOptions).then(response => {
    // 		if (response.status !== 200 && response.status !== 304) {
    // 			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
    // 		}

    // 		return response.data
    // 	})
    // }

    // const getTenantRoleById = (tenantId, roleId) => {
    // 	const requestOptions = {
    // 		method: 'GET',
    // 		headers: {
    // 			'Content-Type': 'application/json',
    // 		},
    // 		url: `${config.apiUrl}/role/tenant-role/${roleId}?tenantId=${tenantId}`,
    // 	}
    // 	return IdentityApi.request(requestOptions).then(response => {
    // 		if (response.status !== 200 && response.status !== 304) {
    // 			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
    // 		}

    // 		return response.data
    // 	})
    // }

    // const setInterceptor = (tenant) => {
    //     console.log("I WAS HIT !!! 1 ")
    //     IdentityApi.interceptors.request.use(config => {
    //         console.log("I WAS HIT !!! 2 ",keycloakInstances)
    //         keycloakInstances[tenant.tenantId].logout().updateToken(5)
    //         .then(refreshed => {

    //             if (refreshed) {
    //             updateSession(keycloakInstances[tenant.tenantId].logout())
    //             }
    //             config.headers.Authorization = 'Bearer ' + keycloakInstances[tenant.tenantId].logout().token;
    //             config.headers['Content-Type']  = 'application/json'

    //             return Promise.resolve(config)
    //         })
    //         .catch(() => {
    //             keycloakInstances[tenant.tenantId].logout().login();
    //         })
    //     })
    // }

    return {
        IdentityService: {
            setup,
            verifyAuth,
            ensureLogin,
            logout,
            initiateIdentityService,
            // initiateLogin,
            // completeLogin,
            // fetchInfo,
            // logoutService,
            // updateSession,
            // getUserRoleById,
            // getRolesByTenant,
            // getTenantRoleById,
        },
        IdentityApi,
    }
}

export default createIdentityService
