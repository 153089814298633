import React, { useCallback } from 'react'
import {
    Flex,
    Text,
    Divider,
} from '@chakra-ui/react'
import {
    IoArrowBackCircleSharp,
} from 'react-icons/io5'
import Links from './Links'

const NestedSlidingSidebar = ({ theme, title, links, onNavigateBack }) => {
    const renderedLinks = <Links theme={theme} links={links} keyPrefix="nestedbar." />

    const handleBackClick = useCallback(() => {
        onNavigateBack()
    }, [onNavigateBack])

    return (
        <Flex direction="column" gridRowGap={2} bg={theme.tertiary}>
            <Flex alignItems="center" gridColumnGap={2} px={3} py={1} onClick={handleBackClick}
                  borderRadius="md" bg="blackAlpha.300" _hover={{ cursor: 'pointer' }}>
                <IoArrowBackCircleSharp/>
                <Text>Back</Text>
            </Flex>
            <Divider borderColor="whiteAlpha.500"/>
            <Flex direction="column" gridRowGap={1}>
                <Text pl={2} textTransform="uppercase" fontSize="md" fontWeight="bold">{title}</Text>
                { renderedLinks }
            </Flex>
        </Flex>
    )
}

export default NestedSlidingSidebar