export const GET_TENANT_REQUEST = 'GET_TENANT_REQUEST'
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS'
export const GET_TENANT_FAILURE = 'GET_TENANT_FAILURE'
export const GET_TENANTDATA_REQUEST = 'GET_TENANTDATA_REQUEST'
export const GET_TENANTDATA_SUCCESS = 'GET_TENANTDATA_SUCCESS'
export const GET_TENANTDATA_FAILURE = 'GET_TENANTDATA_FAILURE'
export const APPDATA_REQUEST = 'APPDATA_REQUEST'
export const APPDATA_SUCCESS = 'APPDATA_SUCCESS'
export const APPDATA_FAILURE = 'APPDATA_FAILURE'

export const SET_TEAMS_DATA = 'SET_TEAMS_DATA'

export const USERDATA_REQUEST = 'USERDATA_REQUEST'
export const USERDATA_SUCCESS = 'USERDATA_SUCCESS'
export const USERDATA_FAILURE = 'USERDATA_FAILURE'
