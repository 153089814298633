import { useState, useCallback, useEffect } from 'react'
import { AtomicBlockUtils } from 'draft-js'
import { 
    EditorUploadTab, 
    EditorLoader, 
    UploadUrlButton, 
    UploadUrlInput,
    UploadFileInput,
    Alert } from './default'


const UploadModel = (props)=>{
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [modal, setModal] = useState({ url: false, upload: false })
    const [inputUrl, setInputUrl] = useState('')
    const [btndisabled, setBtndisabled] = useState(false)
    const [uploadTabConfig, setUploadTabConfig] = useState( { tab: props.tab ||{ upload: false, url: false }, switchTab: props.tab.upload || false, toggleTab: null })
    const [customInput, setCustomInput] = useState({})


    useEffect(()=>{
       const { tab } =  props
       let config = uploadTabConfig
       config.tab = tab
       config.switchTab=tab.upload
       setUploadTabConfig(config)

       let modelProps = modal
       modelProps.url = !tab.upload && tab.url ? true : false
       modelProps.upload = tab.upload ? true : false 
       setModal(modelProps)
    }, [modal, props, uploadTabConfig])
    

    const toggleTab = useCallback((toggle)=>{
        const uploadTab = Object.assign({
            ...uploadTabConfig,
            switchTab: toggle
        })
        const modalProps = Object.assign({
            ...modal,
            url: !toggle ? true : false,
            upload: toggle ? true : false

        })
        setUploadTabConfig(uploadTab)
        setModal(modalProps)
    }, [uploadTabConfig, modal])

    const createEntity = useCallback(({entityObj}) => {
        const { editorState, onChange, type } = props
        if(type && entityObj && typeof entityObj === 'object' && Object.keys(entityObj).length > 0){
            const entityKey = editorState
                .getCurrentContent()
                .createEntity(`${type}`, 'MUTABLE', entityObj)
                .getLastCreatedEntityKey()

            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                ' '
            )
            onChange(newEditorState)
        }else{
            setErrorMsg(`Entity Type is missing from config!`)
        }
    }, [props])

    const validateFileSize = useCallback((size) =>{
        const { maxUploadSize } = props
        let maxFileSize = maxUploadSize || 2 //in MB
        if(maxFileSize && size){
            let fileSize = parseFloat(size / 1024 / 1024)
            if(fileSize > maxFileSize){
                setErrorMsg(`File Size must be less than ${maxFileSize}MB`)
                setLoading(false)
                return false
            } else if(fileSize <= maxFileSize) {
                setErrorMsg(``)
                return true
            } else return true
        }
        else {
            setErrorMsg(`File size is Missing !`)
            setLoading(false)
            return false
        }
        
    }, [props])

    const validateFileType = useCallback((file) =>{
        const {allowed} = props
        if(file && file.name && allowed && Array.isArray(allowed) && allowed.length > 0){
            let splitExt = file.name.substr(file.name.lastIndexOf('.')+1)
            if(splitExt && allowed.indexOf(splitExt) == -1){
                setErrorMsg(`Please Upload only ${allowed.join(',')} extension File!`)
                setLoading(false)
                return false
            } else return true
        }
        else{
            setErrorMsg(`File type or allowed type is Missing!`)
            setLoading(false)
            return false
        }


    }, [props])

    const onCustomInputChange = useCallback((value) =>{
        const customInputVal = Object.assign({}, ...customInput, value)
        setCustomInput(customInputVal)
     }, [customInput])
    
    const onChange= useCallback(async(file)=> {
            const {type}= props
            setLoading(true)
            if (file && file.size && file.type && validateFileSize(file.size) && validateFileType(file)) {
                    let uploadConfig = {customInput, type:type ? type.toLowerCase() : null, access:'private'}
                    let upload = await props.uploadCallback(file,uploadConfig)
                    if (upload && upload.data) {
                        let entityObj = upload.data
                        createEntity({entityObj})
                        setLoading(false)
                        setErrorMsg(false)
                        props.toggle()
                    }
                    else{
                        setErrorMsg(`Error while uploading...!`)
                    }
            }
    
        }, [customInput, props, createEntity, validateFileSize, validateFileType])

    const isValidExtenstion= useCallback((inputUrl)=>{
        const {allowed}= props
        if (inputUrl) {
            let splitExt = inputUrl.substr(inputUrl.lastIndexOf('.')+1)
            if(splitExt && allowed && Array.isArray(allowed) && allowed.length > 0  && allowed.indexOf(splitExt)==-1){
                setErrorMsg(`Invalid ${allowed.join(',')} Extension Upload URL`)
                setBtndisabled(true)
                return false
            }
            else return true

        }
        else return false
    }, [props])

    const isHttpsURL = useCallback((inputUrl)=>{
        let pattern = new RegExp('^(https:)')
        let checkHttps = pattern.test(inputUrl)
        if(checkHttps){ 
            setErrorMsg(false)
            setBtndisabled(true)
            return true
        }
        else{ 
            setErrorMsg(`Please use a URL which starts with 'https'`)
            setBtndisabled(false)
            return false
        }
    }, [])

    const validateUrlInput = useCallback((inputUrl)=>{
        if(isValidUrl(inputUrl)){
        if (isHttpsURL(inputUrl) && isValidExtenstion(inputUrl))
            return true
        else
            return false
        }else {
            setErrorMsg(`Invalid URL`)
            setBtndisabled(false)
            return false
        }
    }, [isHttpsURL, isValidExtenstion])

    const addInputUrl = useCallback(()=>{
        setBtndisabled(true)
        if(inputUrl && validateUrlInput(inputUrl)){
                let entityObj = {link:inputUrl}
                createEntity({entityObj})
                props.toggle()
            }  
        }, [inputUrl, props, validateUrlInput,createEntity])
    
    const inputChange = useCallback((value)=>{
        setInputUrl(value)
    },[])

    const isValidUrl=(inputUrl)=>{
        try {
            new URL(inputUrl)
            return true
        } catch (_) {
            return false
        }
    }

    const {inputAccept}= props
    return (
        <div className="rdw-image-modal">
            <EditorUploadTab
                {...uploadTabConfig}
                toggleTab={toggleTab}
            />

            <div>
                <Alert
                    msg={errorMsg}
                />
                <UploadFileInput
                    validateFileSize={validateFileSize}
                    upload={modal.upload}
                    inputAccept={inputAccept || '*'}
                    onChange={onChange}
                    customFileConfig={props.customFileConfig}
                    onCustomInputChange={onCustomInputChange}
                    toggle={props.toggle}
                />
                <UploadUrlInput
                    url={modal.url}
                    inputUrl={inputUrl}
                    inputChange={inputChange}
                />

            </div>
            <div>&nbsp;</div>
            <UploadUrlButton
                url={modal.url}
                disabled={btndisabled}
                addInputUrl={addInputUrl}
                toggle={props.toggle}
            />
            <EditorLoader loading={loading} />

        </div>

    )
}
export default UploadModel