import Role from "./Role"

type Roles = { [key: string]: Role }

// Role/Permission Constants
//   - Can be used as `Roles.asc_admin` etc
//
const roles: Roles = {
    asc_admin: 'asc_admin',
    AscAdmin: 'ASC Admin',
    LiveChatAgent: 'chat_agent',
    LiveChatSuperviser: 'chat_agent_supervisor',
    LiveChatAdmin:'chat_agent_admin',
    TicketAgent: 'ticket_agent',
    TicketUser:'ticket_user',
    TicketSuperviser: 'ticket_agent_supervisor',
    TicketAdmin: 'ticket_admin',
    CustomerAgent: 'customer_agent',
    aidochub:'aidochub'
}

export default roles
