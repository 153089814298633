import _ from 'lodash'
import ConfigType from 'common/ConfigType'
import Role from '../router/Role'

type InitProps = {
    config: ConfigType,
    tenant: string,
    identity: any, // TODO: Replace
}

/**
 * A static class used to verify if the current user has specific permssions
 * or not.
 *
 * - Usage:
 *
 *         Permissions.hasAny(['asc_admin', 'bot_analyst'])
 *         // => returns true if current user has either 'asc_admin' or 'bot_analyst'
 *
 */
class Permissions {
    static config: ConfigType
    static tenant: string
    static identity: any
    static hasInitialized: boolean = false

	static initialize({ config, tenant, identity }: InitProps) {
		this.config   = config
        this.tenant   = tenant
        this.identity = identity
        this.hasInitialized = true
	}

    // Ensure that Permissions has been initialized before it is used
    static verifyInitialized() {
        if (!this.hasInitialized) {
            throw new Error("[Permissions] cannot be used before it has been initialized!")
        }
    }

    /**
     * Returns true if the current user has any of the specified
     * roles, otherwise returns false
     *
     * @param   {Role[]}  roles  List of roles to check
     *
     * @return  {boolean} Returns true if current user has any of the specified roles
     */
    static hasAny(roles?: Role[]) {
        this.verifyInitialized()
        return (roles && this.identity.roles?.some((role: Role) => roles.includes(role))) ?? false
    }

    /**
     * Helper method to figure out if a route is authorized or not
     *
     * @param   {boolean}   isPublic  Is it a public route?
     * @param   {string[]}  roles     Roles that this route is authorized for
     *
     * @return  {boolean} Returns true if authorized, otherwise returns false.
     *                    Also returns true if roles is empty.
     */
    static isAuthorized({ isPublic = false, roles = undefined }: { isPublic?: boolean, roles?: Role[] }) {
        return (isPublic || !roles || _.isEmpty(roles) || Permissions.hasAny(roles))
    }
}

export { Permissions }