// import { toast } from 'react-toastify'
import * as alertConstants from './alert.constants'

const createAlertActions = ({ dispatch }) => {
	const success = message => {
		dispatch({ type: alertConstants.SUCCESS, payload: message })
		// toast.success(message)
		console.log("ALERT SUCCESS:", message)
		dispatch(clear())
	}

	const info = message => {
		dispatch({ type: alertConstants.INFO, payload: message })
		// toast.info(message)
		console.log("ALERT INFO:", message)
		dispatch(clear())
	}

	const error = message => {
		dispatch({ type: alertConstants.ERROR, payload: message })
		if (message && Array.isArray(message) && message.length > 0) {
			message.each(error => {
				console.log("ALERT ERROR:", error.message)
				// toast.error(error.message)
			})
		} else {
			// toast.error(message)
			console.log("ALERT ERROR:", message)
		}
		dispatch(clear())
	}

	const clear = () => dispatch({ type: alertConstants.CLEAR })

	return {
		success,
		info,
		error,
		clear,
	}
}

export default createAlertActions