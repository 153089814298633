import _ from 'lodash'
import { useEffect, useRef } from 'react'

/**
 *  This is a function you can use to figure out why a functional React
 *  component is re-rendering.
 *
 *  Usage:
 *      import useTraceUpdate from '../path/to/this/file'
 *
 *      function MyComponent(props) {
 *          useTraceUpdate(props);
 *          return <div>{props.children}</div>;
 *      }
 *
 *  This should now log a map of changed props whenever this component re-renders
 *
 *  Source: https://stackoverflow.com/a/51082563/834459
 */

export default function useTraceUpdate({ ns, props, log }) {
    const prev = useRef(props)
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            // Does the value have the same address in this props compared to last props'
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v]
            }
            return ps
        }, {})
        if (Object.keys(changedProps).length > 0) {
            if (_.isFunction(log)) {
                log("Changed Props:", changedProps)
            } else {
                console.log(`${ns}: Changed props:`, changedProps)
            }
        }
        prev.current = props
    })
}

/**
 *  If you're using a React class, you can use the following hook:
 */

// componentDidUpdate(prevProps, prevState) {
//     Object.entries(this.props).forEach(([key, val]) =>
//         prevProps[key] !== val && console.log(`Prop '${key}' changed`)
//     );
//     if (this.state) {
//         Object.entries(this.state).forEach(([key, val]) =>
//             prevState[key] !== val && console.log(`State '${key}' changed`)
//         );
//     }
// }