import React, { useContext } from 'react'
import {
    Flex,
    Box,
    Text,
    Table,
    Thead,
    Th, Tr,
    Checkbox,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    FaSortAmountDown,
    FaSortAmountDownAlt,
} from 'react-icons/fa'
import commonProps from './propTypes'

import InfiniteLoader from 'react-window-infinite-loader'
import { VariableSizeList } from 'react-window'

import {
    LoadingSpinner,
    ThemeContext,
} from '../../../ui'

const TableView = ({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    headerBgColor,
    CHECKBOX_COL_NAME,
    isAllRowsSelected,
    selectedFlatRows,
    toggleAllRows,
    rows,
    totalCount,
    preGlobalFilteredRows,
    isLoadingMoreData,
    isRefetchingData,
    isItemLoaded,
    loadMoreItems,
    prepareRow,
    tableHeight,
    getRowSize,
}) => {
    const { theme } = useContext(ThemeContext)
    const lightStripe = useColorModeValue("gray.50",  "blackAlpha.400")
    const darkStripe  = useColorModeValue("gray.100", "blackAlpha.300")
    const borderColor = useColorModeValue(theme.muted, "blackAlpha.700")

    const renderRow = React.useCallback(({ index, style }) => {
        const row = rows[index]
        prepareRow(row)

        const backgroundColor = (row.index % 2 === 0) ? lightStripe : darkStripe
        const rowProps = row.getRowProps({ style: { width: '100%' }})

        return (
            <Flex {...rowProps} bg={backgroundColor} display="inline-flex" justifyContent="center" alignItems="center" style={style}>
            {row.cells.map((cell) => {
                const cellProps = cell.getCellProps()
                cellProps.style.flex  = 1
                cellProps.style.display = 'flex'
                cellProps.style.overflowWrap = "anywhere"

                if (cell.column.hasFixedWidth) {
                    delete cellProps.style.flex
                    cellProps.style.width = `${cell.column.width}px`
                }

                const isCheckbox = (cell.column.id === CHECKBOX_COL_NAME)
                const checkboxStyles = {}
                if (isCheckbox) {
                    delete cellProps.style.width
                    checkboxStyles.px = 0
                    checkboxStyles.pl = 4
                    checkboxStyles.pr = 1
                    checkboxStyles.height = 'unset'
                }
                return (
                    <Flex {...cellProps} h="full" px={6} py={2} {...checkboxStyles}>
                        { cell.render("Cell") }
                    </Flex>
                )
            })}
            </Flex>
        )
    }, [prepareRow, rows, CHECKBOX_COL_NAME, lightStripe, darkStripe])

    return (
        <Table width="full" {...getTableProps()}>
            <Thead>
                {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps({ style: { width: '100%' }})}>
                    {headerGroup.headers.map((column) => {
                        const headerProps = column.getHeaderProps(column.getSortByToggleProps())
                        // headerProps.style.width = column.collapse ? '0.0000000001%' : '1%'
                        headerProps.style.flex = 1
                        if (column.hasFixedWidth) {
                            delete headerProps.style.flex
                            headerProps.style.width = `${column.width}px`
                        }
                        const isCheckbox = (column.id === CHECKBOX_COL_NAME)
                        const checkboxStyles = {}
                        if (isCheckbox) {
                            headerProps.style.width = '36px'
                            checkboxStyles.px = 0
                            checkboxStyles.pl = 4
                            checkboxStyles.pr = 1
                        }

                        return (
                        <Th color={theme.fg1} borderColor={borderColor}
                            backgroundColor={headerBgColor} borderStyle="solid"
                            _first={{ borderTopLeftRadius: 'md' }}
                            _last={{ borderTopRightRadius: 'md' }}
                            {...checkboxStyles}
                            {...headerProps}>
                                { !isCheckbox &&
                                <Flex h="full" alignItems="center" gridColumnGap={1}>
                                    <Text>{column.render("Header")}</Text>
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                        <FaSortAmountDown aria-label="sorted descending" />
                                        ) : (
                                        <FaSortAmountDownAlt aria-label="sorted ascending" />
                                        )
                                    ) : null}
                                </Flex>
                                }
                                { isCheckbox &&
                                <Flex h="full" alignItems="center">
                                    <Checkbox bg={theme.bg1} isChecked={isAllRowsSelected}
                                              isIndeterminate={!isAllRowsSelected && selectedFlatRows && (selectedFlatRows.length > 0)}
                                              onChange={toggleAllRows}/>
                                </Flex>
                                }
                        </Th>
                        )
                    })}
                </Tr>
                ))}
            </Thead>

            { isRefetchingData &&
                <LoadingSpinner title={"Re-Fetching Content..."} />
            }

            { !isRefetchingData &&
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={totalCount} loadMoreItems={loadMoreItems}>
                {({ onItemsRendered, ref }) => (
                <VariableSizeList ref={ref} onItemsRendered={onItemsRendered} bg={theme.bg1} {...getTableBodyProps()} height={tableHeight} itemCount={rows.length} itemSize={getRowSize} width="100%">
                    { renderRow }
                </VariableSizeList>
                )}
            </InfiniteLoader>
            }

            <Flex>
                <Box w="full" h="full" color={theme.fg1} borderColor={borderColor}
                        backgroundColor={headerBgColor} borderBottom="none"
                        textTransform="none"
                        _first={{ borderBottomLeftRadius: 'md' }}
                        _last={{ borderBottomRightRadius: 'md' }}>

                    { !isRefetchingData &&
                        <Flex gridColumnGap={2} px={6} py={3} fontWeight="bold" fontSize="sm">
                        <Text>Total: {totalCount} records</Text>

                        { (rows.length !== preGlobalFilteredRows.length) &&
                            <Text>Filtered {rows.length} of {preGlobalFilteredRows.length} records</Text>
                        }

                        { isLoadingMoreData &&
                        <Flex gridColumnGap={2}>
                            <Text fontWeight="bold">·</Text>
                            <Text>Loading more records...</Text>
                        </Flex>
                        }
                        </Flex>
                    }
                </Box>
            </Flex>
        </Table>
    )
}

TableView.propTypes = {
    ...commonProps,
}

export default TableView