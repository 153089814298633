import PropTypes from 'prop-types'

const header = PropTypes.shape({
    key:         PropTypes.string.isRequired,
    label:       PropTypes.string,
    type:        PropTypes.string,
    renderFn:    PropTypes.func,
    canSearch:   PropTypes.bool,
    canToggle:   PropTypes.bool,
    canFilter:   PropTypes.bool,
    canSort:     PropTypes.bool,
    transformFn: PropTypes.func,
})

const propTypes = {
    /**
     * A list of the records to be rendered in the table.
     * If this DataTable is in infiniteScroll mode, the table
     * expects that when it calls 'onFetchData', additional
     * rows will be appended to records
    */
    records: PropTypes.array.isRequired,

    /**
     * The total number of records that this table will render at most.
     * If this DataTable is in infiniteScroll mode, this is NOT the
     * small amount fetched initially, but the total number that
     * could ever be fetched.
    */
    totalCount: PropTypes.number.isRequired,

    /**
     * The headers dictates what columns are rendered. The `key` attribute is
     * the only one required - all others can be inferred or computed from that value.
     * Look at the `header` propType for more info.
    */
    headers: PropTypes.arrayOf(header).isRequired,

    /**
     * Optional function - if passed to the component, the DataTable will function in
     * infiniteScroll mode. Will be invoked any time addtional data needs to be fetched
     * OR the sorters/filters change - so that the API can be queried with those parameters.
     *
     * Arguments should be run through ServiceHelper.prepareOdataQueryStr_ to create
     * appropriate query string for the API to consume.
    */
    onFetchData: PropTypes.func,

    /**
     * Optional function - if passed, creates a column at the end of the table and renders
     * a button group according to the values returned by this function.
     */
    getRowActions: PropTypes.func,

    /**
     * Optional function - if passed, creates a row above the table with bulk actions, rendered
     * according to the values returned by this function.
     */
    getGroupActions: PropTypes.func,

    /**
     * Table Height as pixel number (required for virtualization)
     */
    tableHeight: PropTypes.number.isRequired,

    /**
     * Row Height as pixel number (required for virtualization)
     */
    rowHeight: PropTypes.number.isRequired,

    /**
     * Optional - for when search bar is displayed
     */
    searchPlaceholder: PropTypes.string,

    /**
     * If using custom header types, you can specify a custom formatter as well.
     * For simple uses, provide a renderFn, for complex/repeatable uses, provide
     * a customFormatter.
     */
    customFormatters: PropTypes.object,

    /**
     * Additional options that may be required by sub-components
     */
    options: PropTypes.object,
}

export default propTypes