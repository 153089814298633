import React, { useCallback, useContext, useEffect } from 'react'
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'

import { ENTER, SPACE_BAR } from '../util/KeyCode'
import ThemeContext from '../theme/ThemeContext'

const ConfirmModal = ({ isOpen, onCancel, onConfirm, title, confirmText = "Confirm", children, colorScheme = "red", maxWidth, disableKeyConfirm = false, trapFocus, size = "md",t}) => {
    const theme = useContext(ThemeContext)
    const cancelRef = React.useRef()

    useEffect(() => {
        if (isOpen && cancelRef.current) {
            cancelRef.current.focus()
        }
    }, [isOpen])

    const handleKeyDown = useCallback(event => {
        if (!disableKeyConfirm) {
            if (event.key === ENTER) {
                onConfirm()
            } else if (event.key === SPACE_BAR) {
                onCancel()
            }
        }
    }, [onCancel, onConfirm, disableKeyConfirm])

    return (
        <AlertDialog
            isOpen={isOpen}
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            isCentered
            trapFocus={trapFocus}
            size={size}>
            <AlertDialogOverlay>
            <AlertDialogContent maxWidth={maxWidth}>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">{title}</AlertDialogHeader>

                <AlertDialogBody>{children}</AlertDialogBody>

                <AlertDialogFooter bg={theme.bg2} borderBottomRadius="md" onKeyDown={handleKeyDown}>
                    { onCancel &&
                    <Button ref={cancelRef} onClick={onCancel} color="#333">
                        {t?t("Cancel"):'Cancel'}
                    </Button>
                    }
                    <Button colorScheme={colorScheme} onClick={onConfirm} ml={3}>
                        {confirmText}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ConfirmModal