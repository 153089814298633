import React, { useContext, useMemo } from 'react'
import _ from 'lodash'
import {
    Text,
} from '@chakra-ui/react'
import Highlighter from 'react-highlight-words'

import convertToString from '../helpers/convertToString'
import ThemeContext from '../../theme/ThemeContext'

// TODO: Schema: { options, value, column, state }
const SimpleText = ({ options = {}, value = "", column: { disableGlobalFilter }, state: { globalFilter } }) => {
    const { theme } = useContext(ThemeContext)
    const {
        fontSize     = 'sm',
        overflowWrap = 'anywhere',
        align        = 'center',
        shouldTruncate = false,
        truncateLen    = 35,
    } = options

    let text = value
    if (!_.isString(text)) {
        text = convertToString(text)
    }

    if (shouldTruncate) {
        text = _.truncate(text.toString(), { length: truncateLen })
    }

    const searchWords = useMemo(() => {
        return globalFilter ? globalFilter.split(" ") : []
    }, [globalFilter])

    return (
    <Text fontSize={fontSize} alignSelf={align} color={theme.fg1} overflowWrap={overflowWrap}>
        { disableGlobalFilter && value }
        { !disableGlobalFilter &&
            <Highlighter autoEscape searchWords={searchWords} textToHighlight={text}
                         highlightStyle={{ overflowWrap }}
                         unhighlightStyle={{ overflowWrap }}/>
        }
    </Text>
    )
}
SimpleText.type = 'text'

export default SimpleText
