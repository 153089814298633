import React, { useRef, useCallback, useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    SearchIcon,
    CloseIcon,
} from '@chakra-ui/icons'
import ThemeContext from '../theme/ThemeContext'

/**
 * A simple search input field. Expands to fill the space given.
 * State is expected to be tracked by the parent component.
 *
 * When the clear field 'X' is clicked - the onSearchChange callback
 * is invoked with an empty string
 *
 */
const SearchField = ({ size = "md", shouldDebounce, timeout = 30, onSearchChange, searchText, placeholder }) => {
    const { theme } = useContext(ThemeContext)
    const borderColor = useColorModeValue(theme.muted, "whiteAlpha.200")

    const searchBarRef = useRef()
    const clearFilter = useCallback(() => {
        setLocalText("")
        onSearchChange("")
        searchBarRef.current.focus()
    }, [onSearchChange])

    const [localText, setLocalText] = useState(searchText)
    useEffect(() => setLocalText(searchText), [searchText])

    const [timeoutId, setTimeoutId] = useState(null)
    const handleChange = useCallback(event => {
        const value = event.target.value
        setLocalText(value)

        if (shouldDebounce) {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }

            setTimeoutId(setTimeout(() => {
                onSearchChange(value)
            }, timeout))
        } else {
            onSearchChange(value)
        }
    }, [onSearchChange, timeout, timeoutId, shouldDebounce])

    const closeBtnSize = useMemo(() => {
        const sizeMap = {
            sm:  "xs",
            md:  "sm",
            lg:  "md",
            xl:  "lg",
            xxl: "xl",
        }

        return sizeMap[size] || "sm"
    }, [size])

    const borderRadiusSize = useMemo(() => {
        const sizeMap = {
            sm:  "md",
            md:  "lg",
            lg:  "xl",
            xl:  "xxl",
        }

        return sizeMap[size] || "sm"
    }, [size])

    return (
        <FormControl w="full">
            <InputGroup size={size} w="full" borderColor={theme.muted} borderRadius={borderRadiusSize}>
                <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.400" />}/>
                <Input ref={searchBarRef} placeholder={placeholder} color={theme.fg1} bg={theme.bg1} value={localText}
                       onChange={handleChange} borderRadius={borderRadiusSize} borderColor={borderColor}/>
                { searchText && (searchText !== "") &&
                <InputRightElement children={<IconButton onClick={clearFilter} size={closeBtnSize} color={theme.fg1}
                                   bgColor={theme.bg1} _hover={{ bg: theme.bg2 }} _active={{ bg: theme.bg2 }}
                                   icon={<CloseIcon/>} />} />
                }
            </InputGroup>
        </FormControl>
    )
}

SearchField.propTypes = {
    /**
     * ASC UI Theme object
     */
    theme: PropTypes.object,

    /**
     * An amount of time in milliseconds to wait before firing
     */
    timeout: PropTypes.number,

    /**
     * Callback that is invoked with the new searchText
     * onSearchChange - a way to batch multiple keystrokes into
     * fewer callbacks for better performance. Higher numbers will
     * feel more 'laggy'
     */
    onSearchChange: PropTypes.func,

    /**
     * Search string to be displayed in the input field
     */
    searchText: PropTypes.string,

    /**
     * Input placeholder text, e.g. "Search for ..."
     */
    placeholder: PropTypes.string,
}

SearchField.defaultProps = {
    timeout: 30,
}

export default SearchField