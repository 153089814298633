import React from 'react'
import { Flex, Box, Text, Button } from '@chakra-ui/react'
import * as AdaptiveCards from 'adaptivecards'
import parse from 'html-react-parser'
import marked from 'marked'
import moment from 'moment'
import { FaStar } from 'react-icons/fa6'
import './explainable.css'

const adaptiveCard = new AdaptiveCards.AdaptiveCard()

const ChatDetails = ({chatData}) => {
  const cdnUri = 'https://primarycdn.blob.core.windows.net'

   /** ----------------------- Renderer to be used by marked for converting markdown to html------------------- */
  const render_content = () => {
    let render = new marked.Renderer()
    render.list = function (body, ordered, start) {
      let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
      if (ordered) {
        return `<ol>${newtext}</ol>`
      } else {
        return `<ul>${newtext}</ul>`
      }
    }

    render.blockquote = function (quote) {
      let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
      return `<blockquote>${newtext}</blockquote>`
    }
    render.code = function (string) {
      return string
    }
    render.image = function (href, title, text, alt, height, width) {
      const newImage = `<img src="${href}" alt="Image" title="Image">`
      return newImage
    }
    return render
  }

  function AdaptiveHtmlBot(data) {
        for (let i = 0; i < data.card.body.length; i++) {
      if (data.card.body[i] && data.card.body[i].value)
        data.card.body[i].value = ""
    }
    // data.card.version = "1.0"
    adaptiveCard.parse(data.card)
    const renderedCard = adaptiveCard.render()
    return <Box>{parse(renderedCard.outerHTML)}</Box>
  }

  const Chatuserratings = (data) => {
     const rating = Math.min(parseInt(data.ratings ? data.ratings.trim() : '0'),5)
    return rating > 0 ? (
      <Flex display="inline-flex" gap="2px">
        {Array.from(Array(rating).keys()).map((num) => (
          <FaStar key={num} size="24px" color="#ff931e" />
        ))}
      </Flex>
    ) : (
      <Text>N/A</Text>
    )
  }

  const FeedBackCard = ({ cardData}) => {
    return (
      <Box fontSize='14px' className="rating">
        <Flex alignItems='center' gap='5px'>Rating: <Chatuserratings ratings={cardData.feedback} /></Flex>
        <Box>Comments: {cardData.comments || cardData.comments != "" ? cardData.comments : "N/A"}</Box>
      </Box>
    )
  }

  const AnswerHtml = (data) => {
    let markdown = ''
    if (data.ans) {
      const splitAns = data.ans.split('~')
      markdown = splitAns[0].replace(/\\n/g, ' \n')

      markdown = markdown.replace(/(\[button\])/g, '<button>').replace(/(\[\/button\])/g, '</button>').replace(/(\[pagebreak\/\])/g, '<p>---------------</p>').replace(/(\[linebreak\/\])/g, '<br>').replace(/\{.*?\}/g, '').replace(/\[video\](.*?)\[\/video\]/g, `<video poster="${cdnUri}/cdn/thumbnail.png" preload="none" src="$1"></video>`)
    }
    const html = marked(markdown, { renderer: render_content() })
    return <Box>{parse(html)}</Box>
  }

  return (
      <Flex direction='column' borderRadius='8px' border='1px solid #D1D5DB' overflow='auto' gap='10px' p='14px 12px'>    
        <Box id="adcard" style={{ display: 'none' }}></Box>
        {chatData && chatData.length > 0 ?
          chatData.map(function (item, idx) {
            const sentBy = item.sent_by && item.bot && (item.sent_by.toLowerCase() === item.bot.toLowerCase())
            return (
              <Flex key={idx} direction='column' alignSelf={sentBy ?'flex-start':'flex-end'}>

                {/* showing chat list */}
                {item.text && item.text !== "" &&
                  <Flex bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} fontSize='14px' w='388px' p='6px 12px' borderRadius='18px'>
                    <AnswerHtml ans={item.text} />
                    {item.text == 'Did you mean:' &&
                      <Box>
                        {item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && item.attachments[0].content.buttons.map((chat, index) =>
                              <Text key={index}>{chat.title}</Text>
                            )}
                        {item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && item.attachments[0].content.buttons.map((chat, index) =>
                              <Text key={index}>{chat.title}</Text>
                                                      )}
                      </Box>
                    }
                  </Flex>
                }

                {item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && item.attachments[0].contentType == 'application/vnd.microsoft.keyboard' &&
                    <Box className='convo-wrapper clearfix' bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} w='388px' p='6px 12px' borderRadius='18px'>
                      <Flex direction='column' gap='13px'>
                        {item.attachments[0].content.buttons.map((chat, index) =>
                            <Button key={index} w='100%' p='5px 6px' fontSize='13px' bg='#fff' color='#c4c4c4' borderRadius='50px' _hover={{ bg: '#e85a5b',color:'#fff',border:'1px solid, #e85a5b',transition:'all .3s' }}className="ac-pushButton">{chat.title}</Button>
                                                  )}
                      </Flex>
                    </Box>
                  }

                {item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && (item.attachments[0].contentType == 'application/vnd.microsoft.card.hero') &&
                    <Box className='convo-wrapper clearfix ' fontSize='14px' bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} w='388px' p='11px 10px' borderRadius='18px'>
                      <Text><strong>{item.attachments[0].content.title}</strong></Text>
                      {item.attachments[0].content.text &&
                        <AnswerHtml ans={item.attachments[0].content.text} />
                      }
                      <Flex direction='column' gap='13px'>
                        {item.attachments[0].content.buttons.map((chat, index) =>
                            <Button key={index} w='100%' p='5px 6px' fontSize='13px' bg='#fff' color='#c4c4c4' borderRadius='50px' _hover={{ bg: '#e85a5b',color:'#fff',border:'1px solid, #e85a5b',transition:'all .3s' }}className="ac-pushButton">{chat.title}</Button>
                                                  )}
                      </Flex>
                    </Box>
                  }

                {item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && item.attachments[0].contentType === 'application/vnd.microsoft.card.adaptive' &&
                    <Box bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} w='388px' p='6px 12px' borderRadius='18px'>
                      {<AdaptiveHtmlBot cardid={item.id} card={item.attachments[0].content} />}
                    </Box>
                  }

                  {
                    item.card_input && Object.keys(item.card_input).length > 0 &&
                    Object.keys(item.card_input).filter(key => key.indexOf('feedback') != -1).length != 0 &&
                    <Box className='convo-wrapper' bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} w='388px' p='6px 12px' borderRadius='18px'>
                      <FeedBackCard cardId={item.id} cardData={item.card_input} id={idx} pos="2" />
                    </Box>
                  }
                  {
                    item.card_input && Object.keys(item.card_input).length > 0 &&
                    Object.keys(item.card_input).filter(key => key.indexOf('feedback') != -1).length == 0 &&
                    <Box bg={sentBy ?'#D1D5DB':'#6467F2'} color={sentBy ?'#000':'#fff'} w='388px' p='6px 12px' borderRadius='18px'>
                      {
                        Object.values(item.card_input).map((cardText,index) => {
                          return (<Box key={index} className='convo-wrapper' fontSize='14px'>{cardText}</Box>)
                        })
                      }
                    </Box>
                  
                  }

                  {(item.text || item.card_input || (item.attachments && item.attachments.length > 0)) &&
                  <Flex direction='column' color='#999' alignSelf={sentBy ?'flex-start':'flex-end'} fontSize='12px'>
                    <Text className={sentBy ? "user-name" : "sender-name"} alignSelf={sentBy ?'flex-start':'flex-end'}>
                      {item.sent_by && item.sent_by === "You"
                        ? 'User' : item.user_info && item.user_info.name && item.user_info.agentId ? item.user_info.name : item.sent_by
                            || item.bot || (item.user_info && item.user_info.name)}
                    </Text>
                    <Text alignSelf={sentBy ?'flex-start':'flex-end'}>{item.start_timestamp && moment(item.start_timestamp).tz(moment.tz.guess()).format('MMM-DD-YYYY hh:mm:ss A z')}</Text>
                  </Flex>
                }
    
              </Flex>
            )
          })
        :
          <Box className="no-content">
            <Text>There is no chat data!</Text>
          </Box>
        }
      </Flex>
  )
}
export default ChatDetails
