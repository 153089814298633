import React from "react"
import { CrawlContextProvider } from "app/components/CrawlUrl/context/CrawlContext"
import CrawlContainer from "app/components/CrawlUrl/CrawlContainer"

const Urls = () => {
    return (
      <React.Fragment>
            <CrawlContextProvider  >
                <CrawlContainer/>
            </CrawlContextProvider>
      </React.Fragment>
    )
}

export default Urls