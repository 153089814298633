import {
    Flex,Text,Button, Modal, ModalOverlay, ModalContent, ModalBody,} from  "@chakra-ui/react"
import {HiOutlineExclamation} from 'react-icons/hi'
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'

const KnowledgeArticlesDeleteModal = () => {
    const {closeDeleteModal,globalState,deleteKnowledgeArticles} = useKnowledgeArticlesContext()
    return (
        <Modal isOpen={globalState.deleteModalState.isOpen} onClose={closeDeleteModal} w='512px' isCentered data-testid='ka_delete_modal_container'>
            <ModalOverlay />
            <ModalContent padding='24px' borderRadius='8px' boxShadow='0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)'>
                <ModalBody display='flex' gap='16px' alignItems='flex-start' padding='0px' data-testid='ka_delete_modal_body'>
                    <Flex w='40px' h='40px' padding='8px' justify='center' alignItems='center' bg='#FEE2E2' borderRadius='24px'>
                        <HiOutlineExclamation size='24px' color='#DC2626' data-testid='ka_delete_modal_icon'/>
                    </Flex>
                    <Flex direction='column' alignItems='flex-start' gap='16px'>
                        <Flex direction='column' gap='8px' alignItems='flex-start' alignSelf='stretch'>
                            <Text as='h4' fontSize='18px' fontWeight={500} color='#111827' data-testid='delete_modal_title'>Delete Article</Text>
                            <Text color='#6B7280' fontSize='14px' data-testid='ka_delete_modal_text'>Are you sure want to delete this article? This action cannot be undone.</Text>
                        </Flex>
                        <Flex justifyContent='flex-end' gap='12px' alignItems='center' alignSelf='stretch' >
                            <Button onClick={closeDeleteModal} data-testid='ka_delete_modal_cancel' fontSize='14px' bg="#fff" borderRadius='6px' border='1px solid #D1D5DB' color="#374151" padding='9px 17px' box-shadow='0px 1px 2px 0px rgba(0, 0, 0, 0.05)'>Cancel</Button>
                            <Button onClick={deleteKnowledgeArticles} data-testid='ka_delete_modal_submit' fontSize='14px' bg="#DC2626" borderRadius='6px' border='1px solid #D1D5DB' color="#fff" padding='9px 17px' box-shadow='0px 1px 2px 0px rgba(0, 0, 0, 0.05)' >Delete</Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default KnowledgeArticlesDeleteModal