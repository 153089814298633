import React, { useCallback, useState, useMemo, useContext } from 'react'
import _ from 'lodash'
import {
    Flex,
    Badge,
    FormControl,
    FormLabel,
    Button,
    useDisclosure,
    ButtonGroup,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    BiSortDown,
} from 'react-icons/bi'
import {
    FaSortAmountDownAlt,
    FaSortAmountDown,
} from 'react-icons/fa'

import SearchField   from '../SearchField'
import PopoverButton from './PopoverButton'
import ThemeContext from '../../theme/ThemeContext'

const SortButton = ({ columns }) => {
    const { theme } = useContext(ThemeContext)
    const { isOpen, onToggle, onClose } = useDisclosure()

    const [searchText, setSearchText] = useState("")
    const handleTextChange = useCallback(text => {
        setSearchText(text)
    }, [])

    const sortableColumns = useMemo(() => _.filter(columns, col => col.canSort), [columns])
    const visibleColumns  = _.filter(sortableColumns, column => {
        if (_.isString(column.Header)) {
            return _.lowerCase(column.Header).match(_.lowerCase(searchText))
        }
    })

    const activeBtnColor = useColorModeValue('blackAlpha', 'blue')

    let numSorted = 0
    const controls = _.map(visibleColumns, column => {
        const { id: name, Header: label, isSorted, isSortedDesc, clearSortBy, toggleSortBy } = column
        const currentState = isSorted ? (isSortedDesc ? 'desc' : 'asc') : 'none'
        numSorted += (currentState !== 'none') ? 1 : 0

        // toggleSortBy: Function(descending, multi) => void
        const sortAsc    = () => toggleSortBy(false, true)
        const sortDesc   = () => toggleSortBy(true, true)

        const buttonsData = [
            { label: "None", state: 'none', icon: null, onClick: clearSortBy },
            { label: "ASC",  state: 'asc',  icon: <FaSortAmountDownAlt/>, onClick: sortAsc  },
            { label: "DESC", state: 'desc', icon: <FaSortAmountDown/>,    onClick: sortDesc },
        ]
        const buttons = _.map(buttonsData, ({ label, state, icon, onClick }) => (
            <Button key={`sort.${name}.${state}`} onClick={onClick} leftIcon={icon}
                    colorScheme={(currentState === state) ? activeBtnColor : null}>
                {label}
            </Button>
        ))

        return (
            <FormControl key={`sort.${name}`} display="flex" alignItems="center" gridColumnGap={2} justifyContent="space-between">
                <FormLabel htmlFor={`sort-${name}`} mb={1}>{label}</FormLabel>
                <ButtonGroup size="sm" isAttached>
                    { buttons }
                </ButtonGroup>
            </FormControl>
        )
    })

    const label = (
        <>
            Sort
            { (numSorted > 0) &&
                <Badge bg={theme.primary} color={theme.bg2} borderRadius="md" ml={2} px={2} py={1}>{numSorted}</Badge>
            }
        </>
    )

    return (
        <PopoverButton isOpen={isOpen} toggleIsOpen={onToggle} closeOnBlur={true} width="md"
                       onClose={onClose} icon={<BiSortDown/>} label={label}>
            <Flex direction="column" gridRowGap={4}>
                <SearchField focusCondition={isOpen} onSearchChange={handleTextChange} searchText={searchText} placeholder="Find a field..."/>
                <Flex direction="column" gridRowGap={3}>
                    { controls }
                </Flex>
            </Flex>
        </PopoverButton>
    )
}

export default SortButton