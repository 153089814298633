import React, { useContext } from 'react'
import {
    Flex,
    Heading,
    StyleProps,
} from '@chakra-ui/react'
import { ThemeContext } from '../ui'
import filterChakraProps from './helpers/filterChakraProps'

interface PanelProps extends StyleProps {
    /**
     * Optional title of the Panel
     */
    title?: string,

    /**
     * Contents of the Panel
     */
    children: React.ReactNode,
}

const Panel: React.FC<PanelProps> = (props: PanelProps) => {
    const { title, children } = props
    const chakraProps = filterChakraProps(props)

    const { theme } = useContext(ThemeContext)
    return (
        <Flex bg={theme.bg2} direction="column" w="100%" gridRowGap={2} shadow="base" px={4} pt={4} pb={8} borderRadius="md" {...chakraProps}>
            { title &&
            <Heading size="md">{title}</Heading>
            }
            { children }
        </Flex>
    )
}

export default Panel
