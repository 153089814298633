import React, { useCallback } from 'react'
import {
	Flex,
	Image,
	Button,
	Heading,
} from '@chakra-ui/react'

const GenericErrorPage = ({ cdnUri, message, redirectTo = '/', redirectText = 'Back to Homepage' }) => {

	// TODO: history.push is not working as expected...tabling issue for now
	// const navToRoot = useCallback(() => history.push(""), [history])
	const navToRoot = useCallback(() => {
		window.location = redirectTo
	}, [redirectTo])

	return (
		<Flex h="100%" w="100%" px={8} py={4} gridColumnGap={8} alignItems="center" justifyContent="center">
			<Image src={`${cdnUri}/cdn/desk.jpg`} alt="not-found" w="60%"></Image>
			<Flex direction="column" justifyContent="center" alignItems="center" gridRowGap={8}>
				<Heading size="lg" textAlign="center">{message}</Heading>
				<Button onClick={navToRoot} colorScheme="blue">{redirectText}</Button>
			</Flex>
		</Flex>
	)
}

export default GenericErrorPage