import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

const IdentityApi = axios.create({
    timeout: 90000,
    responseType: 'json',
    headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false })),
})

export default IdentityApi