import { Flex, Stack } from '@chakra-ui/react'
import _ from 'lodash'
import React, { Suspense, useEffect, useState } from 'react'
import Action from './Action'
import Field from './Field'
import WorkflowButton from './WorkFlowButton'

const SectionDetail = props => {
    const [metaData, setMetaData] = useState(props.meta)
    const [entityValuesState, setEntityValues] = useState(props.entityValues)
    const [idKey, setIdKey] = useState(props.meta && props.meta.idKey)
    const [bKeys, setBKeys] = useState([])
    const [itemId, setItemId] = useState(props.params && props.params.itemId)
    const [propsValues, setPropsValues] = useState(props.propsValues)

    useEffect(() => {
        if (props) {
            if (props.propsValues) {
                setPropsValues(props.propsValues)
            } else {
                setPropsValues(null)
            }
            setMetaData(props.meta)
            setEntityValues(props.entityValues)
            setIdKey(props.meta.idKey)
            setItemId(props.params && props.params.itemId)
        }
    }, [props])

    const renderItem = item => {
        if (!item) return
        const { fields, actions, sections, tabs, workflowAction, gridMeta } = metaData
        let dependsOn
        let itemDef, columns, refData, newProps
        switch (item.type) {
            case 'section':
                itemDef = sections[item.name]
                if (itemDef && !itemDef.hide)
                    return (
                        <Stack key={`${item.name}_1`}>
                            <SectionDetail key={item.name} entityValues={entityValuesState} {...props} name={item.name} />
                        </Stack>
                    )
                break
            case 'field':
                itemDef = fields[item.name]
                let entityValues = entityValuesState
                console.log(entityValues, 'entityStates')
                if (itemDef) {
                    if (itemDef.dependsOn && itemDef.dependsOn.pop && entityValues) {
                        dependsOn = {}
                        itemDef.dependsOn.forEach(f => {
                            dependsOn[f] = entityValues[f]
                        })
                    }
                    if (entityValues && itemDef.name && itemDef.name.includes('_')) {
                        let splitName = itemDef.name.replace(/_/g, '.')
                        entityValues[itemDef.name] = _.get(entityValues, splitName)
                    }
                    if (entityValues && !entityValues[itemDef.name]) {
                        entityValues[itemDef.name] = itemDef.default ? itemDef.default : null
                    }
                    return (
                        <Stack key={item.name} mt={5}>
                            <Field
                                key={item.name}
                                name={item.name}
                                dependsOn={dependsOn}
                                fieldMeta={itemDef}
                                values={(entityValues && entityValues[itemDef.name]) || ''}
                                formValues={entityValuesState}
                                {...props}
                            />
                        </Stack>
                    )
                }
                break
            case 'action':
                itemDef = actions[item.name]
                if (itemDef)
                    return (
                        <Flex key={item.name}>
                            <Action actionMeta={itemDef} entityValues={entityValuesState} {...props} />
                        </Flex>
                    )
                break
            case 'workflowAction':
                itemDef = workflowAction[item.name]
                let possibleStates = []
                if (entityValuesState && entityValuesState[itemDef.ref]) {
                    possibleStates = entityValuesState[itemDef.ref]
                }
                if (itemDef)
                    return (
                        <Flex key={item.name}>
                            <WorkflowButton workflowActionMeta={itemDef} possibleStates={possibleStates} {...props} />
                        </Flex>
                    )
                break
            // case 'tabGroup':
            //     itemDef = tabs[item.name]
            //     if (itemDef)
            //         return (
            //             <React.Fragment key={item.name}>
            //                 <TabDetail key={item.name} {...this.props} tabGroupItem={itemDef} sections={sections} entityValues={entityValues} handleClickArrow={this.handleClickArrow.bind(this)} switchTabIcon={this.state.switchTabIcon} handleCustomAction={this.props.handleCustomAction} />
            //             </React.Fragment>
            //         )
            //     break
            // case 'action':
            //     itemDef = actions[item.name]
            //     if (itemDef)
            //         return (
            //             <div key={item.name}><Action key={item.name} actionMeta={itemDef}{...this.props} /></div>
            //         )
            //     break;
            // case 'workflowAction':
            //     itemDef = workflowActions[item.name]
            //     if (itemDef){
            //         let possibleStates = [];
            //         if(this.props.entity && this.props.entity.entityValues){
            //             possibleStates = this.props.entity.entityValues[itemDef.ref]
            //         }
            //     return (
            //         <div key={item.name}>
            //         <WorkflowButton workflowActionMeta={itemDef}{...this.props} possibleStates = {possibleStates}/>
            //         </div>
            //     )
            //     }
            //     break;
            // case 'cardList':
            //     itemDef = gridMeta[item.name];
            //     columns = itemDef && itemDef.items && itemDef.items.map(c => { c.shown = true; return c })
            //     newProps = {...this.props};
            //     if (itemDef.dataSource.ref) {
            //         let refs = itemDef.dataSource.ref.split('.')
            //         refData = refs.reduce((p, c) => {
            //         return p && p[c]
            //         }, entityValues)
            //     }
            //     else
            //         refData = entityValues
            //     if (itemDef && columns)
            //     return (
            //             <GridLayout
            //             key={item.name}
            //             meta={this.props.meta}
            //             list={refData}
            //             columns={columns}
            //             fields={fields}
            //             entity={this.props.entity}
            //             itemDef={itemDef}
            //             itemClick={this.props.itemClick}
            //             currentItem={item}
            //             entityValues={this.state.entityValues}
            //             {...newProps}
            //             />
            //     )
            //         break;
            // case 'grid':
            //     itemDef = gridMeta[item.name];
            //     columns = itemDef && itemDef.items
            //     if (itemDef.dataSource.ref) {
            //         let refs = itemDef.dataSource.ref.split('.')
            //         refData = refs.reduce((p, c) => {
            //         return p && p[c]
            //         }, entityValues)
            //     }
            //     else
            //         refData = entityValues
            //     newProps = {...this.props};
            //     if(newProps.columns)
            //     delete newProps['columns']
            //     if(newProps.itemDef)
            //     delete newProps['itemDef']
            //     if(newProps.list)
            //     delete newProps['list']
            //     if (itemDef && columns)
            //         return (
            //         // <div key={item.name} className="col-sm-12">
            //             <GridLayout
            //             key={item.name}
            //             lookUpMapping={this.state.lookUpMapping}
            //             meta={this.props.meta}
            //             list={refData}
            //             columns={columns}
            //             fields={fields}
            //             totalCount={this.props.totalCount}
            //             importData={this.props.importData}
            //             handleListAction={this.props.handleListAction}
            //             entity={this.props.entity}
            //             itemDef={itemDef}
            //             itemClick={this.props.itemClick}
            //             currentItem={item}
            //             gridParentData={this.state.gridParentData}
            //             entityValues={this.state.entityValues}
            //             {...newProps}
            //             />
            //         // </div>
            //         )
            //     break;
            // case 'player':
            //     if(this.state.propsValues && (this.state.propsValues.videoUrl || this.state.propsValues.refUrl)){
            //         return(
            //             <VideoPlayer
            //             key={item.name}
            //             {...this.state.propsValues}
            //             />
            //         )
            //     } else {
            //         return <span key={item.name}> </span>
            //     }
            //     // break;
            // case 'chat':
            //     if (window.EmbeddableWidget && window.EmbeddableWidget.el)
            //       window.EmbeddableWidget.unmount();
            //     if(this.state.propsValues && this.state.propsValues.flowName){
            //         return(
            //             <ChatWidget
            //             key={item.name}
            //             params={item.params}
            //             classNames={item.classNames}
            //             {...this.state.propsValues}
            //             {...this.props}
            //             />
            //         )
            //     } else {
            //         return <span key={item.name}> </span>
            //     }
            // case 'treeView':

            //     return  (<Treeview
            //         key={item.name}
            //         {...this.props}
            //         />)

            //     // break;
            //     case 'contentView':
            //     return  (<ContentView
            //         key={item.name}
            //         {...this.props}
            //         />)

            // break;
            default:
        }
    }

    const { entityValues, meta, name, linkedLists } = props
    const { sections } = meta
    const itemsByCols = {}
    const secDef = sections[name]
    secDef &&
        secDef.cols &&
        secDef.items &&
        secDef.items.forEach(i => {
            if (i.col) {
                itemsByCols[i.col] = itemsByCols[i.col] || []
                itemsByCols[i.col].push(i)
            } else {
                itemsByCols.unassigned = itemsByCols.unassigned || []
                itemsByCols.unassigned.push(i)
            }
        })
    itemsByCols.unassigned = itemsByCols.unassigned || (secDef && !secDef.cols && secDef.items)

    console.log(secDef)

    if (secDef && secDef.cols && itemsByCols.unassigned) {
        console.error(`Some elements are not assigned to columns ${itemsByCols.unassigned}`)
        return <div></div>
    } else
        return (
            <Suspense fallback={<h2> Loading...</h2>}>
                {secDef && secDef.cols && (
                    <>
                        <Flex direction="row" w="full">
                            {secDef.cols.map((c, i, arr) => {
                                return (
                                    <Flex direction="column" w="full" flex={c} key={i + 1}>
                                        {itemsByCols[i + 1] &&
                                            itemsByCols[i + 1].map(item => {
                                                return renderItem(item)
                                            })}
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </>
                )}
                {itemsByCols &&
                    itemsByCols.unassigned &&
                    itemsByCols.unassigned.map(item => {
                        return renderItem(item)
                    })}
                {/* {
                    // component 
                   Component && <Component {...this.props}{...compProps} propsMap={secDef.otherProps} />
                } */}
            </Suspense>
        )
}

export default SectionDetail
