import React, {useRef,useState,useContext,useEffect} from "react"
import { Box,Button,Flex,Text,Image,HStack,Stack, Spinner, Tooltip, UnorderedList, ListItem, Select,  Modal, Heading, Divider,Progress,
  ModalOverlay, ModalContent,ModalHeader, ModalFooter,ModalBody, ModalCloseButton, useDisclosure, Input } from "@chakra-ui/react"
import { ArrowBackIcon } from '@chakra-ui/icons'
import {  toast } from "react-toastify"
import upload from "../../../assets/upload.png"
import { AiOutlinePlusCircle, AiOutlineFile, AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { TiWarningOutline } from "react-icons/ti"
import {BsFiletypeXlsx, BsFiletypeCsv , BsFilePdf,BsFiletypeTxt,BsFiletypeDoc, BsFiletypeXls } from "react-icons/bs"
import { PiMicrosoftWordLogoLight } from "react-icons/pi"
import {HiOutlineTrash} from 'react-icons/hi2'
import {GrClose} from 'react-icons/gr'
import AppContext from 'AppContext'
import {WorkspaceContext} from '../../commonComponents/Layout/Layout'
import { useServicesContext } from "services/apiServices"
import computer from "../../../assets/computer.png"
import back from "../../../assets/back.png"
import { useQueryClient,useQuery } from "react-query"
import { useDocumentContext } from "./context/DocumentContext"
import { useKnowledgeGapsContext } from "../KnowledgeGaps/context/KnowledgeGapsContext"

const MyDevicePicker = ({handleClose,categories,simpleTags,dimensions,commonAttributes,totalFiles,onUploadSuccess,onUploadFailure,parent}) => {
    const [files, setFiles] = useState([])
    const [fileSizeError,setFileSizeError]=useState("")
    const [directory,setDirectory]=useState(false)
    const [disableButton,setDisableButton]=useState(false)
    const [selectedL1, setSelectedL1] = useState('')
    const [selectedL2, setSelectedL2] = useState('')
    const [selectedL3, setSelectedL3] = useState('')
    const [selectedL4, setSelectedL4] = useState('')
    const [selectedL5, setSelectedL5] = useState('')
    const [selectedL6, setSelectedL6] = useState('')
    const [simpleMetaTags, setSimpleMetaTags] = useState('')
    const [simpleMetaTagsValue, setSimpleMetaTagsValue] = useState('')
    const [simpleMetadata, setSimpleMetadata] = useState([])
    const [simpleMetadataApi,setSimpleMetadataApi]=useState([])
    const [selectedId, setSelectedId] = useState('')
    const [userAttributeData,setUserAttributeData]=useState([])
    const [userAttributekey,setUserAttributeKey]=useState('')
    const [userAttributeValue,setUserAttributeValue]=useState('')
    // const [requests,setRequests]=useState([])
    const currentReference = useRef(null)
    const [stopRequest,setStopRequest]=useState(false)
    const [disableUpload,setDisableUpload]=useState(true)
    const [refreshRequests,setRefreshRequests] = useState(false)
    // const [jobStatus, setJobStatus] = useState([])
    const queryClient=useQueryClient()
    const [replaceFilesPrompt,setReplaceFilesPrompt]=useState(false)
    const [replaceFilesFilter,setReplaceFilesFilter]=useState([])
    
    const { onClose } = useDisclosure()
    const { identity } = useContext(AppContext)
    const { workspaceMetadata } = useContext(WorkspaceContext)
    const {apiCall,createOrUpdateAsync} = useServicesContext()
    const replaceButtonRef = useRef()
    const inputRefFolder = useRef()
    const inputRefFile = useRef()
    const successCount= useRef(0)
    const failureCount= useRef(0)

    const tenantId = identity?.profile?.tenantUid
    let levelsDepth=[]
    
    if(dimensions.length>0){
      levelsDepth=dimensions[0]?.level_labels.map((e)=>{
        return e.value
      })
    }
    const totalFileSize = files.reduce((total,file)=>total+file.file.size,0)

    const formatDate = (dateString) => {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const amOrPm = hours >= 12 ? 'PM' : 'AM'
      const formattedHours = (hours % 12) || 12
      const formattedDate = `${day}-${month}-${year}`
      const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`
      return `${formattedDate} ${formattedTime}`
    }
    const originalDate = replaceFilesFilter[0]?.updatedAt
    const formattedDate = formatDate(originalDate)

    let grouped={}
    if(userAttributeData.length>0){
      grouped = userAttributeData.reduce((acc, item) => {
        if (!acc[item.key]) {
          acc[item.key] = []
        }
        acc[item.key].push(item.value)
        return acc
      }, {})
    }

    const addFiles = (files)=> {
      const newFiles = Array.from(files).map(file => ({ file }))
      setFiles(newFiles)
    } 

    const checkFilesErrors = (files) => {
      if(directory&&(files.length===0)){ 
        setFileSizeError("No files exists in the folder")
        if (!disableUpload)
          setDisableUpload(true)
        return true
      }
      if(files.length<=5){
        const supportedFormats = ['pdf', 'csv', 'xlsx', 'txt', 'docx']
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const fileExtension = file.name.split('.').pop().toLowerCase()
          if (!supportedFormats.includes(fileExtension)) {
            setFileSizeError('Only docx, pdf and text files are allowed')
             if (!disableUpload)
              setDisableUpload(true)
            setFiles([])
            return true
          }
          if(files[i].size>(10*1024*1024)){
            setFileSizeError(`${file.name} is larger than 10 MB`)
            if (!disableUpload)
              setDisableUpload(true)
            setFiles([])
            return true
          }
        }
        if (disableUpload)
          setDisableUpload(false)
        return false
      }else{
        setFileSizeError("Only 5 files can be uploaded at a time")
        if (!disableUpload)
          setDisableUpload(true)
        setFiles([])
        return true
      }    
    }

    /**-------------------------------- Event Handlers --------------------- */
    const handleDragOver = (e) => {
      e.preventDefault()
      e.stopPropagation()
    } 
    const handleDrop = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFileSizeError('')
      const items = e.dataTransfer.items
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const entry = item.webkitGetAsEntry()
        if (entry.isFile) {
          setDirectory(false)

          if (!checkFilesErrors(e.dataTransfer.files)) 
            addFiles(e.dataTransfer.files)
          
        } else if (entry.isDirectory) {
          setFileSizeError("Drag and drop files only")
        }
      }
    }

    const handleChange=(e)=>{
      e.preventDefault()
      setFileSizeError('')

      if (!checkFilesErrors(e.target.files)) {
        addFiles(e.target.files)
      } 
      
      if(e.target.files.length===1)
        inputRefFile.current.value=''
      else
        inputRefFolder.current.value=''
    }

    const handleReplaceFiles=()=>{
      setReplaceFilesPrompt(true)
    }
    const handleReplaceConfirm=()=>{
      onClose()
      setReplaceFilesPrompt(false)
      handleUpload()
    }
    const handleReplaceFilesClose=()=>{
      onClose()
      setReplaceFilesPrompt(false)
      setFiles([])
    }

    const getJobStatus = () => apiCall({
      options: {
      url: `job/itemStatus`,
      method: "GET",
      query:`$filter=(type eq 'files')&$skip=0&$top=100000&$orderby=createdAt desc`
      },
    })
    const {data:requestsData} = useQuery('requests',()=>getJobStatus(),{enabled:refreshRequests})

    useEffect(()=>{
      if(requestsData){
        setRefreshRequests(false)
        const currentJobs = requestsData.rows.filter(row => files.some(file => file.request === row.requestId))
        setFiles(prev => prev.map(file => ({...file,status:requestsData.rows.find(row=>row.requestId===file.request)})))
        if (currentJobs.length > 0 && currentJobs.every(item => item.status === 'Completed')) {
          setStopRequest(true)
        }
       }
    },[requestsData])

    const handleUpload =async () => {
      setStopRequest(false)
      // setRequests([])
      setFileSizeError("")
      setDisableUpload(true)
      setDisableButton(true)
      let hierarchicalTags=''
      let simpleTags=''
      let attributes=''
      let cumulativeData
      hierarchicalTags =selectedL6?`l1:${selectedL1}|l2:${selectedL2}|l3:${selectedL3}|l4:${selectedL4}|l5:${selectedL5}|l6:${selectedL6}|`:selectedL5?`l1:${selectedL1}|l2:${selectedL2}|l3:${selectedL3}|l4:${selectedL4}|l5:${selectedL5}|`:selectedL4?`l1:${selectedL1}|l2:${selectedL2}|l3:${selectedL3}|l4:${selectedL4}|`:selectedL3?`l1:${selectedL1}|l2:${selectedL2}|l3:${selectedL3}|`:selectedL2?`l1:${selectedL1}|l2:${selectedL2}|`:selectedL1?`l1:${selectedL1}|`:''
      simpleMetadataApi.forEach((obj) => {
        const label = obj.key
        const value = obj.value
        if (label && value && value !== ''||undefined||null ) {
          simpleTags += `${label}:${value}|`
        }
      })

      if(userAttributeData.length>0){
        userAttributeData.forEach((obj) => {
          const label = obj.key
          const value = obj.value
          if (label!=='' && value!=='') {
             attributes += `${label}:${value}|`
          }
        })
      }
      const keyValuePairs = attributes.split('|')
      const consolidatedValues = {}
      for (const pair of keyValuePairs) {
        const [key, value] = pair.split(':')
        if (consolidatedValues[key]) {
          consolidatedValues[key].push(value)
        } else {
          consolidatedValues[key] = [value]
        }
      }
  
      //consolidate values for storing in DB.
      const dbAttributes = userAttributeData.reduce((prevResult,currentItem)=>{
        const index = prevResult.findIndex(item => item.key === currentItem.key)
        if(index===-1){
          const itemToAdd = {...currentItem, id:prevResult.length+1,value:[currentItem.value]}
          return [...prevResult,itemToAdd]
        }
        let itemToUpdate = prevResult[index]
        itemToUpdate = {...itemToUpdate,value:[...itemToUpdate.value,currentItem.value]}
        prevResult[index] = itemToUpdate
        return prevResult
      },[])
  
      let userAttributes = ''
      for (const key in consolidatedValues) {
        userAttributes += `${key}:${consolidatedValues[key].join(',')}|`
      }
      if (userAttributes.endsWith('|')) {
        userAttributes = userAttributes.slice(0, -2)
      }
      cumulativeData = `${hierarchicalTags}${simpleTags}${userAttributes}team:${workspaceMetadata}`
      const levels=[{'hierarchicalTags':selectedL6?[selectedL1,selectedL2,selectedL3,selectedL4,selectedL5,selectedL6]:selectedL5?[selectedL1,selectedL2,selectedL3,selectedL4,selectedL5]:selectedL4?[selectedL1,selectedL2,selectedL3,selectedL4]:selectedL3?[selectedL1,selectedL2,selectedL3]:selectedL2?[selectedL1,selectedL2]:selectedL1?[selectedL1]:[]}]
      const simple=[{'simpleTags':simpleMetadata}]
      const tags=[...levels,...simple,{'workspace':workspaceMetadata}]
      if(files.length<=5){
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData()
            formData.append("tenantid",tenantId)
            formData.append("doc_property", cumulativeData)
            formData.append("attribute_filter",JSON.stringify(dbAttributes))
            formData.append("metadata",JSON.stringify(tags))  
            formData.append("workspace",JSON.stringify(workspaceMetadata))
            setFileSizeError("")
            let updateRecord=totalFiles.filter((item)=>{
              return item.docName===files[i].file.name
            })
            if(!directory){
              formData.append("files",files[i].file) 
            }
            if(directory){
              const folder = files[0].file.webkitRelativePath.split('/')[0]
              const relativePath = files[i].file.webkitRelativePath.replace(`${folder}/`, '')
              const modifiedFile = new File([files[i].file], relativePath, { type: files[i].file.type })
              formData.append("files", modifiedFile)
            }
            let response 
            let endpoint
            if(updateRecord.length>0){
                formData.append("uploadType","update")
                endpoint='InputFiles/update_file'
              response= await createOrUpdateAsync({url:endpoint,method:'PUT',variables:'docs'},formData)
            }
            else{
                formData.append("uploadType","create")
                endpoint='InputFiles/createInputFile'
              response =await createOrUpdateAsync({url:endpoint,method:'POST',variables:'docs'},formData)
            }
            if(response.status===200){
              successCount.current +=1
              setFiles(prev => prev.map((file) => file.file.name === response.name ? { ...file, request: response.data } : file))
            }
            else{
              failureCount.current += 1
              if(parent==='gap')
                onUploadFailure(response,files[i].file.name)
              }
          }
         if(parent==='upload' && successCount.current === 0)
            onUploadFailure()
          if((successCount.current+failureCount.current) === files.length){
            setSimpleMetadataApi([])
            setUserAttributeData([])
          }
      }
      else{
        setFileSizeError("Only 5 files can be select at a time")
        setDisableButton(false)
      }
      successCount.current = 0
      failureCount.current = 0
    }

    useEffect(()=>{
        const fetchData=()=>{
          if(files.filter(file=>file.request).length>0){
            setRefreshRequests(true)
            queryClient.invalidateQueries('requests')
          }
        }
        fetchData()
        if(!stopRequest && files.filter(file=>file.request).length>0){
          currentReference.current=setInterval(fetchData,totalFileSize < 1024*1024 ? 1000:totalFileSize < 10*1024*1024? 5000: 10000)
        }
      return ()=>{clearInterval(currentReference.current)}
    },[files.filter(file=>file.request).length])

    useEffect(()=>{
      if(stopRequest){
        onUploadSuccess(files[0]?.file.name)
        clearInterval(currentReference.current)
        setDisableButton(false)
      }
    },[stopRequest])

    // ---------------------------------Hierarchy Tab-----------------------------------------//
    const handleL1Change = (event) => {
      setSelectedL1(event.target.value)
      setSelectedL2('') 
      setSelectedL3('') 
      setSelectedL4('') 
      setSelectedL5('') 
      setSelectedL6('') 
    }

    const handleL2Change = (event) => {
      setSelectedL2(event.target.value)
      setSelectedL3('') 
      setSelectedL4('') 
      setSelectedL5('') 
      setSelectedL6('') 
    }
    const handleL3Change = (event) => {
      setSelectedL3(event.target.value)
      setSelectedL4('') 
      setSelectedL5('') 
      setSelectedL6('') 
    }
    const handleL4Change = (event) => {
      setSelectedL4(event.target.value)
      setSelectedL5('') 
      setSelectedL6('') 
    }
    const handleL5Change = (event) => {
      setSelectedL5(event.target.value)
      setSelectedL5('') 
    }
    const handleSimpleTagsChange=(event)=>{
      setSimpleMetaTags(event.target.value)
      const selectedOptionId = event.target.options[event.target.selectedIndex].getAttribute('id')
      setSelectedId(selectedOptionId)

    }
    const handleSimpleTagsValueChange=(event)=>{
      setSimpleMetaTagsValue(event.target.value)
    }
    const handleSimpleMetadata=(event)=>{
      event.preventDefault()
      let repeatedValues=[]
      repeatedValues = simpleMetadata.filter((item)=>item.key===simpleMetaTags)
      if(repeatedValues.length>0){
        const index = simpleMetadata.findIndex(tag=>tag.key===simpleMetaTags)
        const modifiedSimpleMetadata = [...simpleMetadata]
        const modifiedSimpleMetadataApi = [...simpleMetadataApi]
        modifiedSimpleMetadata[index].value=simpleMetaTagsValue
        modifiedSimpleMetadataApi[index].value=simpleMetaTagsValue
        setSimpleMetadata(modifiedSimpleMetadata)
        setSimpleMetadataApi( modifiedSimpleMetadataApi)
      }else{
        setSimpleMetadata([...simpleMetadata,{id:simpleMetadata.length+1, key:simpleMetaTags,value:simpleMetaTagsValue}])
        setSimpleMetadataApi([...simpleMetadataApi,{id:simpleMetadata.length+1, key:selectedId,value:simpleMetaTagsValue}])
      }
      setSimpleMetaTags('')
      setSimpleMetaTagsValue('')
    }
    const handleRemoveMetadata=(e)=>{
      const remainingData = simpleMetadata.filter((item)=>item.id!==e)
      const remainingDataApi = simpleMetadataApi.filter((item)=>item.id!==e)
      setSimpleMetadata(remainingData)
      setSimpleMetadataApi(remainingDataApi)
    }

    const handleUserAttributeData=(event)=>{
      event.preventDefault()
      let isMultipleValues=[]
      isMultipleValues=commonAttributes.filter((item)=>{
        return item.isMultivalue==='Yes'
      })
      const isMultipleValuesKeys=isMultipleValues.filter(item=>item.key===userAttributekey)
      if(isMultipleValuesKeys.length>0){
        const repeatedValues=userAttributeData.filter((item)=>{return item.key===userAttributekey && item.value===userAttributeValue})
        if(repeatedValues.length>0){
          userAttributekey && userAttributeValue && setUserAttributeData([...userAttributeData])
        }
        else{
          userAttributekey && userAttributeValue && setUserAttributeData([...userAttributeData,{id:userAttributeData.length+1,key:userAttributekey,value:userAttributeValue}])
        }
      }
      else{
        const repeatedvalues=userAttributeData.filter((item)=>item.key===userAttributekey)
        if(repeatedvalues.length>0){
          const updated=userAttributeData.filter((item)=>item.key!==userAttributekey)
          userAttributekey && userAttributeValue && setUserAttributeData([...updated,{id:userAttributeData.length+1,key:userAttributekey,value:userAttributeValue}])
        }
        else{
          userAttributekey && userAttributeValue && setUserAttributeData([...userAttributeData,{id:userAttributeData.length+1,key:userAttributekey,value:userAttributeValue}])
        }
      }
      setUserAttributeKey('')
      setUserAttributeValue('')
    }
    const handleAttributesKeyChange=(event)=>{
      setUserAttributeKey(event.target.value)
      // const selectedOptionId = event.target.options[event.target.selectedIndex].getAttribute('id')
    }
    const handleAttributesValueChange=(event)=>{
      setUserAttributeValue(event.target.value)
    }
    const handleRemoveUserAttributedata = (key, id,value) => {
      const updatedUserAttributeData = [...userAttributeData]
      const sameKey=updatedUserAttributeData.filter((item)=>item.key===key)
      let updated
      if(sameKey.length==1){
        updated=updatedUserAttributeData.filter(ele=>{return ele.id!==sameKey[0].id})
      }
      else if(sameKey.length>1){  
         const findId=updatedUserAttributeData.filter((item)=>{
          return item.value===value
        })
        updated=updatedUserAttributeData.filter(ele=>{return ele.id!==findId[0].id})
          }
      setUserAttributeData(updated) 
    }

    useEffect(() => {
      if(files.length>0){
        let selectedFiles=[...files]
        const filteredArray = totalFiles.filter(smallItem =>
          selectedFiles.some(largeItem => largeItem.file.name === smallItem.docName)
        )
        setReplaceFilesFilter(filteredArray)
       }
      else {
        setSelectedL1('')
        setSelectedL2('')
        setSelectedL3('')
        setSelectedL4('')
        setSelectedL5('')
        setSelectedL6('')
        setSimpleMetaTags('')
        setSimpleMetaTagsValue('')
        setSimpleMetadata([])
      }
    },[files])
// -------------------------------End Hierarchy Tab---------------------------------------//
    /** ------------------------------- End Event Handlers ------------------- */
    const isUploadDisabled = ()=>{
      return files.length === 0 ? true :false
    }
  return (
    <Stack color="#617182">
      <Flex gap={2} alignItems="center" p="10px" borderBottom={`1px solid #D1D5DB`}>
        {/* <Image src={back} alt="back" color="#2563EB" onClick={handleClose} cursor='pointer' /> */}
        <Box padding="2px" border="2px solid #2563EB" borderRadius="100px" display="inline-flex" onClick={handleClose} cursor="pointer"><ArrowBackIcon height="15px" width="15px" color="#2563EB" cursor="pointer"/></Box>
        <Heading as="h6" className="modal-title">
          Upload Documents
        </Heading>
      </Flex>
      <Flex style={{marginTop:'0px'}} justify={parent==='upload'?'space-between':'center'} pos='relative'>
       {/* <Flex minW="71px" minH="calc(100vh - 60px)" bg='#f5f5f5' position="absolute" left="0" justify='center' > */}
        {/* {parent==='upload' && 
          <Flex minW="71px" minH="calc(100vh - 60px)" bg='#f5f5f5' justify='center' >
            <Button display='flex' alignItems='center' justify='center' p='8px' borderRadius='5px' bg='rgb(210, 233, 255)'><Image width="30px" height="30px" src={computer} alt='My Device'/></Button>
          </Flex>
        } */}
        {/* <Box pt='20px' paddingStart={parent==='upload'? '40px':'0px'}> */}
        <Box p='20px' flexGrow={parent==='upload'?1:0}>
          {/* <Box justifyContent="center" display="flex" alignItems="center">
           <Image width="30px" height="30px" src={computer} alt={computer} />
            <Text as="span" ps="5px">
              My Device
            </Text>
          </Box> */}
          <Flex borderWidth="1px" borderColor="#D1D5DB" borderStyle="dashed" borderRadius="6px" p="15px" marginY="30px" flexDirection="column" alignItems="center" justify="center" onDragOver={handleDragOver} onDrop={handleDrop} minW="600px">
              <Box display="flex" flexDir="column" alignItems="center">
              <Image src={upload} alt="upload" />
              {/* {parent==='upload'&& <span>{files ? files["length"] : "No"} file{files.length>1?'s':''} selected</span>} */}
            </Box>
            <Text my="20px" fontSize="14px" color="#4B5563" fontWeight="500" lineHeight="normal">Drag and Drop File{parent==='upload'?'s':''} Here</Text>
            <Flex>
              <input ref={inputRefFile} id="file" type="file" name="files" multiple={parent==='upload'} hidden onChange={handleChange} accept=".pdf,.docx,.txt" />
              <Button data-testid='select_by_file' backgroundColor="#2563EB" fontSize="14px" color="white" fontWeight="500" lineHeight="20px" padding="9px 17px" borderRadius="6px" name="false" id="file" 
              _disabled={{
                _hover:{backgroundColor:"#E5E7EB"},
                  backgroundColor:"#E5E7EB",
                  color:"#8993A1",
                  cursor:"not-allowed"
                 }}
                 _active={{backgroundColor:"#2563EB"}}
                 _hover={{backgroundColor:  "#2563EB"}}
                  disabled={disableButton? true : false} onClick={() => {
                  setDirectory(false)
                  inputRefFile.current.click()
                }} > {parent==='upload'?'Browse for files':'Browse File'} </Button>
              &nbsp; &nbsp;
              <input ref={inputRefFolder} id="file" type="file" name="files" directory="" mozdirectory="" webkitdirectory="" hidden onChange={handleChange} accept=".pdf,.docx,.txt" />
              {parent==='upload' && <Button data-testid='select_by_folder' backgroundColor="#2563EB" fontSize="14px" color="white" fontWeight="500" lineHeight="20px" padding="9px 17px" borderRadius="6px" id="folder" name="true" disabled={disableButton? true : false} 
              _disabled={{
                _hover:{backgroundColor:"#E5E7EB"},
                  backgroundColor:"#E5E7EB",
                  color:"#8993A1",
                  cursor:"not-allowed"
                 }}
                 _active={{backgroundColor:"#2563EB"}}
                 _hover={{backgroundColor:"#2563EB"}}
                  onClick={() => {
                  setDirectory(true)
                  inputRefFolder.current.click()
                }}> Browse for folder </Button>}
            </Flex>
            <Box minH='16px' mt='5px'>
              {fileSizeError!==""?
                <Text as='span' fontSize='14px' ps='10px' color='red' textAlign='center' >{fileSizeError}</Text>
                :null}
            </Box>
          </Flex>
          {files.length > 0 &&
            <Flex direction='column' gap='13px'>
              {files.map((file, index) => {
                return (
                  <Flex key={index} p='10px 9px' justify='space-between' alignItems='center' alignSelf='stretch' borderRadius='10px' bg='#F8F9FD'>
                    <Flex gap='14px' alignItems='center'>
                      <AiOutlineFile size='24px' color='#94A3B8' />
                      {file.file.name.length > 60 ?
                        <Tooltip placement='bottom-start' bg='transparent' boxShadow='none' label={<Text bg='#F0F6FF' fontWeight={400} color='#617182' borderRadius='10px' width='max-content' p='5px 10px' fontSize='14px'>{file.file.name}</Text>}>
                            <Text fontSize='14px'>{file.file.name.slice(0, 60) + '...'}</Text>   
                        </Tooltip>
                        :
                        <Text fontSize='14px'>{file.file.name}</Text>}
                    </Flex>
                    {!file.request ?
                      <AiOutlineCloseCircle size='24px' cursor='pointer' color='#94A3B8' onClick={() => setFiles(prev=>prev.filter(item=>item.file.name!==file.file.name))} />
                      : file.status?.status !== 'Completed' ? <Spinner size='md' speed='0.65s' emptyColor='gray.200' color='blue.500' />
                        : file.status?.failedChunk?.errorData.length > 0 || file.status?.successChunk?.successData[1].pineconeData?.word_count === -1 ?
                          <Flex gap='4px' alignItems='center'>
                            <TiWarningOutline size='17px' color='#CF3626' />
                            <Text as='span' fontSize='12px' color='#CF3626'>  Unable to process this file</Text>
                          </Flex>
                      : <Flex gap='20px' alignItems='center'>
                            {/*<Text p='3px 9px' borderRadius='100px' border='1px solid #2563EB' color='#2563EB' fontSize='12px'>Imported: {file.status?.successChunk?.successData[1].pineconeData?.word_count} words</Text>*/}
                          <AiOutlineCheckCircle size='24px' color='#229F54' />
                        </Flex>
                    }
                  </Flex>
                )
              })}
            </Flex>
          }
          {(parent==='upload'|| files.length>0) &&
            <Box borderRadius='10px' mt="15px">
            <Text color='#000' fontWeight='500' >Metadata Management</Text>
            {(parent==='upload') &&
              <Box display='flex' justifyContent='space-between' py='2'>
                <Box >
                  <Text my='2' fontWeight='500' fontSize='14px' >Hierarchical Tags</Text>
                  <Stack gap='10px' >
                    <Select data-testid='level_1' fontSize='14px' bg='#fff' width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL1} value={selectedL1} onChange={handleL1Change} >
                        <option value="" >Select {levelsDepth[0]}</option>
                          {categories.length>0 && categories.map((item) => (
                            item.key === 'l1' && (
                              <option key={item.value} value={item.value}>
                                {item.value}
                              </option>
                            )
                          ))
                        }
                    </Select>
                    {levelsDepth[1] && selectedL1 && categories.filter((item)=> item.key==='l2'&& item.parent === selectedL1).length>0 && (
                      <Box>
                        <Select data-testid='level_2' fontSize='14px' bg='#fff' width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL2} onChange={handleL2Change}>
                          <option value="" >Select {levelsDepth[1]}</option>
                          {
                            categories.length>0 && categories.filter((item)=> item.key==='l2'&& item.parent === selectedL1)
                            .map((item,index)=>{
                            return (
                              <option key={index} value={item.value}>
                                {item.value}
                              </option>
                            )
                          })
                        }
                        </Select>
                      </Box>
                    )}
        
                    {levelsDepth[2] && selectedL2 && categories.filter((item)=>item.key==='l3' && item.parent === selectedL2).length>0 && (
                      <Box>
                        <Select data-testid='level_3' fontSize='14px' bg='#fff' width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL3} value={selectedL3} onChange={handleL3Change}>
                          <option value="" >Select {levelsDepth[2]}</option>
                          {
                            categories.length>0 && categories.filter((item)=>item.key==='l3' && item.parent === selectedL2)
                            .map((item,index)=>{
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              )
                            })
                          }
                        </Select>
                      </Box>
                    )}
                    {levelsDepth[3] && selectedL3 && categories.filter((item)=>item.key==='l4' && item.parent === selectedL3).length>0 && (
                      <Box>
                        <Select data-testid='level_4' fontSize='14px' bg='#fff'  width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL4} value={selectedL4} onChange={handleL4Change}>
                        <option value="" >Select {levelsDepth[3]}</option>
                          {
                            categories.length>0 && categories.filter((item)=>item.key==='l4' && item.parent === selectedL3)
                            .map((item,index)=>{
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              )
                            })
                          }
                        </Select>
                      </Box>
                    )}
                    {levelsDepth[4] && selectedL4 && categories.filter((item)=>item.key==='l5' && item.parent === selectedL4).length>0 &&(
                      <Select data-testid='level_5' fontSize='14px' bg='#fff'  width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL5}  value={selectedL5} onChange={handleL5Change}>
                        <option value="" >Select {levelsDepth[4]}</option>
                          {
                            categories.length>0 &&  categories.filter((item)=>item.key==='l5' && item.parent === selectedL4)
                            .map((item,index)=>{
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              )
                            })
                          }
                      </Select>
                    )}
                    {levelsDepth[5] && selectedL5 && categories.filter((item)=>item.key==='l6' && item.parent === selectedL5).length>0 && (
                      <Select data-testid='level_6' fontSize='14px' bg='#fff'   width='224px' textOverflow='ellipsis' overflow='hidden' title={selectedL6}  value={selectedL6} onChange={(event) => setSelectedL6(event.target.value)}>
                        <option value="" >Select {levelsDepth[5]}</option>
                        {
                          categories.length>0 &&  categories.filter((item)=>item.key==='l6' && item.parent === selectedL5 )
                          .map((item,index)=>{
                            return (
                              <option key={index} value={item.value}>
                                {item.value}
                              </option>
                            )
                          })
                        }
                      </Select>
                    )}
                  </Stack>
                </Box>
                <Box>
                  <Text my='2' textAlign='start' fontWeight='500' fontSize='14px' width='calc(100% - 50px)' >Simple Tags</Text>
                  <form onSubmit={handleSimpleMetadata} display='flex'  >
                    <Box display='flex' gap='10px' >
                      <Select data-testid='simple_meta_tags_key' fontSize='14px' bg='#fff'  width='165px' textOverflow='ellipsis' overflow='hidden' value={simpleMetaTags} placeholder="Select Metadata" onChange={handleSimpleTagsChange} >
                      {
                        simpleTags.length>0 && simpleTags.filter((item)=>{return item.parent===null})
                          .map((data,index)=>{
                            return(
                              <option key={index} value={data.value} id={data.key}>{data.value}</option>
                              )
                        })
                      }
                      </Select>
                      <Select data-testid='simple_meta_tags_val' fontSize='14px' bg='#fff'  width='165px' textOverflow='ellipsis' overflow='hidden' value={simpleMetaTagsValue} placeholder="Select Value" onChange={handleSimpleTagsValueChange} >
                      {
                        simpleMetaTags && simpleTags.length>0 && simpleTags.filter((item)=>{return item.parent!==null && item.parent === simpleMetaTags})
                        .map((data,index)=>{
                          return(
                            <option key={index} value={data.value} >{data.value}</option>
                            )
                        })
                      }
                      </Select>
                      <Button 
                       data-testid='simple_meta_tags_add'
                       disabled={simpleMetaTags && simpleMetaTagsValue? false : true}
                       display='flex' p='10px' alignItems='center' justifyContent='center' as='span'
                       type='submit'
                       border={`1px solid #D1D5DB`}
                       borderRadius='6px'
                       background="white"
                       _hover={{
                        backgroundColor: "white",
                       
                      }}
                       color="#6B7280"
                       cursor="pointer"
                       fontSize="14px"
                       fontWeight="500"
                       lineHeight="normal"
                       _disabled={{
                        cursor:"not-allowed",
                        background:"#E5E7EB",
                        border:"1px solid #E5E7EB",
                        color:"#8993A1",
                        _hover:{
                          backgroundColor: "#E5E7EB",
                          color:"#8993A1",
                          cursor:"not-allowed"
                        }
                       }}
                       onClick={handleSimpleMetadata}>Add </Button>
                    </Box>
                  </form>
                  <Box my='2' >
                    <Stack>
                      {
                        simpleMetadata.map((item,index)=>{
                          return(
                          <Box display='flex' alignItems='center' flexWrap='wrap' key={index} my='1' >
                            <Text fontSize='14px' >{item.key} : {item.value}</Text>
                            <Text data-testid='simple_meta_tags_remove' display='flex' alignItems='center' mx='1' cursor='pointer' onClick={()=>{handleRemoveMetadata(item.id)}} ><GrClose  size='10px' /></Text>
                          </Box>
                            )
                        })
                      }
                    </Stack>
                  </Box>
                </Box>
              </Box>
            }
            <Box>
              <Text my='2' fontWeight='500' fontSize='14px' >Audience</Text>
              <form onSubmit={handleUserAttributeData} display='flex'  >
                <Box display='flex' gap='10px' >
                  <Select data-testid='user_attribute_key' fontSize='14px' bg='#fff'  width='165px' textOverflow='ellipsis' overflow='hidden' value={userAttributekey} placeholder="Select Attribute" onChange={handleAttributesKeyChange} >
                    {
                      commonAttributes.length!==0 && commonAttributes.map((data,index)=>{
                        return(
                          <option key={index} value={data.key} id={data.id}>{data.key}</option>
                          )
                        })
                    }
                  </Select>
                  {
                    userAttributekey!=="" && commonAttributes.length>0 && commonAttributes.find((item)=>{return item.key===userAttributekey})?.validationRule.field !== 'Free Text' ?
                  <Select data-testid='user_attribute_value' fontSize='14px' bg='#fff'  width='165px' textOverflow='ellipsis' overflow='hidden' value={userAttributeValue} placeholder="Select Value" onChange={handleAttributesValueChange} >
                  {
                    userAttributekey!=="" && commonAttributes.length>0 && commonAttributes.find((item)=>{return item.key===userAttributekey})?.valueLabels
                    .map((data,index)=>{
                      return(
                        <option key={index} value={data} >{data}</option>
                        )  
                    })
                  }
                </Select>
                :
                <Input border="1px solid green" width='165px' type='text' placeHolder='Enter Attribute' onChange={handleAttributesValueChange} value={userAttributeValue} />
                 }
                  <Button data-testid='user_attribute_add'
                    disabled={commonAttributes && userAttributekey && userAttributeValue? false : true}
                    display='flex'
                    p='10px'
                    alignItems='center'
                    justifyContent='center' 
                    as='span' type='submit'
                    border={`1px solid #D1D5DB`}
                    borderRadius='6px'
                    cursor="pointer"
                    background="white"
                    _hover={{background:"white"
                    }}
                    color="#6B7280"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="normal"
                    _disabled={{
                      cursor:"not-allowed",
                      background:"#E5E7EB",
                      border:"1px solid #E5E7EB",
                      color:"#8993A1",
                      _hover:{
                        backgroundColor: "#E5E7EB",
                        color:"#8993A1",
                        cursor:"not-allowed"
                      }
                     }}
                    
                    onClick={handleUserAttributeData}>Add</Button>
                </Box>
              </form>
              <Box my='2' >
              {userAttributeData.length!==0 &&
              Object.keys(grouped).map((key, index) => {
                return (
                  <Box key={index}>
                  <Box display='flex' alignItems='center' flexWrap='wrap'  my='2' p='5px 5px 5px 10px' width='fit-content'  border={'2px solid #1e90ff'} borderRadius='5px' >
                    <Text fontSize='16px' color='#000' me='10px' fontWeight='500' >{key}</Text>
                    {grouped[key].map((item,indexVal) => (
                      <Box key={indexVal} display='flex' alignItems='center'>
                      <Box display='flex' alignItems='center' ms={indexVal===0?'0':'3px'} backgroundColor='#e0e7ff' borderRadius='5px' p='5px 10px' >
                        <Text fontSize='16px' color='#3730a3' >{item}</Text>
                        <Text data-testid='attribute_remove' display='flex' alignItems='center' ms='2' cursor='pointer' color='#94a3b8' onClick={() => { handleRemoveUserAttributedata(key,index,item) }}>
                          <GrClose style={{color:"#94a3b8",fontSize:"14px",fontWeight:"500"}} />
                        </Text>
                      </Box>
                      {indexVal < grouped[key].length - 1 && (
                        <Text fontSize='16px' color='#000' ps='3px' fontWeight='500'>
                          OR
                        </Text>
                      )}
                      </Box>
                    ))}
                    </Box>
                    {index < Object.keys(grouped).length - 1 && (
                      <Text fontSize='16px' color='#000' my='5px' fontWeight='500'>
                        AND
                      </Text>
                    )}
                  </Box>
                )
              })
              }
            </Box>
            </Box>
            </Box>
          }
          <HStack justify="flex-end" paddingTop="15%">
            <Button className="secondaryButton" onClick={handleClose}>
              Cancel

            </Button>
           {(parent==='upload' || files.length>0) &&
            <Flex gap={2}>
              {(parent==='gap' && isUploadDisabled()) && <Button variant="outline" color="#6B7280" onClick={handleClose}>Cancel</Button>}
                <Button data-testid='upload_docs'
                 disabled={parent==='gap'?isUploadDisabled():disableUpload} 
                 type="submit"
                 color={files.length>0 ? "white" : parent==='upload'?"white":'#8993A1'}
                 bg={files.length>0 ? "#2563EB" : parent==='upload'? "#2563EB" :'#E5E7EB'} 
                 cursor={files.length>0?"pointer":parent==='upload'?'pointer':'not-allowed'}
                 _hover={{
                  backgroundColor:`${files.length>0?"#0047E3":""}`,
                 }}
                 _disabled={{
                  backgroundColor:"#E5E7EB",
                  color:"#8993A1",
                  cursor:"not-allowed"
                 }}
                 fontSize='14px'
                 fontWeight="500"
                 lineHeight="20px"
                 borderRadius="6px"
                 onClick={replaceFilesFilter.length>0 ? handleReplaceFiles : handleUpload}>
                {parent==='upload'?'Upload':'Fix gap with uploaded document'} </Button>
            </Flex>
          } 
          </HStack>
          <Modal isOpen={replaceFilesPrompt} isCentered onClose={handleReplaceFilesClose} finalFocusRef={replaceButtonRef}>
            <ModalOverlay />
            <ModalContent p="0px">
              <ModalHeader p="0px">
                <Box display="flex" padding="15px 15px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #D1D5DB">
                  <Text fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal">{replaceFilesFilter.length>1 ? `Files already exists!`:`File already exists!`}</Text>
                <ModalCloseButton color="#94A3B8" />
                </Box>
                
                </ModalHeader>
              
              <ModalBody p="0px">
                <Box padding="15px">
                {
                  replaceFilesFilter.length===1 ?<Text fontSize="15px" color="#6B7280" fontWeight="400" lineHeight="normal">{replaceFilesFilter[0].docName} was uploaded by {replaceFilesFilter[0]?.updatedBy} on {formattedDate} </Text> :
                  <Text>The files were already uploaded</Text>
                }
                <Text fontSize="15px" color="#6B7280" fontWeight="400" lineHeight="normal" my='2' >{replaceFilesFilter.length>1 ? `Would you like to replace the files?`:`Would you like to replace the file?`}</Text>

                </Box>
              </ModalBody>
              <ModalFooter p="10px 15px">
                <Button data-testid='replace_cancel' className="secondaryButton" colorScheme='blue' mr={3} onClick={handleReplaceFilesClose}>
                  Cancel
                </Button>
                <Button data-testid='replace_confirm' className="primary-button"  onClick={handleReplaceConfirm} >Yes</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Flex>
    </Stack>
  )
}

export default MyDevicePicker