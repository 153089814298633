import React from "react"
import {SharepointContextProvider} from 'app/components/Sharepoint/context/SharepointContext'
import SharepointContainer from "app/components/Sharepoint/SharepointContainer"

const Sharepoint = () => {

    return (
      <React.Fragment>
        <SharepointContextProvider>
          <SharepointContainer />
        </SharepointContextProvider>
      </React.Fragment>
    )
}

export default Sharepoint