import React from "react"
import KnowledgeGapsContainer from "app/components/KnowledgeGaps/KnowledgeGapsContainer"
import {KnowledgeGapsProvider} from 'app/components/KnowledgeGaps/context/KnowledgeGapsContext'

const Gaps = () => {
    return (
      <React.Fragment>
        <KnowledgeGapsProvider >
            <KnowledgeGapsContainer/>
        </KnowledgeGapsProvider>
      </React.Fragment>
    )
}

export default Gaps